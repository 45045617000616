import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const LegalComplianceChart = ({ data }) => {
  const maxValues = {
    legal_compliance: 10,
    water_management: 10,
    wastewater_management: 5,
    waste_management: 5,
    energy_management: 10,
  };

  const extractScores = (criteriaData = {}) => {
    const subCriteria = criteriaData?.environment?.subCriteria || [];

    const findScore = (name) => {
      const item = subCriteria.find((c) => c.name === name);
      return item ? item.score : 0;
    };

    return [
      {
        category: "Legal Compliance",
        avgValue: findScore("legal_compliance"),
        maxValue: maxValues.legal_compliance,
        remainingToMax: Math.max(
          0,
          maxValues.legal_compliance - findScore("legal_compliance")
        ),
      },
      {
        category: "Water Management",
        avgValue: findScore("water_management"),
        maxValue: maxValues.water_management,
        remainingToMax: Math.max(
          0,
          maxValues.water_management - findScore("water_management")
        ),
      },
      {
        category: "Wastewater Management",
        avgValue: findScore("wastewater_management"),
        maxValue: maxValues.wastewater_management,
        remainingToMax: Math.max(
          0,
          maxValues.wastewater_management - findScore("wastewater_management")
        ),
      },
      {
        category: "Waste Management",
        avgValue: findScore("waste_management"),
        maxValue: maxValues.waste_management,
        remainingToMax: Math.max(
          0,
          maxValues.waste_management - findScore("waste_management")
        ),
      },
      {
        category: "Energy Management",
        avgValue: findScore("energy_management"),
        maxValue: maxValues.energy_management,
        remainingToMax: Math.max(
          0,
          maxValues.energy_management - findScore("energy_management")
        ),
      },
    ];
  };

  const salesData = extractScores(data.sales_criteria);
  const serviceData = extractScores(data.service_criteria);

  const getYAxisDomain = () => [0, 10];

  const CustomLegend = ({ payload }) => (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            marginRight: "10px",
          }}
        >
          <span
            style={{
              backgroundColor: entry.color,
              marginRight: 4,
              fontSize: "20px",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span style={{ color: "#555", fontSize: "14px" }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );

  const renderChart = (chartData, title) => (
    <div style={{ width: "48%", height: 400 }}>
      <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
        {title}
      </h3>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          barSize={60}
          margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="category"
            tick={{ fontSize: 12 }}
            angle={-30}
            textAnchor="end"
            interval={0}
            dy={10}
            height={110}
          />
          <YAxis domain={getYAxisDomain()} />
          <Tooltip
            formatter={(value, name, props) => {
              const { payload } = props;
              if (name === "Achieved") {
                return [
                  `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                  name,
                ];
              }
              return null;
            }}
          />
          <Legend content={CustomLegend} />
          <Bar
            dataKey="avgValue"
            stackId="progress"
            fill="#2C7C69"
            name="Achieved"
            label={{
              position: "insideTop",
              fill: "#fff",
              formatter: (value) => (value !== 0 ? value.toFixed(1) : ""),
            }}
          />
          <Bar
            dataKey="remainingToMax"
            stackId="progress"
            fill="#7FC8A9"
            name="Remaining"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      {renderChart(salesData, "Sales - Environment")}
      {renderChart(serviceData, "Service - Environment")}
    </div>
  );
};

export default LegalComplianceChart;
