import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

// ESGGScores Component
const ESGGScores = ({ data }) => {
  const aggregateData = (filteredData) => {
    const totals = {
      environment: 0,
      social: 0,
      governance: 0,
      general: 0,
    };

    const maxValues = {
      Environment: 40,
      Social: 40,
      Governance: 15,
      General: 5,
    };

    let count = filteredData.length || 1;

    filteredData.forEach((entry) => {
      totals.environment += isNaN(entry.environment) ? 0 : entry.environment;
      totals.social += isNaN(entry.social) ? 0 : entry.social;
      totals.governance += isNaN(entry.governance) ? 0 : entry.governance;
      totals.general += isNaN(entry.general) ? 0 : entry.general;
    });

    const avgEnvironment = totals.environment / count;
    const avgSocial = totals.social / count;
    const avgGovernance = totals.governance / count;
    const avgGeneral = totals.general / count;

    return [
      {
        category: "Environment",
        avgValue: avgEnvironment,
        maxValue: maxValues.Environment,
        remainingToMax: maxValues.Environment - avgEnvironment,
        achievedColor: "#2C7C69",
        maxColor: "#7FC8A9",
      },
      {
        category: "Social",
        avgValue: avgSocial,
        maxValue: maxValues.Social,
        remainingToMax: maxValues.Social - avgSocial,
        achievedColor: "#FC6E51",
        maxColor: "#FEB2A8",
      },
      {
        category: "Governance",
        avgValue: avgGovernance,
        maxValue: maxValues.Governance,
        remainingToMax: maxValues.Governance - avgGovernance,
        achievedColor: "#4A90E2",
        maxColor: "#AFCBFF",
      },
      {
        category: "General",
        avgValue: avgGeneral,
        maxValue: maxValues.General,
        remainingToMax: maxValues.General - avgGeneral,
        achievedColor: "#b0b0b0",
        maxColor: "#e3e3e3",
      },
    ];
  };

  const processedData = aggregateData(data);
  const maxYAxis = Math.max(...processedData.map((d) => d.maxValue)) + 5;

  return (
    <div>
      <div style={{ width: "100%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          ESG Score
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={processedData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, maxYAxis]} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Achieved") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    "Score",
                  ];
                }
                return [null, null];
              }}
            />
            <Bar
              dataKey="avgValue"
              stackId="score"
              name="Achieved"
              label={{
                position: "insideBottom",
                fill: "#ffffff",
                formatter: (value) => value.toFixed(1),
              }}
            >
              {processedData.map((entry, index) => (
                <Cell key={`cell-achieved-${index}`} fill={entry.achievedColor} />
              ))}
            </Bar>
            <Bar dataKey="remainingToMax" stackId="score" name="Maximum">
              {processedData.map((entry, index) => (
                <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

// Data Preparation Function
const prepareESGData = (criteria, categoryLabel) => {
  return {
    category: categoryLabel,
    environment: criteria.environment.summary_score || 0,
    social: criteria.social.summary_score || 0,
    governance: criteria.governance.summary_score || 0,
    general: criteria.general.summary_score || 0,
  };
};

// Parent Component
const ESGScoreContainer = ({data}) => {
  const rawData = {
    sales_criteria: {
      environment: { summary_score: 14 },
      social: { summary_score: 20.5 },
      governance: { summary_score: 14 },
      general: { summary_score: 3 },
    },
    service_criteria: {
      environment: { summary_score: 9 },
      social: { summary_score: 18.5 },
      governance: { summary_score: 11 },
      general: { summary_score: 2 },
    },
  };

  const dataForChart = [
    prepareESGData(data.sales_criteria, "Sales"),
    prepareESGData(data.service_criteria, "Service"),
  ];

  return <ESGGScores data={dataForChart} />;
};

export default ESGScoreContainer;
