import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import APIServices from "../../service/APIService";
import Swal from "sweetalert2";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Category } from "@mui/icons-material";

const FeedbackSystem = () => {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [category, setCategory] = useState(null)
    const [type, setType] = useState(null)

    const [priority, setPriority] = useState(null)

    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const vendorCode = useSelector((state) => state.user.currentVendorCode);
    const priority_list = [{ name: 'Low', id: 1 }, { name: 'Medium', id: 2 }, { name: 'High', id: 3 }]
    const category_list = [{ name: 'Techincal', id: 1 }, { name: 'Sustainability subject related issue', id: 2 }]
    const type_list = [
        { name: "Login & Access Issue", id: 1, category: 1 },
        { name: "Account locked", id: 2, category: 1 },
        { name: "Submission & Data Entry Issues", id: 3, category: 1 },
        { name: "Unable to submit data", id: 4, category: 1 },
        { name: "System crashes during data upload", id: 5, category: 1 },
        { name: "File upload errors (e.g., invalid format, file size restrictions)", id: 6, category: 1 },
        { name: "Form or Assessment not loading / crashes", id: 7, category: 1 },
        { name: "Unable to save responses", id: 8, category: 1 },
        { name: "UI/UX & Navigation Issues", id: 9, category: 1 },
        { name: "Drop-downs, buttons, or input fields not functioning", id: 10, category: 1 },
        { name: "Other Technical Issues", id: 11, category: 1 },
        { name: "Any other bug or system-related issue", id: 12, category: 1 },
        { name: "Difficulty in mapping sustainability data to specific frameworks (GRI, BRSR, CDP, etc.)", id: 13, category: 2 },
        { name: "Incorrect calculations for emissions, water usage, or energy consumption", id: 14, category: 2 },
        { name: "Misalignment of submitted data with reporting requirements", id: 15, category: 2 },
        { name: "Uncertainty in interpreting framework-specific guidelines (e.g., GRI vs. CSRD vs. BRSR)", id: 16, category: 2 },
        { name: "Difficulty linking evidence to specific ESG indicators", id: 17, category: 2 },
        { name: "Incorrect role assignment leading to data access restrictions", id: 18, category: 2 },
        { name: "Lack of training on sustainability reporting and frameworks", id: 19, category: 2 },
        { name: "Requests for additional sustainability metrics to align with emerging frameworks", id: 20, category: 2 }
      
    ]
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle email submission logic
        console.log("Subject:", subject, type, priority);
        console.log("Message:", message, checkEditorValue(message));
        if (!checkEditorValue(message) && subject.trim().length && type && priority) {
            APIServices.post(API.Ticketing_UP(admin_data.id), { raised_by: login_data.id, message, subject, priority, category: type, userType: login_data.role === 'clientadmin' ? 1 : login_data.role === 'clientuser' ? 2 : login_data.role === 'clientdealer' ? 3 : 0 }).catch((e) => { console.log(e) })
            APIServices.post(API.SendMail, { to: 'support@eisqrsolutionsprivatelimited.zohodesk.in', subject:subject+' - TVS' , body: `<div> <p>Enterprise Name: <span style="font-weight:600;"> ${admin_data?.information?.companyname}</span> </p> <p>User Email Id: <span style="font-weight:600;"> ${login_data.email}</span> </p> ${login_data.role === 'clientsupplier' ? `<p>Vendor Name: <span style="font-weight:600;"> ${vendorCode.supplierName}</span></p><p>Vendor Code: <span style="font-weight:600;"> ${vendorCode.code}</span> </p>` : ''} ${login_data.role === 'clientdealer' ? `<p>Vendor Name: <span style="font-weight:600;"> ${vendorCode.dealerName}</span></p><p>Vendor Code: <span style="font-weight:600;"> ${vendorCode.code}</span> </p>` : ''}<p>Issue Category: <span style="font-weight:600;">${category_list.find(x=>x.id === category)?.name }</span> </p> <p>Issue Type: <span style="font-weight:600;">${type_list.find(x => x.id === type)?.name}</span> </p> <p  >Priority: <span style="color:${priority === 1 ? 'yellow' : priority === 2 ? 'green' : 'red'}; font-weight:600;">${priority_list.find(x => x.id === priority)?.name}</span> </p>   <p>${message}</p><div>` }).then((res) => {
                setMessage("");
                setSubject("");
                setPriority(null)
                setType(null)
                setCategory(null)

                Swal.fire({
                    title: "Ticket raised successfully, will get back to you soon",

                    confirmButtonText: 'Close Popup',
                    allowOutsideClick: false,
                })

            }).catch((err) => {
                console.log(err)
                Swal.fire({
                    title: "Something went wrong",

                    confirmButtonText: 'Close Popup',
                    allowOutsideClick: false,
                })

            })

        }

    };
    function checkEditorValue(htmlString) {
        if (!htmlString) {
            return true
        }
        const regex = /^<p>\s*<\/p>$/;
        return regex.test(htmlString);
    }
    return (
        <div className="flex justify-center" style={{ flexDirection: "column" }}>
            {admin_data.id === 289 ?
                <>
                    <div
                        className="flex col-12 flex-start"
                        style={{ flexDirection: "column" }}
                    >
                        <span className="text-big-one">
                            NAVIGOS Ticketing System
                        </span>
                        <p className="ml-1">We value your feedback and are here to help with any issues you’re facing! Share the issues you’ve encountered, and feel free to include any details that might help us understand better. Your input is important, and we’ll ensure to follow up with you promptly. Thank you for helping us improve ! </p>
                        {/* <Tag className="ml-3 p-tag-blue">

              {login_data.role === "clientadmin"
                ? "Enterprise Admin"
                : getRoles(login_data.information)}
            </Tag> */}
                    </div>
                    <div className="p-2">
                        <form
                            onSubmit={handleSubmit}
                            className="p-fluid rounded-lg"
                            style={{ width: "100%" }}
                        >
                            <div className="field col-4 p-0">
                                <label htmlFor="subject" className="block text-lg font-medium mb-2">
                                    Choose Issue Category<span className="ml-1 mandatory">*</span>
                                </label>
                                <Dropdown
                                    id="type"
                                    options={category_list}
                                    onChange={(e) => { setCategory(e.value); setType(null) }}
                                    value={category}
                                    optionValue="id"
                                    optionLabel="name"
                                    placeholder="Select Issue Category"

                                />
                            </div>
                            <div className="field col-4 p-0">
                                <label htmlFor="subject" className="block text-lg font-medium mb-2">
                                    Choose Issue Type <span className="ml-1 mandatory">*</span>
                                </label>
                                <Dropdown
                                    id="category"
                                    options={type_list.filter(x=>x.category === category)}
                                    onChange={(e) => { setType(e.value) }}
                                    value={type}
                                    optionValue="id"
                                    optionLabel="name"
                                    placeholder="Select Issue Type"

                                />
                            </div>
                            <div className="field  col-4 p-0">
                                <label htmlFor="subject" className="block text-lg font-medium mb-2">
                                    Choose Priority <span className="ml-1 mandatory">*</span>
                                </label>
                                <Dropdown
                                    id="priority"
                                    options={priority_list}
                                    onChange={(e) => { setPriority(e.value) }}
                                    placeholder="Select priority"
                                    value={priority}
                                    optionValue="id"
                                    optionLabel="name"

                                />
                            </div>
                            <div className="field">
                                <label htmlFor="subject" className="block text-lg font-medium mb-2">
                                    Subject <span className="ml-1 mandatory">*</span>
                                </label>
                                <InputText
                                    id="subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    placeholder="Enter subject"

                                />
                            </div>

                            <div className="field mt-4">
                                <label htmlFor="message" className="block fs-14 text-lg  font-medium mb-2">
                                    Message <span className="ml-1 mandatory">*</span>
                                </label>
                                <Editor
                                    id="message"
                                    value={message}
                                    style={{ resize: "none", height: 300, overflowY: 'scroll' }}
                                    onTextChange={(e) => setMessage(e.htmlValue)}
                                    rows={5}
                                    placeholder="Enter your message"
                                    className="html-editor"
                                />
                            </div>

                            <div className="col-12 flex justify-content-end ">
                                <Button
                                    disabled={!subject.length || checkEditorValue(message) || !category || !type || !priority}
                                    type="submit"
                                    label="Raise Ticket"
                                    icon="pi pi-send"
                                    style={{ width: 'auto', padding: 10 }}
                                />
                            </div>
                        </form>
                    </div>  </> :
                'You have no access to view this page'
            }

        </div>
    );
};

export default FeedbackSystem;
