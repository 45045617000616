import React, { useEffect, useState, useRef } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Editor } from 'primereact/editor';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import 'primeflex/primeflex.css';
import { TabMenu } from "primereact/tabmenu";
import { Tab, Row, Col, Nav, Table, Form, Modal } from 'react-bootstrap';
import SupplierPanel from "../SupplierScreen/SupplierPanel";
import { SupplierMSIDashboard } from "./SupplierMSIDashboard";
import { getMonthsBetweenNew, getRPTextFormat } from "../../../../components/BGHF/helper";
import { Badge } from "primereact/badge";
import { Tag } from "primereact/tag";
import { Accordion, AccordionTab } from "primereact/accordion";
import SupplierReport from "./SupplierReport";
import AuditPanel from "../Auditor/AuditPanel";
import TakeActionSupplier from "./TakeActionSupplier";
import TakeActionDealer from "./TakeActionDealer";

const SupplierHomeTVS = () => {

    const [assignments, setAssignments] = useState([])
    const [data, setData] = useState([])
    const [submissiondata, setSubmissionData] = useState([])
    const [submittedAssessment, setSubmittedAssessment] = useState([])
    const [actionlist, setActionList] = useState([])
    const [activeAss, setActiveAss] = useState(null)
    const [asssrflist, setAssSrfList] = useState([])
    const [activeindex, setActiveIndex] = useState(0)
    const [activeindex2, setActiveIndex2] = useState('My Actions')
    const [reportdialog, setReportDialog] = useState(false)
    const [activeform, setActiveForm] = useState({})
    const [selectedGmsOne, setSelectedGmsOne] = useState('GMS1'); // Default to GMS1
    const [currentFormData, setCurrentFormData] = useState({
        status: null,
        targetDate: null
    });
    const [takeActionData, setTakeActionData] = useState([])
    const [takeActionModal, setTakeActionModal] = useState(false)

    const [takeDealerModal, setTakeDealerModal] = useState(false)
    const [takeActionDealer, setTakeActionDealer] = useState([])

    const { fymonth } = useSelector((state) => state.user.fyStartMonth);

    // State for GMS Two nested tabs
    const [selectedGmsTwo, setSelectedGmsTwo] = useState('2.1'); // Default to 2.1

    // Read-only flag
    const [readOnly, setReadOnly] = useState(false); // Change to true if necessary

    const [show, setShow] = useState(false)
    const userList = useSelector(state => state.userlist.userList)
    const supplierList = useSelector(state => state.userlist.supplierList)

    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);

    const vendorCode = useSelector((state) => state.user.currentVendorCode);

    const [assobj, setAssObj] = useState({ supplierId: null, auditor_ids: [], assessmentStartDate: null, assessmentEndDate: null, auditStartDate: null, auditEndDate: null, srfId: null })
    const [asssupplierobj, setAssSupplierObj] = useState({ supplierId: null })


    const [supplierlist, setSupplierList] = useState([])
    const [auditorlist, setAuditorList] = useState([])
    const [adddialog, setAddDialog] = useState(false)
    const [responseDialog, setResponseDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [supplierdialog, setSupplierDialog] = useState(false)
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const supplier = [
        {
            id: '240826-0058',
            supplierName: "Supplier A",
            questionary: "Questionary A",
            assessmentStartDate: "2024-10-01",
            assessmentEndDate: "2024-12-01",
            status: "Pending",
            score: 85
        },
        {
            id: '240826-0058',
            supplierName: "Supplier B",
            questionary: "Questionary B",
            assessmentStartDate: "2024-09-15",
            assessmentEndDate: "2024-11-15",
            status: "Completed",
            score: 90
        },
        {
            id: '240826-0058',
            supplierName: "Supplier C",
            questionary: "Questionary C",
            assessmentStartDate: "2024-08-20",
            assessmentEndDate: "2024-10-20",
            status: "In Progress",
            score: 75
        },
    ];
    const categoryOptions = [
        { id: 1, label: "Environment" },
        { id: 2, label: "Social" },
        { id: 3, label: "Governance" },
    ];

    const priorityOptions = [
        { id: 3, label: "High" },
        { id: 2, label: "Medium" },
        { id: 1, label: "Low" },
    ]
    const statusOptions = [
        { id: 1, label: "Not Started" },
        { id: 2, label: "In Progress" },
        { id: 3, label: "On Hold" },
        { id: 4, label: "Completed" },

    ]
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [actionModal, setActionModal] = useState(false)
    const [actionModal2, setActionModal2] = useState(false)

    const [actionmodal, showActionModal] = useState(false)

    const viewAudit = (rowData) => {
        setSelectedAudit(rowData)
        setActionModal(true)
    }

    const forceUpdate = useForceUpdate()


    useEffect(() => {
        updateActions()
    }, [])
    useEffect(() => {
        const handleFocus = () => {

            if (localStorage.getItem("reloadsaio")) {

                updateActions()

                // Clear the flag to avoid repeated execution
                localStorage.removeItem("reloadsaio");
            }
        };

        // Add event listener when component mounts
        window.addEventListener("focus", handleFocus);

        // Cleanup event listener when component unmounts
        return () => {
            window.removeEventListener("focus", handleFocus);
        };
    }, []);
    const getActionId = (item) => {
        if (item.categoryOfFinding === 1) {
            return 'GP' + item.id
        } else if (item.categoryOfFinding === 2) {
            return 'OFI' + item.id
        } else if (item.categoryOfFinding === 3) {
            if (item.nonComplianceType === 1) {
                return 'NCR' + item.id + ' (Major)'
            } else if (item.nonComplianceType === 2) {
                return 'NCR' + item.id + ' (Minor)'
            } else if (item.nonComplianceType === 3) {
                return 'NC' + item.id + ' (Major)'
            }
        }
    }
    const updateActions = () => {
        setTakeActionModal(false)
        let locstring = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        let uriString = {

            "where": {
                "supplierId": login_data.id, vendorCode: vendorCode.code
            },
            "include": [
                {
                    "relation": "supplierAssignmentSubmission"
                },
                {
                    "relation": "auditorAssignmentSubmission", scope: {
                        fields: { type: true, approved_on: true, approverComments: true, rejected_on: true, auditorMSIScore: true, submitted_on: true, modified_on: true, id: true },
                    }
                },
                {

                    "relation": "vendor"
                },
                {
                    "relation": "supplierActions",
                    scope: { include: ['supplierActionHistories'] }
                },
                {
                    "relation": "srf"
                }
            ]

        };

        const promise0 = []
        const promise1 = APIServices.get(API.SupplierAssessmentAss_Up(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        const promise3 = APIServices.get(API.SRF)


        const promise4 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise5 = APIServices.get(API.SRF_Entity_User_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify({ include: ['srf'] }))}`)
        const promise6 = APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(locstring))}`
        );
        const promise7 = APIServices.get(API.ValueChainSubmission_UP(admin_data.id))

        const promise8 = APIServices.get(API.Actions)

        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8]).then((values) => {


            let action = values[8].data.filter(item =>
                item.vendorId === vendorCode?.id?.toString() &&
                item.status === 'Initiated' &&
                ['Checklist Submission', 'Checklist Submission Returned'].includes(item.actionType)
            );



            console.log(action)
            let srf_list = values[3].data
            let actions = values[1].data.filter(x => x.supplierActions && x?.auditorAssignmentSubmission?.type === 2).flatMap(x => x.supplierActions.map(y => ({ ...y, date: x?.auditorAssignmentSubmission?.modified_on, msi: getMSIIDByAssignment(x) }))).filter(x => x.type >= 1 && x.categoryOfFinding === 3).map(x => ({ ...x, statusCode: x.type === 1 ? 7 : 0, title: (x.type === 1 ? 'Action for ' : 'View ') + (x.msi + ' ' + x.actionId), actionId: x.msi + ' ' + x.actionId, currentStatus: x.type !== 1 ? 'Submitted' : 'Not Started', dueDate: DateTime.fromISO(x.date, { zone: 'utc' }).toLocal().plus({ days: 2 }).toFormat('dd-LLL-yyyy'), formType: 5, timeLine: x.type !== 1 ? 'Submitted' : getOverdueDays(DateTime.fromISO(x.date, { zone: 'utc' }).toLocal().toFormat('LLL-yyyy')) >= 0 ? 'Overdue' : 'Due Soon' }))
            console.log(actions)
            const shapedSite = values[6].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setActionList(values[1].data.filter(i => i.supplierActions).flatMap(i => i.supplierActions))
            if (values[2].data.length && values[2].data[0].cf_ids && values[2].data[0].cf_ids.length) {
                let assSrfList = values[2].data[0].cf_ids.filter(i => srf_list.map(x => x.id).includes(i)).map(i => srf_list.find(x => x.id === i))
                setAssSrfList(assSrfList)
                console.log(assSrfList)
                setSupplierList(supplierList.filter(i => i.role === 'clientsupplier'))
                setAuditorList(userList.filter(i => values[4].data.some(x => x.user_id === i.id && x.roles && x.roles.includes(17))))

            }
            let assignment = [values[5].data.filter(i => i.srfId === 86 && i.type === 1 && i.reporter_ids.includes(vendorCode.id)).length ? {
                formType: 1, coverage: 'Corporate', srfId: 86
                , name: "Add / Update LCA Data Collection Form for parts supplied to TVS Motors", title: "Add / Update LCA Data Collection Form for parts supplied to TVS Motors", dueDate: null, currentStatus: 'NA', timeLine: "Due Now", statusCode: 6
            } : null].filter(x => x)

            for (const item of values[5].data.filter(i => i.srfId !== 86 && i.reporter_ids.includes(vendorCode.id))) {
                console.log(item)
                let months = getMonthsBetweenNew(
                    item.start_date,
                    item.end_date,
                    item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency,
                    0,
                    fymonth
                );

                for (const item2 of months) {

                    let found = values[7].data.find(x => x.srfId === item.srfId && x.entityUserAssId === item.id && x.vendorId === vendorCode.id &&
                        x.entityAssId === item.entityAssId &&
                        x.tier0_id === item.tier0_id &&
                        x.tier1_id === item.tier1_id &&
                        x.tier2_id === item.tier2_id &&
                        x.tier3_id === item.tier3_id && getRPTextFormat(x.reporting_period) === item2)
                    console.log(found, item, item2, vendorCode, values[7].data)
                    if (found) {
                        let type = found.type;
                        let reject = found.reject;
                        let status =
                            type === 0 && (!reject)
                                ? 0
                                : type === 0 && (reject === 1 || reject === 2)
                                    ? 1
                                    : type === 1 && reject === 1
                                        ? 2
                                        : type === 1
                                            ? 3
                                            : type === 2
                                                ? 4
                                                : type === 3
                                                    ? 5
                                                    : null;
                        if (status === 0 || status === 1) {
                            status =
                                getOverdueDays(item2) >= 0
                                    ? 99
                                    : getOverdueDays(item2) >= -10
                                        ? 6 : getOverdueDays(item2) >= -15 ? 9
                                            : 7;
                        }

                        assignment.push({
                            coverage: getCoverageText(item, shapedSite), data: found, srfId: item.srfId, formType: 1, frequency: item.frequency, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: item2, self: item.reviewer_ids.length ? false : true, locationId: item.locationId, level: item.level,
                            reporting_period: item2, name: item.srf?.title, title: 'Submit ' + item.srf?.title + ' for the reporting period of ' + item2 || '', dueDate: getDueDate(item2), currentStatus: !type && reject ? "Returned" : 'Draft', timeLine: status === 6 ? 'Due Now' : status === 7 ? "Overdue" : status === 9 ? "Duesoon" : "Upcoming", statusCode: status
                        })
                    } else if (!found) {
                        let stat = getOverdueDays(item2) >= 0
                            ? 100
                            : getOverdueDays(item2) >= -10
                                ? 6 : getOverdueDays(item2) >= -15 ? 9
                                    : 7
                        if (item.srfId === 86) {
                            console.log(item)
                        }
                        assignment.push({
                            coverage: getCoverageText(item, shapedSite), srfId: item.srfId, formType: 1, frequency: item.frequency, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: item2, self: item.reviewer_ids.length ? false : true, locationId: item.locationId, level: item.level,
                            reporting_period: item2, name: item.srf?.title, title: 'Submit ' + item.srf?.title + ' for the reporting period of ' + item2 || '', dueDate: getDueDate(item2), currentStatus: 'Not Started', timeLine: stat === 6 ? 'Due Now' : stat === 7 ? "Overdue" : stat === 9 ? "Duesoon" : "Upcoming", statusCode: stat
                        })
                    }

                }
            }
            const filteredMSI = values[1].data.filter(x => (!x.supplierAssignmentSubmission || (x.supplierAssignmentSubmission && x.supplierAssignmentSubmission?.type === 0)))
            for (const x of filteredMSI) {
                let stat = getOverdueDaysByUTC(x.assessmentEndDate) >= 0
                    ? 100
                    : getOverdueDaysByUTC(x.assessmentEndDate) >= -10
                        ? 6 : getOverdueDaysByUTC(x.assessmentEndDate) >= -15 ? 9
                            : 7
                x.title = 'Submit MSI self-assessment ' + getMSIIDByAssignment(x)
                x.dueDate = getDate(x.assessmentEndDate, 'dd-LLL-yyyy')
                x.currentStatus = x?.supplierAssignmentSubmission ? 'Started' : 'Not Started'
                x.timeLine = stat === 6 ? 'Due Now' : stat === 9 ? "Due Soon" : stat === 100 ? "Upcoming" : "Overdue"
                x.formType = 2
                x.statusCode = stat
            }
            setAssignments(filteredMSI)
            setSubmittedAssessment(values[1].data.filter(x => (x?.supplierAssignmentSubmission && x?.supplierAssignmentSubmission?.type === 1)))
            // setSubmittedAssessment(values[1].data)

            setSubmissionData([...actions.filter(x => x.statusCode === 0), ...assignment.filter(x => ![6, 7, 9, 99, 100, 101].includes(x.statusCode))])
            console.log(assignment.filter(x => [6, 7, 9].includes(x.statusCode)), assignment)

            let att = []
            if (login_data.id === 378) {
                // att.push({ timeLine: 'Due Soon', statusCode: 9, currentStatus: 'Not Started', dueDate: '05-Apr-2025', title: 'Submit LCA Data Collection Form to TVS in 2024-25', formType: 0 })
            }
            setData([...att, ...actions.filter(x => x.statusCode === 7), ...assignment.filter(x => ([6, 7, 9, 101].includes(x.statusCode) || x.frequency === 4)), ...filteredMSI, ...action])


        })
    }
    const getMSIIDByAssignment = (item) => {

        return 'MSI-' + item?.vendor?.code + '-' + DateTime.fromISO(item.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')
    }
    const msiIdTemplate = (rowData) => {
        return (<div>{getMSIIDByAssignment(rowData)} </div>)
    }
    const reportTemplate = (rowData) => {
        return (rowData?.auditorAssignmentSubmission?.type === 2 ? <div onClick={() => { setSelectedAudit(rowData); setReportDialog(true); }}>View  </div> : 'NA')
    }
    const statusTemplate = (rowData) => {
        return (<div> {!rowData.auditStartDate ? 'Audit Not Scheduled' : !rowData.auditorAssignmentSubmission ? 'Audit Not Started' : rowData.auditorAssignmentSubmission?.type ? 'Audit Complete' : 'Audit Onprogress'} </div>)
    }
    const supplierScoreTemplate = (rowData) => {
        let finished = rowData?.supplierAssignmentSubmission?.type === 1
        console.log(rowData)

        return (
            <div className={finished ? 'clr-navy fw-6 fs-14 cur-pointer text-underline' : 'fw-5 fs-14'} onClick={(e) => { e.preventDefault(); if (finished) { setSelectedAudit(rowData); setActionModal(true) } }}>
                {rowData?.supplierAssignmentSubmission?.supplierMSIScore ? rowData?.supplierAssignmentSubmission?.supplierMSIScore : 'NA'}
            </div>
        );

    }
    const auditorScoreTemplate = (rowData) => {
        let finished = rowData?.auditorAssignmentSubmission?.type === 1 && rowData?.assessmentEndDate


        return (
            <div className={finished ? 'clr-navy fw-6 fs-14 cur-pointer text-underline' : 'fw-5 fs-14'} onClick={(e) => { e.preventDefault(); if (finished) { setSelectedAudit(rowData); setActionModal2(true) } }}>
                {rowData?.auditorAssignmentSubmission?.auditorMSIScore ? rowData?.auditorAssignmentSubmission?.auditorMSIScore : 'NA'}
            </div>
        );

    }
    const findingTemplate = (rowData) => {
        return <>{rowData?.supplierActions?.length || '-'}</>
    }
    const getDueDate = (dateStr) => {
        const [from, to] = dateStr.split(" to ");
        if (to) {
            return DateTime.fromFormat(to, "LLL-yyyy").plus({ month: 1 }).startOf('month').toFormat('dd-LLL-yyyy');
        }
        return DateTime.fromFormat(from, "LLL-yyyy").plus({ month: 1 }).startOf('month').toFormat('dd-LLL-yyyy')
    };

    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";
        console.log(rowData);
        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    function getOverdueDays(monthString) {
        console.log(monthString);
        const [startMonth, endMonth] = monthString.split(" to ");

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split("-");
        const endOfMonth = DateTime.fromObject({
            year: parseInt(year),
            month: DateTime.fromFormat(monthValue, "LLL").month,
        }).endOf("month");
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, "days").days);
        return endOfMonth.diff(currentDate, "days").days;
    }
    function getOverdueDaysByUTC(monthString) {

        const endOfMonth = DateTime.fromISO(monthString, { zone: 'Asia/Calcutta' })
        const currentDate = DateTime.local();

        return endOfMonth.diff(currentDate, "days").days;
    }



    const idTemplate = (rowData) => {
        return (
            // <a
            //     href="#"
            //     onClick={() => openDialog(rowData)} // Trigger dialog open
            //     style={{ textDecoration: 'none', color: '#007bff' }}
            // >
            //     {rowData.id}
            // </a>

            <div className='maskid' onClick={() => viewAudit(rowData)}>
                {rowData.id}
            </div>
        );
    };


    const getDate = (date, format) => {

        if (!date) {
            return 'Not Set'
        } if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const dateTemplate = (date) => {
        console.log(getDate(date))
        return getDate(date)

    }
    const getSupplier = (id) => {

        let user_name = 'Not Found'
        if (id === admin_data.id) {
            return 'Enterprise Admin'
        }
        let index = supplierList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = supplierList[index].information.supplierName
        }
        return user_name

    }
    const resendMail = (rowData) => {
        let assessment = assignments.find(i => i.id === activeAss.id)
        let txt = supplierList.find(i => rowData.supplierId === i.id)
        if (assessment) {
            let body = `<p>Hi ${txt.information.empname}</p>  <p>You have received mail in order to fill your Questionary Response, <a href="${window.location.origin}/supplier/assessment/${rowData.id}">click here </a> to open Supplier Assessment Questionary Form</p><hr/><p style='font-style:italic'>This email is an automated notification. Please do not reply to this message</p>`
            APIServices.post(API.SubmissionMail, { email: [txt.email], subject: 'Supplier Assessment Form - ' + assessment.title, body: body }).then(res => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Mail Sent Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })

        }


    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }
    const onCommentChange = (item, val, nan) => {

        item.comment = val;
        forceUpdate()
    }
    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {
        const getLabel = (item) => item.label.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, " ").replace(/&nbsp;/g, " ").replace('&amp;', '&');

        return (
            <div key={index} className="form-row grid m-0 p-fluid" style={{ padding: 10, border: item.error === 1 ? '1px solid red' : 'none' }}>
                <Tooltip target={`.tooltip-${index}`} position="top" />

                {/* Query Column */}
                <label className="col-4 fs-16 fw-5 text-justify">
                    {getLabel(item)}
                    {item.required && <span className="mandatory mr-2">*</span>}
                    {item.description && <i className={`material-icons fs-14 tooltip-${index}`} data-pr-tooltip={item.description}>help</i>}
                </label>

                {/* Response Column */}
                <div className="col-4">
                    {item.type === 'checkbox-group' && item.values.map((cb, cbind) => (
                        <div className="flex align-items-center" key={cbind}>
                            <Checkbox disabled={show} inputId={`cb-${index}-${cbind}`} name={cb.label} value={cb.value} onChange={(e) => onCheckBoxSelected(item, cbind)} checked={cb.selected} />
                            <label htmlFor={`cb-${index}-${cbind}`} className="ml-2">{cb.label}</label>
                        </div>
                    ))}

                    {item.type === 'date' && (
                        <Calendar disabled={show} value={item.value ? new Date(item.value) : null} onChange={(e) => onDateSelected(item, e.value)} />
                    )}

                    {item.type === 'number' && (
                        <InputNumber disabled={show} value={item.value} onChange={(e) => onNumberChange(item, e.value)} />
                    )}

                    {item.type === 'radio-group' && item.values.map((rb, rbindex) => (
                        <div className="flex align-items-center" key={rbindex}>
                            <RadioButton disabled={show} inputId={`rb-${index}-${rbindex}`} name={rb.label} value={rb.value} onChange={(e) => onRadioButtonSelected(item, rbindex)} checked={rb.selected === true} />
                            <label htmlFor={`rb-${index}-${rbindex}`} className="ml-2">{rb.label}</label>
                        </div>
                    ))}

                    {item.type === 'select' && (
                        <Dropdown disabled={show} value={item.value} options={item.values} optionLabel="label" onChange={(e) => onChangeDropwdown(item, e.value)} />
                    )}

                    {item.type === 'textarea' && (
                        <Editor disabled={show} value={item.value} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />
                    )}

                    {item.type === 'text' && (
                        <InputText disabled={show} value={item.value} onChange={(e) => onNumberChange(item, e.target.value)} />
                    )}
                </div>

                {/* Comments Column */}
                <div className="col-4">
                    <InputText disabled={show} value={item.comments} onChange={(e) => onCommentChange(item, e.target.value)} placeholder="Add comments" />
                </div>
            </div>
        );
    };

    const questionaryTemplate = (rowData) => {

        let txt = asssrflist.find(i => rowData.srfId === i.id)
        console.log(txt)
        return (<>{!txt ? '' : txt.title} </>)

    }

    const openDialog = (rowData) => {
        setSelectedRow(rowData); // Store the clicked row's data
        setDialogVisible(true);  // Show the dialog
    };

    // Function to close the dialog
    const hideDialog = () => {
        setDialogVisible(false);
        setSelectedRow(null); // Clear the selected row
    };
    const updateAssessment = (val) => {
        setSelectedAudit(val);
        let loc = JSON.parse(JSON.stringify(assignments))
        let index = loc.findIndex(i => i.id === val.id)
        if (index) {
            loc[index].supplierAssignmentSubmission = val.supplierAssignmentSubmission;
            setAssignments(loc)
            forceUpdate()
        }
    }
    const footer = () => {
        return (
            <div className='flex col-12 justify-content-end'>
                <Button label='Save & Exit' onClick={() => { handleSubmit() }} />
            </div>
        )
    }
    const handleSubmit = async (e) => {
        setSubmitted(true)
        let loc = JSON.parse(JSON.stringify(actionlist))
        let obj = { status: currentFormData.status, targetDate: currentFormData.targetDate }
        if (obj.status && obj.targetDate) {
            if (currentFormData.id) {
                let newObj = { modified_by: login_data.id, modified_on: DateTime.utc() }
                APIServices.patch(API.SupplierAction_Edit(currentFormData.id), { ...newObj, ...obj }).then((res) => {
                    let index = loc.findIndex(i => i.id === currentFormData.id)
                    if (index !== -1) {
                        loc[index] = { ...currentFormData, ...newObj, ...obj }
                        setActionList(loc)
                    }
                    setSubmitted(false)
                    showActionModal(false)
                })
            }
        }

    };
    const categoryTemplate = (option) => {
        return categoryOptions.find(c => c.id === option.category)?.label || 'Not Found';
    };
    const statusTemplate_ = (option) => {
        return statusOptions.find(c => c.id === option.status)?.label || 'Not Found';
    };
    const priorityTemplate = (option) => {
        return priorityOptions.find(c => c.id === option.priority)?.label || 'Not Found';
    };
    const sNoTemplate = (rowData, { rowIndex }) => <span className="cur-pointer clr-navy text-underline" onClick={() => { editAction(rowData) }}>{rowData.title}</span>;

    const editAction = (rowData) => {
        let loc = JSON.parse(JSON.stringify(rowData))
        if (loc.dueDate) {
            loc.targetDate = DateTime.fromISO(loc.targetDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        setCurrentFormData(loc)
        showActionModal(true)

    }
    const entityRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data));
        let allOptions = allentity.map((i) => i.coverage);
        let entOptions = [];
        allOptions.forEach((i) => {
            if (!entOptions.includes(i)) {
                entOptions.push(i);
            }
        });

        console.log(allentity);
        return (
            <MultiSelect
                panelClassName={"hidefilter"}
                value={options.value}
                options={entOptions}
                filter
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const dcfRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data));

        let IdOptions = allentity.map((i) => ({ title: i.title || 'Not Found', id: i.srfId })).filter(
            (item, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.title === item.title
                )
        );

        console.log(allentity);
        return (
            <MultiSelect
                panelClassName={"hidefilter"}
                filter
                value={options.value}
                options={IdOptions}
                optionValue="id"
                optionLabel="title"
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const statusBodyTemplate = (rowData) => {
        console.log(rowData)
        switch (rowData.status) {
            case 6:
                return <Badge severity="warning" value="Due Now" />;
            case 7:
                return <Badge severity="danger" value="Overdue" />;
            case 9:
                return <Badge severity="info" value="Due Soon" />;
            default:
                return <Badge severity="info" value="Upcoming" />;
        }
    };
    const statusRowFilterTemplate = (options) => {
        let allStatusOptions = [

            { name: "Submissions Overdue", id: 7 },
            { name: "Submission Due", id: 6 },
            { name: "Due Soon", id: 9 }


        ];

        return (
            <MultiSelect
                panelClassName={"hidefilter"}
                value={options.value}
                options={allStatusOptions}
                optionLabel="name"
                optionValue="id"
                filter
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const actionBodyTemplate = (rowData) => {

        return (<div className="fw-7 fs-16 clr-navy cur-pointer" onClick={() => { window.open(window.location.origin + '/cf_preview_view').sessionStorage.setItem('cfpreview', rowData.srfId) }}> {rowData.title || 'NA'} </div>)
    };
    const rpRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data));
        let allOptions = allentity.map((i) => i.reporting_period);
        let entOptions = [];
        allOptions.forEach((i) => {
            if (!entOptions.includes(i)) {
                entOptions.push(i);
            }
        });

        console.log(allentity);
        return (
            <MultiSelect
                panelClassName={"hidefilter"}
                value={options.value}
                options={entOptions}
                filter
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const assessmentHeader = (item) => {
        return (
            <div className="flex align-items-center justify-content-between gap-2 w-full">
                <div className="font-bold ">{'MSI-' + login_data?.supplierCode + '-' + DateTime.fromISO(item.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}</div>
                <div >Due On : <span className="font-bold">{DateTime.fromISO(item.assessmentEndDate, { zone: 'utc' }).toLocal().toFormat('dd-M-yyyy')}</span> </div>

                <div >Status : <tag className="status-tag-orange">Not Started</tag> </div>
            </div>

        )

    }

    const takeActionSupplier = (data) => {

        console.log(data)

        setTakeActionModal(true)
        setTakeActionData(data)

    }

    const takeActionControl = (rowData) => {
        setTakeDealerModal(true)
        setTakeActionDealer(rowData)
    }

    const actionTemplate = (rowData) => {

        if (rowData.actionType === "Checklist Submission" || rowData.actionType === "Checklist Submission Returned") {
            return <div className="table-link-clickable" onClick={() => takeActionControl(rowData)} >{rowData.actionType} - {rowData.maskId}  </div>
        }

        switch (rowData.formType) {

            case 1:

                console.log(rowData)
                return <div className="table-link-clickable" onClick={() => {
                    // window.open(window.location.origin + '/cf_preview_view').sessionStorage.setItem('cfpreview', rowData.srfId)
                    rowData.srfId === 86 ? window.open(window.origin + '/lca_input_entry').sessionStorage.setItem('srfsubmission', JSON.stringify({ id: rowData.srfId, submitId: rowData?.data?.id || null, params: { state: { data: { supplierId: login_data.id, vendorId: vendorCode?.id, vendorCode: vendorCode?.code, frequency: rowData.frequency, entityUserAssId: rowData.entityUserAssId, self: rowData.self, entityAssId: rowData.entityAssId, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period }, status: true } } })) :
                        window.open(window.origin + '/srf_input_entry').sessionStorage.setItem('srfsubmission', JSON.stringify({ id: rowData.srfId, submitId: rowData?.data?.id || null, params: { state: { data: { supplierId: login_data.id, vendorId: vendorCode?.id, vendorCode: vendorCode?.code, frequency: rowData.frequency, entityUserAssId: rowData.entityUserAssId, self: rowData.self, entityAssId: rowData.entityAssId, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period }, status: true } } }))
                }} >{rowData.title} </div>

            case 2:
                return <div className="table-link-clickable" onClick={() => { viewAudit(rowData) }} >{rowData.title}  </div>
            case 5:
                return <div className="table-link-clickable" onClick={() => { takeActionSupplier(rowData) }} >{rowData.title}  </div>
            case 0:
                return <div className="table-link-clickable" onClick={() => {

                    window.open(window.origin + '/lca')
                }} >{rowData.title} </div>

        }
    }
    const archieveActionTemplate = (rowData) => {

        switch (rowData.formType) {

            case 1:

                console.log(rowData)
                return <div className="table-link-clickable" onClick={() => {
                    // window.open(window.location.origin + '/cf_preview_view').sessionStorage.setItem('cfpreview', rowData.srfId)

                    window.open(window.origin + '/srf_input_entry').sessionStorage.setItem('srfsubmission', JSON.stringify({ id: rowData.srfId, submitId: rowData?.data?.id || null, params: { state: { data: { supplierId: login_data.id, vendorId: vendorCode?.id, vendorCode: vendorCode?.code, frequency: rowData.frequency, entityUserAssId: rowData.entityUserAssId, self: rowData.self, entityAssId: rowData.entityAssId, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period }, status: true } } }))
                }} >{rowData.title} </div>

            case 5:
                return <div className="table-link-clickable" onClick={() => { takeActionSupplier(rowData) }} >{rowData.title}  </div>
            default:
                return <></>

        }
    }
    const RowFilterTemplate = (options, obj) => {
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(data.map((i) => i[obj])))}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const timelineTemplate = (rowData) => {
        console.log(rowData)
        switch (rowData.statusCode) {
            case 6:
                return <Badge severity="warning" value="Due Now" />;
            case 7:
                return <Badge severity="danger" value="Overdue" />;
            case 9:
                return <Badge severity="info" value="Due Soon" />;
            case 101:
                return <Badge severity="info" value="No Due" />;
            default:
                return <Badge severity="info" value="Upcoming" />;

        }
    };

    const currentStatusTemplate = (rowData) => {
        if (rowData.status === 'Initiated') {
            return <Tag value={'Pending'} />
        }

        return <Tag value={rowData.currentStatus} />

    };

    const footerTemplate = (
        <></>
    );

    const dueDateBodyTemplate = (rowData) => {
        if (!rowData || !rowData.dueDate) {
            return '';
        }

        // Attempt to parse the date
        const parsedDate = new Date(rowData.dueDate);

        // If date is invalid, just return the original value
        if (isNaN(parsedDate)) {
            return rowData.dueDate;
        }

        // Format the date as DD-MM-YYYY
        const day = String(parsedDate.getDate()).padStart(2, '0');
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
        const year = parsedDate.getFullYear();

        return `${day}-${month}-${year}`;
    };


    return (
        <div className="col-12">
            <div className="col-12">

                <div className="col-12 flex align-items-center" style={{ padding: '0px 20px' }}>
                    <span className="text-big-one"> Hello  {vendorCode?.supplierName || vendorCode?.dealerName} !  </span>  {login_data.email && <Badge className="ml-3 p-tag-blue flex align-items-center" value={vendorCode?.code}> </Badge>}


                </div>
                <div className="col-12" style={{ padding: '0px 20px' }} >
                    <div className="text-big-one text-navy flex fs-16">Thank you for being a trusted value chain partner of TVS Motors.
                    </div>
                    <div className="text-micro text-navy flex" style={{ flexDirection: 'column' }}>
                        <div>
                            At TVS Motors, we are dedicated to implementing sustainable business practices throughout our value chain. As part of this commitment, we require all our partners to align with our sustainability program,  <strong>My Sustainability Index (MSI)</strong>. This process involves completing a self-assessment checklist, followed by an on-site evaluation conducted by a qualified sustainability assessor. You will also need to address any areas identified during the assessment and submit your responses in the "My Actions" section below. Timely responses to these actions will lead to updates in your MSI scores and adjustments to your ranking accordingly.
                        </div> <br />
                        <div>  Additionally, TVS Motors is focused on reporting the Environmental, Social, and Governance (ESG) practices of our key value chain partners to meet various regulatory and sustainability standards. We are also committed to our <strong>Net Zero</strong> initiatives, which require us to monitor carbon emissions from materials, manufacturing and other operational processes including transportation, and across the value chain. To support this, we will need your periodic reporting on specific data collection requirements, which will also be listed in your “My Actions” section.
                        </div>  <br />
                        We appreciate your support in advancing TVS Motors’ sustainability goals. Thank you for your collaboration.</div>

                </div>

            </div>
            <div className="col-12">
                <TabMenu className="roundedge" model={[{ label: 'My Actions' }, vendorCode.supplierName ? { label: 'MSI Reports' } : null, { label: 'Archived Data Submissions' }
                ].filter(x => x)} onTabChange={(e) => { console.log(e); setActiveIndex(e.index); setActiveIndex2(e.value.label) }} activeIndex={activeindex} />

                {activeindex2 === "My Actions" &&
                    <div>
                        <DataTable scrollable value={data} paginator rows={10} rowsPerPageOptions={[10, 20, 50, 100]} filters={{
                            timeLine: { value: null, matchMode: "in" },
                            title: { value: null, matchMode: "in" },
                            currentStatus: { value: null, matchMode: "in" }
                        }} >
                            <Column header="Timeline" field='timeLine' showFilterMatchModes={false}
                                filter body={timelineTemplate}
                                filterElement={(options) =>
                                    RowFilterTemplate(options, "timeLine")
                                } />
                            <Column header="Required Action" field='title' body={actionTemplate} showFilterMatchModes={false}
                                filter
                                filterElement={(options) =>
                                    RowFilterTemplate(options, "title")
                                } />
                            <Column header="Due Date" field='dueDate' body={dueDateBodyTemplate} />
                            <Column header="Current Status" field='currentStatus' showFilterMatchModes={false}
                                filter body={currentStatusTemplate}
                                filterElement={(options) =>
                                    RowFilterTemplate(options, "currentStatus")
                                } />

                        </DataTable>
                    </div>
                }
                {activeindex2 === "MSI Reports" && <div className="col-12">
                    {/* <Accordion  >
                        {assignments.map((item) => {
                            return (
                                <AccordionTab headerClassName="accfull" header={assessmentHeader(item)}>
                                    <SupplierPanel users={supplierList} updateData={(e) => { updateAssessment(e) }} readOnly={true} auditId={item} />

                                </AccordionTab>
                            )
                        })

                        }
                    </Accordion> */}
                    <DataTable value={submittedAssessment}>

                        <Column header="MSI ID" body={msiIdTemplate} />
                        <Column header="Self-Assessment Submission Date" body={(rowData) => { return getDate(rowData?.supplierAssignmentSubmission?.submitted_on) }} />
                        <Column header="Status" body={statusTemplate} />


                        <Column header="MSI Self Assessment Score" field="supplierAssignmentSubmission.supplierMSIScore" body={supplierScoreTemplate} />
                        <Column header="MSI Calibration Dates" body={(rowData) => { return getDate(rowData.auditStartDate) }} />
                        <Column header="MSI Score" field="auditorAssignmentSubmission.auditorMSIScore" body={auditorScoreTemplate} />
                        <Column header="Audit Report" body={reportTemplate} />

                        <Column header="Status of Findings" body={findingTemplate} />
                    </DataTable>

                </div>}
                {activeindex2 === 'Archived Data Submissions' &&

                    <div className="col-12">

                        <DataTable value={submissiondata.filter(x => x.formType === 5 || (x.formType === 1 && x.data))}>
                            <Column header="Required Action" field='title' body={archieveActionTemplate}
                            />
                            <Column header="Reporting Period" field="reporting_period" />
                            <Column header="Date of Submission" body={(rowData) => { return <>{getDate(rowData?.data?.reporter_modified_on || rowData?.supplierActionHistories[rowData?.supplierActionHistories?.length - 1]?.supplier_submitted_on)}</> }} />

                        </DataTable>

                    </div>}
                {/* {activeindex === 1 && activeindex2 === 0 &&
                    <div className="col-12">
                        <DataTable value={actionlist} paginator rows={5} className="datatable-responsive styled-table" emptyMessage='No pending action'>

                            <Column field="title" header="Action" body={sNoTemplate} style={{ width: "25%" }} />
                            <Column field="category" header="Category" body={categoryTemplate} style={{ width: "15%" }} />
                            <Column field="priority" header="Priority" body={priorityTemplate} style={{ width: "15%" }} />
                            <Column field="status" header="Status" body={statusTemplate_} style={{ width: "15%" }} />
                            <Column
                                field="targetDate"
                                header="Expected Completion Date"
                                body={rowData => getDate(rowData.targetDate, 'dd-MM-yyyy')}
                                style={{ width: "15%" }}
                            />
                            <Column
                                field="dueDate"
                                header="Target Date"
                                body={rowData => getDate(rowData.dueDate, 'dd-MM-yyyy')}
                                style={{ width: "15%" }}
                            />


                        </DataTable>
                    </div>

                }
                {activeindex === 3 &&
                    <DataTable

                        value={data}
                        tableClassName="font-lato"
                        filters={{
                            srfId: { value: null, matchMode: "in" },
                            coverage: { value: null, matchMode: "in" },
                            reporting_period: { value: null, matchMode: "in" },
                            status: { value: null, matchMode: "in" }
                        }}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        rows={10}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="({currentPage} of {totalPages})"
                        paginator
                        scrollable
                        emptyMessage="No Assignment(s)"
                    >
                        <Column
                            header="Timeline"
                            style={{ minWidth: "8%" }}
                            field="status"
                            showFilterMatchModes={false}
                            showApplyButton={true}
                            filterElement={statusRowFilterTemplate}
                            body={statusBodyTemplate}
                            filter
                        />

                        <Column
                            header="Required Submissions"
                            field="srfId"
                            style={{ minWidth: "20%" }}
                            body={actionBodyTemplate}
                            showFilterMatchModes={false}
                            showApplyButton={true}
                            filterElement={dcfRowFilterTemplate}
                            filter
                        />



                        <Column
                            header="Reporting Entity"
                            field="coverage"
                            style={{ minWidth: "10%" }}
                            showFilterMatchModes={false}
                            showApplyButton={true}
                            filterElement={entityRowFilterTemplate}
                            filter
                        />

                        <Column
                            header="Reporting Period"
                            style={{ minWidth: "8%" }}
                            field="reporting_period"
                            showFilterMatchModes={false}
                            showApplyButton={true}
                            filterElement={rpRowFilterTemplate}
                            filter
                        />


                    </DataTable>

                } */}

                {selectedAudit && <Dialog
                    visible={actionModal}
                    header={`TVS Motors Supplier MSI Self-assessment ( ${getMSIIDByAssignment(selectedAudit)} )`}
                    style={{ width: '75%' }}
                    onHide={() => setActionModal(false)}


                >



                    <SupplierPanel editable={(!selectedAudit.supplierAssignmentSubmission || selectedAudit?.supplierAssignmentSubmission?.type === 0)} vendorCode={vendorCode} closeModal={(e) => { setActionModal(() => e); updateActions(); forceUpdate(); }} users={supplierList} updateData={(e) => { updateAssessment(e) }} readOnly={true} auditId={selectedAudit} />



                </Dialog>
                }
                <Dialog
                    visible={actionModal2}
                    style={{ width: '75%' }}
                    onHide={() => { setActionModal2(false); setTimeout(() => { setSelectedAudit(null) }, 500) }}


                >

                    <AuditPanel auditId={selectedAudit} editable={false} closeModal={(e) => { setActionModal2(e) }} />



                </Dialog>

                <Dialog
                    visible={takeActionModal}
                    style={{ width: '75%' }}
                    onHide={() => { setTakeActionModal(false) }}
                    header="Action Taken"
                    footer={footerTemplate}

                >

                    <TakeActionSupplier data={takeActionData} refresh={() => { updateActions() }} />



                </Dialog>

                {<Dialog visible={actionmodal} style={{ width: '75%' }} footer={footer} header={"Update Action"} onHide={() => { showActionModal(false) }}>
                    <div className="p-fluid grid m-0 p-3">
                        {currentFormData.description && <div className="p-field col-12 font-italic ">
                            {currentFormData.description}
                        </div>}
                        <div className="p-field col-6 ">
                            <label htmlFor="status">Select Status<span className="mandatory"> *</span>  </label>
                            <Dropdown id="status" className='mt-2' value={currentFormData.status} optionLabel="label" optionValue='id' options={statusOptions} onChange={(e) => { setCurrentFormData((prev) => ({ ...prev, status: e.value })) }} placeholder="Select Priority" />

                            {submitted && !currentFormData.status && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Select Status
                                </small>
                            )}
                        </div>
                        <div className="p-field col-6 ">
                            <label htmlFor="status">Expected Completion Date<span className="mandatory"> *</span>  </label>
                            <Calendar dateFormat="dd/m/yy" minDate={new Date()} id="status" className='mt-2' value={currentFormData.targetDate} onChange={(e) => { setCurrentFormData((prev) => ({ ...prev, targetDate: e.value })) }} placeholder="Select Target Date" />

                            {submitted && !currentFormData.targetDate && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Set Target Date
                                </small>
                            )}
                        </div>
                    </div>

                </Dialog>}
                <Dialog visible={reportdialog} style={{ width: 750 }} onHide={() => { setReportDialog(false) }} >

                    <SupplierReport report={selectedAudit} />




                </Dialog>

                <Dialog visible={takeDealerModal} style={{ width: 750 }} onHide={() => { setTakeDealerModal(false) }} >

                    <TakeActionDealer data={takeActionDealer} />




                </Dialog>

            </div >

        </div >
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SupplierHomeTVS, comparisonFn);