import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import CriticalNonCompliances from "./NonComplianceComponent";

// Function to wrap long text into multiple lines

const SubGraph4Demo = ({ supplyData }) => {
  const [chartData, setChartData] = useState([]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            fontSize: "14px",
            fontFamily: "Lato",
            lineHeight: "1.5",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
          {payload.map((entry) => (
            <p key={entry.name} style={{ margin: 0, color: "black" }}>{`${entry.name
              }:${entry.name === "Maximum" ? 20 : entry.value}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
          marginTop: "10px",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (supplyData.length > 0) {
      // Compute average scores for social factors
      const totalSuppliers = supplyData.length;

      const totalHealthSafety = supplyData.reduce(
        (sum, item) => sum + (parseFloat(item.health_safety) || 0),
        0
      );

      const totalSocialStewardship = supplyData.reduce(
        (sum, item) => sum + (parseFloat(item.social_stewardship_framework) || 0),
        0
      );

      const totalSustainability = supplyData.reduce(
        (sum, item) => sum + (parseFloat(item.supplier_sustainability_ambassadorship_framework) || 0),
        0
      );

      const avgHealthSafety = (totalHealthSafety / totalSuppliers).toFixed(1);
      const avgSocialStewardship = (
        totalSocialStewardship / totalSuppliers
      ).toFixed(1);
      const avgSustainability = (totalSustainability / totalSuppliers).toFixed(
        1
      );

      setChartData([
        {
          category: "Occupational Health & Safety",
          maxScore: 20 - avgHealthSafety,
          avgScore: avgHealthSafety,
        },
        {
          category: "Supplier Social Stewardship Framework",
          maxScore: 10 - avgSocialStewardship,
          avgScore: avgSocialStewardship,
        },
        {
          category: "Supplier Sustainability Ambassadorship Framework",
          maxScore: 20 - avgSustainability,
          avgScore: avgSustainability,
        },
      ]);
    }
  }, [supplyData]);

  const CustomizedTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          textAnchor="middle"
          fontSize={10}
          fill="#666"
          dominantBaseline="middle"
        >
          {wrapText(payload.value, 20)} {/* Wrap text with custom width */}
        </text>
      </g>
    );
  };

  const wrapText = (text, width = 40) => {
    let words = text.split(" ");
    let lines = [];
    let currentLine = "";

    words.forEach((word) => {
      if ((currentLine + " " + word).length > width) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += (currentLine ? " " : "") + word;
      }
    });

    lines.push(currentLine); // Push the remaining line
    return lines.map((line, index) => (
      <tspan key={index} x="0" dy={index === 0 ? 0 : 10}>
        {line}
      </tspan>
    ));
  };

  return (
    <div className="container mt-4">
      <h5 className="mb-3 text-center text-dark">Social Section Performance</h5>

      <ResponsiveContainer
        width="100%"
        height={400}

      >
        <BarChart barSize={50} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="category"
            fontSize={12} // Set font size to 6
            interval={0} // Ensure all labels are displayed
            tick={<CustomizedTick />} // Custom tick rendering
          />
          <YAxis domain={[0, 20]} />
          <Tooltip content={CustomTooltip} />
          <Legend content={CustomLegend} />
          <Bar dataKey="avgScore" stackId="a" fill="#FC6E51" name=" Achieved">
            <LabelList
              dataKey="avgScore"
              position="insideBottom"
              style={{ fontSize: "12px", fill: "white" }}
            />
          </Bar>
          <Bar
            dataKey="maxScore"
            stackId="a"
            fill="#FEB2A8"
            name="Maximum"
          ></Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="col-12 flex justify-content-center">
        <CriticalNonCompliances count={0} />

      </div>
    </div>
  );
};

// Custom tick rendering for wrapping text

export default SubGraph4Demo;
