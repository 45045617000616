import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from "recharts";

const ESGScoresTab = ({ tabIndex, data }) => {
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setSelectedData(transformData(data));
    } else {
      setSelectedData([]); // Ensure selectedData is always an array
    }
  }, [data]);

  const transformData = (data) => {
    if (!Array.isArray(data) || data.length === 0) return [];

    const totalRecords = data.length;

    // Helper function to calculate sum of specific fields
    const sumFields = (fields) => {
      return data.reduce((sum, item) => {
        return (
          sum + fields.reduce((acc, field) => acc + (item[field] || 0), 0) // Ensure missing fields default to 0
        );
      }, 0);
    };

    // Define field categories
    const environmentFields = [
      "environment",
      "water_management",
      "waste_management",
      "energy_management",
    ];
    const socialFields = [
      "personnel_safety",
      "fire_safety",
      "5s_and_house_keeping",
      "electrical_safety",
      "road_safety",
    ];
    const governanceFields = ["governance_framework"];

    // Calculate totals
    const env_total = sumFields(environmentFields) / totalRecords;
    const soc_total = sumFields(socialFields) / totalRecords;
    const gov_total = sumFields(governanceFields) / totalRecords;

    // Return transformed data
    return [
      {
        category: "Environment",
        avgValue: parseFloat(env_total.toFixed(2)),
        remainingToMax: 40 - parseFloat(env_total.toFixed(2)),
        achievedColor: "#2C7C69",
        maxColor: "#7FC8A9",
        maxValue: 40,
      },
      {
        category: "Social",
        avgValue: parseFloat(soc_total.toFixed(2)),
        remainingToMax: 50 - parseFloat(soc_total.toFixed(2)),
        achievedColor: "#FC6E51",
        maxColor: "#FEB2A8",
        maxValue: 50,
      },
      {
        category: "Governance",
        avgValue: parseFloat(gov_total.toFixed(2)),
        remainingToMax: 10 - parseFloat(gov_total.toFixed(2)),
        achievedColor: "#4A90E2",
        maxColor: "#AFCBFF",
        maxValue: 10,
      },
    ];
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            fontSize: "14px",
            fontFamily: "Lato",
            lineHeight: "1.5",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
          {console.log(payload, "PAYLOAD")}
          {payload.map((entry) => (
            <p key={entry.name} style={{ margin: 0, color: "black" }}>{`${
              entry.name
            }:${
              entry.name === "Maximum" ? entry.payload.maxValue : entry.value
            }`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ height: 450 }}>
      <div
        style={{
          width: "60%",
          height: 400,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h3
          style={{
            textAlign: "center",
            marginBottom: 20,
            marginTop: 20,
            color: "#555",
          }}
        >
          ESG Score
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={selectedData}
            barSize={60}
            margin={{ top: 10, right: 30, left: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, 40]} />
            <Tooltip content={CustomTooltip} />
            <Bar
              dataKey="avgValue"
              stackId="score"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                fontSize: 12,
                formatter: (value) => value.toFixed(1),
              }}
            >
              {selectedData.map((entry, index) => (
                <Cell
                  key={`cell-achieved-${index}`}
                  fill={entry.achievedColor}
                  label={{
                    position: "insideTop",
                    fill: "#fff",
                    fontSize: 12,
                    formatter: (value) => value.toFixed(1),
                  }}
                />
              ))}
            </Bar>
            <Bar dataKey="remainingToMax" stackId="score" name="Maximum">
              {selectedData.map((entry, index) => (
                <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
              ))}
            </Bar>
          </BarChart>

          <div
            className="legend"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <span
                style={{
                  color: "#e3e3e3",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#e3e3e3",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#7FC8A9",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#7FC8A9",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FFB6C1",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FFB6C1",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div style={{ marginRight: "30px" }}>
              <span
                style={{
                  color: "#AFCBFF",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#AFCBFF",
                  marginRight: "5px",
                }}
              ></span>
              Maximum
            </div>
            <div>
              <span
                style={{
                  color: "#b0b0b0",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#b0b0b0",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#2C7C69",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#2C7C69",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FF6F61",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FF6F61",
                  marginRight: "5px",
                }}
              ></span>
            </div>

            <div>
              <span
                style={{
                  color: "#4A90E2",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#4A90E2",
                  marginRight: "5px",
                }}
              ></span>
              Achieved
            </div>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ESGScoresTab;
