import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from 'luxon';
import SupplierReport from '../SupplierScreen/SupplierReport';
import { Dialog } from 'primereact/dialog';
import useForceUpdate from 'use-force-update';
import SupplierPanel from '../SupplierScreen/SupplierPanel';
import AuditPanel from '../Auditor/AuditPanel';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import APIServices from '../../../../service/APIService';
import { API } from '../../../../constants/api_url';
import Swal from 'sweetalert2';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import ActionOfStatus from './ActionOfStatus';
const SuppliersTableCompleted = ({ data, assessorList, globalFilter, supplierList, editSupplier }) => {

    console.log(data, 'ddd')
    const [search, setSearch] = useState('')
    const [databk, setDatabk] = useState([])
    const [datas, setDatas] = useState([])
    const [selectedAttachments, setSelectedAttachments] = useState([])
    const [docdialog, setDocDialog] = useState(false)
    const [reportdialog, setReportDialog] = useState(false)
    const [selectedAudit, setSelectedAudit] = useState(null)
    const forceUpdate = useForceUpdate();
    const [actionModal, setActionModal] = useState(false)
    const [actionModal2, setActionModal2] = useState(false)
    const [actionReportData, setActionReportData] = useState([])
    const [actionStatusReport, setActionStatusReport] = useState(false)

    const getActionsStatus = (data) => {
        if (!Array.isArray(data)) return "0/0";

        const category3Items = data.filter(item => item.categoryOfFinding === 3);
        const totalCategory3 = category3Items.length;

        if (totalCategory3 === 0) return "0/0";

        const type3Count = category3Items.filter(item => item.nonComplianceType === 3).length;
        return `${type3Count}/${totalCategory3}`;
    }
    useEffect(() => {


        // Add statusofaction to each item
        const updatedData = data.map(item => ({
            ...item,
            statusOfActions: getActionsStatus(item.supplierActions)

        }));

        setDatabk(updatedData);
        setDatas(updatedData);

    }, [data]);

    const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }, { name: 'IDM (Indirect Material)', value: 10 }, { name: 'Import', value: 11 }]
    const calibrationIdBodyTemplate = (rowData) => {
        return (
            <>
                {rowData?.auditorAssignmentSubmission?.type === 2 ?

                    <label className='clr-navy fw-7 fs-14 cur-pointer text-underline' onClick={(e) => { e.preventDefault(); setSelectedAudit(rowData); setReportDialog(true); }}>
                        {'MSI-' + (rowData?.vendorCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}
                    </label>
                    : !rowData?.assessmentEndDate ?
                        <label className={rowData?.attachment2?.length ? 'clr-navy fw-7 fs-14 cur-pointer text-underline' : ''} onClick={(e) => { e.preventDefault(); if (rowData?.attachment2?.length) { setSelectedAttachments(rowData?.attachment2); setDocDialog(true) } }}>
                            {'MSI-' + (rowData?.vendorCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}
                        </label>
                        :
                        <label>
                            {'MSI-' + (rowData?.vendorCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}
                        </label>
                }
            </>

        );
    };
    const supplierScoreTemplate = (rowData) => {
        let finished = rowData?.supplierAssignmentSubmission?.type === 1


        return (
            <div className={finished ? 'clr-navy fw-6 fs-14 cur-pointer text-underline' : 'fw-5 fs-14'} onClick={(e) => { e.preventDefault(); if (finished) { setSelectedAudit(rowData); setActionModal(true) } }}>
                {rowData?.supplierAssignmentSubmission?.supplierMSIScore ? rowData?.supplierAssignmentSubmission?.supplierMSIScore : 'NA'}
            </div>
        );

    }
    const getCalibirationId = (rowData) => {
        return 'MSI-' + (rowData?.vendorCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')
    }
    const auditorScoreTemplate = (rowData) => {
        let finished = ((rowData?.auditorAssignmentSubmission?.type === 1 || rowData?.auditorAssignmentSubmission?.type === 2) && rowData?.assessmentEndDate) || (!rowData?.assessmentEndDate && rowData?.attachment1?.length)


        return (
            <div className={finished ? 'clr-navy fw-6 fs-14 cur-pointer text-underline' : 'fw-5 fs-14'} onClick={(e) => { e.preventDefault(); if (finished) { if (rowData?.attachment1?.length) { setSelectedAttachments(rowData?.attachment1); setDocDialog(true) } else { setSelectedAudit(rowData); setActionModal2(true) } } }}>
                {rowData?.auditorAssignmentSubmission?.auditorMSIScore ? rowData?.auditorAssignmentSubmission?.auditorMSIScore : 'NA'}
            </div>
        );

    }
    const reportTemplate = (rowData) => {
        return (rowData?.auditorAssignmentSubmission?.type === 1 ? <div onClick={() => { setSelectedAudit(rowData); setReportDialog(true); }}>View  </div> : 'NA')
    }
    const nameTemplate = (rowData) => {

        return (

            rowData?.vendor?.supplierName || 'NA'

        );
    };
    const searchFn = (e) => {
        let val = e.target.value
        setDatas(databk.filter(x => x?.vendor?.supplierName.trim().toLowerCase().includes(val?.trim().toLowerCase()) || x?.vendor?.code.trim().toLowerCase().includes(val?.trim().toLowerCase())))
        setSearch(val)
    }
    const locationTemplate = (rowData) => {
        return (
            rowData?.vendor?.supplierLocation || 'NA'
        );
    };
    const categoryTemplate = (rowData) => {
        console.log(rowData)
        return (

            categoryList.find(i => i.value === rowData?.vendor?.supplierCategory)?.name || 'Not Found'

        );
    };
    const assessmentDueDate = (rowData) => {
        return (

            DateTime.fromISO(rowData?.supplierAssignmentSubmission?.submitted_on, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy')

        );
    };
    const calibrationStartDate = (rowData) => {
        return (

            rowData.auditStartDate ? DateTime.fromISO(rowData.auditStartDate, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy') : 'Not Set'

        );
    };
    const calibrationEndDate = (rowData) => {
        return (

            rowData.auditEndDate ? DateTime.fromISO(rowData.auditEndDate, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy') : 'Not Set'


        );
    };
    const ratingTemplate = (rowData) => {
        const score = rowData?.auditorAssignmentSubmission?.auditorMSIScore || null
        return (

            <div style={{ width: score > 40 ? 50 : 80 }}>
                {score != null ? score > 85 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/platinum_rating.png').default} /> : score > 70 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/gold_rating.png').default} /> : score > 55 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/silver_rating.png').default} /> : score > 40 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/bronze_rating.png').default} /> : "Needs Improvement" : 'NA'}
            </div>
        )
    }
    const contactTemplate = (rowData) => {
        return (

            supplierList.find(i => i.id === rowData.supplierId)?.information?.supplierContact || 'NA'

        );
    };
    const pySpendBodyTemplate = (rowData) => {
        return `${supplierList.find(i => i.id === rowData.supplierId)?.information?.supplierSpentOn || '- '} Cr. INR`;
    };

    const statusOfActionsTemplate = (rowData) => {
        if (!Array.isArray(rowData.supplierActions)) {
            return <span className="redBox">0/0</span>;
        }

        const category3Items = rowData.supplierActions.filter(item => item.categoryOfFinding === 3);
        const totalCategory3 = category3Items.length;

        if (totalCategory3 === 0) {
            return <span className="redBox">0/0</span>;
        }

        const type3Count = category3Items.filter(item => item.type === 3).length;
        const ratio = `${type3Count}/${totalCategory3}`;

        const className = type3Count === totalCategory3 ? 'greenBox' : 'orangeBox';

        return <span className={className} style={{cursor:'pointer'}} onClick={() => dealerActionReport(rowData)}>{ratio}</span>;
    };

    const dealerActionReport = (rowData) => {
        setActionReportData(rowData)
        setActionStatusReport(true)

    }

    const team1Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group1?.assessors?.length) {
            return rowData?.group1?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team2Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group2?.assessors?.length) {
            return rowData?.group2?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team3Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group3?.assessors?.length) {
            return rowData?.group3?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team4Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group4?.assessors?.length) {
            return rowData?.group4?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const handleCalibrationClick = (rowData) => {
        // Logic when calibration ID is clicked
        alert('Clicked on: ' + rowData.calibrationId);
    };
    const actionBodytemplate = (rowData) => {
        return (
            <div>
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => editSupplier(rowData)}
                >
                    <i className="pi pi-pencil" />
                </button>
            </div>
        )
    }
    const sortAuditStartDate = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.auditStartDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.auditStartDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.auditStartDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.auditStartDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const sortAuditEndDate = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.auditEndDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.auditEndDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.auditEndDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.auditEndDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const RowFilterTemplate = (options, obj) => {
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(data.map((i) => i[obj]))).filter(x => x)}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                filter
                panelClassName='hidefilter'
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const handleReportAttachmentUpload = (e, id, obj) => {
        let allowedext = ['.pdf', '.PDF', '.xls', '.xlsx', '.doc', '.docx']
        const file = e.target.files[0]
        let filtered_files = allowedext.includes(file.name.substr(file.name.lastIndexOf('.')))

        let formData = new FormData()
        let count = 0
        formData.append('file', file)
        console.log(filtered_files)
        e.target.value = null
        if (filtered_files) {

            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {

                APIServices.patch(API.SupplierAssessmentAss_Edit(id), { [obj]: res.data.files }).then((res2) => {

                    let loc = JSON.parse(JSON.stringify(datas))
                    let loc2 = JSON.parse(JSON.stringify(databk))
                    let index = loc.findIndex(i => i.id === id)
                    if (index !== -1) {
                        loc[index] = { ...loc[index], [obj]: res.data.files }
                        setDatas(loc)
                    }
                    let index2 = loc2.findIndex(i => i.id === id)
                    if (index2 !== -1) {
                        loc2[index] = { ...loc2[index], [obj]: res.data.files }
                        setDatabk(loc2)
                    }

                })

            }).catch((e) => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Something went wrong while uploading file",
                    showConfirmButton: false,
                    timer: 2000,
                });
            })
        } else {

            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format PDF,Word & Excel only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const handleRemoveAttachment = (id, obj) => {
        APIServices.patch(API.SupplierAssessmentAss_Edit(id), { [obj]: [] }).then((res2) => {

            let loc = JSON.parse(JSON.stringify(datas))
            let loc2 = JSON.parse(JSON.stringify(databk))
            let index = loc.findIndex(i => i.id === id)
            if (index !== -1) {
                loc[index] = { ...loc[index], [obj]: [] }
                setDatas(loc)
            }
            let index2 = loc2.findIndex(i => i.id === id)
            if (index2 !== -1) {
                loc2[index] = { ...loc2[index], [obj]: [] }
                setDatabk(loc2)
            }

        })
    }
    const caliAttTemplate = (rowData) => {
        return !rowData.assessmentEndDate ? rowData?.attachment1?.length ? <span className='mandatory cur-pointer text-underline' onClick={() => { handleRemoveAttachment(rowData.id, 'attachment1') }}>Remove</span> : <label htmlFor={rowData.id + 'one'} className='cur-pointer clr-navy text-underline' >Add <input type='file' accept=".pdf,.xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            id={rowData.id + 'one'} hidden onChange={(e) => { handleReportAttachmentUpload(e, rowData.id, 'attachment1') }} ></input> </label> : 'NA'
    }
    const auditAttTemplate = (rowData) => {

        return !rowData.assessmentEndDate ? rowData?.attachment2?.length ? <span className='mandatory cur-pointer text-underline' onClick={() => { handleRemoveAttachment(rowData.id, 'attachment2') }}>Remove</span> : <label htmlFor={rowData.id + 'two'} className='cur-pointer clr-navy text-underline' >Add <input type='file' accept=".pdf,.xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            id={rowData.id + 'two'} hidden onChange={(e) => { handleReportAttachmentUpload(e, rowData.id, 'attachment2') }} ></input> </label> : 'NA'

    }
    return (
        <div>
            <div className="col-12 flex justify-content-end" >
                <div className='col-5'>
                    <span className="p-input-icon-left" style={{ width: '100%' }}>
                        <i className="pi pi-search" />
                        <InputText value={search} style={{ width: '100%' }} onChange={searchFn} placeholder="Search Code/Name" />
                    </span>
                </div>
            </div>
            <DataTable value={datas} paginator rows={10} responsiveLayout="scroll" globalFilter={globalFilter} filters={{ supplierName: { matchMode: 'in', value: null }, location: { matchMode: 'in', value: null }, msiId: { matchMode: 'in', value: null }, cat: { matchMode: 'in', value: null }, stat: { matchMode: 'in', value: null } }} className="mt-2 h-500">
                <Column field="msiId" header="Calibration ID" body={calibrationIdBodyTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "msiId")
                    }></Column>
                <Column field="supplierName" header="Name" body={nameTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "supplierName")
                    } ></Column>
                <Column field="location" header="Location" body={locationTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "location")
                    }></Column>
                <Column field="supplierContact" header="Supplier Contact" body={contactTemplate}></Column>

                <Column field="category" header="Category" body={categoryTemplate}></Column>
                <Column field="selfAssessmentDueDate" header="Self-Assessment Submit Date" body={assessmentDueDate} ></Column>

                <Column field="supplierAssignmentSubmission.supplierMSIScore" header="MSI Self-assessment Score" body={supplierScoreTemplate} ></Column>
                <Column field="calibrationStartDate" sortable sortFunction={sortAuditStartDate} header="Calibration Start Date" body={calibrationStartDate}></Column>
                <Column field="calibrationEndDate" sortable sortFunction={sortAuditEndDate} header="Calibration End Date" body={calibrationEndDate}></Column>
                {/* <Column field="calibrationStatus" header="Calibration Status" ></Column> */}
                {/* <Column field="report" header="Report" ></Column> */}
                <Column field="auditorAssignmentSubmission.auditorMSIScore" header="MSI Score" body={auditorScoreTemplate} ></Column>
                <Column field="msiRating" header="MSI Rating" body={ratingTemplate} sortable sortField='auditorAssignmentSubmission.auditorMSIScore' ></Column>
                <Column field="statusOfActions" header="Status of Actions" body={statusOfActionsTemplate}></Column>
                <Column header="Action Plan Attachment" body={auditAttTemplate}></Column>
                <Column header="Calibiration Attachment" body={caliAttTemplate}></Column>
                <Column field="teamMember1" header="Calibration Team Member 1" body={team1Template}></Column>
                <Column field="teamMember2" header="Calibration Team Member 2" body={team2Template}></Column>
                <Column field="teamMember3" header="Calibration Team Member 3" body={team3Template}></Column>
                <Column field="teamMember3" header="Calibration Team Member 4" body={team4Template}></Column>
                {/* <Column header="Schedule MSI Calibration" body={actionBodytemplate}></Column> */}

            </DataTable>
            {selectedAudit && <Dialog
                visible={actionModal}
                header={`TVS Motors Supplier MSI Self-assessment ( ${getCalibirationId(selectedAudit)} )`}
                style={{ width: '75%' }}
                onHide={() => setActionModal(false)}


            >



                <SupplierPanel vendorCode={selectedAudit.vendorCode} closeModal={(e) => { setActionModal(() => e); forceUpdate(); }} readOnly={true} auditId={selectedAudit} />



            </Dialog>
            }

            <Dialog
                visible={actionModal2}
                style={{ width: '75%' }}
                onHide={() => { setActionModal2(false); setTimeout(() => { setSelectedAudit(null) }, 500) }}


            >

                <AuditPanel auditId={selectedAudit} editable={false} closeModal={(e) => { setActionModal2(e) }} />



            </Dialog>
            <Dialog visible={reportdialog} style={{ width: '90%' }} className="custom-dialog" onHide={() => { setReportDialog(false) }} >
                <SupplierReport report={selectedAudit} />
            </Dialog>
            <Dialog visible={docdialog} style={{ width: '90%', height: '90vh' }} className="custom-dialog" onHide={() => { setDocDialog(false) }} >
                <DocViewer style={{ height: 500 }} documents={[{ uri: API.Docs + selectedAttachments[0]?.originalname }]} pluginRenderers={DocViewerRenderers} />
            </Dialog>

            <Dialog visible={actionStatusReport} style={{ width: '90%' }} className="custom-dialog" onHide={() => { setActionStatusReport(false) }} >
                <ActionOfStatus report={actionReportData} />
            </Dialog>
        </div>

    );
};

export default SuppliersTableCompleted;
