import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

// Sample data derived from the table
const data = [
  {
    year: "FY-2023",
    renewable: 104853802.89,
    nonRenewable: 17969872.22,
  },
  {
    year: "FY-2024",
    renewable: 0,
    nonRenewable: 0,
  },
];

const RenewableNonRenewableBarChart = () => {
  return (
    <div style={{ height: 500 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Renewable and Non-Renewable Energy Consumption
      </h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />

          <YAxis>
            <Label
              value="Energy Consumption (GJ)"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />
          {/* Renewable Energy Bar */}
          <Bar
            dataKey="renewable"
            name="Renewable Energy"
            fill="#82ca9d"
            barSize={60}
          />
          {/* Non-Renewable Energy Bar */}
          <Bar
            dataKey="nonRenewable"
            name="Non-Renewable Energy"
            fill="#8884d8"
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RenewableNonRenewableBarChart;
