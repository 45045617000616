import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';

import moment from 'moment';
import axios from 'axios';

import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'

import { CascadeSelect } from 'primereact/cascadeselect';

import * as XLSX from 'xlsx'
import FileSaver, { saveAs } from 'file-saver';
import { setRef } from '@fullcalendar/core';
import { SplitButton } from 'primereact/splitbutton';
import Swal from 'sweetalert2';


import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"

import cx from "classnames";

import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import $ from 'jquery'
import { API } from '../../../../constants/api_url';
import APIServices from '../../../../service/APIService';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { DateTime } from 'luxon';
import { Tag } from 'primereact/tag';
import { fetchUserList } from '../../../../RTK/Background/userProfileList';
import { adjustColumnWidths } from '../../../../components/BGHF/helper';
import XlsxPopulate from 'xlsx-populate';


const google = window.google;
// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;
// (0.79/1000)

const AddClientSupplierTVS = () => {

    const [data, setData] = useState([])
    const [databk, setDataBk] = useState([])
    const dispatch = useDispatch()
    const [allimporteddata, setAllImportedData] = useState([])
    const [apidata, setAPiData] = useState([])
    const [addvendordialog, setAddVendorDialog] = useState(false)
    const [vendorCode, setVendorCode] = useState("");
    const [selectedSupplier, setSelectedSupplier] = useState({})
    const allRoles = useSelector((state) => state.user.allRoles)
    const [importdialog, setImportDialog] = useState(false)

    const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }, { name: 'IDM (Indirect Material)', value: 10 }, { name: 'Import', value: 11 }]
    const [search, setSearch] = useState('')
    const [plantlist, setPlantList] = useState([])
    const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
    const [adddialog, setAddDialog] = useState(false)
    const [cropdialog, setCropDialog] = useState(false)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [importentries, setImportEntries] = useState({ invalidRows: [], validRows: [], actualRows: [] })

    const [config, setConfig] = useState({ selectedLocationIds: [], selectedLocation: [], selectedModule: [], selectedModuleIds: [], admin: { approver: false, user: true } })
    const [selectedconfig, setSelectedConfig] = useState({ name: 'Assigned Locations' })
    const [configdialog, setConfigDialog] = useState(false)
    const imgRef = useRef(null)
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)

    const previewCanvasRef = useRef(null)
    const [supplier, setSupplier] = useState({ supplierSpentOn: 0, supplierName: '', supplierSPOC: '', supplierCode: '', supplierLocation: '', plantLocation: null, supplierEmail: '', supplierContact: '', supplierEmail2: '', supplierContact2: '', supplierEmail3: '', supplierContact3: '', supplierCategory: null })
    const [submitted, setSubmitted] = useState(false)
    const [filter, setFilter] = useState('')
    const forceUpdate = useForceUpdate()
    const dt = useRef(null);
    const [loctree, setLocTree] = useState([])
    const [modtree, setModTree] = useState()

    let configmenu = [{ name: 'Assigned Locations' }, { name: 'Admin' }]
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const excelHeader = [
        "Vendor Code", "Supplier Name", "Category", "Location", "Plant Location", "SPOC Name", "Spend in Cr (FY24)", "SPOC Mail Id", "SPOC Contact No", "SPOC Mail Id #2", "SPOC Contact No #2", "SPOC Mail Id #3", "SPOC Contact No #3"

    ];
    const locationtree = { "name": "", "children": [{ "name": "India", "children": [{ "name": "TamilNadu", parent: 100, "children": [{ "name": "Vellore" }, { "name": "Sholinghur" }] }, { "name": "AP", "children": [{ "name": "Chittoor" }, { "name": "Puttur" }] }] }, { "name": "Japan", "children": [{ "name": "Yuhan", "children": [{ "name": "Site1" }, { "name": "Site2" }] }, { "name": "Beijing", "children": [{ "name": "Site3" }, { "name": "Site4" }] }] }] }


    useEffect(() => {

        renderData()

        let uriString = {
            "include": ["locationTwos"]

        }
        APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`).then((res) => {
            setPlantList(res.data.flatMap(x => x.locationTwos ? x.locationTwos : []))

        })

    }, [])
    useEffect(() => {
        return () => {
            dispatch(fetchUserList(admin_data.id))
        }
    }, [])
    const renderData = () => {
        let datas = []
        let promise = new Promise((resolve, error) => {
            APIServices.get(API.Supplier_UP(admin_data.id))
                .then((res) => {
                    setAPiData(res.data)
                    setData(res.data);
                    setDataBk(res.data)
                    // res.data.forEach((item, index) => {
                    //     if (item.role === 'clientsupplier' && item.clientId === admin_data.id) {

                    //         datas.push({ id: item.id, vendorCodes: item?.vendorCodes || [], supplierSPOC: item.information.supplierSPOC ? item.information.supplierSPOC : '', plantLocation: item.information.plantLocation ? item.information.plantLocation : null, supplierSpentOn: (item.information.supplierSpentOn || item.information.supplierSpentOn === 0) ? item.information.supplierSpentOn : null, supplierCategory: item.information.supplierCategory ? item.information.supplierCategory : null, supplierName: item.information.supplierName ? item.information.supplierName : '', supplierContact: item.information.supplierContact ? item.information.supplierContact : null, supplierEmail: item.email, information: item.information, supplierLocation: item.information.supplierLocation ? item.information.supplierLocation : '', supplierContact2: item.information.supplierContact2 ? item.information.supplierContact2 : null, supplierContact3: item.information.supplierContact3 ? item.information.supplierContact3 : null, supplierEmail2: item.information.supplierEmail2 ? item.information.supplierEmail2 : '', supplierEmail3: item.information.supplierEmail3 ? item.information.supplierEmail3 : '' })

                    //     }
                    //     if (index === res.data.length - 1) {
                    //         resolve(datas)
                    //     }
                    // })
                })
        })
        promise.then((d) => { console.log(d); forceUpdate() })


    }
    const importSupplierFromExcel = async (e) => {
        const file = e.files[0];
        const reader = new FileReader();
        reader.onload = async (event) => {
            const arrayBuffer = event.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: "array" }); // Read as array
            const sheetName = workbook.SheetNames[0];
            const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            Swal.fire({
                title: 'Please Wait !',
                html: 'Validating fields for ',// add html attribute if you want or remove
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            });
            e.options.clear()

            const result = await validateExcel(data);
            console.log(result)
            Swal.close()
            if (result.validRows.length || result.invalidRows.length) {
                setImportEntries(result)
                setImportDialog(true)
            } else {

                Swal.fire({
                    position: 'center',
                    title: 'No Data to import',
                    showConfirmButton: false,
                    timer: 1500
                })
            }

            console.log("Valid Rows:", result.validRows);
            console.log("Invalid Rows:", result.invalidRows);
        };

        reader.readAsArrayBuffer(file);


    }

    const getPlantLocationId = (id) => {
        return plantlist.find(x => x.id === id)?.name || 'Not Found'
    }

    const getCategoryId = (id) => {
        return categoryList.find(x => x.value === id)?.id
    }
    const validateExcel = (data) => {
        const validRows = [];
        const invalidRows = [];
        const actualRows = [];

        return new Promise((resolve) => {
            const isValidEmail = (email) => !email || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
            const isValidPhone = (phone) => !phone || /^\d{7,}$/.test(phone); // Min 10 digits
            const isPositiveNumber = (value) => {
                if (value === "" || value === null || value === undefined) return false;
                const num = parseFloat(value);
                return !isNaN(num) && num >= 0;
            };

            // Helper function to get ID from mapping
            const getIdFromMapping = (name, mapping, obj) => {
                if (!name) return null;
                const match = mapping.find((item) => item.name.toLowerCase() === name.toLowerCase());
                return match ? match[obj] : null;
            };

            for (const row of data) {
                const errors = [];
                const {
                    "Vendor Code": vendorCode,
                    "Spend in Cr (FY24)": supplierSpentOn,
                    "Supplier Name": supplierName,
                    "Category": category,
                    "Location": location,
                    "Plant Location": plantLocation,
                    "SPOC Name": spocName,
                    "SPOC Mail Id": spocMail,
                    "SPOC Contact No": spocContact,
                    "SPOC Mail Id #2": spocMail2,
                    "SPOC Contact No #2": spocContact2,
                    "SPOC Mail Id #3": spocMail3,
                    "SPOC Contact No #3": spocContact3
                } = row;

                // ✅ Check Mandatory Fields
                if (!vendorCode) errors.push("Vendor Code is required.");
                if (!isPositiveNumber(supplierSpentOn)) errors.push("Supplier Spent On must be a positive number.");
                console.log(isPositiveNumber(supplierSpentOn))
                if (!supplierName) errors.push("Supplier Name is required.");
                if (!category) errors.push("Category is required.");
                if (!location) errors.push("Location is required.");
                if (!plantLocation) errors.push("Plant Location is required.");
                if (!spocName) errors.push("SPOC Name is required.");
                if (!spocMail || !isValidEmail(spocMail)) errors.push("Invalid or missing SPOC Mail Id.");
                if (!spocContact) errors.push("SPOC Contact No is required.");

                // ✅ Validate Phone Numbers (Min 10 Digits)
                if (!isValidPhone(spocContact)) errors.push("Invalid SPOC Contact No. It must be at least 7 digits.");
                if (spocContact2 && !isValidPhone(spocContact2)) errors.push("Invalid SPOC Contact No #2. It must be at least 7 digits.");
                if (spocContact3 && !isValidPhone(spocContact3)) errors.push("Invalid SPOC Contact No #3. It must be at least 7 digits.");

                // ✅ Get IDs for Category & Plant Location
                const categoryId = getIdFromMapping(category, categoryList, "value");
                const plantLocationId = getIdFromMapping(plantLocation, plantlist, "id");

                // ✅ Validate IDs
                if (!categoryId) errors.push(`Invalid Category: ${category}`);
                if (!plantLocationId) errors.push(`Invalid Plant Location: ${plantLocation}`);



                // ✅ Validate Optional Emails
                if (spocMail2 && !isValidEmail(spocMail2)) errors.push("Invalid SPOC Mail Id #2.");
                if (spocMail3 && !isValidEmail(spocMail3)) errors.push("Invalid SPOC Mail Id #3.");

                const branchCodeString = vendorCode ? String(vendorCode) : "";
                let newObj = { information: {} }






                const validatedRow = {
                    role: 'clientsupplier',
                    supplierCode: branchCodeString,
                    clientId: admin_data.id,
                    userId: login_data.id,
                    created_on: DateTime.utc(),
                    email: spocMail,
                    information: {
                        supplierContact: spocContact || '',
                        supplierContact2: spocContact2 || '',
                        supplierContact3: spocContact3 || '',
                        supplierCategory: categoryId,
                        plantLocation: plantLocationId,
                        supplierSpentOn: parseFloat(supplierSpentOn),
                        supplierSPOC: spocName,
                        supplierLocation: location,
                        supplierName: supplierName,
                        supplierEmail2: spocMail2 || '',
                        supplierEmail3: spocMail3 || '',
                    }

                }
                // Classify Rows
                if (errors.length > 0) {
                    invalidRows.push({ ...row, Remarks: errors.join(", ") });
                } else {
                    validRows.push(row);
                    actualRows.push(validatedRow);
                }
            }

            resolve({ validRows, invalidRows, actualRows });
        });
    };
    const downloadExcelTemplate = async () => {
        const headers = [
            ["Vendor Code", "Supplier Name", "Category", "Location", "Plant Location", "Spend in Cr (FY24)", "SPOC Name", "SPOC Mail Id", "SPOC Contact No", "SPOC Mail Id #2", "SPOC Contact No #2", "SPOC Mail Id #3", "SPOC Contact No #3"]
        ];

        // Sample dropdown values
        const categorylist = categoryList.map(i => i.name)
        const plantList = plantlist.map(i => i.name)

        // Create a new workbook
        const workbook = await XlsxPopulate.fromBlankAsync();
        const sheet1 = workbook.addSheet("Supplier");
        workbook.deleteSheet("Sheet1"); // Delete default sheet

        // Add headers with styling
        headers[0].forEach((header, colIndex) => {
            sheet1.cell(1, colIndex + 1).value(header)
        });

        // Create a hidden "Validation" sheet for dropdown values
        const validationSheet = workbook.addSheet("Validation");
        plantList.forEach((val, idx) => validationSheet.cell(`B${idx + 1}`).value(val));

        workbook.definedName("PlantList", `Validation!$B$1:$B$${plantList.length}`);

        // Function to apply dropdown validation
        const applyDropdownValidation = (colLetter, rangeName) => {
            for (let row = 2; row <= 201; row++) { // 200 rows + header
                sheet1.cell(`${colLetter}${row}`).dataValidation({
                    type: "list",
                    allowBlank: false,
                    showDropDown: true,
                    formula1: `=${rangeName}`
                });
            }
        };


        applyDropdownValidation("E", "PlantList"); // "Plant Location" column



        // Generate the Excel file
        const blob = await workbook.outputAsync();
        FileSaver.saveAs(new Blob([blob], { type: "application/octet-stream" }), "Supplier_Import_Template.xlsx");


    };
    const downloadSuppliersData = () => {
        console.log(data)
        if (databk.length) {
            const excelData = [];

            for (const item of databk) {



                excelData.push({
                    'Vendor Code': item.code,
                    'Supplier Name': item.supplierName,
                    'Category': categoryList.find(x => x.value === item.supplierCategory)?.name || 'Not Found',
                    'Location': item.supplierLocation,
                    'Plant Location': plantlist.find(x => x.id === item.plantLocation)?.name || 'Not Found',
                    'Spend in Cr (FY24)': item.supplierSpentOn,
                    'SPOC Name': item.supplierSPOC,
                    'SPOC Mail Id': item.supplierEmail,
                    'SPOC Contact No': item.supplierContact,
                    'SPOC Mail Id #2': item.supplierEmail2,
                    'SPOC Contact No #2': item.supplierContact2,
                    'SPOC Mail Id #3': item.supplierEmail3,
                    'SPOC Contact No #3': item.supplierContact3
                });
            }


            const worksheet = XLSX.utils.json_to_sheet(excelData, { header: Object.keys(excelData[0]) });


            adjustColumnWidths(worksheet);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Supplier Master List');

            // Export the workbook
            XLSX.writeFile(workbook, `Supplier Master List.xlsx`);
        }
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="flex col-12 justify-content-between" >
                    <div className='grid m-0 p-0 col-8'>
                        <Button label="Download Master List" style={{ width: 180 }} className='mr-2' onClick={() => { downloadSuppliersData() }} />

                        <Button label="Download Template" style={{ width: 165 }} className='mr-2' onClick={() => { downloadExcelTemplate() }} />
                        <FileUpload
                            chooseOptions={{
                                label: "Import Supplier",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}

                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importSupplierFromExcel(e);
                            }}
                        />


                    </div>
                    <Button label="New Supplier" icon="pi pi-plus" style={{ width: 150 }} onClick={() => { setSupplier({ supplierSpentOn: 0, supplierName: '', supplierSPOC: '', supplierCode: '', supplierLocation: '', plantLocation: null, supplierEmail: '', supplierContact: '', supplierEmail2: '', supplierContact2: '', supplierEmail3: '', supplierContact3: '', supplierCategory: null }); setAddDialog(true) }} />

                </div>
            </React.Fragment>

        </div>
    )


    const checkValidMailID = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const addNewsupplier = () => {
        console.log(supplier)
        if (!supplier.id) {
            let loc = data
            setSubmitted(true)
            if (checkValidMailID(supplier.supplierEmail.trim()) && supplier.supplierCode && supplier.supplierCategory && supplier.supplierContact.trim().length && (supplier.supplierEmail2.trim().length ? checkValidMailID(supplier.supplierEmail2.trim()) : true) && (supplier.supplierEmail3.trim().length ? checkValidMailID(supplier.supplierEmail3.trim()) : true) && supplier.supplierName.trim().length !== 0 && supplier.supplierSPOC.trim().length !== 0 && supplier.supplierLocation.trim().length !== 0) {

                let obj = supplier
                let newObj = { information: {} }
                newObj['email'] = supplier.supplierEmail
                newObj['userId'] = login_data.id.toString();

                newObj['clientId'] = admin_data.id
                newObj['role'] = 'clientsupplier'
                newObj['supplierCode'] = supplier.supplierCode
                newObj.information['supplierContact'] = supplier.supplierContact
                newObj.information['supplierContact2'] = supplier.supplierContact2

                newObj.information['supplierCategory'] = supplier.supplierCategory
                newObj.information['supplierSPOC'] = supplier.supplierSPOC
                newObj.information['plantLocation'] = supplier.plantLocation
                newObj.information['supplierSpentOn'] = supplier.supplierSpentOn

                newObj.information['supplierSPOC'] = supplier.supplierSPOC
                newObj.information['supplierLocation'] = supplier.supplierLocation
                newObj.information['supplierContact3'] = supplier.supplierContact3
                newObj.information['supplierName'] = supplier.supplierName
                newObj.information['supplierEmail2'] = supplier.supplierEmail2
                newObj.information['supplierEmail3'] = supplier.supplierEmail3



                console.log(newObj)
                APIServices.post(API.External_Supplier_UP, newObj)
                    .then((res) => {
                        Swal.fire({
                            title: res.data.message,

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        })

                        if (res?.data?.status) {
                            setSubmitted(false)
                            setAddDialog(false)

                            renderData()
                        }

                    }).catch((e) => {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong while adding supplier in platform`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })




                setSupplier({ supplierSpentOn: 0, supplierName: '', supplierSPOC: '', supplierCode: '', supplierLocation: '', plantLocation: null, supplierEmail: '', supplierContact: '', supplierEmail2: '', supplierContact2: '', supplierEmail3: '', supplierContact3: '', supplierCategory: null })

                forceUpdate()
            }
        } else {
            setSubmitted(true)
            if (checkValidMailID(supplier.supplierEmail.trim()) && supplier.supplierCategory && supplier.supplierContact.trim().length && (supplier.supplierEmail2.trim().length ? checkValidMailID(supplier.supplierEmail2.trim()) : true) && (supplier.supplierEmail3.trim().length ? checkValidMailID(supplier.supplierEmail3.trim()) : true) && supplier.supplierName.trim().length !== 0 && supplier.supplierLocation.trim().length !== 0 && supplier.supplierSPOC.trim().length !== 0) {
                let obj = supplier
                let newObj = {
                    information: {
                        modified_on: DateTime.utc(),
                        modified_by: login_data.id
                    }
                }

                newObj.information['supplierContact'] = supplier.supplierContact
                newObj.information['supplierContact2'] = supplier.supplierContact2
                newObj.information['supplierCategory'] = supplier.supplierCategory
                newObj.information['supplierSPOC'] = supplier.supplierSPOC
                newObj.information['supplierLocation'] = supplier.supplierLocation
                newObj.information['supplierContact3'] = supplier.supplierContact3
                newObj.information['supplierName'] = supplier.supplierName
                newObj.information['supplierEmail2'] = supplier.supplierEmail2
                newObj.information['supplierEmail3'] = supplier.supplierEmail3
                newObj.information['plantLocation'] = supplier.plantLocation
                newObj.information['supplierSpentOn'] = supplier.supplierSpentOn
                if (newObj['clientId'] !== null) {
                    APIServices.patch(API.VendorCode_Edit(supplier.id), newObj.information)
                        .then((res) => {
                            setSupplier({ supplierSpentOn: 0, supplierName: '', supplierSPOC: '', supplierCode: '', supplierLocation: '', plantLocation: null, supplierEmail: '', supplierContact: '', supplierEmail2: '', supplierContact2: '', supplierEmail3: '', supplierContact3: '', supplierCategory: null })

                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: `Updated successfully`,
                                showConfirmButton: false,
                                timer: 1500
                            })

                            renderData()
                        }).catch((e) => {


                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: `Something went wrong`,
                                showConfirmButton: false,
                                timer: 1500
                            })

                        })
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Something went wrong kindly logout & try again.`,
                        showConfirmButton: false,
                        timer: 2500
                    })
                }
                setAddDialog(false)
                setSelectedConfig({ name: 'Assigned Locations' })
                setSubmitted(false)

            }
        }
    }
    const addDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewsupplier() }} />

        </div>)
    }
    const updateDataConfig = (data2) => {
        let id = data[selectedConfigIndex].id
        let apidatalocal = apidata
        let apiIndex = apidata.findIndex((i) => { return i.id === id })

        let newObj = apidatalocal[apiIndex]
        delete newObj.username;
        delete newObj.email;

        newObj.information.config = data2
        newObj.company = admin_data.information.companyname

        let promise = new Promise((resolve, error) => {
            APIServices.patch(API.UserProfile_Edit(id), newObj).then((res) => {
                if (res.status === 200) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        })
        promise.then((status) => {
            if (status) {
                renderData()
            }
        })
    }
    const saveClientSupplier = () => {
        let loc = data;
        if ((config.selectedLocationIds.size !== 0 && config.selectedLocationIds.length !== 0) && (config.admin.approver || config.admin.user)) {
            if (selectedConfigIndex !== null) {

                loc[selectedConfigIndex].config = [config]

                updateDataConfig([config])

            } else {
                let locc = supplier

                locc.config = [config]
                setSupplier(locc)

            }


            setConfigDialog(false)
            setSelectedConfigIndex(null)
        } else {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: `Location & Module should be selected`,
                showConfirmButton: false,
                timer: 1500
            })
        }

    }
    const addDialogFooter2 = (rowData) => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { saveClientSupplier() }} />

        </div>)
    }
    const cropandsave = async () => {

        let canvas = previewCanvasRef.current
        let URLs = canvas.toDataURL('image/png')
        let img = document.createElement('img')
        img.src = URLs
        img.style.height = canvas.height
        img.style.width = canvas.width

        setTimeout(() => {


            // atob to base64_decode the data-URI
            var image_data = atob(URLs.split(',')[1]);
            // Use typed arrays to convert the binary data to a Blob
            var arraybuffer = new ArrayBuffer(image_data.length);
            var view = new Uint8Array(arraybuffer);
            for (var i = 0; i < image_data.length; i++) {
                view[i] = image_data.charCodeAt(i) & 0xff;
            }
            try {
                // This is the recommended method:
                var blob = new Blob([arraybuffer], { type: 'application/octet-stream' });
            } catch (e) {
                // The BlobBuilder API has been deprecated in favour of Blob, but older
                // browsers don't know about the Blob constructor
                // IE10 also supports BlobBuilder, but since the `Blob` constructor
                //  also works, there's no need to add `MSBlobBuilder`.
                var bb = new (window.WebKitBlobBuilder || window.MozBlobBuilder);
                bb.append(arraybuffer);
                var blob = bb.getBlob('application/octet-stream'); // <-- Here's the Blob
            }

            // Use the URL object to create a temporary URL
            var url = (window.webkitURL || window.URL).createObjectURL(blob);
            let loc = supplier
            loc.logo = url
            setSupplier(supplier)
            setCropDialog(false)
        }, 1000)

    }

    const editSupplier = (user) => {
        console.log(user)
        if (typeof user.supplierContact === 'number') {
            user.supplierContact = user.supplierContact.toString()
        } else if (typeof user.supplierContact !== 'string') {
            user.supplierContact = ''
        }
        if (typeof user.supplierContact2 === 'number') {
            user.supplierContact2 = user.supplierContact2.toString()
        } else if (typeof user.supplierContact2 !== 'string') {
            user.supplierContact2 = ''
        }
        if (typeof user.supplierContact3 === 'number') {
            user.supplierContact3 = user.supplierContact3.toString()
        } else if (typeof user.supplierContact3 !== 'string') {
            user.supplierContact3 = ''
        }
        setAddDialog(true)
        setSupplier(JSON.parse(JSON.stringify(user)))
    }
    const deleteUser = (user) => {
        console.log(user);

        Swal.fire({
            title: 'Delete user ?' + user.empname,
            text: "You won't be able to reuse mail id " + user.supmailid,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete User!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { value: password } = await Swal.fire({
                    title: 'Enter Master password',
                    input: 'password',
                    inputLabel: 'Password',
                    inputPlaceholder: 'Enter Master password',
                    inputAttributes: {
                        maxlength: 10,
                        autocapitalize: 'off',
                        autocorrect: 'off'
                    }
                })

                if (password) {
                    if (password === 'failletter') {
                        APIServices.delete(API.UserProfile_Edit(user.id))
                            .then((res) => {


                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: `User deleted successfully`,
                                    showConfirmButton: false,
                                    timer: 1500
                                })

                                renderData()
                            }).catch((e) => {

                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: `Something went wrong`,
                                    showConfirmButton: false,
                                    timer: 1500
                                })

                            })
                    }
                }

            }
        })
    }
    const blockUser = (user) => {
        let title = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? 'Block user ' + user.empname + ' ?' : 'Unblock user ' + user.empname + ' ?'
        let text = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? "Once Blocked user cannot access the platform " : "Once user unblocked, they can access the platform "
        let cnftxt = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? 'Yes, Block User!' : 'Yes, Unblock User!'

        Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: cnftxt,
        }).then((result) => {
            if (result.isConfirmed) {
                let newObj = user.information;
                delete newObj.email
                delete newObj.username
                newObj.information['blocked'] = user.information.information['blocked'] === undefined ? true : !user.information.information['blocked'];
                console.log(newObj)
                APIServices.patch(API.UserProfile_Edit(user.id), newObj)
                    .then((res) => {


                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `User details updated successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                        renderData()
                    }).catch((e) => {

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
            }
        })
    }
    const nameTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>

                <label className='cur-pointer text-underline clr-navy fw-6' onClick={() => { editSupplier(rowData) }}> {rowData.supplierName}</label>
            </>
        );
    }
    const idTemplate = (rowData) => {
        console.log(rowData)
        return (
            <div className="flex flex-wrap gap-2">

                {rowData?.code || 'NA'}
            </div>
        );
    }
    const categoryTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                <span className="p-column-title">Approved</span>
                {categoryList.find(x => x.value === rowData.supplierCategory)?.name || 'Not Found'}
            </>
        );
    }
    const plantLocationTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                <span className="p-column-title">Approved</span>
                {getPlantLocationId(rowData.plantLocation)}
            </>
        );
    }
    const logoTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <img src={rowData.logo} width='50' />
            </>
        );
    }
    const configTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <i onClick={() => { openConfig(rowData) }} className='material-icons'>settings</i>
            </>
        );
    }
    const roleTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <span>{rowData.config[0].admin.approver ? 'Approver' : 'User'}</span>
            </>
        );
    }
    const editTemplate = (rowData) => {

        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <i onMouseOut={(e) => { e.currentTarget.style.color = '#495057' }} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'green' }} onClick={() => { editSupplier(rowData) }} className='material-icons'>edit</i>
                {/* <i onMouseOut={(e) => { e.currentTarget.style.color = '#495057' }} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'red' }} onClick={() => { deleteUser(rowData) }} className='material-icons'>delete</i> */}
                {/* <i onMouseOut={(e) => { e.currentTarget.style.color = rowData.information.information.blocked ? 'red' : '#495057' }} style={{ cursor: 'pointer', color: rowData.information.information.blocked ? 'red' : '#495057' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'red' }} onClick={() => { blockUser(rowData) }} className='material-icons'>block</i> */}

            </div>
        );
    }


    const addConfigCountry = () => {

        if (config.location.newcountry !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.newcountry.trim().toLowerCase() })

            if (index === -1) {
                loc.location.countrylist.push({ country: config.location.newcountry, city: [] })
                loc.location.newcountry = ''
                setConfig(loc)
                forceUpdate()
            }
        }

    }
    const addConfigCity = () => {

        if (config.location.newcity !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.selectedcountry.trim().toLowerCase() })

            let index2 = loc.location.countrylist[index].city.findIndex((i) => { return i.city.trim().toLowerCase() === config.location.newcity.trim().toLowerCase() })
            if (index2 === -1) {
                loc.location.countrylist[index].city.push({ city: config.location.newcity, location: [] })
                loc.location.newcity = ''
                setConfig(loc)
                forceUpdate()
            }

        }

    }
    const addConfigLocation = () => {
        if (config.location.newlocation !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.selectedcountry.trim().toLowerCase() })

            let index2 = loc.location.countrylist[index].city.findIndex((i) => { return i.city.trim().toLowerCase() === config.location.selectedcity.trim().toLowerCase() })

            let index3 = loc.location.countrylist[index].city[index2].location.findIndex((i) => { return i.location.trim().toLowerCase() === config.location.newlocation.trim().toLowerCase() })

            if (index3 === -1) {
                loc.location.countrylist[index].city[index2].location.push({ location: config.location.newlocation })
                loc.location.newlocation = ''
                setConfig(loc)
                forceUpdate()
            }

        }
    }
    const ArrowIcon = ({ isOpen, className }) => {
        const baseClass = "arrow";
        const classes = cx(
            baseClass,
            { [`${baseClass}--closed`]: !isOpen },
            { [`${baseClass}--open`]: isOpen },
            className
        );
        return <IoMdArrowDropright className={classes} />;
    };

    const CheckBoxIcon = ({ variant, ...rest }) => {
        switch (variant) {
            case "all":
                return <TbCheckbox style={{ fontSize: 19 }} {...rest} />;
            case "none":
                return <MdCheckBoxOutlineBlank style={{ fontSize: 19 }} {...rest} />;
            case "some":
                return <MdOutlineIndeterminateCheckBox style={{ fontSize: 19 }} {...rest} />;
            default:
                return null;
        }
    };
    const openConfig = (config) => {

        setSelectedConfigIndex(data.findIndex(((item) => { return item.id === config.id })))
        setSelectedConfig({ name: 'Location' })

        if (config.config.length === 0) {

            setConfig({ selectedLocationIds: [], selectedModuleIds: [], selectedLocation: [], selectedModule: [], admin: { approver: false, user: true } })
        } else {

            setConfig(config.config[0])
        }

        setConfigDialog(true)
    }
    const contactTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empid}
            </>
        );
    }
    const destTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empcontactname}
            </>
        );
    }
    const emailTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.supplierEmail}
            </>
        );
    }
    const sendMail = (rowData) => {

        return (
            <div onClick={() => {
                console.log(rowData)
                APIServices.post(API.SupplierResend, { userId: rowData.userProfileId, vendorId: rowData.id }).then((res) => {
                    renderData()
                    Swal.fire({
                        position: 'center',
                        title: 'Email Sent To Supplier',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }}>

                {!rowData.emailSentCount ? 'Send Mail' : 'Resend Mail'}
            </div>
        );
    }
    const userIDTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.supplierLocation}
            </>
        );
    }
    const updatesupplier = (obj, val) => {
        console.log(obj, typeof val)
        let loc = supplier
        loc[obj] = val
        setSupplier(loc)
        forceUpdate()
    }
    const centerAspectCrop = (
        mediaWidth,
        mediaHeight,
        aspect
    ) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }
    const onImageLoad = (e) => {

        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, 1))

    }
    const getCountryIndex = () => {
        let index = config.location.countrylist.findIndex((i) => { return i.country === config.location.selectedcountry })

        return index
    }
    const getCityIndex = () => {
        let index = config.location.countrylist[getCountryIndex()].city.findIndex((i) => { return i.city === config.location.selectedcity })
        return index
    }
    const vendorCodeFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data)).map(i => i.code).filter(i => i)

        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={allentity}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const plantLocationFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(id => plantlist.find(x => x.id === id.plantLocation) ? plantlist.find(x => x.id === id.plantLocation) : { name: 'Not Found', id: null })))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                optionValue='id'
                optionLabel='name'
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const resendFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(x => x.emailSentCount))).map(i => i ? 'Send Mail' : 'ResendMail')
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                optionValue='id'
                optionLabel='name'
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const categoryFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(id => categoryList.find(x => x.value === id.supplierCategory) ? categoryList.find(x => x.value === id.supplierCategory) : { name: 'Not Found', value: null })))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                optionValue='value'
                optionLabel='name'
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const onRoleChange = (e, obj) => {
        let loc = supplier
        loc.role[obj] = !e.value
        setSupplier(loc)
        console.log(loc)
        forceUpdate()
    }
    const addVendorTemplate = (e) => {

        return <div className='table-link-clickable' onClick={() => {
            console.log(e)
            setSelectedSupplier(e);
            setAddDialog(true)
            delete e.id
            setSupplier(JSON.parse(JSON.stringify(e)))
        }}  >Add Vendor Code </div>
    }
    const handleSave = () => {
        if (vendorCode.trim().length) {
            APIServices.post(API.VendorCode_SupplierUP(selectedSupplier.id), { code: vendorCode, clientId: admin_data.id, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                let loc = JSON.parse(JSON.stringify(data))
                let index = loc.findIndex(x => x.id === selectedSupplier.id)
                if (res.data.status) {
                    if (index !== -1) {
                        if (loc[index].vendorCodes) {
                            loc[index].vendorCodes.push(res.data.data)
                        } else {
                            loc[index].vendorCodes = [res.data.data]
                        }
                        setData(loc)
                        setAddVendorDialog(false)
                    }
                    Swal.fire({
                        position: 'center',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        position: 'center',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }
    const searchDF = (e) => {
        let loc = search;
        console.log(databk)
        setData(databk.filter((k) => { return k?.code?.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) || k?.supplierName?.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) || k?.supplierLocation?.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) || k?.supplierEmail?.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) }))

        setSearch(loc)
    }
    const downloadReport = (actual) => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(actual);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer);
        });
    }
    const downloadErrorReport = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);

        // Create a workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Suppliers");

        // Write file and trigger download
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(dataBlob, "Supplier_Multiple_Error_Import.xlsx");
    }
    const uploadSupplier = (data) => {
        setImportDialog(false)
        Swal.fire({
            title: 'Please Wait !',
            html: 'Importing Supplier into system, dont reload page ',// add html attribute if you want or remove
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        Swal.close()
        APIServices.post(API.BulkSupplierImport, data).then(async (res) => {
            console.log(res)
            if (!res.data.status) {
                const { value: accept } = await Swal.fire({
                    title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Error Report</div>`,
                    html: `<div style="overflow:auto;max-height:200px" >Error found in ${res?.data?.rejectedSupplier?.length} entries, download error report to more details</div>`,

                    confirmButtonColor: '#315975',
                    showCancelButton: true,

                    confirmButtonText:
                        'Download',

                })
                if (accept) {
                    downloadErrorReport(res?.data?.rejectedSupplier || [])
                }


            }
            renderData()
            Swal.close()
        }).catch((e) => {
            Swal.close()
        })
    }
    const saveAsExcelFile = (buffer) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, 'TVSM_Supplier_Import_Report_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    return (
        <div className="grid p-fluid">
            {(login_data.role === 'clientadmin' || allRoles.includes(12) || allRoles.includes(2)) ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{

                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`Supplier (${data.length})`} </h5>
                    </div>

                    <div>
                        <div className="col-12 flex justify-content-end" >
                            <div className='col-5'>
                                <span className="p-input-icon-left" style={{ width: '100%' }}>
                                    <i className="pi pi-search" />
                                    <InputText value={search.df} style={{ width: '100%' }} onChange={searchDF} placeholder="Search Supplier Code/Name/SPOC Mail/Location" />
                                </span>
                            </div>
                        </div>
                        <DataTable ref={dt} value={data} filters={{ code: { value: null, matchMode: 'in' }, plantLocation: { value: null, matchMode: 'in' }, supplierCategory: { value: null, matchMode: 'in' }, supplierCode: { value: null, matchMode: 'in' } }}
                            dataKey="id" paginator rows={25} rowsPerPageOptions={[25, 50, 100, 150]} className="datatable-responsive h-500"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            globalFilterFields={['empname']} globalFilter={filter} emptyMessage="No records found." header={header} responsiveLayout="scroll">
                            <Column field="code" header="Vendor Code" body={idTemplate} filter showFilterMatchModes={false} showApplyButton={true} filterElement={vendorCodeFilterTemplate}  ></Column>
                            <Column field="supplierName" header="Name" body={nameTemplate} ></Column>
                            <Column field="supplierCategory" header="Category" body={categoryTemplate} filter showFilterMatchModes={false} showApplyButton={true} filterElement={categoryFilterTemplate} ></Column>
                            <Column header="Location" field="supplierLocation" ></Column>
                            <Column header="Plant Location" field="plantLocation" body={plantLocationTemplate} filter showFilterMatchModes={false} showApplyButton={true} filterElement={plantLocationFilterTemplate} ></Column>
                            <Column field="supplierEmail" header="SPOC Mail ID" body={emailTemplate} ></Column>
                            <Column header="Spend(FY24)" sortable field="supplierSpentOn"></Column>
                            {/* <Column header="Action" body={addVendorTemplate}></Column> */}

                            <Column header="E-Mail Action" sortable field="emailSpentCount" body={sendMail}  ></Column>


                        </DataTable>
                        <Dialog visible={adddialog} style={{ width: '450px' }} header={supplier.id ? "Edit Supplier" : "Add Supplier"} modal className="p-fluid" footer={addDialogFooter} onHide={() => { setSubmitted(false); setAddDialog(false) }}>

                            <div className="field">
                                <label htmlFor="refdate">Supplier Company Name <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} value={supplier.supplierName} onChange={(e) => { updatesupplier('supplierName', e.target.value) }} placeholder="Name" />
                                {submitted && supplier.supplierName.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Supplier Company Name is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Vendor Code  <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} disabled={supplier.id} value={supplier.id ? supplier.code : supplier.supplierCode} onChange={(e) => { updatesupplier('supplierCode', e.target.value) }} placeholder="Vendor Code" />
                                {submitted && (!supplier.supplierCode || supplier.supplierCode.trim().length === 0) && <small className="p-invalid" style={{ color: 'red' }}>Vendor is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Category <span style={{ color: 'red' }}>*</span></label>
                                <Dropdown value={supplier.supplierCategory} optionLabel='name' onChange={(e) => { updatesupplier('supplierCategory', e.target.value) }} placeholder="Category" options={categoryList} />
                                {submitted && supplier.supplierCategory === null && <small className="p-invalid" style={{ color: 'red' }}>Supplier Category is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Spend in Cr (FY24) </label>
                                <InputNumber minFractionDigits={2} value={supplier.supplierSpentOn} onChange={(e) => { updatesupplier('supplierSpentOn', e.value) }} placeholder="Supplier Spent On" />


                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Supplier Location <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} value={supplier.supplierLocation} onChange={(e) => { updatesupplier('supplierLocation', e.target.value) }} placeholder="Supplier location name" />
                                {submitted && supplier.supplierLocation.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Supplier location is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Plant Location <span style={{ color: 'red' }}>*</span></label>
                                <Dropdown value={supplier.plantLocation} optionLabel='name' optionValue='id' onChange={(e) => { updatesupplier('plantLocation', e.value) }} placeholder="Plant Location" options={plantlist} />
                                {submitted && supplier.plantLocation === null && <small className="p-invalid" style={{ color: 'red' }}>Plant Location is mandatory</small>}


                            </div>
                            <label className='fw-6 fs-18 clr-navy mb-2'>Contact Detail #1 (SPOC) <span style={{ color: 'red' }}>*</span> </label>
                            <div className="field">
                                <label htmlFor="refdate">Supplier SPOC Name<span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} value={supplier.supplierSPOC} onChange={(e) => { updatesupplier('supplierSPOC', e.target.value) }} placeholder="SPOC Name" />
                                {submitted && supplier.supplierSPOC.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Supplier SPOC Name is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Supplier E-Mail Id <span style={{ color: 'red' }}>*</span></label>
                                <InputText disabled={supplier.id} type={'email'} value={supplier.supplierEmail} onChange={(e) => { updatesupplier('supplierEmail', e.target.value) }} placeholder="Mail ID" />
                                {submitted && !checkValidMailID(supplier.supplierEmail.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Supplier Mail ID is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Supplier Contact No <span style={{ color: 'red' }}>*</span></label>
                                <InputText keyfilter="int" value={supplier.supplierContact} onChange={(e) => { updatesupplier('supplierContact', e.target.value) }} placeholder="supplierContact" />

                                {submitted && !supplier.supplierContact && <small className="p-invalid" style={{ color: 'red' }}>Supplier Contact No is mandatory</small>}

                            </div>
                            <label className='fw-6 fs-18 clr-navy mb-2'>Contact Detail #2 </label>
                            <div className="field">
                                <label htmlFor="refdate">Supplier E-Mail Id #2 </label>
                                <InputText type={'email'} value={supplier.supplierEmail2} onChange={(e) => { updatesupplier('supplierEmail2', e.target.value) }} placeholder="Mail ID" />
                                {submitted && supplier.supplierEmail2.trim().length !== 0 && !checkValidMailID(supplier.supplierEmail2.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Supplier Contact No #2</label>
                                <InputText keyfilter="int" value={supplier.supplierContact2} onChange={(e) => { updatesupplier('supplierContact2', e.target.value) }} placeholder="Supplier Contact #2" />


                            </div>
                            <label className='fw-6 fs-18 clr-navy mb-2'>Contact Detail #3 </label>
                            <div className="field">
                                <label htmlFor="refdate">Supplier E-Mail Id #3 </label>
                                <InputText type={'email'} value={supplier.supplierEmail3} onChange={(e) => { updatesupplier('supplierEmail3', e.target.value) }} placeholder="Mail ID" />
                                {submitted && supplier.supplierEmail3.trim().length !== 0 && !checkValidMailID(supplier.supplierEmail3.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Invalid Mail Id</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Supplier Contact No #3</label>
                                <InputText keyfilter="int" value={supplier.supplierContact3} onChange={(e) => { updatesupplier('supplierContact3', e.target.value) }} placeholder="Supplier Contact #3" />




                            </div>





                        </Dialog>
                        <Dialog
                            header={"Add Vendor Code for " + selectedSupplier?.supplierName}
                            visible={false}
                            style={{ width: "30vw" }}
                            onHide={() => setAddVendorDialog(false)}
                            footer={
                                <div className="flex justify-content-end gap-2">
                                    <Button label="Close" className="p-button-secondary" onClick={() => setAddVendorDialog(false)} />
                                    <Button label="Save" icon="pi pi-save" onClick={handleSave} />
                                </div>
                            }
                        >
                            <div className="flex flex-column gap-3">
                                <label htmlFor="vendorCode" className="font-semibold">
                                    Vendor Code
                                </label>
                                <InputText
                                    id="vendorCode"
                                    value={vendorCode}
                                    onChange={(e) => setVendorCode(e.target.value)}
                                    placeholder="Enter Vendor Code"
                                    className="w-full"
                                />
                            </div>
                        </Dialog>
                        <Dialog visible={importdialog} style={{ width: '85%' }} onHide={() => { setImportDialog(false) }} header="Supplier Import Validation Report" >



                            <div className='col-12 flex justify-content-end'>
                                <Button style={{ width: 200 }} label="Download Report" className="mr-2" onClick={() => { downloadReport([...importentries.invalidRows, ...importentries.validRows]) }} />
                                {importentries?.actualRows?.length !== 0 && <Button style={{ width: 200 }} label="Upload Entries" onClick={() => { uploadSupplier(importentries.actualRows) }} />}
                            </div>

                            <TabView
                                activeIndex={importentries?.validRows?.length ? 0 : 1}

                                panelContainerStyle={{ height: "calc(100vh - 200px)" }}
                            >
                                {importentries?.validRows?.length && <TabPanel
                                    header={
                                        `Valid Supplier Entries ${importentries?.validRows?.length}`
                                    }
                                >
                                    <DataTable className='h-max' value={importentries?.validRows} scrollable >
                                        {excelHeader.map((x) =>
                                            (<Column header={x} field={x} />)
                                        )}
                                    </DataTable>

                                </TabPanel>}

                                {importentries?.invalidRows?.length && <TabPanel
                                    header={
                                        `InValid Supplier Entries ${importentries?.invalidRows?.length}`
                                    }
                                >
                                    <DataTable className='h-max' value={importentries?.invalidRows} scrollable >
                                        {[...excelHeader, 'Remarks'].map((x) =>
                                            (<Column header={x} field={x} />)
                                        )}
                                    </DataTable>

                                </TabPanel>}
                            </TabView>

                        </Dialog>

                    </div>







                </div>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
        </div>
    )
}

export default AddClientSupplierTVS



