// @ts-nocheck
import React, { useRef, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

const ListBoxItem = (props) => {
    const history = useHistory();
    const nameRef = useRef(null)
    const op = useRef(null);

    // const [active, setActive] = useState(props.data.flag ? props.data.flag : false)

    const handleNameUpdate = () => {
        const newName = nameRef.current.textContent.trim();

        props.onHandleEditItem(props.data.id, newName)


    };


    const handleFlagUpdate = (flag) => {

        // setActive(flag)
        props.onHandleEditFlag(props.data.id, flag)


    };
    const menuItems = [
        ...(props.mode === 'tier3'
            ? [{
                label: 'Curate',
                icon: 'pi pi-file',
                command: () => props.onHandleCurate(props.data.id)
            }]
            : []
        ),
        {
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => props.onHandleDelete(props.data.id)
        }
    ];


    return (
        <>

            <div className={`list d-flex justify-content-between align-items-center p-3 border-bottom cursor-pointer ${(props.selectedId === props.data.id ? "active" : '')}`} onClick={() => props.onHandleSelect(props.data.id)}>

                <div className="content">
                    <p
                        className="content-editable list-name m-0"
                        ref={nameRef}
                        contentEditable="true"
                        onBlur={handleNameUpdate}
                        autoComplete="off"
                    >
                        {props.data.name}
                    </p>
                </div>
                <div className="options d-flex align-items-center">
                    {props.mode === 'tier3' &&
                        <p style={{ marginBottom: 0 }}>
                            {props.data.srfId && `SRF-${props.data.srfId}`}
                        </p>

                    }
                    <Button
                        icon="pi pi-ellipsis-v"
                        className="p-button-text p-0"
                        onClick={(e) => op.current.toggle(e)}
                        aria-haspopup
                        aria-controls="overlay_menu"
                    />
                    <Menu model={menuItems} popup ref={op} id="overlay_menu" />
                </div>
            </div>



        </>
    )
}

ListBoxItem.defaultProps = {
    flag: false
};

export default ListBoxItem;