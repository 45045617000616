import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const SubGraph2Demo = ({ supplyData,selectedSite }) => {
  const [chartData, setChartData] = useState([]);
  const [selectedbu,setSelectedBU]= useState(selectedSite)

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul 
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  // Define colors for each MSI rating
  const COLORS = {
    Platinum: "#CCCED5",
    Gold: "#F5C37B",
    Silver: "#EAECF0",
    Bronze: "#D28B24",
    "Needs Improvement": "#EE5724",
  };

  const renderCustomizedLabel = ({ value, x, y }) => {
    return (
      <text
        x={ x}
        y={y}
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={12}
        fontWeight={600}
      >
        {selectedSite === 'All' ?  value:  ''}
      </text>
    );
  };

  useEffect(() => {
    if (supplyData.length > 0) {
      // Categorize suppliers based on MSI score
      const ratingCounts = {
        Platinum: 0,
        Gold: 0,
        Silver: 0,
        Bronze: 0,
        "Needs Improvement": 0,
      };

      supplyData.forEach((item) => {
        if (item.msi_score > 86) ratingCounts["Platinum"]++;
        else if (item.msi_score >= 71) ratingCounts["Gold"]++;
        else if (item.msi_score >= 56) ratingCounts["Silver"]++;
        else if (item.msi_score >= 41) ratingCounts["Bronze"]++;
        else ratingCounts["Needs Improvement"]++;
      });

      // Convert to chart data format
      const processedData = Object.entries(ratingCounts).map(
        ([rating, count]) => ({
          name: rating,
          value: count,
          color: COLORS[rating], // Add color from the COLORS object
        })
      );

      setChartData(processedData);
    }
  }, [supplyData]);

  return (
    <div className="container mt-4">
      {/* Charts */}

      <h5 className="mb-3 text-center text-dark">
        Distribution
      </h5>

      <ResponsiveContainer width={600} height={400}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            label={renderCustomizedLabel}
            labelLine={selectedSite === 'All'}
           
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
          <Legend  content={CustomLegend} wrapperStyle={{bottom:-35}}  />
        </PieChart>
      </ResponsiveContainer>

    </div>
  );
};

export default SubGraph2Demo;
