import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App';
// import App from './STTApp';
//import * as serviceWorker from './serviceWorker';


import { Provider } from 'react-redux';
import store from './RTK/store';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react'
import Interceptor from '../src/service/setupInterceptor'
import { BrowserRouter } from 'react-router-dom';

let persistor = persistStore(store)
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </BrowserRouter>
);

Interceptor(store)
