import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const GovernanceChart = ({ data }) => {
  // Function to filter data based on category
  const filterDataByCategory = (category) =>
    data.filter((entry) => entry.category === category);

  // Function to compute average values and max values for comparison
  const aggregateData = (filteredData) => {
    const totals = {
      legal_requirement: 0,
      fair_business_practices: 0,
      data_privacy: 0,
    };
    const maxValues = {
      legal_requirement: 5,
      fair_business_practices: 5,
      data_privacy: 5,
    };
    let count = filteredData.length || 1; // Prevent division by zero

    filteredData.forEach((entry) => {
      const governance = isNaN(entry.legal_requirement) ? 0 : entry.legal_requirement;
      const fairBusinessPractices = isNaN(entry.fair_business_practices)
        ? 0
        : entry.fair_business_practices;
      const dataPrivacy = isNaN(entry.data_privacy) ? 0 : entry.data_privacy;

      totals.legal_requirement += governance;
      totals.fair_business_practices += fairBusinessPractices;
      totals.data_privacy += dataPrivacy;
    });

    // Helper function to clamp values between 0 and maxValue for graph display
    const clampForGraph = (value) => Math.max(0, value);
    const clampRemaining = (avgValue, maxValue) =>
      Math.max(0, Math.min(maxValue, maxValue - avgValue));

    return [
      {
        category: "Governance",
        avgValue: totals.legal_requirement / count,
        avgValueForGraph: clampForGraph(totals.legal_requirement / count),
        maxValue: maxValues.legal_requirement,
        remainingToMax: clampRemaining(
          totals.legal_requirement / count,
          maxValues.legal_requirement
        ),
      },
      {
        category: "Fair Business Practices",
        avgValue: totals.fair_business_practices / count,
        avgValueForGraph: clampForGraph(totals.fair_business_practices / count),
        maxValue: maxValues.fair_business_practices,
        remainingToMax: clampRemaining(
          totals.fair_business_practices / count,
          maxValues.fair_business_practices
        ),
      },
      {
        category: "Data Privacy",
        avgValue: totals.data_privacy / count,
        avgValueForGraph: clampForGraph(totals.data_privacy / count),
        maxValue: maxValues.data_privacy,
        remainingToMax: clampRemaining(
          totals.data_privacy / count,
          maxValues.data_privacy
        ),
      },
    ];
  };

  const salesData = aggregateData(filterDataByCategory("Sales"));
  const serviceData = aggregateData(filterDataByCategory("Service"));

  // Function to determine Y-Axis domain based on values
  const getYAxisDomain = (data) => {
    const allValues = data.flatMap((item) => [
      item.avgValueForGraph,
      item.maxValue,
    ]);
    const maxValue = Math.max(...allValues);
    return [0, maxValue];
  };

  const salesYAxisDomain = getYAxisDomain(salesData);
  const serviceYAxisDomain = getYAxisDomain(serviceData);

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      {/* Sales Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Sales - Governance
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={salesData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={salesYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Achieved") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              fill="#4A90E2"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              fill="#AFCBFF"
              name="Maximum"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Service Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Service - Governance
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={serviceData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={serviceYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Achieved") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              fill="#4A90E2"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              fill="#AFCBFF"
              name="Maximum"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GovernanceChart;
