import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = [
  {
    year: "FY-2023",
    e_waste: 29.33,
    waste_sent_to_landfill: 80.74,
    waste_sent_for_coprocessing: 2956.13,
    battery_waste: 114.17,
    waste_recovered: 34.68,
    other_hazardous_waste: 1422.24,
    biomedicalWasteIncineration: 0.4,
  },
  {
    year: "FY-2024",
    e_waste: 0,
    waste_sent_to_landfill: 0,
    waste_sent_for_coprocessing: 0,
    battery_waste: 0,
    waste_recovered: 0,
    other_hazardous_waste: 0,
    biomedicalWasteIncineration: 0,
  },
];

const DemoHazardous = () => {
  return (
    <div style={{ height: 500 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Hazardous Waste Disposed
      </h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />

          <YAxis>
            <Label
              value="Million Tons (MT)"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />

          <Bar
            dataKey="battery_waste"
            name="Battery Waste"
            fill="#FFD6C2"
            barSize={60}
          />
          <Bar
            dataKey="waste_sent_for_coprocessing"
            name="Waste sent for Coprocessing"
            fill="#FFC1A9"
            barSize={60}
          />
          <Bar
            dataKey="biomedicalWasteIncineration"
            name="Bio-medical Waste Incineration"
            fill="#FFB09B"
            barSize={60}
          />
          <Bar dataKey="e_waste" name="E-Waste" fill="#FF9878" barSize={60} />

          <Bar
            dataKey="waste_sent_to_landfill"
            name="Waste sent to Landfill"
            fill="#FF9878"
            barSize={60}
          />
          <Bar
            dataKey="waste_recovered"
            name="Waste Recovered"
            fill="#E67D62"
            barSize={60}
          />

          <Bar
            dataKey="other_hazardous_waste"
            name="Other Hazardous Waste"
            fill="#CC6951"
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DemoHazardous;
