import React, { useEffect, useState } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const SubGraph1Demo = ({ supplyData }) => {
  const [chartData, setChartData] = useState([]);

  const customTooltip = ({ payload, label }) => {
    if (!payload || payload.length === 0) return null;

    const { category, achievedScore, maxScore } = payload[0].payload;

    // Set the maxScore based on the category
    let maxScoreValue = 0;
    if (category === "Environment") maxScoreValue = 40;
    else if (category === "Social") maxScoreValue = 50;
    else if (category === "Governance") maxScoreValue = 10;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      >
        <h4>{category}</h4>
        <p>
          <strong>Achieved Score:</strong> {achievedScore}
        </p>
        <p>
          <strong>Maximum Score:</strong> {maxScoreValue}
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (supplyData.length > 0) {
      // Compute average scores for each ESG category
      const totalSuppliers = 1

      const totalEnv = supplyData.filter(x=>x.esg === 1).reduce(
        (sum, item) => sum + item.sectionTotalScore,
        0
      );
      const totalSocial = supplyData.filter(x=>x.esg === 2).reduce(
        (sum, item) => sum + item.sectionTotalScore,
        0
      );
      const totalGov = supplyData.filter(x=>x.esg === 3).reduce(
        (sum, item) => sum + item.sectionTotalScore,
        0
      );
console.log(totalEnv,totalSocial,totalGov,supplyData)
      const avgEnv = (totalEnv / totalSuppliers).toFixed(1);
      const avgSocial = (totalSocial / totalSuppliers).toFixed(1);
      const avgGov = (totalGov / totalSuppliers).toFixed(1);

      setChartData([
        {
          category: "Environment",
          achievedScore: avgEnv,
          maxScore: 40 - avgEnv,
          achievedColor: "#2C7C69",
          maxColor: "#7FC8A9",
        },
        {
          category: "Social",
          achievedScore: avgSocial,
          maxScore: 50 - avgSocial,
          achievedColor: "#FC6E51",
          maxColor: "#FEB2A8",
        },
        {
          category: "Governance",
          achievedScore: avgGov,
          maxScore: 10 - avgGov,
          achievedColor: "#4A90E2",
          maxColor: "#AFCBFF",
        },
      ]);
    }
  }, [supplyData]);

  return (
    <div className="container mt-4" style={{background:'#DAF3EF'}}>
      <h5 className="mb-3 text-center text-dark">
        ESG Score of Calibrated Suppliers
      </h5>

      <ResponsiveContainer width={600} height={400}>
        <BarChart
          data={chartData}
          barSize={60}
          margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="category" />
          <YAxis domain={[0, 50]} />
          <Tooltip content={customTooltip} />
          {/* Render achieved score bar with conditionally assigned colors */}
          <Bar dataKey="achievedScore" stackId="a" name="Achieved Score">
            {chartData.map((entry, index) => (
              <Cell key={`cell-achieved-${index}`} fill={entry.achievedColor} />
            ))}
            <LabelList
              dataKey="achievedScore"
              position="insideBottom"
              style={{ fontSize: "12px", fill: "white" }}
            />
          </Bar>
          {/* Render remaining score bar with conditionally assigned colors */}
          <Bar dataKey="maxScore" stackId="a" name="Max Score">
            {chartData.map((entry, index) => (
              <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
            ))}
            <LabelList
              dataKey="maxScore"
              position="top"
              content={({ x, y, width, value, index }) => {
                let hardcodedMaxScore = 0;

                // Set the hardcoded value based on the category
                if (chartData[index].category === "Environment") {
                  hardcodedMaxScore = 40;
                } else if (chartData[index].category === "Social") {
                  hardcodedMaxScore = 50;
                } else if (chartData[index].category === "Governance") {
                  hardcodedMaxScore = 10;
                }

                return (
                  <text
                    x={x + width / 2} // Center the label
                    y={y - 10} // Position label above the bar
                    textAnchor="middle"
                    fill="black"
                    fontSize="12px"
                  >
                    {hardcodedMaxScore}
                  </text>
                );
              }}
            />
          </Bar>
        </BarChart>
        {/* Custom legend for colors */}
        <div
          className="legend"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div>
            <span
              style={{
                color: "#7FC8A9",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%", // Make it round
                backgroundColor: "#7FC8A9",
                marginRight: "5px",
              }}
            ></span>
          </div>
          <div>
            <span
              style={{
                color: "#FFB6C1",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%", // Make it round
                backgroundColor: "#FFB6C1",
                marginRight: "5px",
              }}
            ></span>
          </div>
          <div style={{ marginRight: "30px" }}>
            <span
              style={{
                color: "#AFCBFF",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%", // Make it round
                backgroundColor: "#AFCBFF",
                marginRight: "5px",
              }}
            ></span>
            Maximum
          </div>
          <div>
            <span
              style={{
                color: "#2C7C69",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%", // Make it round
                backgroundColor: "#2C7C69",
                marginRight: "5px",
              }}
            ></span>
          </div>
          <div>
            <span
              style={{
                color: "#FF6F61",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%", // Make it round
                backgroundColor: "#FF6F61",
                marginRight: "5px",
              }}
            ></span>
          </div>

          <div>
            <span
              style={{
                color: "#4A90E2",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%", // Make it round
                backgroundColor: "#4A90E2",
                marginRight: "5px",
              }}
            ></span>
            Achieved
          </div>
        </div>
      </ResponsiveContainer>
    </div>
  );
};

export default SubGraph1Demo;
