import React, { useState, useEffect, useRef } from "react";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import 'primeflex/primeflex.css';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { MultiSelect } from "primereact/multiselect";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import * as XLSX from 'xlsx';
import XlsxPopulate from "xlsx-populate";
import { saveAs } from 'file-saver';
import { checkRoleAccessByRoleIds, getFiscalYearsFromStartDate, getLocationData } from "../../components/BGHF/helper";
const OverallValueChainSubmissionStatus = () => {
    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);

    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [asssrflist, setAssSrfList] = useState([])
    const [masterlist, setMasterList] = useState([])

    const [yearoptions, setYearOptions] = useState([])
    const [rawsitelist, setRawSiteList] = useState([])
    const [data, setData] = useState([])
    const [databk, setDataBk] = useState([])
    const [activetab, setActiveTab] = useState(6)
    const dt = useRef(null)
    const userList = useSelector(state => state.userlist.userList)
    const supplierList = useSelector(state => state.userlist.supplierList)
    const dealerList = useSelector(state => state.userlist.dealerList)

    const [loading, setLoading] = useState(true)
    const [refData, setRefData] = useState([])
    const [submission, setSubmission] = useState([])
    const [assignmentlist, setAssignmentList] = useState([])


    const [srflist, setSrfList] = useState([])
    const [entityList, setEntityList] = useState([])



    const [filter, setFilter] = useState({ year: null, searchstr: '', srf: 0, partner: 0, partnerType: 1 })
    useEffect(() => {
        let selectedYear = null
        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate, fymonth)
        if (yrOptions.length !== 0) {
            selectedYear = yrOptions[yrOptions.length - 1].name
            setFilter((prev) => ({ ...prev, year: selectedYear }))
        }
        console.log(yrOptions)
        setYearOptions(yrOptions)
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };

        let uriString2 = {
            include: [
                {
                    relation: "newTopics",
                    scope: {
                        include: [
                            {
                                relation: "newMetrics",
                                scope: { include: [{ relation: "newDataPoints" }] },
                            },
                        ],
                    },
                },
            ],
        };
        let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;

        const promise0 = APIServices.get(Overall)
        const promise1 = APIServices.get(API.SRF_Title_Only)
        const promise2 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        // const promise3 = APIServices.get(
        //     API.LocationOne_UP(admin_data.id) +
        //     `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        // );
        // const promise4 = APIServices.get(
        //     API.DCF_Entity_UP(admin_data.id)
        // );
        const promise3 = APIServices.get(
            API.SRF_Entity_User_UP(admin_data.id)
        );
        const promise4 = APIServices.get(
            API.ValueChainSubmission_UP(admin_data.id)
        );
        const promise5 = APIServices.get(API.Supplier_UP(admin_data.id))
        const promise6 = APIServices.get(API.Dealer_UP(admin_data.id))

        // const promise7 = APIServices.get(API.GetRole_Up(admin_data.id))
        Promise.all([
            promise0,
            promise1,
            promise2,
            promise3,
            promise4, promise5, promise6]).then((values) => {

                let curated_srf_ids = [], srf_list = values[1].data.filter(x => (!x.tags || x.tags?.includes(admin_data.id))), quantitative_ass_list = values[3].data.filter(x => x.srfId !== 86), quantitative_entity_list = values[4].data
                let quantitative_submitted = values[4].data
                setMasterList([...values[5].data, ...values[6].data].map(i => ({ ...i, name: i.code + " : " + (i.supplierName || i.dealerName || '') })))


                if (values[2].data.length && values[2].data[0].cf_ids && values[2].data[0].cf_ids.length) {
                    curated_srf_ids = values[2].data[0].cf_ids.filter(i => srf_list.map(x => x.id).includes(i)).map(i => srf_list.find(x => x.id === i))

                    setAssSrfList(curated_srf_ids)
                    setSrfList(curated_srf_ids.filter(x => x.formType === 1))
                }

                setSubmission(quantitative_submitted)
                let filtered_qn_ass = quantitative_ass_list.filter(
                    (i) =>
                        srf_list.map((j) => j.id).includes(i.srfId)
                );
                // setSrfList(srf_list.filter(i => curated_srf_ids.includes(i.id)))


                setAssignmentList(filtered_qn_ass)
                // setRawSiteList(shapedSite)
                console.log(srf_list);
                console.log(curated_srf_ids);
                console.log([...values[5].data, ...values[6].data])
                if (selectedYear && admin_data) {
                    let loc = filterSubmissionStatus([], quantitative_submitted, filtered_qn_ass.filter(x => x.type === 1), selectedYear)
                    let entityOption = [{ name: 'All' }]

                    const mappedData = loc.map(item => {

                        return {
                            ...item,
                            reporters: item.reporter_ids.map(id => [...values[5].data, ...values[6].data].find(x => x.id === id)).map(x => x?.code + " : " + (x?.supplierName || x?.dealerName || '')),
                            // reviewers: item.reviewer_ids.map(id => getUser(id)).filter(Boolean),
                            // approvers: item.approver_ids.map(id => getUser(id)).filter(Boolean)
                        };
                    });
                    console.log(mappedData)
                    setEntityList(entityOption)
                    setData(mappedData.filter(i => i.status === activetab))
                    setDataBk(mappedData)
                    setLoading(false)
                }
                console.log(filtered_qn_ass)
            })


    }, [])
    const getUser = (id) => {
        if (id === admin_data.id) {
            return "Enterprise Admin";
        }
        console.log(userLookup[id])
        return userLookup[id]?.code + " : " + (userLookup[id]?.supplierName || userLookup[id]?.dealerName || '')
    };

    const userLookup = masterlist.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
    }, {});

    function getOverdueDays(monthString) {
        console.log(monthString)
        const [startMonth, endMonth] = monthString.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days;
    }
    const filterSubmissionStatus = (shapedsite, quantitative_submitted, filtered_qn_ass, selectedYear) => {
        let submissionStatus = []
        let filteredAssignment = filterAssignmentsByFiscalYear(filtered_qn_ass, selectedYear)
        console.log(filteredAssignment, quantitative_submitted)
        if (filteredAssignment.length) {
            for (const item of filteredAssignment) {
                let months = getMonthsBetween(item.start_date, item.end_date, item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency, selectedYear, fymonth)
                console.log(months)
                for (const mn of months) {
                    let index = quantitative_submitted.findIndex(i => (mn === getRPTextFormat(i.reporting_period) && i.entityAssId === item.entityAssId && i.entityUserAssId === item.id && i.srfId === item.srfId && i.tier0_id === item.tier0_id && i.tier1_id === item.tier1_id && i.tier2_id === item.tier2_id && i.tier3_id === item.tier3_id))
                    if (index !== -1) {
                        let type = quantitative_submitted[index].type
                        let reject = quantitative_submitted[index].reject
                        let status = (type === 0 && (reject === 0 || reject === null)) ? 0 :
                            (type === 0 && (reject === 1 || reject === 2))
                                ? 1
                                : type === 1 && reject === 1
                                    ? 2
                                    : type === 1
                                        ? 3
                                        : type === 2
                                            ? 4
                                            : type === 3
                                                ? 5
                                                : null
                        if (status === 0 || status === 1) {

                            status = getOverdueDays(mn) <= -DateTime.utc().toLocal().daysInMonth ? 7 : getOverdueDays(mn) <= 0 ? 6 : 99


                        }


                        submissionStatus.push({ ...item, dueMonth: getDueMonth(mn), reporting_period: mn, data: quantitative_submitted[index], status, coverage: getCoverageText(item, shapedsite) })
                    } else {
                        let status = getOverdueDays(mn) <= -DateTime.utc().toLocal().daysInMonth ? 7 : getOverdueDays(mn) <= 0 ? 6 : 100

                        submissionStatus.push({ ...item, dueMonth: getDueMonth(mn), reporting_period: mn, status, coverage: getCoverageText(item, shapedsite) })
                    }
                }

            }
        }
        return submissionStatus
    }
    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                    "LLL-yyyy"
                );
                const endDate = DateTime.fromFormat(
                    item[item.length - 1],
                    "MM-yyyy"
                ).toFormat("LLL-yyyy");
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
            }
        }
    };

    const filterAssignmentsByFiscalYear = (assignments, year) => {
        const { startDate, endDate } = getFiscalYearRange(year, fymonth);
        const currentDate = DateTime.local().startOf('day');

        return assignments.filter(assignment => {
            console.log(assignment)
            const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).startOf('day') : currentDate;
            const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('day') : currentDate;

            return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
                (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
                (assignmentStartDate <= startDate && assignmentEndDate >= endDate);
        });
    };
    const getFiscalYearRange = (year, fymonth) => {
        let startDate, endDate;
        console.log(fymonth)

        if (fymonth === 1) {
            startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
        } else {
            startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
        }

        return { startDate, endDate };
    };
    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];
            console.log(entity);
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            }
        } else {
            return false;
        }
    };
    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";
        console.log(rowData);
        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    function getMonthsBetween(start_date, end_date, frequency, filterYear, fymonth) {
        let fyStartDate, fyEndDate;

        if (fymonth === 1) {
            // Calendar year: Jan to Dec of the filterYear
            fyStartDate = DateTime.fromObject({ year: filterYear, month: 1, day: 1 });
            fyEndDate = DateTime.fromObject({ year: filterYear, month: 12, day: 31 });
        } else {
            // Fiscal year: Apr (fymonth) of the previous year to Mar (fymonth - 1) of the filterYear
            fyStartDate = DateTime.fromObject({ year: filterYear - 1, month: fymonth, day: 1 });
            fyEndDate = DateTime.fromObject({ year: filterYear, month: fymonth - 1, day: 1 }).endOf('month');
        }

        // Override with provided start_date and end_date if available
        const userStartDate = start_date ? DateTime.fromISO(start_date, { zone: 'utc' }).startOf('month') : fyStartDate;
        const userEndDate = end_date ? DateTime.fromISO(end_date, { zone: 'utc' }).endOf('month') : fyEndDate;

        // Adjust the final start and end dates to be within the fiscal year range
        const finalStartDate = userStartDate < fyStartDate ? fyStartDate : userStartDate;
        const finalEndDate = userEndDate > fyEndDate ? fyEndDate : userEndDate;

        const months = [];
        let currentMonth = finalStartDate;

        while (currentMonth <= finalEndDate) {
            if (frequency === 1) {
                months.push(currentMonth.toFormat("LLL-yyyy"));
            } else {
                const periodEnd = currentMonth.plus({ months: frequency - 1 });
                if (periodEnd <= finalEndDate) {
                    months.push(
                        currentMonth.toFormat("LLL-yyyy") +
                        " to " +
                        periodEnd.toFormat("LLL-yyyy")
                    );
                }
            }
            currentMonth = currentMonth.plus({ months: frequency });
        }

        return months;
    }
    const updateYearFilter = (val, obj) => {
        let loc = { ...filter, [obj]: val }


        if (obj === 'year') {
            let data = filterSubmissionStatus(rawsitelist, submission, assignmentlist.filter(x => x.type === loc.partnerType), val)

            const mappedData = data.map(item => {

                return {
                    ...item,
                    reporters: item.reporter_ids.map(id => getUser(id)).filter(Boolean),

                };
            });
            setSrfList(asssrflist.filter(x => x.formType === loc.partnerType))

            setData(mappedData.filter(i => i.status === activetab))
            setDataBk(mappedData)
            setFilter((prev) => ({ ...prev, partner: 0, srf: 0, searchstr: '', [obj]: val }))
        } else if (obj === 'partner') {

            const arr = JSON.parse(JSON.stringify(databk))

            let str = loc.searchstr.trim().toLowerCase()
            let filtered = arr.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str))
            );
            setData(filtered.filter(i => (i.reporter_ids.includes(val) || val === 0) && (i.status === activetab)))
            setFilter((prev) => ({ ...prev, [obj]: val, srf: 0 }))
            console.log('databk', databk);
        } else if (obj === 'srf') {
            const arr = JSON.parse(JSON.stringify(databk))
            let str = loc.searchstr.trim().toLowerCase()
            let filtered = arr.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str))
            );
            setData(filtered.filter(i => (i.reporter_ids.includes(loc.partner) || loc.partner === 0) && (i.status === activetab) && (i.srfId === val || val === 0)))
            setFilter((prev) => ({ ...prev, [obj]: val }))
        } else if (obj === 'partnerType') {

            let data = filterSubmissionStatus(rawsitelist, submission, assignmentlist.filter(x => x.type === val), loc.year)

            const mappedData = data.map(item => {

                return {
                    ...item,
                    reporters: item.reporter_ids.map(id => getUser(id))

                };
            });
            setDataBk(mappedData)
            const arr = JSON.parse(JSON.stringify(mappedData))
            let str = loc.searchstr.trim().toLowerCase()
            let filtered = arr.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str))
            );
            setSrfList(asssrflist.filter(x => x.formType === val))
            console.log(assignmentlist.filter(x => x.type === val))
            setData(filtered.filter(i => (i.status === activetab) && i.type === val))
            setFilter((prev) => ({ ...prev, [obj]: val, partner: 0, srf: 0 }))
        } else {
            const arr = JSON.parse(JSON.stringify(databk))
            let str = val.trim().toLowerCase()
            let filtered = arr.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str))
            );
            setData(filtered.filter(i => (i.reporter_ids.includes(loc.partner) || loc.partner === 0) && (i.status === activetab) && (i.srfId === loc.srf || loc.srf === 0)))
            setFilter((prev) => ({ ...prev, [obj]: val }))
        }




    }
    const dcfTemplate = (rowData) => {
        console.log(rowData)
        let text = 'Not Found'
        let index = srflist.find(i => i.id === rowData.srfId)
        if (index) {
            text = rowData.srfId + " : " + index.title
        }
        return <div className={rowData?.data?.id ? 'clr-navy fs-14 fw-6 cur-pointer text-underline' : ''} onClick={() => { if (rowData?.data?.id) { window.open(window.origin + '/srf_input_preview').sessionStorage.setItem('srfsubmissionpreview', JSON.stringify({ id: rowData.srfId, submitId: rowData?.data?.id || null, params: { state: { data: { frequency: rowData.frequency, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period }, status: true } } })) } }} >{text}</div>
    }
    const reporterTemplate = (rowData) => {
        let text = []
        console.log(rowData)
        return <div><Tooltip target={'.reporter'} position="top" />  <div className="text-three-dot reporter" data-pr-tooltip={rowData.reporters.join(",")} style={{ maxWidth: 150 }}>{rowData.reporters.join(",")}</div></div>

    }
    const reviewerTemplate = (rowData) => {
        let text = []
        if (rowData.reviewer_ids) {
            rowData.reviewer_ids.forEach((i) => {
                let userData = userList.find(x => x.id === i)
                if (userData) {
                    if (i === admin_data.id) {
                        text.push('Enterprise Admin')
                    } else {
                        text.push(userData.information.empname)
                    }
                } else if (i === admin_data.id) {
                    text.push('Enterprise Admin')
                }
            })
        } else {
            text.push('Self')
        }
        return <div><Tooltip target={'.reviewer'} position="top" />  <div className="text-three-dot reviewer" data-pr-tooltip={text.join(",")} style={{ maxWidth: 150 }}>{text.join(',')}</div></div>
    }
    const apporverTemplate = (rowData) => {
        let text = []
        if (rowData.approver_ids) {
            rowData.approver_ids.forEach((i) => {
                let userData = userList.find(x => x.id === i)
                if (userData) {
                    if (i === admin_data.id) {
                        text.push('Enterprise Admin')
                    } else {
                        text.push(userData.information.empname)
                    }
                } else if (i === admin_data.id) {
                    text.push('Enterprise Admin')
                }
            })
        }
        return <div><Tooltip target={'.approver'} position="top" />  <div className="text-three-dot approver" data-pr-tooltip={text.join(",")} style={{ maxWidth: 150 }}>{text.join(',')}</div></div>

    }
    const dcfRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let IdOptions = allentity.map(i => i.srfId)
        let dcfOptions = srflist.filter(i => IdOptions.includes(i.id)).map(i => { return { title: i.id + " : " + i.title, id: i.id } })

        console.log(allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={dcfOptions}
                optionValue="id"
                optionLabel="title"
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const rpRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let allOptions = allentity.map(i => i.reporting_period)
        let entOptions = []
        allOptions.forEach((i) => {
            if (!entOptions.includes(i)) {
                entOptions.push(i)
            }
        })


        console.log(allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                value={options.value}
                options={entOptions}
                filter
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const entityRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let allOptions = allentity.map(i => i.coverage)
        let entOptions = []
        allOptions.forEach((i) => {
            if (!entOptions.includes(i)) {
                entOptions.push(i)
            }
        })


        console.log(allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                value={options.value}
                options={entOptions}
                filter
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const sortRP = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {

                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    const getCountByStatus = (code) => {
        // let filtered = dt.current.getVirtualScroller().props.items.map(i => i.id)
        const arr = JSON.parse(JSON.stringify(databk))
        let str = filter.searchstr.trim().toLowerCase()
        let filtered = arr.filter(item =>
            item.reporters.some(i => i.toLowerCase().trim().includes(str))
        );
        return filtered.filter(i => (i.partner === filter.partner || filter.partner === 0) && (i.status === code) && (i.srfId === filter.srf || filter.srf === 0)).length


    }
    const getPercentage = () => {
        let value = 0
        const allStatus = [6, 7, 1, 3, 4, 0, 5]
        // let filtered = dt.current.getVirtualScroller().props.items.map(i => i.id)
        let loc = filter
        const arr = JSON.parse(JSON.stringify(databk))
        let str = loc.searchstr.trim().toLowerCase()
        let filtered = arr.filter(item =>
            item.reporters.some(i => i.toLowerCase().trim().includes(str))
        );
        let loc_ = filtered.filter(i => (i.coverage === loc.partner || loc.partner === 'All') && (i.srfId === loc.srf || loc.srf === 0))

        let approved = loc_.filter(i => i.status === 5).length
        let total = loc_.filter(i => allStatus.includes(i.status)).length
        if (approved && total) {
            value = ((approved / total) * 100).toFixed(2)
        }
        if (!loc_.length) {
            value = 100
        }

        return value
    }
    const getPercentageColor = () => {
        let value = 0
        const allStatus = [6, 7, 1, 3, 4, 0, 5]
        // let filtered = dt.current.getVirtualScroller().props.items.map(i => i.id)
        let loc = filter
        const arr = JSON.parse(JSON.stringify(databk))
        let str = loc.searchstr.trim().toLowerCase()
        let filtered = arr.filter(item =>
            item.reporters.some(i => i.toLowerCase().trim().includes(str))
        );
        let loc_ = filtered.filter(i => (i.coverage === loc.partner || loc.partner === 'All') && (i.srfId === loc.srf || loc.srf === 0))

        let approved = loc_.filter(i => i.status === 5).length
        let total = loc_.filter(i => allStatus.includes(i.status)).length
        if (approved && total) {
            value = ((approved / total) * 100).toFixed(2)
        }
        if (!loc_.length) {
            value = 100
        }


        return value <= 50 ? 'red' : value <= 99 ? 'yellow' : 'green'
    }
    const activeTab = (status) => {

        if (status === activetab) {

        } else {


            const arr = JSON.parse(JSON.stringify(databk))
            let str = filter.searchstr.trim().toLowerCase()
            let filtered = arr.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str))
            );
            setData(filtered.filter(i => (i.partner === filter.partner || filter.partner === 0) && (i.status === status) && (i.srfId === filter.srf || filter.srf === 0)))


            setActiveTab(status)
        }


    }
    const handleSearch = (e) => {
        let str = e.target.value.trim().toLowerCase()
        const arr = JSON.parse(JSON.stringify(databk))
        let filtered = arr.filter(item =>
            item.reporters.some(i => i.toLowerCase().trim().includes(str))
        );
        setData(filtered)
        setFilter((prev) => ({ ...prev, searchstr: e.target.value }))

    }
    const generateDataRows = (data, year) => {
        const rows = [];
        const uniqueCombos = {};

        // Sort the data based on reporting_period
        data.sort((a, b) => DateTime.fromFormat(a.reporting_period.split(' ')[0], 'MMM-yyyy') - DateTime.fromFormat(b.reporting_period.split(' ')[0], 'MMM-yyyy'));

        data.forEach(item => {
            const comboKey = `${item.dcf}_${item.coverage}`;

            if (!uniqueCombos[comboKey]) {
                uniqueCombos[comboKey] = new Array(14).fill('');
                uniqueCombos[comboKey][0] = item.dcf;
                uniqueCombos[comboKey][1] = item.coverage;
                rows.push(uniqueCombos[comboKey]);
            }

            const periods = item.reporting_period.split(' to ');
            const startMonth = DateTime.fromFormat(periods[0], 'MMM-yyyy').month - 1;
            const statusIndex = startMonth + 2; // Adjust for DCF and Entity columns

            if (item.frequency === 1) {
                uniqueCombos[comboKey][statusIndex] = item.status_;
            } else {
                for (let i = 0; i < item.frequency; i++) {
                    uniqueCombos[comboKey][statusIndex + i] = item.status_;
                }
            }
        });

        return rows;
    };

    const mergeCells = (worksheet, dataRows, excelData, year) => {
        const mergeRanges = [];

        excelData.forEach((item, dataIndex) => {
            const rowIndex = dataRows.findIndex(row => row[0] === item.dcf && row[1] === item.coverage);
            if (rowIndex === -1) return; // If the row is not found, skip

            const periods = item.reporting_period.split(' to ');
            const startMonth = DateTime.fromFormat(periods[0], 'MMM-yyyy').month - 1;
            const statusIndex = startMonth + 2; // Adjust for DCF and Entity columns

            if (item.frequency > 1) {
                const startCell = XLSX.utils.encode_cell({ r: rowIndex + 1, c: statusIndex });
                const endCell = XLSX.utils.encode_cell({ r: rowIndex + 1, c: statusIndex + item.frequency - 1 });
                mergeRanges.push({ s: XLSX.utils.decode_cell(startCell), e: XLSX.utils.decode_cell(endCell) });
            }
        });

        worksheet['!merges'] = mergeRanges;
    };

    const adjustColumnWidths = (worksheet) => {
        const colWidths = [];

        // Calculate maximum width for each column
        for (const [key, value] of Object.entries(worksheet)) {
            if (key[0] === '!') continue;

            const col = XLSX.utils.decode_cell(key).c;

            const cellValue = value.v != null ? value.v.toString() : '';
            const cellWidth = cellValue.length;

            if (!colWidths[col] || colWidths[col] < cellWidth) {
                colWidths[col] = cellWidth;
            }
        }

        // Apply calculated widths to worksheet columns
        worksheet['!cols'] = colWidths.map(width => ({ width: width + 2 })); // Add some padding
    };
    const adjustMonthsArray = (months, fymonth) => {
        // Adjust fymonth to 0-based index
        const startIndex = fymonth - 1;
        // Slice the array from the start index to the end and concatenate with the beginning of the array to the start index
        const adjustedMonths = [...months.slice(startIndex), ...months.slice(0, startIndex)];
        return adjustedMonths;
    };
    const downloadStatics = () => {
        if (data.length) {
            const excelData = [];

            for (const item of data) {
                let d = srflist.find(i => i.id === item.srfId);
                if (d) {
                    item.dcf = d.title;
                } else {
                    item.dcf = 'Not Found';
                }

                item.status_ = item.status === 6 ? 'Submissions Due' :
                    item.status === 7 ? 'Submissions Overdue' :
                        item.status === 1 ? 'Resubmission Required' :
                            item.status === 3 ? 'Under Review' :
                                item.status === 4 ? 'Under Approval' :
                                    item.status === 5 ? 'Approved' : item.status === 99 ? 'Drafted Upcoming Submission' : item.status === 100 ? 'Upcoming Submission' : 'NA';

                excelData.push({
                    'dcf': item.dcf,
                    'frequency': item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency,
                    'coverage': item.coverage,
                    'reporting_period': item.reporting_period,
                    'status_': item.status_,
                    'reporters': item.reporters.join(','),
                    'reviewers': item.reviewers.join(','),
                    'approvers': item.approvers.join(',')
                });
            }

            // Create worksheet
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const headers = ["DCF", "Entity (Coverage)", ...months.map(month => `${month}-${filter.year}`)];
            const dataRows = generateDataRows(excelData, filter.year);
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...dataRows]);

            mergeCells(worksheet, dataRows, excelData, filter.year);

            adjustColumnWidths(worksheet);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Export the workbook
            XLSX.writeFile(workbook, `${filter.year}_DCF_Submission_Statistics.xlsx`);
        }
    };

    const getDueMonth = (dateStr) => {
        const [from, to] = dateStr.split(' to ')
        if (to) {
            return to
        }
        return from
    }

    const downloadReport = () => {
        if (data.filter(i => i.status === activetab).length) {
            const excelData = []
            const headers = [
                { header: 'DCF', key: 'dcf' },
                { header: 'Reporting Entity', key: 'coverage' },
                { header: 'Reporting Period', key: 'reporting_period' },
                { header: 'Status', key: 'status_' },
                { header: 'Reporter(s)', key: 'reporters' },
                { header: 'Reviewer(s)', key: 'reviewers' },
                { header: 'Approver(s)', key: 'approvers' }
            ];
            for (const item of data.filter(i => i.status === activetab)) {
                let d = srflist.find(i => i.id === item.srfId)
                if (d) {
                    item.dcf = d.title
                } else {
                    item.dcf = 'Not Found'
                }

                item.status_ = item.status === 6 ? 'Submissions Due' :
                    item.status === 7 ? 'Submissions Overdue' :
                        item.status === 1 ? 'Resubmission Required' :
                            item.status === 3 ? 'Under Review' :
                                item.status === 4 ? 'Under Approval' :
                                    item.status === 5 ? 'Approved' : item.status === 99 ? 'Drafted Upcoming Submission' : item.status === 100 ? 'Upcoming Submission' : 'NA';

                excelData.push({ 'dcf': item.dcf, 'coverage': item.coverage, 'reporting_period': item.reporting_period, 'status_': item.status_, 'reporters': item.reporters.join(','), 'reviewers': item.reviewers.join(','), 'approvers': item.approvers.join(',') })
            }
            // Create worksheet

            const worksheet = XLSX.utils.json_to_sheet(excelData, { header: headers.map(h => h.key) });

            // Set headers
            headers.forEach((header, index) => {
                const cell = XLSX.utils.encode_cell({ r: 0, c: index });
                worksheet[cell].v = header.header;
            });
            adjustColumnWidths(worksheet);

            // Create a new workbook
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, filter.year.toString());

            // Export the workbook
            XLSX.writeFile(workbook, filter.year.toString() + '_DCF_Submission_Status.xlsx');

        }
    }
    const handleNan = (value) => {
        if (isNaN(value) || !value || !isFinite(value)) {
            return 0
        } else {
            return (value * 100).toFixed(2)
        }
    }
    const exportToExcel = () => {
        // Map your data to include only the needed columns with custom headers
        const exportData = data.map(item => (
            {

                "Partner Type": filter.partnerType === 1 ? 'Supplier' : 'Dealer',
                'Partner Name': item.reporters.join(","),
                'SRFId': item.srfId, // Only the ID
                'SRF': srflist.find(i => i.id === item.srfId)?.title || 'Not Found',
                'Reporting Period': item.reporting_period,
                "Status": activetab === 6 ? "Submissions Due" : activetab === 7 ? "" : activetab === 3 ? "Submitted" : activetab === 4 ? "Reviewed" : ""
            }));

        // Convert JSON data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        // Export the Excel file
        XLSX.writeFile(workbook, (filter.partnerType === 1 ? 'Supplier' : 'Dealer') + '_submission_status_export.xlsx');
    };
    // For the SRFId column: show only the srfId value.
    const srfIdTemplate = (rowData, exportMode = false) => {
        const text = rowData.srfId || 'Not Found';
        return exportMode ? text : <>{text}</>;
    };

    // For the SRF column: show only the title from srflist.
    const srfTemplate = (rowData, exportMode = false) => {
        let text = 'Not Found';
        const found = srflist.find(item => item.id === rowData.srfId);
        if (found) {
            text = found.title;
        }
        return exportMode ? text : <>{text}</>;
    };
    function generateMonthArray(year, fymonth) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let result = [];

        // Fiscal year starting from fymonth
        if (fymonth === 1) {
            // Calendar year starting from January
            for (let i = 0; i < 12; i++) {
                result.push(`${months[i]}-${year}`);
            }
        } else {
            // Fiscal year starting from the given month (not January)
            // Generate 12 months, adjusting the year if necessary
            for (let i = 0; i < 12; i++) {
                let monthIndex = (fymonth - 1 + i) % 12;
                let currentYear = year - 1;

                // Determine if we need to roll over to the next year
                if (monthIndex < fymonth - 1) {
                    currentYear++;
                }

                result.push(`${months[monthIndex]}-${currentYear}`);
            }
        }

        return result;
    }

    const downloadSummary = () => {

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const fyMonthHeader = generateMonthArray(filter.year, fymonth)
        const headers = [
            { header: 'Status', key: 'title' },
            ...fyMonthHeader.map(i => ({ header: i, key: i }))
        ]
        console.log(fyMonthHeader)
        const staticData = [
            ["DCF Submission Status :  " + filter.year],
            ["Entity : " + (filter.entity === 'All' ? 'Enterprisewide' : filter.entity)],
            headers.map(i => i.header)
        ];
        const filteredData = databk.filter(i => (i.coverage.trim().toLowerCase() === filter.entity.trim().toLowerCase() || filter.entity === 'All'))
        console.log(headers)
        let monthData = fyMonthHeader.map(i => ({ [i]: filteredData.filter(x => x.dueMonth === i) })).reduce((acc, current) => {
            return { ...acc, ...current };
        }, {})
        const allStatus = [6, 7, 1, 3, 4, 0, 5]
        const excelData = [


            {
                title: 'Submissions Due', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 6).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Submissions Overdue', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 7).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Resubmission Required', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 1).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Under Review', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 3).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Under Approval', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 4).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Unlocked for Submission', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 0).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Approved & Locked', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 5).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: '% Completion', ...fyMonthHeader.map(i => ({ [i]: handleNan(monthData[i].filter(x => (allStatus.includes(x.status))).length / monthData[i].filter(x => x.status === 5).length) })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },

        ]
        const wsData = [...staticData];

        excelData.forEach((row, index) => {
            wsData.push([
                row.title, row[fyMonthHeader[0]], row[fyMonthHeader[1]], row[fyMonthHeader[2]], row[fyMonthHeader[3]], row[fyMonthHeader[4]]
                , row[fyMonthHeader[5]], row[fyMonthHeader[6]], row[fyMonthHeader[7]], row[fyMonthHeader[8]], row[fyMonthHeader[9]], row[fyMonthHeader[10]], row[fyMonthHeader[11]]
            ]);
        });
        console.log(excelData)
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        ws['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }, // Merge cells B1 to M1
            { s: { r: 1, c: 0 }, e: { r: 1, c: 12 } }
        ];
        adjustColumnWidths(ws);
        const backgroundColorStyle = {
            fill: {
                fgColor: { rgb: "FFFF00" }  // Yellow color
            }
        };

        // Apply the background color to the nth row, from An to Mn
        const n = 9; // Replace with the desired row number (1-based index)
        for (let col = 0; col < 12; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: n - 1, c: col });
            if (!ws[cellAddress]) ws[cellAddress] = {};
            ws[cellAddress].s = backgroundColorStyle;
        }
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Export the workbook to Excel
        // XLSX.writeFile(wb, "DCF_Submission_Summary_"+filter.year+".xlsx");

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });

        XlsxPopulate.fromDataAsync(data).then((workbook) => {
            workbook.sheets().forEach((sheet) => {
                const n = 11; // Replace with the desired row number (1-based index)

                // Apply the background color to the nth row, from An to Mn
                for (let col = 1; col <= 13; col++) {
                    sheet.cell(n, col).style({
                        fill: {
                            type: 'solid',
                            color: 'D3D3D3' // Yellow color
                        }
                    });
                }




            })
            workbook
                .outputAsync()
                .then((workbookBlob) =>
                    saveAs(URL.createObjectURL(workbookBlob), "DCF_Submission_Summary_" + filter.year + ".xlsx")
                )

        })



    }
    const pbTemplate = (a, b) => {
        console.log(a, b)
        return <></>
    }

    return (
        <div className='col-12 font-lato'>
            <div
                className="col-12 "
                style={{
                    justifyContent: "center",
                }}
            >
                <label className="text-big-one clr-navy flex fs-18 fw-6 flex justify-content-start">

                    SRF Submission Status
                </label>
            </div>
            {!loading ? <div>
                <div className='col-12'>
                    <div className='flex justify-content-end' >
                        <Button type="button" label="Download Table" className="clr-navy-imp" onClick={exportToExcel} icon="pi pi-download" outlined />
                    </div>
                    <div className='grid m-0'>
                        <div className='col-3 grid m-0 align-items-center'>
                            <label>Year</label>
                            <Dropdown value={filter.year} options={yearoptions} onChange={(e) => { updateYearFilter(e.value, 'year') }} optionLabel="label" optionValue="name" style={{ width: 200 }} className='ml-2' />
                        </div>


                        <div className='col-3  grid m-0 align-items-center'>
                            <label>Partner Type</label>
                            <Dropdown value={filter.partnerType} options={[{ id: 1, name: 'Supplier' }, { id: 2, name: 'Dealer' }]} onChange={(e) => { updateYearFilter(e.value, 'partnerType') }} optionLabel="name" optionValue="id" style={{ width: 200 }} className='ml-2' />
                        </div>

                        {filter.partnerType &&
                            <>
                                <div className='col-3 grid m-0 align-items-center'>
                                    <label>Select {[{ id: 1, name: 'Supplier' }, { id: 2, name: 'Dealer' }].find(x => x.id === filter.partnerType)?.name || ''}</label>
                                    <Dropdown filter value={filter.partner} options={[{ name: 'All', id: 0 }, ...masterlist.filter(i => filter.partnerType === 1 ? i.supplierName : i.dealerName)]} onChange={(e) => { updateYearFilter(e.value, 'partner') }} optionLabel="name" optionValue="id" style={{ width: 200 }} className='ml-2' />
                                </div>
                                <div className='col-3 grid m-0 align-items-center'>
                                    <label>SRF</label>
                                    <Dropdown filter value={filter.srf}
                                        options={[{ id: 0, title: 'All' }, ...srflist.map(x => ({ ...x, title: x.id + " : " + x.title }))]}
                                        onChange={(e) => { updateYearFilter(e.value, 'srf') }} optionLabel="title" optionValue="id" style={{ width: 200 }} className='ml-2' />
                                </div>
                            </>
                        }
                        <div className="col-12 flex justify-content-end">
                            <div className='col-3'>
                                <span className="p-input-icon-left" style={{ width: '100%' }}>
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search partner name" onChange={(e) => { updateYearFilter(e.target.value, 'searchstr') }} value={filter.searchstr} style={{ width: '100%' }} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex p-flex-wrap submission-display-card-container" >
                    <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 6 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(6) }}><div className="grid m-0 align-items-center"><div className=' card-number-1  col-4'>{getCountByStatus(6)} </div> <div className='clr-navy card-label-1  col-8'>Submissions Due </div></div> </div>
                    <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 7 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(7) }}><div className="grid m-0 align-items-center"><div className=' card-number-1 ' col-4>{getCountByStatus(7)} </div> <div className='clr-navy card-label-1  col-8'>Submissions Overdue</div></div> </div>
                    {filter?.partnerType === 2 ?
                        <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 3 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(3) }}><div className="grid m-0 align-items-center"><div className=' card-number-1 ' col-4>{getCountByStatus(3)} </div> <div className='clr-navy card-label-1  col-8'>Under Review</div></div> </div>

                        :
                        <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 3 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(3) }}><div className="grid m-0 align-items-center"><div className=' card-number-1 ' col-4>{getCountByStatus(3)} </div> <div className='clr-navy card-label-1  col-8'>Submitted</div></div> </div>

                    }
                    {filter?.partnerType === 2 &&
                        <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 4 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(4) }}><div className="grid m-0 align-items-center"><div className=' card-number-1 ' col-4>{getCountByStatus(4)} </div> <div className='clr-navy card-label-1  col-8'>Reviewed</div></div> </div>

                    }
                </div>
                <div hidden className="col-12" style={{ position: 'relative' }}>
                    <ProgressBar value={getPercentage()} displayValueTemplate={pbTemplate} color={getPercentageColor()}></ProgressBar>
                    <div className=" fw-7" style={{ color: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}> <label>{getPercentage()} % </label> </div>
                </div>
                <div className='col-12'>
                    <DataTable ref={dt} value={data} tableClassName="font-lato" filters={{ srfId: { value: null, matchMode: 'in' }, coverage: { value: null, matchMode: 'in' }, reporting_period: { value: null, matchMode: 'in' } }} rowsPerPageOptions={[10, 20, 50, 100]} rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" paginator scrollable emptyMessage='No Assignment(s)' >
                        <Column header='SRF' field='srfId' style={{ minWidth: '20%' }} body={dcfTemplate} showFilterMatchModes={false} showApplyButton={true} filter filterElement={dcfRowFilterTemplate} />
                        <Column header='Reporting Period' style={{ minWidth: '8%' }} field='reporting_period' sortable sortFunction={sortRP} showFilterMatchModes={false} showApplyButton={false} filterElement={rpRowFilterTemplate} />
                        <Column header='Partner Name' style={{ minWidth: '25%' }} body={reporterTemplate} />

                    </DataTable>
                </div>
            </div> :
                <div className="col-12 flex justify-content-center"><i className="fs-26 pi pi-spin pi-spinner" /> </div>
            }
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(OverallValueChainSubmissionStatus, comparisonFn);
