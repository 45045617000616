/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import ListBoxItem from "./ListBoxItem";
import { Form, Modal, Button } from "react-bootstrap";
import Editable from "react-bootstrap-editable";
import autoAnimate from "@formkit/auto-animate";
// import { EDIT_TIER_URL } from "../constants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";



const ListBox = (props) => {
    const animateListItem = useRef();
    const animateTestItem = useRef();
    const [showModal, setShowModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [tierDetails, setTierDetails] = useState({ description: '' });
    const [description, setDescription] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedId, setSelectedId] = useState('')
    const [selectedMode, setSelectedMode] = useState('')
    const title = useRef();


    const handleConfigurationModal = (id) => {

        getTierDetails(props.mode, id);

    }


    const handleAddDescription = (id) => {
        getTierDetails(props.mode, id);
    }

    const handleGenerateQR = (id) => {

        generateQR(props.mode, id);
    }

    const getTierDetails = async (mode, id) => {
        const response = await APIServices.get(API.EDIT_TIER_URL(mode, id));
        if (response.status === 200) {
            const tierData = response.data;


            setTierDetails(tierData);
            setDescription(tierData.description ? tierData.description : '')
            setStartDate(tierData.startDate ? new Date(tierData.startDate) : null);
            setEndDate(tierData.endDate ? new Date(tierData.endDate) : null);
            setShowModal(true);
        }
    }


    const generateQR = async (mode, id) => {
        setSelectedId(id)
        setSelectedMode(mode)
        setShowQRModal(true);

    }


    const handleCreateItem = (e) => {
        e.preventDefault();
        // @ts-ignore
        props.onHandleCreateItem(title.current.value);
        // @ts-ignore
        title.current.value = '';


    }


    useEffect(() => { animateListItem.current && autoAnimate(animateListItem.current) }, [animateListItem])
    useEffect(() => { animateTestItem.current && autoAnimate(animateTestItem.current) }, [animateTestItem])

    const handleDeleteItem = (id) => {
        props.handleDeleteItem(props.mode, id)
    }

    const handleEditItem = (id, name) => {
        props.handleEditItem(id, name)
    }

    const onHandleCurate=(id)=>{

        console.log(id)
        props.onHandleCurate(props.mode,id)
    }

    const handleSaveDescription = (id) => {
        const payload = {
            description,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null
        };

        props.handleAddDescription(id, payload);
        // props.handleAddDescription(id, description)
        setShowModal(false);
    }


    const handleEditFlag = (id, flag) => {
        props.handleEditFlag(id, flag)
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        // Reorder the items in the props.lists array
        const reorderedItems = Array.from(props.lists);
        const [removed] = reorderedItems.splice(startIndex, 1);
        reorderedItems.splice(endIndex, 0, removed);

        // Update the order property of the reordered items
        const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            order: index + 1,
        }));

        // Call the handler function to update the order in your parent component
        console.log(updatedItems)
        props.handleReorderItems(updatedItems);
    };



    return (<>
        <div className="card shadow">
            <div className="card-body p-0">
                <div className="card-title pencil-icon p-3 pb-0 mb-0">
                    <Editable initialValue={props.title} onSubmit={(value) =>{ return props.onEditTitle(value, props.mode)}} className="d-flex" mode="inline" />
                </div>

                {
                    props.selected && (<>
                        <Form.Group className="form-group p-3 mb-0">
                            <div className="input-group">
                                <Form.Control type="text" ref={title} className="form-control mb-0" placeholder="Enter name..." aria-label="item" aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary btn-icon mb-0 h-100 z-0" type="button" onClick={(e) => { handleCreateItem(e) }}><i className="pi pi-plus"></i></button>
                                </div>
                            </div>
                        </Form.Group>
                        <div className="h-250" ref={animateListItem}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {props.lists.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <ListBoxItem
                                                                key={item.id}
                                                                onHandleEditItem={handleEditItem}
                                                                onHandleDelete={handleDeleteItem}
                                                                onHandleCurate={onHandleCurate}
                                                                selectedId={props.selectedItem.id}
                                                                data={item}
                                                                mode={props.mode}
                                                                onHandleSelect={(id) => props.handleSelect(id)}
                                                                // flag={props.flag}
                                                                // onHandleEditFlag={handleEditFlag}
                                                                description={props.description}
                                                                // qr={props.qr}
                                                                onHandleAddDescription={handleAddDescription}
                                                                // onHandleGenerateQR={handleGenerateQR}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>


                        </div>
                    </>)
                }

                {!props.selected && (<p className="p-3">Please select a item from left pane to continue</p>)}
                <Modal
                    size="md"
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{tierDetails.name}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form className="forms-sample">



                            <div className="row">
                                <div className="form-group">
                                    <label>Project Description</label>
                                    <textarea style={{ height: '200px' }} className="form-control" defaultValue={tierDetails.description} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                            setStartDate(date);
                                            if (endDate && date && date > endDate) {
                                                setEndDate(null);
                                            }
                                        }}
                                        className="form-control"
                                        dateFormat="dd MMM yyyy"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>End Date</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        className="form-control"
                                        dateFormat="dd MMM yyyy"
                                        minDate={startDate}  // Disable dates before startDate
                                    />
                                </div>
                            </div> */}
                            <Button variant="primary" onClick={() => handleSaveDescription(tierDetails.id)}>
                                Submit
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>

{/* 
                {(selectedId && selectedMode) && <Modal
                    size="md"
                    show={showQRModal}
                    onHide={() => setShowQRModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >

                    <Modal.Body>
                        <form className="forms-sample">


                            <QRCodeGenerator id={selectedId} mode={selectedMode} />
                        </form>
                    </Modal.Body>
                </Modal>} */}

            </div>

        </div>


    </>);
}

export default ListBox;