import React, { useEffect, useState } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Editor } from 'primereact/editor';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";

import { Accordion, AccordionTab } from "primereact/accordion";
import { TabMenu } from "primereact/tabmenu";

import AuditPanel from "../Auditor/AuditPanel";
import AuditorActions from "./AuditorActions";
import { Badge } from "primereact/badge";
import { Tag } from "primereact/tag";
const AuditorHomeTVS = () => {

    const [assignments, setAssignments] = useState([])
    const [submittedAssessment, setSubmittedAssessment] = useState([])
    const [data, setData] = useState([])
    const [archived, setArchived] = useState([])
    const [activeAss, setActiveAss] = useState(null)
    const [asssrflist, setAssSrfList] = useState([])
    const [activeindex, setActiveIndex] = useState(0)
    const [activeform, setActiveForm] = useState({})
    const [addActionModal, setAddActionModal] = useState(false)
    const [selectedGmsOne, setSelectedGmsOne] = useState('GMS1'); // Default to GMS1
    const [actionlistdialog, setActionListDialog] = useState(false)
    const [actionlist, setActionList] = useState([])
    // State for GMS Two nested tabs
    const [selectedGmsTwo, setSelectedGmsTwo] = useState('2.1'); // Default to 2.1
    const allRoles = useSelector((state) => state.user.allRoles);
    // Read-only flag
    const [readOnly, setReadOnly] = useState(false); // Change to true if necessary
    const [supplierform, setSupplierForm] = useState({ id: null, })
    const [show, setShow] = useState(false)
    const userList = useSelector(state => state.userlist.userList)
    const supplierList = useSelector(state => state.userlist.supplierList)

    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const [assobj, setAssObj] = useState({ supplierId: null, auditor_ids: [], assessmentStartDate: null, assessmentEndDate: null, auditStartDate: null, auditEndDate: null, srfId: null })
    const [asssupplierobj, setAssSupplierObj] = useState({ supplierId: null })


    const [supplierlist, setSupplierList] = useState([])
    const [auditorlist, setAuditorList] = useState([])
    const [adddialog, setAddDialog] = useState(false)
    const [responseDialog, setResponseDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [supplierdialog, setSupplierDialog] = useState(false)
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [selectedAudit, setSelectedAudit] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [actionModal, setActionModal] = useState(false)


    const viewAudit = (rowData) => {
        setSelectedAudit(rowData)
        setActionModal(true)
    }

    const forceUpdate = useForceUpdate()


    useEffect(() => {
        refreshEntry()
    }, [])

    function getOverdueDays(monthString) {
        console.log(monthString);
        const [startMonth, endMonth] = monthString.split(" to ");

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split("-");
        const endOfMonth = DateTime.fromObject({
            year: parseInt(year),
            month: DateTime.fromFormat(monthValue, "LLL").month,
        }).endOf("month");
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, "days").days);
        return endOfMonth.diff(currentDate, "days").days;
    }
    function getOverdueDaysByUTC(monthString) {

        const endOfMonth = DateTime.fromISO(monthString, { zone: 'Asia/Calcutta' })
        const currentDate = DateTime.local();

        return endOfMonth.diff(currentDate, "days").days;
    }

    const draftSupplierResponse = () => {
        let newObj = {}
        let dt = DateTime.utc()
        newObj['response'] = supplierform.response
        if (supplierform.id) {
            newObj['type'] = 0
            newObj['modified_by'] = login_data.id
            newObj['modified_on'] = DateTime.utc()
            APIServices.patch(API.SupplierAssessmentSubmission_Edit(supplierform.id), newObj).then((res) => {



                Swal.fire({
                    title: "Your Responses Have Been Recorded & Saved as Draft",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        } else {
            newObj['srfId'] = activeform.srfId
            newObj['userProfileId'] = admin_data.id
            newObj['created_by'] = login_data.id
            newObj['created_on'] = DateTime.utc()
            newObj['supplierId'] = login_data.id
            newObj['type'] = 0
            APIServices.post(API.SupplierAssessmentSubmission(activeform.id), newObj).then((res) => {



                Swal.fire({
                    title: "Your Responses Have Been Recorded & Saved as Draft",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        }





        forceUpdate()
    }

    const saveSupplierResponse = () => {

        let newObj = {}
        let dt = DateTime.utc()




        newObj['response'] = supplierform.response
        if (supplierform.id) {
            newObj['type'] = 1
            newObj['modified_by'] = login_data.id
            newObj['modified_on'] = DateTime.utc()
            APIServices.patch(API.SupplierAssessmentSubmission_Edit(supplierform.id), newObj).then((res) => {



                Swal.fire({
                    title: "Thanks, Your Responses Have Been Recorded & Sent To Respective Auditor",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        } else {
            newObj['srfId'] = activeform.srfId
            newObj['created_by'] = login_data.id
            newObj['created_on'] = DateTime.utc()
            newObj['supplierId'] = login_data.id
            newObj['userProfileId'] = admin_data.id
            newObj['type'] = 1
            APIServices.post(API.SupplierAssessmentSubmission(activeform.id), newObj).then((res) => {



                Swal.fire({
                    title: "Thanks, Your Responses Have Been Recorded & Sent To Respective Auditor",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        }





        forceUpdate()




    }

    const saveAssignmentSupplier = () => {
        setSubmitted(true)
        if (asssupplierobj.supplierId) {
            let newObj = {
                supplierId: asssupplierobj.supplierId
            }
            APIServices.post(API.SupplierList_supass(activeAss.id), { ...newObj, srfId: activeAss.srfId, mailSent: 1, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                let loc = JSON.parse(JSON.stringify(assignments))
                let index = loc.findIndex(i => i.id === activeAss.id)
                if (index !== -1) {
                    if (loc.assessmentSupplierLists) {
                        loc[index].assessmentSupplierLists.push(res.data)
                    } else {
                        loc[index].assessmentSupplierLists = [res.data]
                    }
                }

                setAssignments(loc)

            })
        }

    }
    const saveAssignment = () => {
        setSubmitted(true)
        if (assobj.srfId && assobj.supplierId && assobj.assessmentStartDate && assobj.auditor_ids.length) {
            let newObj = {
                assessmentStartDate: assobj.assessmentStartDate,
                assessmentEndDate: assobj.assessmentEndDate,
                auditStartDate: assobj.auditStartDate,
                auditEndDate: assobj.auditEndDate,
                auditor_ids: assobj.auditor_ids,
                srfId: assobj.srfId,
                supplierId: assobj.supplierId
            }
            console.log(assobj.id)
            if (assobj.id) {
                APIServices.patch(API.SupplierAssessmentAss_Edit(assobj.id), { ...newObj, modified_on: DateTime.utc(), modified_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(assignments))
                    let index = loc.findIndex(i => i.id === assobj.id)
                    if (index !== -1) {
                        loc[index] = { ...assobj, modified_on: DateTime.utc(), modified_by: login_data.id }
                        setAssignments(loc)
                    }

                })
            } else {
                APIServices.post(API.SupplierAssessmentAss_Up(admin_data.id), { ...newObj, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(assignments))
                    loc.push(res.data)
                    setAssignments(loc)
                })

            }

        }
    }

    const updateAssObj = (obj, val) => {
        let loc = assobj
        loc[obj] = val
        setAssObj(loc)
        forceUpdate()

    }
    const supplierNameTemplate = (rowData) => {

        let txt = [{ name: 'Supplier 1', id: 1 }, { name: 'Supplier 2', id: 2 }, { name: 'Supplier 3', id: 3 }].find(i => rowData.supplierId === i.id)
        console.log(txt)
        return (<>{!txt ? '' : txt.name} </>)

    }

    const reportTemplate = (rowData) => {
        console.log(rowData)
        return <span class="material-symbols-outlined cur-pointer clr-navy">
            docs
        </span>

    }
    // const idTemplate = (rowData) => {
    //     let res ={}
    //     if(rowData.supplierAssignmentSubmission){
    //         res =rowData.supplierAssignmentSubmission

    //     }else{
    //         res= {response:rowData.srf.data1}
    //     }

    //     return <div className='clr-navy fw-6 fs-14 text-underline' onClick={() => { setShow(res.id ? res.type === 1 ? true : false  : false); setSupplierForm(res); setResponseDialog(true); setActiveForm(rowData) }}> {rowData.id} </div>
    // }
    const idTemplate = (rowData) => {
        return (
            // <a
            //     href="#"
            //     onClick={() => openDialog(rowData)} // Trigger dialog open
            //     style={{ textDecoration: 'none', color: '#007bff' }}
            // >
            //     {rowData.id}
            // </a>

            <div className={(rowData.supplierAssignmentSubmission && rowData.supplierAssignmentSubmission.type === 1 && rowData.statusCode !== 100) ? 'maskid cur-pointer clr-navy text-underline' : ''} onClick={() => { (rowData.supplierAssignmentSubmission && rowData.supplierAssignmentSubmission.type === 1 && rowData.statusCode !== 100) ? viewAudit(rowData) : viewAudit(rowData) }}>
                {rowData.id}
            </div>
        );
    };

    const actionButton = (rowData) => {

        return (
            <div className=' clr-navy cur-pointer fw-6 fs-16' onClick={() => { setSelectedAudit(rowData); setAddActionModal(true) }}>
                ADD ACTION
            </div>
        )

    }



    const scoreTemplate = (rowData) => {
        return rowData.supplierAssignmentSubmission ? rowData.supplierAssignmentSubmission.type === 1 ? '0' : "-" : 'NA'
    }
    const getDate = (date, format) => {

        if (!date) {
            return 'Not Set'
        } if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const dateTemplate = (date) => {
        console.log(getDate(date))
        return getDate(date)

    }
    const resendMail = (rowData) => {
        let assessment = assignments.find(i => i.id === activeAss.id)
        let txt = supplierList.find(i => rowData.supplierId === i.id)
        if (assessment) {
            let body = `<p>Hi ${txt.information.empname}</p>  <p>You have received mail in order to fill your Questionary Response, <a href="${window.location.origin}/supplier/assessment/${rowData.id}">click here </a> to open Supplier Assessment Questionary Form</p><hr/><p style='font-style:italic'>This email is an automated notification. Please do not reply to this message</p>`
            APIServices.post(API.SubmissionMail, { email: [txt.email], subject: 'Supplier Assessment Form - ' + assessment.title, body: body }).then(res => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Mail Sent Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })

        }


    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }
    const onCommentChange = (item, val, nan) => {

        item.comment = val;
        forceUpdate()
    }
    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {
        const getLabel = (item) => item.label.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, " ").replace(/&nbsp;/g, " ").replace('&amp;', '&');

        return (
            <div key={index} className="form-row grid m-0 p-fluid" style={{ padding: 10, border: item.error === 1 ? '1px solid red' : 'none' }}>
                <Tooltip target={`.tooltip-${index}`} position="top" />

                {/* Query Column */}
                <label className="col-4 fs-16 fw-5 text-justify">
                    {getLabel(item)}
                    {item.required && <span className="mandatory mr-2">*</span>}
                    {item.description && <i className={`material-icons fs-14 tooltip-${index}`} data-pr-tooltip={item.description}>help</i>}
                </label>

                {/* Response Column */}
                <div className="col-4">
                    {item.type === 'checkbox-group' && item.values.map((cb, cbind) => (
                        <div className="flex align-items-center" key={cbind}>
                            <Checkbox disabled={show} inputId={`cb-${index}-${cbind}`} name={cb.label} value={cb.value} onChange={(e) => onCheckBoxSelected(item, cbind)} checked={cb.selected} />
                            <label htmlFor={`cb-${index}-${cbind}`} className="ml-2">{cb.label}</label>
                        </div>
                    ))}

                    {item.type === 'date' && (
                        <Calendar disabled={show} value={item.value ? new Date(item.value) : null} onChange={(e) => onDateSelected(item, e.value)} />
                    )}

                    {item.type === 'number' && (
                        <InputNumber disabled={show} value={item.value} onChange={(e) => onNumberChange(item, e.value)} />
                    )}

                    {item.type === 'radio-group' && item.values.map((rb, rbindex) => (
                        <div className="flex align-items-center" key={rbindex}>
                            <RadioButton disabled={show} inputId={`rb-${index}-${rbindex}`} name={rb.label} value={rb.value} onChange={(e) => onRadioButtonSelected(item, rbindex)} checked={rb.selected === true} />
                            <label htmlFor={`rb-${index}-${rbindex}`} className="ml-2">{rb.label}</label>
                        </div>
                    ))}

                    {item.type === 'select' && (
                        <Dropdown disabled={show} value={item.value} options={item.values} optionLabel="label" onChange={(e) => onChangeDropwdown(item, e.value)} />
                    )}

                    {item.type === 'textarea' && (
                        <Editor disabled={show} value={item.value} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />
                    )}

                    {item.type === 'text' && (
                        <InputText disabled={show} value={item.value} onChange={(e) => onNumberChange(item, e.target.value)} />
                    )}
                </div>

                {/* Comments Column */}
                <div className="col-4">
                    <InputText disabled={show} value={item.comments} onChange={(e) => onCommentChange(item, e.target.value)} placeholder="Add comments" />
                </div>
            </div>
        );
    };

    const questionaryTemplate = (rowData) => {

        let txt = asssrflist.find(i => rowData.srfId === i.id)
        console.log(txt)
        return (<>{!txt ? '' : txt.title} </>)

    }

    const openDialog = (rowData) => {
        setSelectedRow(rowData); // Store the clicked row's data
        setDialogVisible(true);  // Show the dialog
    };

    // Function to close the dialog
    const hideDialog = () => {
        setDialogVisible(false);
        setSelectedRow(null); // Clear the selected row
    };
    const getSupplier = (id) => {

        let user_name = 'Not Found'
        if (id === admin_data.id) {
            return 'Enterprise Admin'
        }
        let index = supplierList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = supplierList[index].information.supplierName
        }
        return user_name

    }
    const actionBodyTemplate = (rowData) => {


        // const color = 2 === 2 ? 'greenBox' : 1 === 0 ? 'redBox' : 'orangeBox';

        // Return the link with dynamic styles and counts
        return <a href="#" onClick={(e) => { if (rowData?.supplierActions?.length) { setActionList(rowData.supplierActions); setActionListDialog(true) } }}> {rowData?.supplierActions?.length ? (`${rowData?.supplierActions.filter(x => x.status === 4)?.length}/${rowData?.supplierActions?.length}`) : 'NA'} </a>;
    }
    const updateAssessment = (val) => {
        setSelectedAudit(val);
        let loc = JSON.parse(JSON.stringify(assignments))
        let index = loc.findIndex(i => i.id === val.id)
        if (index) {
            loc[index].auditorAssignmentSubmission = val.auditorAssignmentSubmission;
            setAssignments(loc)
            forceUpdate()
        }
    }
    const timelineRowFilterTemplate = (options) => {
        let allStatusOptions = [

            { name: "Overdue", id: 7 },
            { name: "Due Soon", id: 9 },
            { name: "Upcoming", id: 100 },

        ];
        return (
            <MultiSelect
                panelClassName={"hidefilter"}
                value={options.value}
                options={allStatusOptions}
                optionLabel="name"
                optionValue="id"
                filter
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    }
    const timelineTemplate = (rowData) => {
        console.log(rowData)
        switch (rowData.statusCode) {
            case 6:
                return <Badge severity="danger" value="Due Soon" />;
            case 9:
                return <Badge severity="warning" value="Overdue" />;
            case 100:
                return <Badge severity="info" value="Upcoming" />;


        }
    };

    const actionTemplate = (rowData) => {

        switch (rowData.formType) {

            case 1:

                console.log(rowData)
                return <div className="table-link-clickable" onClick={() => {
                    // window.open(window.location.origin + '/cf_preview_view').sessionStorage.setItem('cfpreview', rowData.srfId)
                    window.open(window.origin + '/srf_input_entry').sessionStorage.setItem('srfsubmission', JSON.stringify({ id: rowData.srfId, submitId: rowData?.data?.id || null, params: { state: { data: { frequency: rowData.frequency, entityUserAssId: rowData.entityUserAssId, self: rowData.self, entityAssId: rowData.entityAssId, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period }, status: true } } }))
                }} >{rowData.title} </div>

            case 2:
                return <div className="table-link-clickable" onClick={() => { viewAudit(rowData) }} >{rowData.title}  </div>
            case 0:
                return <div className="table-link-clickable" onClick={() => {

                    window.open(window.origin + '/lca')
                }} >{rowData.title} </div>

        }
    }

    const RowFilterTemplate = (options, obj) => {
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(data.map((i) => i[obj])))}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };

    const currentStatusTemplate = (rowData) => {

        return <Tag value={rowData.currentStatus} />

    };
    const statusTemplate = (rowData) => {
        console.log(rowData)
        switch (rowData.auditStatus) {
            case 1:
                return <Badge severity="danger" value="Not Started" />;
            case 2:
                return <Badge severity="warning" value="In Progress" />;
            case 3:
                return <Badge severity="success" value="Completed" />;


        }
    };
    const refreshEntry = (event) => {

        let uriString = {

            "include": [
                {
                    "relation": "supplierAssignmentSubmission", scope: { fields: { type: true, supplierMSIScore: true, submitted_on: true } }
                },
                {
                    "relation": "supplierActions"
                },

                {
                    "relation": "auditorAssignmentSubmission", scope: { fields: { type: true, auditorMSIScore: true } }
                },
                {
                    "relation": "vendor"
                }
            ]

        };

        const promise0 = APIServices.get(API.SupplierAssessmentAss_Up(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)



        Promise.all([promise0]).then((values) => {

            let loc = values[0].data.filter(i => i.auditStartDate && ((i?.group1?.assessors?.includes(login_data.id) || i?.group2?.assessors?.includes(login_data.id) || i?.group3?.assessors?.includes(login_data.id) || i?.group4?.assessors?.includes(login_data.id)) || allRoles.some(x => [2, 12, 18].includes(x)) || login_data.role === 'clientadmin'))
            const finished = loc.filter(x => x?.auditorAssignmentSubmission?.type === 1)
            const filteredMSI = loc.filter(x => (!x.auditorAssignmentSubmission || (x.auditorAssignmentSubmission && x.auditorAssignmentSubmission?.type !== 1)))
            for (const x of filteredMSI) {
                let stat = getOverdueDaysByUTC(x.auditEndDate) >= 0
                    ? 100
                    : getOverdueDaysByUTC(x.auditEndDate) >= -10
                        ? 6 : getOverdueDaysByUTC(x.auditEndDate) >= -15 ? 9
                            : 7
                x.title = 'Conduct Calibiration ' + getMSIIDByAssignment(x)
                x.dueDate = getDate(x.auditEndDate)
                x.currentStatus = x?.auditorAssignmentSubmission ? 'Started' : 'Not Started'
                x.timeLine = stat === 6 ? 'Due Now' : stat === 9 ? "Due Soon" : stat === 100 ? "Upcoming" : "Overdue"
                x.formType = 2
                x.statusCode = stat
            }
            console.log(loc)
            setData(filteredMSI)
            setArchived(finished)

        })


    }
    const getMSIIDByAssignment = (item) => {

        return 'MSI-' + item?.vendor?.code + '-' + DateTime.fromISO(item.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')
    }
    const msiIdTemplate = (rowData) => {
        return (<div>{getMSIIDByAssignment(rowData)} </div>)
    }
    const findingTemplate = (rowData) => {
        return <>{rowData?.supplierActions?.length}</>
    }
    const sortDD = (e) => {
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.dueDate, 'dd-MM-yyyy');
                let dateB = DateTime.fromFormat(b.dueDate, 'dd-MM-yyyy');


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.dueDate, 'dd-MM-yyyy');
                let dateB = DateTime.fromFormat(b.dueDate, 'dd-MM-yyyy');
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    }
    return (
        <div className="col-12">
            <div className="col-12 flex align-items-center" >
                <span className="text-big-one"> Hello  {login_data?.role === 'clientadmin' ? login_data?.information?.companyname : login_data?.information?.empname} ! </span>   <span className="ml-1">{`<${login_data.email}>`} </span> <span className="status-tag-blue">Auditor </span>
            </div>
            <div
                className="col-12 "
                style={{
                    justifyContent: "flex-start",
                }}
            >

                <label className="fs-14 clr-navy flex  justify-content-start">
                    Here, you can view and manage the supplier assessments for the My Sustainability Index (MSI) audits and also provides a summary of pending and completed audits, along with relevant details for your review and action
                </label>
            </div>
            <div className="col-12">
                <TabMenu className="roundedge" model={[{ label: 'My Actions' }, { label: 'MSI Reports' }
                ]} onTabChange={(e) => { setActiveIndex(e.index) }} activeIndex={activeindex} />

                {activeindex === 0 &&
                    <div>
                        <DataTable scrollable value={data} filters={{
                            timeLine: { value: null, matchMode: "in" },
                            title: { value: null, matchMode: "in" },
                            currentStatus: { value: null, matchMode: "in" }
                        }} >
                            <Column header="Timeline" field='timeLine' showFilterMatchModes={false}
                                filter body={timelineTemplate}
                                filterElement={(options) =>
                                    RowFilterTemplate(options, "timeLine")
                                } />
                            <Column header="Required Action" field='title' body={actionTemplate} showFilterMatchModes={false}
                                filter
                                filterElement={(options) =>
                                    RowFilterTemplate(options, "title")
                                } />
                            <Column header="Due Date" field='dueDate' sortable sortFunction={sortDD} />
                            <Column header="Current Status" field='currentStatus' showFilterMatchModes={false}
                                filter body={currentStatusTemplate}
                                filterElement={(options) =>
                                    RowFilterTemplate(options, "currentStatus")
                                } />

                        </DataTable>
                    </div>
                }
                {activeindex === 1 && <div className="col-12">

                    <DataTable value={archived}>

                        <Column header="MSI ID" body={msiIdTemplate} />

                        <Column header="Self-Assessment Submission Date" body={(rowData) => { return getDate(rowData?.supplierAssignmentSubmission?.submitted_on) }} />

                        <Column header="MSI Self Assessment Score" field="supplierAssignmentSubmission.supplierMSIScore" />
                        <Column header="Audit Submission Date" body={(rowData) => { return getDate(rowData?.auditorAssignmentSubmission?.submitted_on) }} />

                        <Column header="MSI Audit Score" field="auditorAssignmentSubmission.auditorMSIScore" />
                        <Column header="Status of Findings" body={findingTemplate} />
                    </DataTable>

                </div>}


            </div>
            <div hidden className="col-12">
                <TabMenu model={[{ label: 'Actions Management' }, { label: 'Reports' },
                ]} onTabChange={(e) => { setActiveIndex(e.index) }} activeIndex={activeindex} className="p-2" />
                <div className="col-12 mt-4">
                    {activeindex === 0 &&
                        <DataTable filters={{
                            auditStatus: { value: null, matchMode: "in" },
                            statusCode: { value: null, matchMode: "in" }
                        }} value={assignments} showGridlines scrollable paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No Assessment Found'>

                            <Column header="MSI ID" body={idTemplate} />
                            {/* <Column header="Supplier Name" body={(rowData) => getSupplier(rowData.supplierId)} /> */}
                            <Column header="Due Date" body={(rowData) => dateTemplate(rowData.auditEndDate)} />
                            <Column header="Required Action(s)" body={(rowData) => getSupplier(rowData.supplierId)} />
                            {/* <Column header="Audit Report" body={reportTemplate} />
                            <Column
                                header="Status"
                                style={{ minWidth: "8%" }}
                                field="auditStatus"
                                showFilterMatchModes={false}
                                showApplyButton={true}
                                filterElement={statusRowFilterTemplate}
                                body={statusTemplate}
                                filter
                            />
                            <Column header="Score" body={(rowData) => rowData.score} /> */}

                            <Column
                                header="Timeline"
                                style={{ minWidth: "8%" }}
                                field="statusCode"
                                showFilterMatchModes={false}
                                showApplyButton={true}
                                filterElement={timelineRowFilterTemplate}
                                body={timelineTemplate}
                                filter
                            />
                        </DataTable>}
                    {activeindex === 1 &&
                        <DataTable value={assignments} showGridlines scrollable paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No Action Found'>
                            <Column header="MSI ID" field='id' />
                            <Column header="Supplier / Vendor Code" body={(rowData) => getSupplier(rowData.supplierId)} />
                            <Column header="Location" field='id' />
                            <Column header="Audit Dates (From-To)" field='id' />
                            <Column header="Status of Non-conformances" body={actionBodyTemplate} />
                            <Column header="Action" body={actionButton} />
                        </DataTable>
                    }
                </div>

                {selectedAudit && <Dialog
                    visible={actionModal}
                    style={{ width: '75%' }}
                    onHide={() => setActionModal(false)}


                >

                    <AuditPanel editable={true} users={supplierList} updateData={(e) => { updateAssessment(e) }} readOnly={true} supplierResponse={selectedAudit.supplierAssignmentSubmission} auditModal={showModal} setAuditModal={setShowModal} auditId={selectedAudit} vendorCode={''} />



                </Dialog>}


                <Dialog
                    visible={addActionModal}
                    header={'Actions'}
                    style={{ width: '75%' }}
                    onHide={() => { setAddActionModal(false); }}


                >




                    <AuditorActions id={selectedAudit?.id} disable={false} />



                </Dialog>
                <Dialog
                    visible={actionlistdialog}
                    header={'Actions Status'}
                    style={{ width: '75%' }}
                    onHide={() => { setActionListDialog(false); }}


                >


                    <Accordion activeIndex={0}>
                        {actionlist.map((action) => {
                            return (
                                <AccordionTab
                                    header={
                                        <div className="col-12 flex justify-content-between parent-full-width">
                                            <div>{action.title}</div>
                                            <div><span >Due Date :</span> {getDate(action.dueDate, 'dd-MM-yyyy')}</div>
                                        </div>
                                    }
                                >

                                    {action?.description}
                                </AccordionTab>
                            )
                        })
                        }
                    </Accordion>



                </Dialog>
            </div>

        </div>
    )
}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AuditorHomeTVS, comparisonFn);