import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";
const MSIGradeChart = ({ data }) => {
  // Function to count occurrences of each grade considering branch_code as one entity
  const aggregateData = (data) => {
    const branchGrades = {};
    const gradeColors = {
      Platinum: "#C0C0C0", // Gray
      Gold: "#FFD700", // Yellow
      Silver: "#E0E0E0", // Light Gray
      Bronze: "#B8860B", // Bronze
      NM: "#FF2E00", // Red
      "Needs Improvement": "#FF2E00", // Ensure it maps correctly
    };


    data.forEach((entry) => {
      const grade = entry.grade || "NM";
      const branchCode = entry.branch_code;

      if (!branchGrades[branchCode]) {
        branchGrades[branchCode] = grade;
      }
    });

    const gradeCounts = {};
    Object.values(branchGrades).forEach((grade) => {
      if (!gradeCounts[grade]) {
        gradeCounts[grade] = 0;
      }
      gradeCounts[grade] += 1;
    });

    return Object.keys(gradeCounts).map((grade) => ({
      name: grade,
      value: gradeCounts[grade],
      color: gradeColors[grade] || "#888888",
    }));
  };

  // const CustomLegend = (props) => {
  //   const { payload } = props;
  //   return (
  //     <ul
  //       style={{
  //         display: "flex",
  //         listStyleType: "none",
  //         justifyContent: "center",
  //       }}
  //     >
  //       {payload.map((entry, index) => (
  //         <li
  //           key={`item-${index}`}
  //           style={{
  //             color: entry.color,
  //             marginRight: "5px",
  //           }}
  //         >
  //           <span
  //             style={{
  //               color: entry.color,
  //               backgroundColor: entry.color,
  //               marginRight: 4,
  //               fontSize: "20px",
  //               width: "10px",
  //               borderRadius: "50%",
  //               display: "inline-block",
  //             }}
  //           ></span>
  //           <span style={{ color: "#555", fontSize: "14px" }}>
  //             {entry.value}
  //           </span>
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };

  const chartData = aggregateData(data);
  console.log("Chart Data:", chartData);

  const renderCustomizedLabel = ({ value, x, y }) => {
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={12}
        fontWeight={600}
      >
        {value}
      </text>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h3 style={{ textAlign: "center", color: "#555" }}>MSI Grade</h3>

      <ResponsiveContainer width={600} height={380}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            label={renderCustomizedLabel}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
          {/* <Legend content={CustomLegend} /> */}
        </PieChart>
      </ResponsiveContainer>

      <div
        className="legend"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="me-3">
          <span
            style={{
              color: "#C0C0C0",
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%", // Make it round
              backgroundColor: "#C0C0C0",
              marginRight: "5px",
            }}
          ></span>
          Platinum
        </div>
        <div className="me-3">
          <span
            style={{
              color: "#FFD700",
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%", // Make it round
              backgroundColor: "#FFD700",
              marginRight: "5px",
            }}
          ></span>
          Gold
        </div>
        <div className="me-3">
          <span
            style={{
              color: "#E0E0E0",
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%", // Make it round
              backgroundColor: "#E0E0E0",
              marginRight: "5px",
            }}
          ></span>
          Silver
        </div>

        <div className="me-3">
          <span
            style={{
              color: "#B8860B",
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%", // Make it round
              backgroundColor: "#B8860B",
              marginRight: "5px",
            }}
          ></span>
          Bronze
        </div>
        <div className="me-3">
          <span
            style={{
              color: "#FF2E00",
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%", // Make it round
              backgroundColor: "#FF2E00",
              marginRight: "5px",
            }}
          ></span>
          NM
        </div>
      </div>
    </div>
  );
};

export default MSIGradeChart;
