// SupplierReport.js
import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  RadarChart, PolarGrid, PolarAngleAxis, Radar,
  CartesianGrid, ResponsiveContainer, BarChart, Bar,
  XAxis, YAxis, Tooltip, Legend
} from 'recharts';
import { Card, Table } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import { DateTime } from 'luxon';
import { groupArrayByKeys } from '../../../../components/BGHF/helper';
import APIServices from '../../../../service/APIService';
import { API } from '../../../../constants/api_url';
import SubGraph1Demo from './reportGraph/SubGraph1Demo';
import SubGraph3Demo from './reportGraph/SubGraph3Demo';
import SubGraph4Demo from './reportGraph/SubGraph4Demo';
import SubGraph5Demo from './reportGraph/SubGraph5Demo';
import ReactToPrint from 'react-to-print';
import { Button } from 'primereact/button';
import ReportSection from './reportSection';

const SupplierReport = forwardRef((props, ref) => {


  const contentRef = useRef(null)
  const [data, setData] = useState(props.report)
  const categoryOptions = [
    { label: 'Good Practices', id: 1 },
    { label: 'Opportunity of Improvement', id: 2 },
    { label: 'Non-compliance', id: 3 },
  ];

  const nonComplianceOptions = [
    { label: 'Regulatory (Major)', id: 1 },
    { label: 'Regulatory (Minor)', id: 2 },
    { label: 'Minor', id: 3 },
  ];
  const [report, setReport] = useState({})
  useEffect(() => {
    if (contentRef.current) {
      console.log("Div element:", contentRef.current);
    }
  }, []);
  useEffect(() => {
    let loc = JSON.parse(JSON.stringify(data))
    APIServices.get(API.AuditorAssessmentSubmission_Edit(props?.report?.auditorAssignmentSubmission?.id)).then(res => {

      let auditorResponse = JSON.parse(res?.data?.response || '[]').flatMap(i => i.assessmentSubSection1s.map(x => ({ ...x, order: i.order, esg: i.order === 1 ? 1 : i.order >= 5 ? 3 : i.order ? 2 : null, categoryTitle: i.order === 1 ? "Environment" : i.order >= 5 ? 'Governance' : i.order ? "Social" : null }))).filter(x => x.categoryTitle)

      for (const act of loc?.supplierActions || []) {
        let order = auditorResponse.find(i => i.id === act.assessmentSubSection2Id)?.order
        act.category = act.categoryOfFinding === 3 ? categoryOptions.find(c => c.id === act.categoryOfFinding)?.label + ' - ' + nonComplianceOptions.find(c => c.id === act.nonComplianceType)?.label : categoryOptions.find(c => c.id === act.categoryOfFinding)?.label
        act.esg = order === 1 ? 1 : order >= 5 ? 3 : order ? 2 : null
      
        console.log(act, auditorResponse)
      }
      const groupedResponse = groupArrayByKeys(auditorResponse, ['categoryTitle'])
      console.log(groupedResponse)
      if (loc.supplierActions) {
        Object.entries(groupedResponse).forEach(([key, value]) => {
          console.log(value)
          for (const xyz of value) {
            console.log(xyz, loc.supplierActions)
            xyz.findings = groupArrayByKeys(loc.supplierActions.filter(x => x.esg === xyz.esg && xyz.id === x.assessmentSubSection2Id), ['category'])
          }


        })
      }

      loc.actualResponse = JSON.parse(res?.data?.response || '[]').map(i => ({ ...i, esg: i.order === 1 ? 1 : i.order >= 5 ? 3 : i.order ? 2 : null, }))

      loc.response = groupedResponse
      console.log(loc)

      setReport(loc)
    })

  }, [data])
  const getActionId = (item) => {
    if (item.categoryOfFinding === 1) {
      return 'GP' + item.id
    } else if (item.categoryOfFinding === 2) {
      return 'OFI' + item.id
    } else if (item.categoryOfFinding === 3) {
      if (item.nonComplianceType === 1) {
        return 'NCR' + item.id + ' (Major)'
      } else if (item.nonComplianceType === 2) {
        return 'NCR' + item.id + ' (Minor)'
      } else if (item.nonComplianceType === 3) {
        return 'NC' + item.id + ' (Major)'
      }
    }
  }
  const getMSIIDByAssignment = (item) => {

    return 'MSI-' + item?.vendor?.code + '-' + DateTime.fromISO(item.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')
  }
  const getDate = (date, format) => {
    if (!date) {
      return 'Not Set'
    }
    if (typeof date === 'string') {
      return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
    } else if (DateTime.isDateTime(date)) {
      return date.toFormat(format ? format : 'dd-MM-yyyy')
    } else {
      return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
    }

  };
  const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }, { name: 'IDM (Indirect Material)', value: 10 }, { name: 'Import', value: 11 }]

  const radarData = [
    { category: "Environmental", value: 80 },
    { category: "Health & safety", value: 60 },
    { category: "Legal compliance", value: 70 },
    { category: "Governance", value: 90 },
    { category: "Social", value: 50 },
    { category: "Sustainability Ambassadorship", value: 65 },
  ];

  const barData = [
    { category: "Social", maxScore: 100, avgScore: 80 },
    { category: "Environment", maxScore: 100, avgScore: 85 },
    { category: "Governance", maxScore: 100, avgScore: 90 },
  ];

  const dataEnvironmental = [
    { name: "Water", maxScore: 100, avgScore: 70 },
    { name: "Waste", maxScore: 100, avgScore: 80 },
    { name: "Product Stewardship", maxScore: 100, avgScore: 90 },
    { name: "Energy", maxScore: 100, avgScore: 75 },
  ];

  const dataSocial = [
    { name: "Occupational health & safety", maxScore: 100, avgScore: 60 },
    { name: "Supplier social stewardship framework", maxScore: 100, avgScore: 70 },
    { name: "Supplier sustainability ambassadorship framework", maxScore: 100, avgScore: 80 },
  ];

  const dataGovernance = [
    { name: "Supplier governance framework", maxScore: 100, avgScore: 85 },
    { name: "Legal compliances", maxScore: 100, avgScore: 95 },
  ];

  const rows = [
    {
      category: "GOOD PRACTICES",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "OPPORTUNITY FOR IMPROVEMENT",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "MINOR NON CONFORMANCE",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "REGULATORY NON COMPLIANCE",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
  ];

  const admin_data = useSelector(state => state.user.admindetail);

  const sections = [
    {
      title: "Waste",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "GOOD PRACTICES",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
                "Voluptatibus excepturi fuga earum non dolorem voluptates vel aspernatur.",
              ],
            },
          ],
        },
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Energy",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Product Stewardship",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
  ];

  const sections1 = [
    {
      title: "Supplier Sustainability Ambassadorship Framework",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Supplier Social Stewardship Framework",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Supplier Occupational Health & Safety Framework",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
  ];

  const sections2 = [
    {
      title: "Legal Compliances",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Supplier Governance Framework",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
  ];

  // Reusable BarChart Section
  const Section = ({ title, data, barColors, backgroundColor }) => (
    <div
      style={{
        backgroundColor,
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <h3 style={{ marginBottom: "20px" }}>{title}</h3>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="maxScore" fill={barColors[0]} />
        <Bar dataKey="avgScore" fill={barColors[1]} />
      </BarChart>
    </div>
  );
  const reactToPrintFn = useReactToPrint({ contentRef });
  return (
    <>
      <div className='col-12 flex justify-content-end'>
        <Button label='Print' icon='pi pi-print' onClick={() => { reactToPrintFn() }} l></Button>

      </div>
      <div ref={contentRef} style={{ width: "100%" }}>
        {/* PAGE 1 */}

        <div className="page text-white min-h-screen flex-col items-center justify-center mb-5" style={{ background: '#00634F' }}>
          <header style={{ marginBottom: "40px", textAlign: "right" }} className='p-4 bg-white'>
            <img
              src={admin_data.information.companylogo} // Replace with the actual path if needed
              alt="TVS Logo"
              style={{ maxWidth: 150 }}
            />
          </header>
          <div className="flex flex-col flex-1">
            <div className="p-10 mt-12" style={{ marginTop: 250 }}>
              <div className="mb-8">
                <img
                  // Update path as needed
                  src={require('../../../../assets/images/test.png').default}
                  alt="TVS Logo"
                  style={{ maxWidth: 250,marginLeft:-60 }}
                />
              </div>
              <div className="p-5">
                <h1 className="text-4xl font-bold mb-2">My sustainability index for suppliers:</h1>
                <p className="text-xl font-medium mb-4">On-site MSI Calibration Report</p>
                <p className="text-md">{DateTime.fromISO(report?.auditorAssignmentSubmission?.modified_on, { zone: 'utc' }).toLocal().toFormat('LLLL dd,yyyy')} </p>
              </div>
            </div>
          </div>
        </div>

        {/* PAGE 2 */}
        <div className="page min-h-screen p-8 mb-5" style={{ background: "#FFFFFF" }}>
          <header style={{ marginBottom: "40px", textAlign: "right" }}>
            <img src={admin_data.information.companylogo} alt="Logo" style={{ height: "20px" }} />
          </header>

          <h1 className="text-2xl font-bold">
            Calibration ID:
            <span className="text-gray-500">
              {getMSIIDByAssignment(data)}
            </span>
          </h1>

          <div>
            <div style={{ marginBottom: 20,borderLeft:'6px solid #e3e3e3',paddingLeft:20 }}>
              <p><strong>Supplier name:</strong> {report?.vendor?.supplierName}</p>
              <p><strong>Vendor code:</strong> {report?.vendor?.code}</p>
              <p><strong>Calibration date:</strong>{getDate(report?.auditorAssignmentSubmission?.modified_on)}</p>
              <p><strong>Supplier category:</strong> {categoryList.find(x => x.value === report?.vendor?.supplierCategory)?.name}</p>
              <p><strong>Supplier location:</strong> {report?.vendor?.supplierLocation}</p>
              <p><strong>MSI Score:</strong> {report?.auditorAssignmentSubmission?.auditorMSIScore}</p>
              <p ><strong>MSI Rank:</strong> <div style={{ width: 50, display: 'inline-table' }}> {report?.auditorAssignmentSubmission?.auditorMSIScore != null ? report?.auditorAssignmentSubmission?.auditorMSIScore > 85 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/platinum_rating.png').default} /> : report?.auditorAssignmentSubmission?.auditorMSIScore > 70 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/gold_rating.png').default} /> : report?.auditorAssignmentSubmission?.auditorMSIScore > 55 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/silver_rating.png').default} /> : report?.auditorAssignmentSubmission?.auditorMSIScore > 40 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/bronze_rating.png').default} /> : 'Needs Improvement' : "NA"} </div> </p>
              <p><strong>Number of critical non-compliance:</strong> {report?.supplierActions?.filter(x => x.categoryOfFinding === 3)?.length || 0} </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8">

            <SubGraph1Demo supplyData={report?.actualResponse || []} />


          </div>
        </div>

        {/* PAGE 3 */}
        <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
          <header style={{ marginBottom: "40px", textAlign: "right" }}>
            <img src={admin_data.information.companylogo} alt="Logo" style={{ height: "20px" }} />
          </header>
          <SubGraph3Demo supplyData={report?.response?.Environment || []} count={report?.supplierActions?.filter(x => x.esg === 1 && x.categoryOfFinding === 3).length || 0} />
          <SubGraph4Demo supplyData={report?.actualResponse || []} count={report?.supplierActions?.filter(x => x.esg === 2 && x.categoryOfFinding === 3).length || 0} />
          <SubGraph5Demo supplyData={report?.actualResponse || []} count={report?.supplierActions?.filter(x => x.esg === 3 && x.categoryOfFinding === 3).length || 0} />
        </div>

        {/* PAGE 4 */}
        <div
          className="page bg-white mb-5"
          style={{ fontFamily: "Arial, sans-serif", padding: "40px", lineHeight: "1.6" }}
        >
          <header style={{ marginBottom: "40px", textAlign: "right" }}>
            <img src={admin_data.information.companylogo} alt="Logo" style={{ height: "20px" }} />
          </header>



          <section style={{ marginBottom: "40px" }}>

            <ReportSection imgSrc={require('../../../../assets/images/report/valuechain/goodpractice.png').default} heading={'Good Practices'}
              content={`Actions, processes, or initiatives that go beyond compliance and
                demonstrate exemplary efforts in sustainability. These practices
                are innovative, scalable, and have a significant positive
                environmental, social, or governance impact.`}
            />
            <hr />

            <ReportSection imgSrc={require('../../../../assets/images/report/valuechain/oppforimprovement.png').default} heading={'Opportunity for Improvement'} content={`Areas where existing processes, policies, or practices meet
                minimum requirements but can be enhanced to deliver better
                sustainability outcomes. These do not represent non-conformance
                but provide room for growth and optimization.`} />
            <hr />
            <h3>Non conformances</h3>
            <ReportSection imgSrc={require('../../../../assets/images/report/valuechain/criticalcompissue.png').default} heading={'Non-Conformances'} content={`Findings highlighting severe lapses that could lead to
                regulatory penalties or serious incidents, posing significant
                risks to operations and stakeholders, and resulting in negative
                scoring as part of the assessment.`} />
            <hr />
            <ReportSection imgWidth={120} imgSrc={require('../../../../assets/images/report/valuechain/regucomp.png').default} heading={'Regulatory Compliance'} content={`Failure to meet the statutory and legal requirements applicable
                to the organization's operations, resulting in risks to the
                sustainability objectives and compliance framework.`} />
            <hr />
            <ReportSection imgWidth={130} imgSrc={require('../../../../assets/images/report/valuechain/minornoncon.png').default} heading={'Minor Non-Conformance'} content={`Small deviations from established standards, policies, or
                procedures that do not pose an immediate or significant risk
                but need correction to prevent escalation into major issues.`} />










          </section>
        </div>

        {/* PAGE 5 */}

        {Object.entries(report?.response || {}).map((row, index) => (
          <div
            className="page bg-white mb-5"
            style={{ fontFamily: "Arial, sans-serif", padding: "40px", lineHeight: "1.6" }}
          >
            <header style={{ marginBottom: "40px", textAlign: "right" }}>
              <img
                src={admin_data.information.companylogo}
                alt="TVS Logo"
                style={{ height: "20px" }}
              />
            </header>
            <section>
              <h1 className="fw-bold">{row[0]}</h1>

              {row[1].map((section) => (
                <div>
                  <div className='col-12 flex grid m-1 p-1' style={{ backgroundColor: "#002147", color: "#fff" }}>


                    <div className='col-6' style={{ background: "#002369", color: "#fff" }}>{section.title}</div>

                    <div className='col-6' style={{ background: "#002369", color: "#fff" }}>
                      Score obtained:  {section?.totalScore?.toFixed(3)}
                    </div>
                  </div>
                  {Object.entries(section?.findings || {}).length ? Object.entries(section?.findings || {})?.map((row, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: "20px",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <h4>{row[0]}</h4>

                      <Table bordered responsive>
                        <thead>
                          <tr>

                            <th style={{ background: row[1]?.[0]?.categoryOfFinding === 1 ? "#B5EECE" : row[1]?.[0]?.categoryOfFinding === 2 ? '#EAEAEA' : '#F0CCB3' }} >Id</th>
                            <th style={{ background: row[1]?.[0]?.categoryOfFinding === 1 ? "#B5EECE" : row[1]?.[0]?.categoryOfFinding === 2 ? '#EAEAEA' : '#F0CCB3' }}>Finding</th>
                            <th style={{ background: row[1]?.[0]?.categoryOfFinding === 1 ? "#B5EECE" : row[1]?.[0]?.categoryOfFinding === 2 ? '#EAEAEA' : '#F0CCB3' }}>Description</th>

                            <th style={{ background: row[1]?.[0]?.categoryOfFinding === 1 ? "#B5EECE" : row[1]?.[0]?.categoryOfFinding === 2 ? '#EAEAEA' : '#F0CCB3' }} >Evidence</th>

                          </tr>
                        </thead>
                        <tbody>
                          {row[1].map((item, idx2) => (
                            <tr key={idx2}>
                              <td style={{ background: row[1]?.[0]?.categoryOfFinding === 1 ? "#DCFFEB" : row[1]?.[0]?.categoryOfFinding === 2 ? '#F4F4F4' : '#FAE9DD' }}>{item.actionId}</td>
                              {item.category && (
                                <td style={{ background: row[1]?.[0]?.categoryOfFinding === 1 ? "#DCFFEB" : row[1]?.[0]?.categoryOfFinding === 2 ? '#F4F4F4' : '#FAE9DD' }}>{item.finding}</td>

                              )}
                              <td style={{ background: row[1]?.[0]?.categoryOfFinding === 1 ? "#DCFFEB" : row[1]?.[0]?.categoryOfFinding === 2 ? '#F4F4F4' : '#FAE9DD' }}>{item.description}</td>

                              <td style={{ background: row[1]?.[0]?.categoryOfFinding === 1 ? "#DCFFEB" : row[1]?.[0]?.categoryOfFinding === 2 ? '#F4F4F4' : '#FAE9DD' }}>
                                <ul>
                                  {item?.auditorAttachments?.map((evi, id) => (
                                    <li className='table-link-clickable' onClick={() => { window.open(API.Docs + evi.originalname) }} key={id}>{evi.originalname}</li>
                                  ))}
                                </ul>
                              </td>
                            </tr>
                          ))



                          }

                        </tbody>
                      </Table>

                    </div>
                  )) :
                    <div className='flex col-12 justify-content-center'>
                      No Observation
                    </div>

                  }
                </div>
              ))}

            </section>
          </div>
        ))}

      </div>









    </>
  );
});

export default SupplierReport;
