import React, { useRef, useEffect, useState } from "react";
import { Card } from "primereact/card";
import QualitativeResponse from "./QualitativeResponse";
import APIServices from "../../../service/APIService";
import { API } from "../../../constants/api_url"; 
import { use } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import moment from "moment";
const QualitativeDataResponse = () => {
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const [rawsitelist, setRawSitelist] = useState([]);


    const [assignedData, setAssignedData] = useState([]);
    const [categoryData, setCategoryData] = useState([
        {
            "id": 2,
            "name": "Environment",
            "created": null,
            "qTopics": [
                {
                    "id": 2,
                    "name": "Management of water-related issues",
                    "created": null,
                    "qCategoryId": 2,
                    "qSections": [
                        {
                            "id": 3,
                            "name": "Policy & Commitment",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 87
                        },
                        {
                            "id": 4,
                            "name": "Water Risk Assessment",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 88
                        },
                        {
                            "id": 5,
                            "name": "Efficiency & Reduction Initiatives",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 89
                        },
                        {
                            "id": 6,
                            "name": "Wastewater Treatment & Discharge",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 90
                        },
                        {
                            "id": 7,
                            "name": "Water Stewardship & Community Engagement",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 91
                        },
                        {
                            "id": 8,
                            "name": "Targets & Performance Tracking",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 92
                        },
                        {
                            "id": 9,
                            "name": "Water accounting or auditing performed",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 93
                        },
                        {
                            "id": 10,
                            "name": "Technologies or practices to recycle or reuse water",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 94
                        },
                        {
                            "id": 11,
                            "name": "Product Water footprint ",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 95
                        },
                        {
                            "id": 13,
                            "name": "X",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 9,
                    "name": "Management of waste-related Impacts",
                    "created": null,
                    "qCategoryId": 2,
                    "qSections": [
                        {
                            "id": 51,
                            "name": "Policy & Procedure",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 52,
                            "name": "Management of waste related impacts",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 53,
                            "name": "Hazardous Waste Handling & Transport",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 54,
                            "name": "Material Efficiency & Waste Reduction",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 55,
                            "name": "Hazardous Substance Management",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 56,
                            "name": "Waste Reduction & Recycling Technologies",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 57,
                            "name": "Employee Awareness & Waste Segregation",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 58,
                            "name": "Waste Stream Mapping & Control",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 59,
                            "name": "Extended Producer Responsibility",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 60,
                            "name": "Waste Reduction Targets",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 10,
                    "name": "Climate Change",
                    "created": null,
                    "qCategoryId": 2,
                    "qSections": [
                        {
                            "id": 61,
                            "name": "Environmental policy & certifications",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 62,
                            "name": "Process for Identifying of Risks and Opportunities",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 63,
                            "name": "Goals and Targets Related to Climate Change",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 64,
                            "name": "Risks and opportunities",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 65,
                            "name": "Transition Planning",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 66,
                            "name": "Reduction of GHG emissions",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 67,
                            "name": "Governance structure of the organization",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 27,
                    "name": "Biodiversity",
                    "created": null,
                    "qCategoryId": 2,
                    "qSections": [
                        {
                            "id": 119,
                            "name": "Policies and Commitments to Halt and Reverse Biodiversity Loss",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        },
                        {
                            "id": 120,
                            "name": "Goals and Targets on Biodiversity Loss",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        },
                        {
                            "id": 121,
                            "name": "Integration and Monitoring of Biodiversity in the Strategy of the Organization",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        },
                        {
                            "id": 123,
                            "name": "Actions taken to reduce impact on biodiversity",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        },
                        {
                            "id": 124,
                            "name": "Activities near biodiversity-sensitive areas",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        }
                    ]
                }
            ]
        },
        {
            "id": 3,
            "name": "Social",
            "created": null,
            "qTopics": [
                {
                    "id": 11,
                    "name": "Labour & Human Rights Framework",
                    "created": null,
                    "qCategoryId": 3,
                    "qSections": [
                        {
                            "id": 68,
                            "name": "Labour & Human Rights Policies",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 69,
                            "name": "Working Conditions",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 70,
                            "name": "Accessibility of Workspace",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 71,
                            "name": "Social Dialogue",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 72,
                            "name": "Training & Career Management",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 73,
                            "name": "Child & Forced Labour Prevention",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 74,
                            "name": "Diversity, Equity & Inclusion (DE&I)",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 75,
                            "name": "Whistleblowing & Grievance Mechanisms",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 12,
                    "name": "Employee health & safety",
                    "created": null,
                    "qCategoryId": 3,
                    "qSections": [
                        {
                            "id": 76,
                            "name": "Risk Assessment & Preparedness",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 77,
                            "name": "Preventive Measures & Training",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 78,
                            "name": "Subcontractor Health & Safety",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 79,
                            "name": "Occupational Health & Safety Governance",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 80,
                            "name": "Incident Reporting & Investigation",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 81,
                            "name": "Confidentiality of Health Data",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 82,
                            "name": "Access to Medical & Health Services",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 83,
                            "name": "Employees Wellness",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 13,
                    "name": "Corporate Social Responsibility",
                    "created": null,
                    "qCategoryId": 3,
                    "qSections": [
                        {
                            "id": 84,
                            "name": "Social Impact Assessments",
                            "created": null,
                            "qTopicId": 13,
                            "srfId": null
                        },
                        {
                            "id": 85,
                            "name": "CSR Activities",
                            "created": null,
                            "qTopicId": 13,
                            "srfId": null
                        }
                    ]
                }
            ]
        },
        {
            "id": 4,
            "name": "Governance",
            "created": null,
            "qTopics": [
                {
                    "id": 14,
                    "name": "Anti-corruption",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 86,
                            "name": "Anti-Corruption Practices ",
                            "created": null,
                            "qTopicId": 14,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 15,
                    "name": "Anti-competitive practices",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 87,
                            "name": "Anti-competitive policy and actions",
                            "created": null,
                            "qTopicId": 15,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 17,
                    "name": "Information security",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 88,
                            "name": "Information Security & Data Protection",
                            "created": null,
                            "qTopicId": 17,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 18,
                    "name": "Management of Consumers/Customers",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 89,
                            "name": "Consumer Grievances",
                            "created": null,
                            "qTopicId": 18,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 19,
                    "name": "Intellectual Property",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 90,
                            "name": "Details related to Intellectual property rights",
                            "created": null,
                            "qTopicId": 19,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 20,
                    "name": "Member of associations",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 91,
                            "name": "Associations",
                            "created": null,
                            "qTopicId": 20,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 21,
                    "name": "Public policy advocacy",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 92,
                            "name": "Public policy advocacy ",
                            "created": null,
                            "qTopicId": 21,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 22,
                    "name": "Board Independence",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 93,
                            "name": "Board Structure",
                            "created": null,
                            "qTopicId": 22,
                            "srfId": null
                        },
                        {
                            "id": 94,
                            "name": "Leadership",
                            "created": null,
                            "qTopicId": 22,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 23,
                    "name": "Board Diversity & Composition",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 95,
                            "name": "Diversity Policy",
                            "created": null,
                            "qTopicId": 23,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 24,
                    "name": "Executive Compensation & Shareholding",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 96,
                            "name": "Long-Term Incentives",
                            "created": null,
                            "qTopicId": 24,
                            "srfId": null
                        },
                        {
                            "id": 97,
                            "name": "Executive Shareholding",
                            "created": null,
                            "qTopicId": 24,
                            "srfId": null
                        },
                        {
                            "id": 98,
                            "name": "Stock Ownership Requirements",
                            "created": null,
                            "qTopicId": 24,
                            "srfId": null
                        },
                        {
                            "id": 99,
                            "name": "Major Shareholders",
                            "created": null,
                            "qTopicId": 24,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 25,
                    "name": "Materiality Analysis",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 100,
                            "name": "Materiality Determination & Disclosure",
                            "created": null,
                            "qTopicId": 25,
                            "srfId": null
                        },
                        {
                            "id": 101,
                            "name": "ESG Targets & Performance",
                            "created": null,
                            "qTopicId": 25,
                            "srfId": null
                        },
                        {
                            "id": 102,
                            "name": "Public Reporting & Transparency",
                            "created": null,
                            "qTopicId": 25,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 26,
                    "name": "Risk Management",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 103,
                            "name": "Risk Governance",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 104,
                            "name": "Risk Management Processes",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 105,
                            "name": "Risk Oversight & Structure",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 106,
                            "name": "Employee Involvement & Reporting",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 107,
                            "name": "Product Development & Business Operations",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 108,
                            "name": "Business ethics",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        }
                    ]
                }
            ]
        },
        {
            "id": 5,
            "name": "Supply Chain",
            "created": null
        },
        {
            "id": 6,
            "name": "General Information",
            "created": null
        }
    ]);

    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";

        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };

    function mergeGroupedSectionData(apiData, localData) {
        const updatedLocalData = {};

        Object.entries(localData).forEach(([categoryName, entries]) => {
            const matchedCategory = apiData.find(cat => cat.name === categoryName);
            if (!matchedCategory) {
                updatedLocalData[categoryName] = entries;
                return;
            }

            updatedLocalData[categoryName] = entries.map(entry => {
                const matchedTopic = matchedCategory.qTopics.find(topic => topic.name === entry.subHeading);
                if (!matchedTopic) return entry;

                const sectionNames = matchedTopic.qSections.map(section => section.name);
                const sectionMap = {};

                // loop through each user in data
                Object.entries(entry.data).forEach(([userId, userSections]) => {
                    const enrichedSections = {};

                    sectionNames.forEach(sectionName => {
                        enrichedSections[sectionName] = userSections[sectionName] || {
                            id: matchedTopic.qSections.find(s => s.name === sectionName)?.id || null,
                            name: sectionName,
                            status: 'Blocked',
                            dueDate: null,
                            isLocked: false,
                            form: [],
                        };
                    });
console.log(enrichedSections)
                    sectionMap[userId] = enrichedSections;
                });

                return {
                    ...entry,
                    data: sectionMap
                };
            });
        });

        return updatedLocalData;
    }



    const transformAssignedDataByCategory = (assignedData) => {
        const grouped = {};

        assignedData.forEach((item) => {
            const title = item.qCategory.name;
            const subHeading = item.qTopic.name;
            const location = getCoverageText(item, rawsitelist) || 'N/A';
            const sectionName = item.qSection.name;
            const dueDate = moment.utc(item.due_date).local().format("DD/MM/YYYY");
            const key = `${title}__${subHeading}__${location}`;

            if (!grouped[title]) grouped[title] = {};
            if (!grouped[title][key]) {
                grouped[title][key] = {
                    title,
                    subHeading,
                    location,
                    data: {} // ← per-user grouped response
                };
            }

            const allUserResponses = item.response || {};
            console.log(allUserResponses)
            Object.entries(allUserResponses).forEach(([userId, userResp]) => {
                if (!grouped[title][key].data[userId]) {
                    grouped[title][key].data[userId] = {}; // Each user's full section set
                }

                grouped[title][key].data[userId][sectionName] = {
                    name: sectionName,
                    status: userResp.status || "Not Started",
                    dueDate,
                    isLocked: false,
                    form: item.srf?.data1
                        ? JSON.parse(item.srf.data1).map((field) => {
                            const responseValue = userResp?.[field.name];

                            if (field.type === "radio-group" && Array.isArray(field.values)) {
                                return {
                                    ...field,
                                    values: field.values.map((option) => ({
                                        ...option,
                                        selected: option.value === responseValue
                                    })),
                                    value: responseValue || null
                                };
                            }

                            return {
                                ...field,
                                value: responseValue || ""
                            };
                        })
                        : null,
                    id: item.id
                };
            });
        });

        // Flatten grouped structure to final output
        const finalGroups = {};

        Object.entries(grouped).forEach(([category, group]) => {
            finalGroups[category] = [];

        Object.values(group).forEach(({ title, subHeading, location, data }) => {
            finalGroups[category].push({ title, subHeading, location, data });
            });
        });

        const merged = mergeGroupedSectionData(categoryData, finalGroups);
        return merged;
    };



    const [groupedData, setGroupedData] = useState({});

    useEffect(() => {

        if (assignedData.length) {
            const grouped = transformAssignedDataByCategory(assignedData);

            console.log("Grouped Data", grouped);
            setGroupedData(grouped);
        }
    }, [assignedData]);

    const getAssignedQualitativeData = async () => {


        const response = await APIServices.post(API.GetAssignedQualitative, {
            userId: login_data.id,
            userProfileId: admin_data.id
        });

        if (response.status === 200) {
console.log(response.data)
            setAssignedData(response.data);

        }
    };

    useEffect( async() => {
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        const locationData = await APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const shapedSite = locationData?.data?.map((item) => {
            if (item.locationTwos) {
                item.locationTwos = item.locationTwos.filter(
                    (locationTwo) =>
                        locationTwo.locationThrees &&
                        locationTwo.locationThrees.length > 0
                );
            }
            return item;
        }).filter((item) => item.locationTwos && item.locationTwos.length > 0);
        setRawSitelist(shapedSite)
        getAssignedQualitativeData();
    }, []);

    const op1 = useRef(null);
    const op2 = useRef(null);


    return (
        <div className="p-4 max-w-4xl mx-auto">
            <Card className="mb-4">




                <div className="d-flex justify-content-between align-items-center mt-3 p-3">
                    <div className="d-flex gap-3 align-items-center">
                        <span className="d-flex align-items-center gap-2">
                            <span className="rounded-circle bg-secondary" style={{ width: "12px", height: "12px" }}></span> Information required
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <span className="rounded-circle bg-warning" style={{ width: "12px", height: "12px" }}></span> Draft
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <span className="rounded-circle bg-success" style={{ width: "12px", height: "12px" }}></span> Data finalised
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <span className="rounded-circle bg-danger" style={{ width: "12px", height: "12px" }}></span> Overdue submission
                        </span>
                    </div>
                    <div className="d-flex gap-4 align-items-center">
                        <span className="d-flex align-items-center gap-2">
                            <i className="pi pi-lock"></i> Locked for submission
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <i className="pi pi-lock-open"></i> Unlocked for updates
                        </span>
                    </div>
                </div>

            </Card>
            <div className="w-100">
                {groupedData["Environment"] && <QualitativeResponse data={groupedData["Environment"] || []} />}
                {groupedData["Social"] && <QualitativeResponse data={groupedData["Social"] || []} />}
                {groupedData["Ethics"] && <QualitativeResponse data={groupedData["Ethics"] || []} />}
                {groupedData["Sustainable Procurement"] && <QualitativeResponse data={groupedData["Sustainable Procurement"] || []} />}
            </div>

        </div>
    );
};

export default QualitativeDataResponse;