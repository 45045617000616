import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
// import { GET_USER_ROLE_BY_MODE, INCIDENT_NEARTERM_WITH_ID, NEAR_TERM_WITH_ID } from "../../constants";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { DateTime } from 'luxon'

import { useSelector } from "react-redux";
import Swal from "sweetalert2";


const AuditorActions = ({ id, disable }) => {
    const [formDataList, setFormDataList] = useState([]);
    const [incidentAssignee, setIncidentAssignee] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [submitted, setSubmitted] = useState(false);
  
    const login_data = useSelector((state) => state.user.userdetail);
    const [currentFormData, setCurrentFormData] = useState({
        title: "",
        description: "",
        category: null,
        priority: null,
        dueDate: null
    });
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        fetchFormDataFromAPI();

    }, [id]);
    const categoryOptions = [
        { id: 1, label: "Environment" },
        { id: 2, label: "Social" },
        { id: 3, label: "Governance" },
    ];

    const priorityOptions = [
        { id: 3, label: "High" },
        { id: 2, label: "Medium" },
        { id: 1, label: "Low" },
    ]
    const fetchFormDataFromAPI = async () => {

        try {

            const url = API.SupplierAction_SupplierAssignment(id)
            const response = await APIServices.get(url);
            if (response.status === 200) {
                setFormDataList(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };



    const handleModalOpen = (index = null) => {
        console.log(index)
        if (index !== null) {
            const selectedFormData = formDataList[index];
            const parseddueDate = selectedFormData.dueDate ? new Date(selectedFormData.dueDate) : new Date();
            setCurrentFormData({
                ...selectedFormData,
                dueDate: parseddueDate,
            });
            setEditingIndex(index);
        } else {
            setCurrentFormData({
                title: "",
                dueDate: new Date(),
                responsibleId: null,
            });
            setEditingIndex(null);
        }
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        
    };

    const handleInputChange = (field, value) => {
        setCurrentFormData(prevData => ({ ...prevData, [field]: value }));
    };

    const handleSubmit = async (e) => {
        setSubmitted(true)
        let loc = JSON.parse(JSON.stringify(formDataList))
        let obj = { category: currentFormData.category, dueDate: currentFormData.dueDate, title: currentFormData.title, priority: currentFormData.priority }
        if (obj.category && obj.dueDate && obj.title && obj.title.trim().length && obj.priority) {
            if (currentFormData.id) {
                let newObj = { modified_by: login_data.id, modified_on: DateTime.utc() }
                APIServices.patch(API.SupplierAction_Edit(currentFormData.id), { ...newObj, ...obj }).then((res) => {
                    let index = loc.findIndex(i => i.id === currentFormData.id)
                    if (index !== -1) {
                        loc[index] = { id: currentFormData.id, ...newObj, ...obj }
                        setFormDataList(loc)
                    }
                    setSubmitted(false)
                    setShowModal(false)
                })
            } else {
                let newObj = { created_by: login_data.id, created_on: DateTime.utc(),status:1 }
                APIServices.post(API.SupplierAction_SupplierAssignment(id), { ...newObj, ...obj }).then((res) => {
                    loc.push(res.data);
                    setFormDataList(loc)
                    setSubmitted(false)
                    setShowModal(false)
                })
            }
        }

    };
    const footer = () => {
        return (
            <div className='flex col-12 justify-content-end'>
                <Button label='Save & Exit' onClick={() => { handleSubmit() }} />
            </div>
        )
    }
    const handleRemoveField = async (item) => {
        Swal.fire({
            title: `Are you sure want to delete  ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                APIServices.delete(API.SupplierAction_Edit(item.id)).then((res) => {
                    let loc = JSON.parse(JSON.stringify(formDataList))
                    let index = loc.findIndex(i => i.id === item.id)
                    if (index !== -1) {
                        loc.splice(index, 1)
                        setFormDataList(loc)

                    }
                })
            }
        })
    };
    const categoryTemplate = (option) => {
        return categoryOptions.find(c => c.id === option.category)?.label || 'Not Found';
    };
    const priorityTemplate = (option) => {
        return priorityOptions.find(c => c.id === option.priority)?.label || 'Not Found';
    };
    const sNoTemplate = (rowData, { rowIndex }) => <span className="cur-pointer clr-navy text-underline" onClick={() => { editAction(rowData) }}>{rowData.title}</span>;

    const editAction = (rowData) => {
        let loc = JSON.parse(JSON.stringify(rowData))
        if (loc.dueDate) {
            loc.dueDate = DateTime.fromISO(loc.dueDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        setCurrentFormData(loc)
        setShowModal(true)

    }


    const actionTemplate = (rowData, { rowIndex }) => (
        <>

            <Button
                icon="pi pi-trash"
                className="p-button-danger p-button-rounded"
                onClick={() => handleRemoveField(rowData)}
                disabled={deleteLoading || disable}  // Disable deleting if disable is true
            />
        </>
    );

    return (
        <div>
            <Button
                label="Add Action"
                icon="pi pi-plus"
                className="p-button-primary m-3"
                onClick={() => handleModalOpen(null)}
                disabled={disable}  // Disable adding new control if disable is true
            />
            <DataTable value={formDataList} paginator rows={5} className="datatable-responsive styled-table">

                <Column field="title" header="Title" body={sNoTemplate} style={{ width: "25%" }} />
                <Column field="description" header="Description" style={{ width: "25%" }} />
                <Column field="category" header="Category" body={categoryTemplate} style={{ width: "15%" }} />
                <Column field="priority" header="Priority" body={priorityTemplate} style={{ width: "15%" }} />
                <Column
                    field="dueDate"
                    header="Due Date"
                    body={rowData => new Date(rowData.dueDate).toLocaleDateString()}
                    style={{ width: "15%" }}
                />

                <Column header="Actions" body={actionTemplate} style={{ width: "15%" }} />
            </DataTable>


            <Dialog visible={showModal} style={{ width: '75%' }} footer={footer} header={editingIndex !== null ? "Edit Action" : "Add Action"} onHide={handleModalClose}>
                <div className="p-fluid grid m-0 p-3">
                    <div className="p-field col-12 ">
                        <label htmlFor="status">Title<span className="mandatory"> *</span>  </label>
                        <InputText className='mt-2' value={currentFormData.title} onChange={(e) => { handleInputChange("title", e.target.value) }} placeholder="title" />

                        {submitted && !currentFormData.title && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Title
                            </small>
                        )}
                    </div>
                    <div className="p-field col-12 ">
                        <label htmlFor="status">Description </label>
                        <InputTextarea style={{ width: '100%', height: 120, overflow: 'auto' }} className='mt-2' value={currentFormData.description} onChange={(e) => { handleInputChange("description", e.target.value) }} placeholder="description" />


                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Category<span className="mandatory"> *</span>  </label>
                        <Dropdown id="status" filter panelClassName="hidefilter" className='mt-2' value={currentFormData.category} optionLabel="label" optionValue='id' options={categoryOptions} onChange={(e) => { handleInputChange("category", e.target.value) }} placeholder="Select Category" />

                        {submitted && !currentFormData.category && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Category
                            </small>
                        )}
                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Category<span className="mandatory"> *</span>  </label>
                        <Dropdown id="status" className='mt-2' value={currentFormData.priority} optionLabel="label" optionValue='id' options={priorityOptions} onChange={(e) => { handleInputChange("priority", e.target.value) }} placeholder="Select Priority" />

                        {submitted && !currentFormData.priority && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Priority
                            </small>
                        )}
                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Due Date<span className="mandatory"> *</span>  </label>
                        <Calendar id="status" dateFormat="dd/m/yy" className='mt-2' value={currentFormData.dueDate} onChange={(e) => { handleInputChange("dueDate", e.target.value) }} placeholder="Select Due Date" />

                        {submitted && !currentFormData.dueDate && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Set Due Date
                            </small>
                        )}
                    </div>
                </div>

            </Dialog>
          
        </div>
    );
};

export default AuditorActions;
