import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const FireSafetyChart = ({ data }) => {
  const maxValues = {
    fire_safety: 10,
    road_safety: 10,
    electrical_safety: 5,
    office_safety: 5,
    sustainability_ambassador_program: 10,
  };

  const extractScores = (criteria = {}) => {
    const subCriteria = criteria?.social?.subCriteria || [];

    const getScore = (name) => {
      const found = subCriteria.find((item) => item.name === name);
      return Math.max(0, found?.score || 0); // Avoid negative or null
    };

    return [
      {
        category: "Fire Safety",
        avgValue: getScore("fire_safety"),
        remainingToMax: Math.max(
          0,
          maxValues.fire_safety - getScore("fire_safety")
        ),
        maxValue: maxValues.fire_safety,
      },
      {
        category: "Road Safety",
        avgValue: getScore("road_safety"),
        remainingToMax: Math.max(
          0,
          maxValues.road_safety - getScore("road_safety")
        ),
        maxValue: maxValues.road_safety,
      },
      {
        category: "Electrical Safety",
        avgValue: getScore("electrical_safety"),
        remainingToMax: Math.max(
          0,
          maxValues.electrical_safety - getScore("electrical_safety")
        ),
        maxValue: maxValues.electrical_safety,
      },
      {
        category: "Office Safety",
        avgValue: getScore("office_safety"),
        remainingToMax: Math.max(
          0,
          maxValues.office_safety - getScore("office_safety")
        ),
        maxValue: maxValues.office_safety,
      },
      {
        category: "SAP",
        avgValue: getScore("sustainability_ambassador_program"),
        remainingToMax: Math.max(
          0,
          maxValues.sustainability_ambassador_program -
            getScore("sustainability_ambassador_program")
        ),
        maxValue: maxValues.sustainability_ambassador_program,
      },
    ];
  };

  const salesData = extractScores(data.sales_criteria);
  const serviceData = extractScores(data.service_criteria);

  const getYAxisDomain = () => [0, 10];

  const CustomLegend = ({ payload }) => (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            marginRight: "10px",
          }}
        >
          <span
            style={{
              backgroundColor: entry.color,
              marginRight: 4,
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span style={{ color: "#555", fontSize: "14px" }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );

  const renderChart = (chartData, title) => (
    <div style={{ width: "48%", height: 400 }}>
      <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
        {title}
      </h3>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          barSize={60}
          margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="category"
            tick={{ fontSize: 12 }}
            angle={-30}
            textAnchor="end"
            interval={0}
            dy={10}
            height={110}
          />
          <YAxis domain={getYAxisDomain()} />
          <Tooltip
            formatter={(value, name, props) => {
              const { payload } = props;
              if (name === "Achieved") {
                return [
                  `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                  name,
                ];
              }
              return [`${value}`, name];
            }}
          />
          <Legend content={CustomLegend} />
          <Bar
            dataKey="avgValue"
            stackId="score"
            fill="#FC6E51"
            name="Achieved"
            label={{
              position: "insideTop",
              fill: "#fff",
              formatter: (value) => value.toFixed(1),
            }}
          />
          <Bar
            dataKey="remainingToMax"
            stackId="score"
            fill="#FEB2A8"
            name="Remaining"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      {renderChart(salesData, "Sales - Social")}
      {renderChart(serviceData, "Service - Social")}
    </div>
  );
};

export default FireSafetyChart;
