export const aps = [
  {
    entity_id: 1,
    wk_no: 34,
    date_of_calibration: 45614,
    zone: "South 1",
    area_office: "TN2",
    assessor: "G Pavithran",
    city: "Salem",
    branch_code: 30152,
    dealer_name: " Jagadamba auto solutions",
    environment: 8,
    water_management: 0,
    waste_management: 4,
    energy_management: 0,
    road_safety: 0,
    electrical_safety: 12,
    fire_safety: 4,
    "5s_and_house_keeping": 5,
    personnel_safety: 2,
    governance_framework: 10,
    total_score: 45,
    grade: "Bronze",
    environment_avg: 12,
    social_avg: 23,
    gove_avg: 10,
  },
  {
    entity_id: 2,
    wk_no: 34,
    date_of_calibration: 45618,
    zone: "Central",
    area_office: "DEL",
    "Assessor Name": "KB Madhu",
    city: "Delhi",
    branch_code: 30294,
    dealer_name: "Excellent Automotives",
    environment: 6,
    water_management: 2,
    waste_management: 8,
    energy_management: 4,
    road_safety: 6,
    electrical_safety: 2,
    fire_safety: 2,
    "5s_and_house_keeping": 2,
    personnel_safety: 2,
    governance_framework: 8,
    total_score: 42,
    grade: "Bronze",
    environment_avg: 20,
    social_avg: 14,
    gove_avg: 8,
  },
  {
    entity_id: 3,
    wk_no: 36,
    date_of_calibration: 45629,
    zone: "East",
    area_office: "",
    "Assessor Name": "JP",
    city: "Bhubaneswar",
    branch_code: 30326,
    dealer_name: "Mohini Marketting",
    environment: 6,
    water_management: 0,
    waste_management: 4,
    energy_management: 0,
    road_safety: 2,
    electrical_safety: 2,
    fire_safety: 4,
    "5s_and_house_keeping": 7,
    personnel_safety: 4,
    governance_framework: 8,
    total_score: 37,
    grade: "NM",
    environment_avg: 10,
    social_avg: 19,
    gove_avg: 8,
  },
  {
    entity_id: 4,
    wk_no: 39,
    date_of_calibration: 45652,
    zone: "South 1",
    area_office: "",
    "Assessor Name": "G Pavithran",
    city: "Chennai",
    branch_code: 30084,
    dealer_name: "Popular Scooters pvt ltd",
    environment: 8,
    water_management: 2,
    waste_management: 6,
    energy_management: 2,
    road_safety: 11,
    electrical_safety: 12,
    fire_safety: 10,
    "5s_and_house_keeping": 12,
    personnel_safety: 2,
    governance_framework: 10,
    total_score: 75,
    grade: "Gold",
    environment_avg: 18,
    social_avg: 47,
    gove_avg: 10,
  },
];

export const ao = [
  {
    entity_id: 1,
    wk_no: 33,
    date_of_calibration: 45608,
    zone: "North",
    assessors: "E Sivakumar",
    city: "Lucknow",
    branch_code: "000002",
    area_office: "Lucknow AO",
    environment: 16,
    water_management: 0,
    waste_water_management: 7.5,
    energy_management: 4,
    road_safety: 5,
    electrical_safety: 10,
    fire_safety: 8,
    "5s_and_house_keeping": 10,
    governance_framework: 10,
    total_score: 70.5,
    grade: "Silver",
    environment_avg: 27.5,
    social_avg: 33,
    gove_avg: 10,
  },
  {
    entity_id: 2,
    wk_no: 36,
    date_of_calibration: 45629,
    zone: "East",
    assessors: "Jagamohan Pattanayak",
    city: "Bhubaneswar",
    branch_code: "000008",
    area_office: "Bhubaneswar AO",
    environment: 12,
    water_management: 2,
    waste_water_management: 2.5,
    energy_management: 3,
    road_safety: 5,
    electrical_safety: 8,
    fire_safety: 4,
    "5s_and_house_keeping": 8,
    governance_framework: 10,
    total_score: 54.5,
    grade: "Bronze",
    environment_avg: 19.5,
    social_avg: 25,
    gove_avg: 10,
  },
  {
    entity_id: 3,
    wk_no: 37,
    date_of_calibration: 45639,
    zone: "South 2",
    assessors: "BS Prakash",
    city: "Bangalore",
    branch_code: "000013",
    area_office: "JP Nagar AO",
    environment: 16,
    water_management: 2,
    waste_water_management: 7.5,
    energy_management: 4,
    road_safety: 2.5,
    electrical_safety: 8,
    fire_safety: 6,
    "5s_and_house_keeping": 8,
    governance_framework: 8,
    total_score: 62,
    grade: "Silver",
    environment_avg: 29.5,
    social_avg: 24.5,
    gove_avg: 8,
  },
  {
    entity_id: 4,
    wk_no: 39,
    date_of_calibration: 45652,
    zone: "East",
    assessors: "Jagamohan Pattanayak",
    city: "Kolkata",
    branch_code: "000010",
    area_office: "AO Office - Kolkata",
    environment: 16,
    water_management: 0,
    waste_water_management: 5,
    energy_management: 0,
    road_safety: 2.5,
    electrical_safety: 4,
    fire_safety: 10,
    "5s_and_house_keeping": 8,
    governance_framework: 10,
    total_score: 55.5,
    grade: "Bronze",
    environment_avg: 21,
    social_avg: 24.5,
    gove_avg: 10,
  },
  {
    entity_id: 5,
    wk_no: 39,
    date_of_calibration: 45653,
    zone: "East",
    assessors: "Jagamohan Pattanayak",
    city: "Guwahati",
    branch_code: "000007",
    area_office: "AO Office - Guwahati",
    environment: 16,
    water_management: 0,
    waste_water_management: 5,
    energy_management: 1,
    road_safety: 2.5,
    electrical_safety: 6,
    fire_safety: 6,
    "5s_and_house_keeping": 6,
    governance_framework: 10,
    total_score: 52.5,
    grade: "Bronze",
    environment_avg: 22,
    social_avg: 20.5,
    gove_avg: 10,
  },
  {
    entity_id: 6,
    wk_no: 39,
    date_of_calibration: 45652,
    zone: "South 1",
    assessors: "G Pavithran",
    city: "Chennai",
    branch_code: "000009",
    area_office: "AO Office - Chennai",
    environment: 12,
    water_management: 4,
    waste_water_management: 7.5,
    energy_management: 1,
    road_safety: 2.5,
    electrical_safety: 12,
    fire_safety: 8,
    "5s_and_house_keeping": 6,
    governance_framework: 10,
    total_score: 63,
    grade: "Silver",
    environment_avg: 24.5,
    social_avg: 28.5,
    gove_avg: 10,
  },
];
