import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import CriticalNonCompliances from "../../adminscreen/SupplyChain/NonComplianceComponent";

const SubGraph5Demo = ({ supplyData, count = 0 }) => {
  const [chartData, setChartData] = useState([]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            fontSize: "14px",
            fontFamily: "Lato",
            lineHeight: "1.5",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
          {payload.map((entry) => (
            <p key={entry.name} style={{ margin: 0, color: "black" }}>{`${entry.name
              }:${entry.name === "Maximum" ? 5 : entry.value}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
          marginTop: "10px",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };
  useEffect(() => {
    if (supplyData.length > 0) {
      // Compute average scores for governance factors
      const totalSuppliers = 1

      const totalLegal = supplyData.find(x => x.id === '68a7836a-10fe-4632-af0d-44d37f59121a')?.sectionTotalScore || 0
      const totalGovernance  = supplyData.find(x => x.id === '95cb751e-0c5e-4527-b2c6-e82550e151d4')?.sectionTotalScore || 0

      const avgGovernance = (totalGovernance / totalSuppliers).toFixed(2);
      const avgLegal = (totalLegal / totalSuppliers).toFixed(2);

      setChartData([
        {
          category: "Supplier Governance Framework",
          maxScore: 5 - avgGovernance,
          avgScore: avgGovernance,
        },
        {
          category: "Legal Compliances",
          maxScore: 5 - avgLegal,
          avgScore: avgLegal,
        },
      ]);
    }
  }, [supplyData]);

  const wrapText = (text, width = 40) => {
    let words = text.split(" ");
    let lines = [];
    let currentLine = "";

    words.forEach((word) => {
      if ((currentLine + " " + word).length > width) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += (currentLine ? " " : "") + word;
      }
    });

    lines.push(currentLine); // Push the remaining line
    return lines.map((line, index) => (
      <tspan key={index} x="0" dy={index === 0 ? 0 : 10}>
        {line}
      </tspan>
    ));
  };

  const CustomizedTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          textAnchor="middle"
          fontSize={10}
          fill="#666"
          dominantBaseline="middle"
        >
          {wrapText(payload.value, 20)} {/* Wrap text with custom width */}
        </text>
      </g>
    );
  };

  return (
    <div className="container mt-4 pt-2 " style={{background:'#EBF1FC'}}>
      <h5 className="mb-3 text-center text-dark">
        Governance Section Performance
      </h5>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          barSize={50}
          data={chartData}
          margin={{ top: 20, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="category"
            fontSize={12}
            tick={<CustomizedTick />} // Custom tick rendering
            interval={0} // Show all labels
          />
          <YAxis domain={[0, 5]} />
          <Tooltip content={CustomTooltip} />
          <Legend content={CustomLegend} />

          {/* Stacked bars */}
          <Bar dataKey="avgScore" stackId="a" fill="#4A90E2" name="Achieved">
            <LabelList
              dataKey="avgScore"
              position="insideBottom"
              style={{ fontSize: "12px", fill: "white" }}
            />
          </Bar>
          <Bar
            dataKey="maxScore"
            stackId="a"
            fill="#AFCBFF"
            name="Maximum"
          ></Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="col-12 flex justify-content-center">
        <CriticalNonCompliances count={count} />

      </div>
    </div>
  );
};

export default SubGraph5Demo;
