import React, { useState, useEffect, useRef } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import 'primeflex/primeflex.css';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { MultiSelect } from "primereact/multiselect";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import * as XLSX from 'xlsx';
import XlsxPopulate from "xlsx-populate";
import { saveAs } from 'file-saver';
import { checkRoleAccessByRoleIds, getFiscalYearsFromStartDate, getLocationData, getOverdueDaysByFrequency } from "../../../../components/BGHF/helper";
import { tvssection } from "../../../../assets/tvs/js/tvssection";
const TVSOverallQuantitativeSubmissionStatus = () => {
    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const [assFramework, setAssFramework] = useState([])
    const [frameworkTags, setAssFrameworkTags] = useState([])
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const tvsSubAdmin = useSelector((state) => state.user.tvsSubAdmin);
    const tvsSubAdminRoles = useSelector((state) => state.user.tvsSubAdminRoles)
    const [yearoptions, setYearOptions] = useState([])
    const [rawsitelist, setRawSiteList] = useState([])
    const [data, setData] = useState([])
    const [databk, setDataBk] = useState([])
    const [activetab, setActiveTab] = useState(6)
    const dt = useRef(null)
    const userList = useSelector(state => state.userlist.userList)
    const [loading, setLoading] = useState(true)
    const [refData, setRefData] = useState([])
    const [submission, setSubmission] = useState([])
    const [assignmentlist, setAssignmentList] = useState([])
    const [sectionlist, setSectionList] = useState([{ title: 'Sustainability', id: 8 }, { title: 'Health', id: 9 }, { title: 'Safety Central', id: 10 }, { title: 'Safety Operational', id: 11 }, { title: 'Supply Chain', id: 12 }, { title: 'Dealership Sustainability', id: 13 }, { title: 'Environmental', id: 14 }, { title: 'Social', id: 15 }, { title: 'Governance', id: 16 }])
    const [assignedsection, setAssignedSection] = useState([])

    const [dcflist, setDcfList] = useState([])
    const [entityList, setEntityList] = useState([])



    const [filter, setFilter] = useState({ year: null, searchstr: '', dcf: 0, entity: 'All', framework: [], section: [] })
    useEffect(() => {
        let selectedYear = null
        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate, fymonth)
        if (yrOptions.length !== 0) {
            selectedYear = yrOptions[yrOptions.length - 1].name
            setFilter((prev) => ({ ...prev, year: selectedYear, section: !tvsSubAdmin ? sectionlist.map(i => i.id) : tvsSubAdminRoles.filter(i => sectionlist.map(x => x.id).includes(i)) }))
        }
        console.log(yrOptions)
        setYearOptions(yrOptions)
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        let uriString2 = {
            include: [
                {
                    relation: "newTopics",
                    scope: {
                        include: [
                            {
                                relation: "newMetrics",
                                scope: { include: [{ relation: "newDataPoints" }] },
                            },
                        ],
                    },
                },
            ],
        };
        let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;

        const promise0 = APIServices.get(Overall)
        const promise1 = APIServices.get(API.DCF_Title_Only)
        const promise2 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        const promise3 = APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const promise4 = APIServices.get(
            API.DCF_Entity_UP(admin_data.id)
        );
        const promise5 = APIServices.get(
            API.DCF_Entity_User_UP(admin_data.id)
        );
        const promise6 = APIServices.get(
            API.QN_Submit_UP(admin_data.id)
        );
        const promise7 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise8 = APIServices.get(API.Report_Name_Twos)
        Promise.all([
            promise0,
            promise1,
            promise2,
            promise3,
            promise4,
            promise5,
            promise6, promise7, promise8]).then((values) => {
                let allframework = values[8].data.filter((i) => { return admin_data.information.report.includes(i.id) })
                setAssFramework(allframework)
                let curated_dcf_ids = [], dcf_list = values[1].data.map(x=>({...x,title:x.id +' : '+ x.title})), quantitative_entity_list = values[4].data, quantitative_ass_list = values[5].data.filter(item =>
                    values[4].data.some(({ tier1_ids, tier2_ids, tier3_ids, tier0_ids, dcfId }) =>
                        dcfId === item.dcfId && ((item.tier1_id !== null && tier1_ids.includes(item.tier1_id)) ||
                            (item.tier2_id !== null && tier2_ids.includes(item.tier2_id)) ||
                            (item.tier3_id !== null && tier3_ids.includes(item.tier3_id)) ||
                            (item.tier0_id !== null && tier0_ids.includes(item.tier0_id)))
                    )
                );
                let quantitative_submitted = values[6].data
                setAssignedSection(!tvsSubAdmin ? sectionlist : tvsSubAdminRoles.filter(i => sectionlist.map(x => x.id).includes(i)).map(i => sectionlist.find(x => x.id === i)))
                let frameworkTags = []

                const curatedIndicators = Array.from(new Set(Object.entries(tvssection).flatMap(i => (tvsSubAdminRoles.includes(parseFloat(i[0])) ? i[1] : []))))
                console.log(curatedIndicators)

                if (values[2].data && values[2].data.length > 0) {
                    values[0].data.forEach((cat) => {
                        if (cat.newTopics !== undefined) {

                            cat.newTopics.forEach((topic) => {

                                if (topic.newMetrics !== undefined && values[2].data[0].topic_ids.includes(topic.id) && (topic.tag === null || parseFloat(topic.tag) === admin_data.id)) {

                                    topic.newMetrics.forEach((metric) => {
                                        if ((curatedIndicators.includes(metric.id) || !tvsSubAdmin) && Array.isArray(metric.data1) && values[2].data[0].metric_ids.includes(metric.id) && metric.data1[0].type !== undefined && metric.data1[0].type === 0 && (metric.tag === null || metric.tag === admin_data.id)) {

                                            if (metric.newDataPoints !== undefined) {
                                                metric.newDataPoints.forEach((dp) => {
                                                    if (Array.isArray(dp.data1) && dp.data1.length !== 0 && dp.data1[0].datasource !== null && typeof dp.data1[0].datasource === 'number') {


                                                        let dcf_index = dcf_list.findIndex(i => i.id === dp.data1[0].datasource)
                                                        if (!curated_dcf_ids.includes(dp.data1[0].datasource) && dcf_index !== -1) {
                                                            console.log('as')
                                                            if (dcf_list[dcf_index].tags === null || !dcf_list[dcf_index].tags.length || dcf_list[dcf_index].tags.includes(admin_data.id)) {
                                                                curated_dcf_ids.push(dp.data1[0].datasource)
                                                                dcf_list[dcf_index]['section'] = findKeyById(tvssection, metric.id)
                                                                frameworkTags.push({ overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], dcfId: dp.data1[0].datasource })

                                                            }

                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                            })
                        }


                    })
                }
                console.log(curated_dcf_ids)
                let shapedSite = []
                let adminShapedSite = values[3].data
                    .map((item) => {
                        if (item.locationTwos) {
                            item.locationTwos = item.locationTwos.filter(
                                (locationTwo) =>
                                    locationTwo.locationThrees &&
                                    locationTwo.locationThrees.length > 0
                            );
                        }
                        return item;
                    })
                    .filter((item) => item.locationTwos && item.locationTwos.length > 0);
                if (login_data.role === 'clientadmin') {
                    shapedSite = values[3].data
                        .map((item) => {
                            if (item.locationTwos) {
                                item.locationTwos = item.locationTwos.filter(
                                    (locationTwo) =>
                                        locationTwo.locationThrees &&
                                        locationTwo.locationThrees.length > 0
                                );
                            }
                            return item;
                        })
                        .filter((item) => item.locationTwos && item.locationTwos.length > 0);
                } else {
                    shapedSite = getLocationData(values[3].data, values[7].data.filter(i => i.user_id === login_data.id), !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles).map((item) => {
                        if (item.locationTwos) {
                            item.locationTwos = item.locationTwos.filter(
                                (locationTwo) =>
                                    locationTwo.locationThrees &&
                                    locationTwo.locationThrees.length > 0
                            );
                        }
                        return item;
                    })
                        .filter((item) => item.locationTwos && item.locationTwos.length > 0);


                }
                setSubmission(quantitative_submitted.filter(i => (login_data.role === 'clientadmin' || checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, i.level, i[`tier${i.level}_id`], values[7].data.filter(i => i.user_id === login_data.id && i.userProfileId === admin_data.id), adminShapedSite).includes(login_data.id))))
                let filtered_qn_ass = quantitative_ass_list.filter(
                    (i) =>
                        dcf_list.map((j) => j.id).includes(i.dcfId) && curated_dcf_ids.includes(i.dcfId) &&
                        (login_data.role === 'clientadmin' || checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, i.level, i[`tier${i.level}_id`], values[7].data.filter(i => i.user_id === login_data.id && i.userProfileId === admin_data.id), adminShapedSite).includes(login_data.id))
                );
                setDcfList(dcf_list.filter(i => curated_dcf_ids.includes(i.id)))
                setAssignmentList(filtered_qn_ass)
                setRawSiteList(shapedSite)
                setAssFrameworkTags(frameworkTags)
                if (selectedYear && admin_data) {
                    let loc = filterSubmissionStatus(shapedSite, quantitative_submitted, filtered_qn_ass, selectedYear, dcf_list)
                    let entityOption = [{ name: 'All' }]
                    const mappedData = loc.map(item => {
                        if (!entityOption.find(i => i.name === item.coverage)) {
                            entityOption.push({ name: item.coverage })
                        }
                        return {
                            ...item, tags: frameworkTags.find(x => x.dcfId === item.dcfId)?.overallTags,
                            reporters: item.reporter_ids.map(id => getUser(id)).filter(Boolean),
                            reviewers: item.reviewer_ids.map(id => getUser(id)).filter(Boolean)
                        };
                    });
                    setEntityList(entityOption)
                    setFilter((prev) => ({ ...prev, framework: allframework.map(i => i.title) }))
                    let filtered1 = mappedData.filter(x => allframework.map(x => x.title)?.length ? x.tags.some(tagsArray => {
                        return tagsArray.some(tag => {
                            return allframework.map(x => x.title).some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                        })
                    }) : false)

                    setData(filtered1.filter(i => i.status === activetab))
                    setDataBk(filtered1)
                    setLoading(false)
                }
                console.log(filtered_qn_ass)
            })


    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])
    const getUser = (id) => {
        if (id === admin_data.id) {
            return "Enterprise Admin";
        }
        console.log(userLookup[id])
        return userLookup[id] ? userLookup[id].information.empname : ''
    };
    const userLookup = userList.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
    }, {});
    function findKeyById(tvssection, id) {
        for (const key in tvssection) {
            if (tvssection[key].includes(id)) {
                return parseFloat(key);
            }
        }
        return 0;
    }
    function getOverdueDays(monthString) {
        console.log(monthString)
        const [startMonth, endMonth] = monthString.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days;
    }
    const filterSubmissionStatus = (shapedsite, quantitative_submitted, filtered_qn_ass, selectedYear, dcflist) => {
        let submissionStatus = []
        let filteredAssignment = filterAssignmentsByFiscalYear(filtered_qn_ass, selectedYear)
        console.log(filteredAssignment)
        if (filteredAssignment.length) {
            for (const item of filteredAssignment) {
                let months = getMonthsBetween(item.start_date, item.end_date, item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency, selectedYear, fymonth)
                console.log(months)
                for (const mn of months) {
                    let index = quantitative_submitted.findIndex(i => (mn === getRPTextFormat(i.reporting_period) && i.entityAssId === item.entityAssId && i.entityUserAssId === item.id && i.dcfId === item.dcfId && i.tier0_id === item.tier0_id && i.tier1_id === item.tier1_id && i.tier2_id === item.tier2_id && i.tier3_id === item.tier3_id))
                    if (index !== -1) {
                        let type = quantitative_submitted[index].type
                        let reject = quantitative_submitted[index].reject
                        let status = (type === 0 && (reject === 0 || reject === null)) ? 0 :
                            (type === 0 && (reject === 1 || reject === 2))
                                ? 1
                                : type === 1 && reject === 1
                                    ? 2
                                    : type === 1
                                        ? 3
                                        : type === 2
                                            ? 4
                                            : type === 3
                                                ? 5
                                                : null
                        if (status === 0 || status === 1) {

                            status = getOverdueDaysByFrequency(mn, item.frequency) ? getOverdueDaysByFrequency(mn, item.frequency) : 99


                        }


                        submissionStatus.push({ ...item, section: dcflist.find(i => i.id === item.dcfId).section, dueMonth: getDueMonth(mn), reporting_period: mn, data: quantitative_submitted[index], status, coverage: getCoverageText(item, shapedsite) })
                    } else {
                        let status = getOverdueDaysByFrequency(mn, item.frequency) ? getOverdueDaysByFrequency(mn, item.frequency) : 100

                        submissionStatus.push({ ...item, section: dcflist.find(i => i.id === item.dcfId).section, dueMonth: getDueMonth(mn), reporting_period: mn, status, coverage: getCoverageText(item, shapedsite) })
                    }
                }

            }
        }
        return submissionStatus
    }
    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                    "LLL-yyyy"
                );
                const endDate = DateTime.fromFormat(
                    item[item.length - 1],
                    "MM-yyyy"
                ).toFormat("LLL-yyyy");
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
            }
        }
    };

    const filterAssignmentsByFiscalYear = (assignments, year) => {
        const { startDate, endDate } = getFiscalYearRange(year, fymonth);
        const currentDate = DateTime.local().startOf('day');

        return assignments.filter(assignment => {
            console.log(assignment)
            const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).startOf('day') : currentDate;
            const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('day') : currentDate;

            return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
                (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
                (assignmentStartDate <= startDate && assignmentEndDate >= endDate);
        });
    };
    const getFiscalYearRange = (year, fymonth) => {
        let startDate, endDate;
        console.log(fymonth)

        if (fymonth === 1) {
            startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
        } else {
            startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
        }

        return { startDate, endDate };
    };
    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];
            console.log(entity);
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            }
        } else {
            return false;
        }
    };
    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";
        console.log(rowData);
        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    function getMonthsBetween(start_date, end_date, frequency, filterYear, fymonth) {
        let fyStartDate, fyEndDate;

        if (fymonth === 1) {
            // Calendar year: Jan to Dec of the filterYear
            fyStartDate = DateTime.fromObject({ year: filterYear, month: 1, day: 1 });
            fyEndDate = DateTime.fromObject({ year: filterYear, month: 12, day: 31 });
        } else {
            // Fiscal year: Apr (fymonth) of the previous year to Mar (fymonth - 1) of the filterYear
            fyStartDate = DateTime.fromObject({ year: filterYear - 1, month: fymonth, day: 1 });
            fyEndDate = DateTime.fromObject({ year: filterYear, month: fymonth - 1, day: 1 }).endOf('month');
        }

        // Override with provided start_date and end_date if available
        const userStartDate = start_date ? DateTime.fromISO(start_date, { zone: 'utc' }).startOf('month') : fyStartDate;
        const userEndDate = end_date ? DateTime.fromISO(end_date, { zone: 'utc' }).endOf('month') : fyEndDate;

        // Adjust the final start and end dates to be within the fiscal year range
        const finalStartDate = userStartDate < fyStartDate ? fyStartDate : userStartDate;
        const finalEndDate = userEndDate > fyEndDate ? fyEndDate : userEndDate;

        const months = [];
        let currentMonth = finalStartDate;

        while (currentMonth <= finalEndDate) {
            if (frequency === 1) {
                months.push(currentMonth.toFormat("LLL-yyyy"));
            } else {
                const periodEnd = currentMonth.plus({ months: frequency - 1 });
                if (periodEnd <= finalEndDate) {
                    months.push(
                        currentMonth.toFormat("LLL-yyyy") +
                        " to " +
                        periodEnd.toFormat("LLL-yyyy")
                    );
                }
            }
            currentMonth = currentMonth.plus({ months: frequency });
        }

        return months;
    }
    const updateYearFilter = (val, obj) => {
        let loc = { ...filter, [obj]: val }


        if (obj === 'year') {
            let data = filterSubmissionStatus(rawsitelist, submission, assignmentlist, val, dcflist)
            let entityOption = [{ name: 'All' }]
            const mappedData = data.map(item => {
                if (!entityOption.find(i => i.name === item.coverage)) {
                    entityOption.push({ name: item.coverage })
                }
                return {
                    ...item, tags: frameworkTags.find(x => x.dcfId === item.dcfId)?.overallTags,
                    reporters: item.reporter_ids.map(id => getUser(id)).filter(Boolean),
                    reviewers: item.reviewer_ids.map(id => getUser(id)).filter(Boolean)
                };
            });
            setEntityList(entityOption)
            let filtered1 = mappedData.filter(x => assFramework.map(x => x.title)?.length ? x.tags.some(tagsArray => {
                return tagsArray.some(tag => {
                    return assFramework.map(x => x.title).some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                })
            }) : false)
            setData(filtered1.filter(i => i.status === activetab))
            setDataBk(filtered1)
            setFilter((prev) => ({ ...prev, entity: 'All', dcf: 0, framework: assFramework.map(i => i.title), section: assignedsection.map(i => i.id), searchstr: '', [obj]: val }))
        } else if (obj === 'entity') {

            const arr = JSON.parse(JSON.stringify(databk))
            let str = loc.searchstr.trim().toLowerCase()
            let filtered1 = arr.filter(x => loc.framework?.length ? x.tags.some(tagsArray => {
                return tagsArray.some(tag => {
                    return loc.framework.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                })
            }) : false)
            let filtered = filtered1.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
                item.reviewers.some(i => i.toLowerCase().trim().includes(str))
            );
            setData(filtered.filter(i => (i.coverage === val || val === 'All') && (loc.section.includes(i.section) || i.section === 0) && (i.status === activetab) && (i.dcfId === loc.dcf || loc.dcf === 0)))
            setFilter((prev) => ({ ...prev, [obj]: val }))
        } else if (obj === 'dcf') {
            const arr = JSON.parse(JSON.stringify(databk))
            let str = loc.searchstr.trim().toLowerCase()
            let filtered1 = arr.filter(x => loc.framework?.length ? x.tags.some(tagsArray => {
                return tagsArray.some(tag => {
                    return loc.framework.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                })
            }) : false)
            let filtered = filtered1.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
                item.reviewers.some(i => i.toLowerCase().trim().includes(str))

            );
            setData(filtered.filter(i => (i.coverage === loc.entity || loc.entity === 'All') && (loc.section.includes(i.section) || i.section === 0) && (i.status === activetab) && (i.dcfId === val || val === 0)))
            setFilter((prev) => ({ ...prev, [obj]: val }))
        } else if (obj === 'section') {
            const arr = JSON.parse(JSON.stringify(databk))
            let str = loc.searchstr.trim().toLowerCase()
            let filtered1 = arr.filter(x => loc.framework?.length ? x.tags.some(tagsArray => {
                return tagsArray.some(tag => {
                    return loc.framework.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                })
            }) : false)
            let filtered = filtered1.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
                item.reviewers.some(i => i.toLowerCase().trim().includes(str))

            );
            setData(filtered.filter(i => (i.coverage === loc.entity || loc.entity === 'All') && (val.includes(i.section)) && (i.status === activetab) && (i.dcfId === loc.dcf || loc.dcf === 0)))
            setFilter((prev) => ({ ...prev, [obj]: val }))
        } else if (obj === 'framework') {
            const arr = JSON.parse(JSON.stringify(databk))
            let str = loc.searchstr.trim().toLowerCase()
            let filtered1 = arr.filter(x => val.length ? x.tags.some(tagsArray => {
                return tagsArray.some(tag => {
                    return val.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                })
            }) : false)
            let filtered = filtered1.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
                item.reviewers.some(i => i.toLowerCase().trim().includes(str))

            );


            setData(filtered.filter(i => (i.coverage === loc.entity || loc.entity === 'All') && (loc.section.includes(i.section) || i.section === 0) && (i.status === activetab) && (i.dcfId === loc.dcf || loc.dcf === 0)))
            setFilter((prev) => ({ ...prev, [obj]: val }))

        } else {
            const arr = JSON.parse(JSON.stringify(databk))
            let str = val.trim().toLowerCase()
            let filtered1 = arr.filter(x => loc.framework?.length ? x.tags.some(tagsArray => {
                return tagsArray.some(tag => {
                    return loc.framework.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                })
            }) : false)
            let filtered = filtered1.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
                item.reviewers.some(i => i.toLowerCase().trim().includes(str))

            );
            setData(filtered.filter(i => (i.coverage === loc.entity || loc.entity === 'All') && (loc.section.includes(i.section) || i.section === 0) && (i.status === activetab) && (i.dcfId === loc.dcf || loc.dcf === 0)))
            setFilter((prev) => ({ ...prev, [obj]: val }))
        }




    }
    const dcfTemplate = (rowData) => {
        console.log(rowData)
        let text = 'Not Found'
        let index = dcflist.find(i => i.id === rowData.dcfId)
        if (index) {
            text = index.title
        }
        return <>{text}</>
    }
    const sectionTemplate = (rowData) => {
        let txt = ''
        let sec = sectionlist.find(i => i.id === rowData.section)
        if (sec) {
            txt = sec.title
        }
        return <>{txt}</>
    }
    const reporterTemplate = (rowData) => {
        let text = []
        if (rowData.reporter_ids) {
            rowData.reporter_ids.forEach((i) => {
                let userData = userList.find(x => x.id === i)
                if (userData) {
                    if (i === admin_data.id) {
                        text.push('Enterprise Admin')
                    } else {
                        text.push(userData.information.empname)
                    }
                } else if (i === admin_data.id) {
                    text.push('Enterprise Admin')
                }
            })
        }
        return <div><Tooltip target={'.reporter'} position="top" />  <div className="text-three-dot reporter" data-pr-tooltip={text.join(",")} style={{ maxWidth: 150 }}>{text.join(',')}</div></div>

    }
    const reviewerTemplate = (rowData) => {
        let text = []
        if (rowData.reviewer_ids) {
            rowData.reviewer_ids.forEach((i) => {
                let userData = userList.find(x => x.id === i)
                if (userData) {
                    if (i === admin_data.id) {
                        text.push('Enterprise Admin')
                    } else {
                        text.push(userData.information.empname)
                    }
                } else if (i === admin_data.id) {
                    text.push('Enterprise Admin')
                }
            })
        } else {
            text.push('Self')
        }
        return <div><Tooltip target={'.reviewer'} position="top" />  <div className="text-three-dot reviewer" data-pr-tooltip={text.join(",")} style={{ maxWidth: 150 }}>{text.join(',')}</div></div>
    }
    const apporverTemplate = (rowData) => {
        let text = []
        if (rowData.approver_ids) {
            rowData.approver_ids.forEach((i) => {
                let userData = userList.find(x => x.id === i)
                if (userData) {
                    if (i === admin_data.id) {
                        text.push('Enterprise Admin')
                    } else {
                        text.push(userData.information.empname)
                    }
                } else if (i === admin_data.id) {
                    text.push('Enterprise Admin')
                }
            })
        }
        return <div><Tooltip target={'.approver'} position="top" />  <div className="text-three-dot approver" data-pr-tooltip={text.join(",")} style={{ maxWidth: 150 }}>{text.join(',')}</div></div>

    }
    const dcfRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let IdOptions = allentity.map(i => i.dcfId)
        let dcfOptions = dcflist.filter(i => IdOptions.includes(i.id)).map(i => { return { title: i.title, id: i.id } })

        console.log(allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={dcfOptions}
                optionValue="id"
                optionLabel="title"
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const rpRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let allOptions = allentity.map(i => i.reporting_period)
        let entOptions = []
        allOptions.forEach((i) => {
            if (!entOptions.includes(i)) {
                entOptions.push(i)
            }
        })


        console.log(allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                value={options.value}
                options={entOptions}
                filter
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const entityRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let allOptions = allentity.map(i => i.coverage)
        let entOptions = []
        allOptions.forEach((i) => {
            if (!entOptions.includes(i)) {
                entOptions.push(i)
            }
        })


        console.log(allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                value={options.value}
                options={entOptions}
                filter
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const sortRP = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {

                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    const getCountByStatus = (code) => {
        // let filtered = dt.current.getVirtualScroller().props.items.map(i => i.id)

        const arr = JSON.parse(JSON.stringify(databk))
        let str = filter.searchstr.trim().toLowerCase()
        let filtered1 = arr.filter(x => filter.framework.length ? x.tags.some(tagsArray => {
            return tagsArray.some(tag => {
                return filter.framework.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
            })
        }) : false)
        console.log(filtered1)
        let filtered = filtered1.filter(item =>
            item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
            item.reviewers.some(i => i.toLowerCase().trim().includes(str))

        );

        return filtered.filter(i => (i.coverage === filter.entity || filter.entity === 'All') && (i.status === code) && (filter.section.includes(i.section) || i.section === 0) && (i.dcfId === filter.dcf || filter.dcf === 0)).length


    }
    const getPercentage = () => {
        let value = 0
        const allStatus = [6, 7, 1, 3, 4]
        // let filtered = dt.current.getVirtualScroller().props.items.map(i => i.id)
        let loc = filter
        const arr = JSON.parse(JSON.stringify(databk)).filter(i => allStatus.includes(i.status))
        let str = loc.searchstr.trim().toLowerCase()

        let filtered1 = arr.filter(x => filter.framework.length ? x.tags.some(tagsArray => {
            return tagsArray.some(tag => {
                return filter.framework.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
            })
        }) : false)
        let filtered = filtered1.filter(item =>
            item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
            item.reviewers.some(i => i.toLowerCase().trim().includes(str))

        );
        let loc_ = filtered.filter(i => (i.coverage === loc.entity || loc.entity === 'All') && (loc.section.includes(i.section) || i.section === 0) && (i.dcfId === loc.dcf || loc.dcf === 0))

        let approved = loc_.filter(i => i.status === 4).length
        let total = loc_.length
        console.log(total, approved)
        if (approved && total) {
            value = ((approved / total) * 100).toFixed(2)
        }
        if (!loc_.length) {
            value = 100
        }


        return value
    }
    const getPercentageColor = () => {
        let value = 0
        const allStatus = [6, 7, 1, 3, 4]
        // let filtered = dt.current.getVirtualScroller().props.items.map(i => i.id)
        let loc = filter
        const arr = JSON.parse(JSON.stringify(databk)).filter(i => allStatus.includes(i.status))
        let str = loc.searchstr.trim().toLowerCase()

        let filtered1 = arr.filter(x => filter.framework.length ? x.tags.some(tagsArray => {
            return tagsArray.some(tag => {
                return filter.framework.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
            })
        }) : false)
        let filtered = filtered1.filter(item =>
            item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
            item.reviewers.some(i => i.toLowerCase().trim().includes(str))

        );
        let loc_ = filtered.filter(i => (i.coverage === loc.entity || loc.entity === 'All') && (loc.section.includes(i.section) || i.section === 0) && (i.dcfId === loc.dcf || loc.dcf === 0))

        let approved = loc_.filter(i => i.status === 4).length
        let total = loc_.length
        console.log(total, approved)
        if (approved && total) {
            value = ((approved / total) * 100).toFixed(2)
        }
        if (!loc_.length) {
            value = 100
        }


        return value <= 50 ? 'red' : value <= 99 ? 'yellow' : 'green'
    }
    const activeTab = (status) => {

        if (status === activetab) {

        } else {


            const arr = JSON.parse(JSON.stringify(databk))
            let str = filter.searchstr.trim().toLowerCase()
            let filtered = arr.filter(item =>
                item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
                item.reviewers.some(i => i.toLowerCase().trim().includes(str))

            );
            setData(filtered.filter(i => (i.coverage === filter.entity || filter.entity === 'All') && ((filter.section.includes(i.section) || i.section === 0)) && (i.status === status) && (i.dcfId === filter.dcf || filter.dcf === 0)))


            setActiveTab(status)
        }


    }
    const handleSearch = (e) => {
        let str = e.target.value.trim().toLowerCase()
        const arr = JSON.parse(JSON.stringify(databk))
        let filtered = arr.filter(item =>
            item.reporters.some(i => i.toLowerCase().trim().includes(str)) ||
            item.reviewers.some(i => i.toLowerCase().trim().includes(str))

        );
        setData(filtered)
        setFilter((prev) => ({ ...prev, searchstr: e.target.value }))

    }
    const generateDataRows = (data, year) => {
        const rows = [];
        const uniqueCombos = {};

        // Sort the data based on reporting_period
        data.sort((a, b) => DateTime.fromFormat(a.reporting_period.split(' ')[0], 'MMM-yyyy') - DateTime.fromFormat(b.reporting_period.split(' ')[0], 'MMM-yyyy'));

        data.forEach(item => {
            const comboKey = `${item.dcf}_${item.coverage}`;

            if (!uniqueCombos[comboKey]) {
                uniqueCombos[comboKey] = new Array(14).fill('');
                uniqueCombos[comboKey][0] = item.dcf;
                uniqueCombos[comboKey][1] = item.coverage;
                rows.push(uniqueCombos[comboKey]);
            }

            const periods = item.reporting_period.split(' to ');
            const startMonth = DateTime.fromFormat(periods[0], 'MMM-yyyy').month - 1;
            const statusIndex = startMonth + 2; // Adjust for DCF and Entity columns

            if (item.frequency === 1) {
                uniqueCombos[comboKey][statusIndex] = item.status_;
            } else {
                for (let i = 0; i < item.frequency; i++) {
                    uniqueCombos[comboKey][statusIndex + i] = item.status_;
                }
            }
        });

        return rows;
    };

    const mergeCells = (worksheet, dataRows, excelData, year) => {
        const mergeRanges = [];

        excelData.forEach((item, dataIndex) => {
            const rowIndex = dataRows.findIndex(row => row[0] === item.dcf && row[1] === item.coverage);
            if (rowIndex === -1) return; // If the row is not found, skip

            const periods = item.reporting_period.split(' to ');
            const startMonth = DateTime.fromFormat(periods[0], 'MMM-yyyy').month - 1;
            const statusIndex = startMonth + 2; // Adjust for DCF and Entity columns

            if (item.frequency > 1) {
                const startCell = XLSX.utils.encode_cell({ r: rowIndex + 1, c: statusIndex });
                const endCell = XLSX.utils.encode_cell({ r: rowIndex + 1, c: statusIndex + item.frequency - 1 });
                mergeRanges.push({ s: XLSX.utils.decode_cell(startCell), e: XLSX.utils.decode_cell(endCell) });
            }
        });

        worksheet['!merges'] = mergeRanges;
    };

    const adjustColumnWidths = (worksheet) => {
        const colWidths = [];

        // Calculate maximum width for each column
        for (const [key, value] of Object.entries(worksheet)) {
            if (key[0] === '!') continue;

            const col = XLSX.utils.decode_cell(key).c;

            const cellValue = value.v != null ? value.v.toString() : '';
            const cellWidth = cellValue.length;

            if (!colWidths[col] || colWidths[col] < cellWidth) {
                colWidths[col] = cellWidth;
            }
        }

        // Apply calculated widths to worksheet columns
        worksheet['!cols'] = colWidths.map(width => ({ width: width + 2 })); // Add some padding
    };
    const adjustMonthsArray = (months, fymonth) => {
        // Adjust fymonth to 0-based index
        const startIndex = fymonth - 1;
        // Slice the array from the start index to the end and concatenate with the beginning of the array to the start index
        const adjustedMonths = [...months.slice(startIndex), ...months.slice(0, startIndex)];
        return adjustedMonths;
    };
    const downloadStatics = () => {
        if (data.length) {
            const excelData = [];

            for (const item of data) {
                let d = dcflist.find(i => i.id === item.dcfId);
                if (d) {
                    item.dcf = d.title;
                } else {
                    item.dcf = 'Not Found';
                }

                item.status_ = item.status === 6 ? 'Submissions Due' :
                    item.status === 7 ? 'Submissions Overdue' :
                        item.status === 1 ? 'Resubmission Required' :
                            item.status === 3 ? 'Under Review' :
                                item.status === 4 ? 'Under Approval' :
                                    item.status === 5 ? 'Approved' : item.status === 99 ? 'Drafted Upcoming Submission' : item.status === 100 ? 'Upcoming Submission' : 'NA';

                excelData.push({
                    'dcf': item.dcf,
                    'frequency': item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency,
                    'coverage': item.coverage,
                    'reporting_period': item.reporting_period,
                    'status_': item.status_,
                    'reporters': item.reporters.join(','),
                    'reviewers': item.reviewers.join(','),
                    'approvers': item.approvers.join(',')
                });
            }

            // Create worksheet
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const headers = ["DCF", "Entity (Coverage)", ...months.map(month => `${month}-${filter.year}`)];
            const dataRows = generateDataRows(excelData, filter.year);
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...dataRows]);

            mergeCells(worksheet, dataRows, excelData, filter.year);

            adjustColumnWidths(worksheet);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Export the workbook
            XLSX.writeFile(workbook, `${filter.year}_DCF_Submission_Statistics.xlsx`);
        }
    };

    const getDueMonth = (dateStr) => {
        const [from, to] = dateStr.split(' to ')
        if (to) {
            return to
        }
        return from
    }

    const downloadReport = () => {
        let filtered = dt.current.getVirtualScroller().props.items

        if (filtered.length) {
            const excelData = []
            const headers = [
                { header: 'DCF', key: 'dcf' },
                { header: 'Reporting Entity', key: 'coverage' },
                { header: 'Reporting Period', key: 'reporting_period' },
                { header: 'Status', key: 'status_' },
                { header: 'Reporter(s)', key: 'reporters' },
                { header: 'Reviewer(s)', key: 'reviewers' }
            ];
            for (const item of filtered) {
                let d = dcflist.find(i => i.id === item.dcfId)
                if (d) {
                    item.dcf = d.title
                } else {
                    item.dcf = 'Not Found'
                }

                item.status_ = item.status === 6 ? 'Submissions Due' :
                    item.status === 7 ? 'Submissions Overdue' :
                        item.status === 1 ? 'Resubmission Required' :
                            item.status === 3 ? 'Under Review' :
                                item.status === 4 ? 'Under Approval' :
                                    item.status === 5 ? 'Approved' : item.status === 99 ? 'Drafted Upcoming Submission' : item.status === 100 ? 'Upcoming Submission' : 'NA';

                excelData.push({ 'dcf': item.dcf, 'coverage': item.coverage, 'reporting_period': item.reporting_period, 'status_': item.status_, 'reporters': item.reporters.join(','), 'reviewers': item.reviewers.join(',') })
            }
            // Create worksheet

            const worksheet = XLSX.utils.json_to_sheet(excelData, { header: headers.map(h => h.key) });

            // Set headers
            headers.forEach((header, index) => {
                const cell = XLSX.utils.encode_cell({ r: 0, c: index });
                worksheet[cell].v = header.header;
            });
            adjustColumnWidths(worksheet);

            // Create a new workbook
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, filter.year.toString());

            // Export the workbook
            XLSX.writeFile(workbook, filter.year.toString() + '_DCF_Submission_Status.xlsx');

        }
    }
    const handleNan = (value) => {
        if (isNaN(value) || !value || !isFinite(value)) {
            return 0
        } else {
            return (value * 100).toFixed(2)
        }
    }
    function generateMonthArray(year, fymonth) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let result = [];

        // Fiscal year starting from fymonth
        if (fymonth === 1) {
            // Calendar year starting from January
            for (let i = 0; i < 12; i++) {
                result.push(`${months[i]}-${year}`);
            }
        } else {
            // Fiscal year starting from the given month (not January)
            // Generate 12 months, adjusting the year if necessary
            for (let i = 0; i < 12; i++) {
                let monthIndex = (fymonth - 1 + i) % 12;
                let currentYear = year - 1;

                // Determine if we need to roll over to the next year
                if (monthIndex < fymonth - 1) {
                    currentYear++;
                }

                result.push(`${months[monthIndex]}-${currentYear}`);
            }
        }

        return result;
    }

    const downloadSummary = () => {

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const fyMonthHeader = generateMonthArray(filter.year, fymonth)
        const headers = [
            { header: 'Status', key: 'title' },
            ...fyMonthHeader.map(i => ({ header: i, key: i }))
        ]
        console.log(fyMonthHeader)
        const staticData = [
            ["DCF Submission Status :  " + filter.year],
            ["Entity : " + (filter.entity === 'All' ? 'Enterprisewide' : filter.entity)],
            headers.map(i => i.header)
        ];
        const filteredData = databk.filter(i => (i.coverage.trim().toLowerCase() === filter.entity.trim().toLowerCase() || filter.entity === 'All'))
        console.log(headers)
        let monthData = fyMonthHeader.map(i => ({ [i]: filteredData.filter(x => x.dueMonth === i) })).reduce((acc, current) => {
            return { ...acc, ...current };
        }, {})
        const allStatus = [6, 7, 1, 3, 4, 0, 5]
        const excelData = [


            {
                title: 'Submissions Due', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 6).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Submissions Overdue', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 7).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Resubmission Required', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 1).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Under Review', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 3).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Under Approval', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 4).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Unlocked for Submission', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 0).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: 'Approved & Locked', ...fyMonthHeader.map(i => ({ [i]: monthData[i].filter(x => x.status === 5).length })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },
            {
                title: '% Completion', ...fyMonthHeader.map(i => ({ [i]: handleNan(monthData[i].filter(x => (allStatus.includes(x.status))).length / monthData[i].filter(x => x.status === 5).length) })).reduce((acc, current) => {
                    return { ...acc, ...current };
                }, {})
            },

        ]
        const wsData = [...staticData];

        excelData.forEach((row, index) => {
            wsData.push([
                row.title, row[fyMonthHeader[0]], row[fyMonthHeader[1]], row[fyMonthHeader[2]], row[fyMonthHeader[3]], row[fyMonthHeader[4]]
                , row[fyMonthHeader[5]], row[fyMonthHeader[6]], row[fyMonthHeader[7]], row[fyMonthHeader[8]], row[fyMonthHeader[9]], row[fyMonthHeader[10]], row[fyMonthHeader[11]]
            ]);
        });
        console.log(excelData)
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        ws['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }, // Merge cells B1 to M1
            { s: { r: 1, c: 0 }, e: { r: 1, c: 12 } }
        ];
        adjustColumnWidths(ws);
        const backgroundColorStyle = {
            fill: {
                fgColor: { rgb: "FFFF00" }  // Yellow color
            }
        };

        // Apply the background color to the nth row, from An to Mn
        const n = 9; // Replace with the desired row number (1-based index)
        for (let col = 0; col < 12; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: n - 1, c: col });
            if (!ws[cellAddress]) ws[cellAddress] = {};
            ws[cellAddress].s = backgroundColorStyle;
        }
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Export the workbook to Excel
        // XLSX.writeFile(wb, "DCF_Submission_Summary_"+filter.year+".xlsx");

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });

        XlsxPopulate.fromDataAsync(data).then((workbook) => {
            workbook.sheets().forEach((sheet) => {
                const n = 11; // Replace with the desired row number (1-based index)

                // Apply the background color to the nth row, from An to Mn
                for (let col = 1; col <= 13; col++) {
                    sheet.cell(n, col).style({
                        fill: {
                            type: 'solid',
                            color: 'D3D3D3' // Yellow color
                        }
                    });
                }




            })
            workbook
                .outputAsync()
                .then((workbookBlob) =>
                    saveAs(URL.createObjectURL(workbookBlob), "DCF_Submission_Summary_" + filter.year + ".xlsx")
                )

        })



    }
    const pbTemplate = (a, b) => {
        console.log(a, b)
        return <></>
    }
    return (
        <div className='col-12 font-lato'>
            <div
                className="col-12 "
                style={{
                    justifyContent: "center",
                }}
            >
                <label className="text-big-one clr-navy flex fs-18 fw-6 flex justify-content-start">

                    DCF Submission Status
                </label>
            </div>
            {!loading ? <div>
                <div className='col-12'>
                    <div className='flex justify-content-end'>
                        <Button type="button" label="Download Table" className="clr-navy-imp" onClick={downloadReport} icon="pi pi-download" outlined />

                        <Button type="button" label="Download Summary" className="clr-navy-imp ml-2" onClick={downloadSummary} icon="pi pi-download" outlined />
                    </div>
                    <div className='grid m-0'>
                        <div className='col-3 grid m-0 align-items-center'>
                            <label>Year</label>
                            <Dropdown value={filter.year} options={yearoptions} onChange={(e) => { updateYearFilter(e.value, 'year') }} optionLabel="label" optionValue="name" style={{ width: 200 }} className='ml-2' />
                        </div>
                        {/* <div className='col-3 grid m-0 align-items-center'>
                            <label>DCF</label>
                            <Dropdown value={filter.dcf} options={[{ id: 0, title: 'All' }, ...dcflist]} onChange={(e) => { updateYearFilter(e.value, 'dcf') }} optionLabel="title" optionValue="id" style={{ width: 200 }} className='ml-2' />
                        </div> */}
                        <div className='col-3 grid m-0 align-items-center'>
                            <label>Section</label>
                            <MultiSelect value={filter.section} options={assignedsection} onChange={(e) => { updateYearFilter(e.value, 'section') }} optionLabel="title" optionValue="id" style={{ width: 200 }} className='ml-2' />
                        </div>
                        <div className='col-3 grid m-0 align-items-center'>
                            <label>Entity</label>
                            <Dropdown value={filter.entity} options={entityList} onChange={(e) => { updateYearFilter(e.value, 'entity') }} optionLabel="name" optionValue="name" style={{ width: 200 }} className='ml-2' />
                        </div>
                        <div className='col-3 grid m-0 align-items-center'>
                            <label>Framework</label>
                            <MultiSelect display="chip" style={{ width: 300 }} value={filter.framework} onChange={(e) => updateYearFilter(e.value, 'framework')} options={assFramework} optionLabel="title" optionValue="title"
                                filter={true} placeholder="Select" panelClassName={'hidefilter'} />
                        </div>
                        <div className="col-12 flex justify-content-end">
                            <div className='col-3'>
                                <span className="p-input-icon-left" style={{ width: '100%' }}>
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search Reporter/Reviewer" onChange={(e) => { updateYearFilter(e.target.value, 'searchstr') }} value={filter.searchstr} style={{ width: '100%' }} />
                                </span>
                            </div>   </div>

                    </div>
                </div>
                <div className="flex p-flex-wrap submission-display-card-container">
                    <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 6 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(6) }}><div className="grid m-0 align-items-center"><div className=' card-number-1  col-4'>{getCountByStatus(6)} </div> <div className='clr-navy card-label-1  col-8'>Submissions Due </div></div> </div>
                    <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 7 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(7) }}><div className="grid m-0 align-items-center"><div className=' card-number-1  col-4'>{getCountByStatus(7)} </div> <div className='clr-navy card-label-1  col-8'>Submissions Overdue</div></div> </div>
                    <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 1 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(1) }}><div className="grid m-0 align-items-center"><div className=' card-number-1  col-4'>{getCountByStatus(1)} </div> <div className='clr-navy card-label-1  col-8'> Resubmission Required</div></div> </div>
                    <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 3 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(3) }}><div className="grid m-0 align-items-center"><div className=' card-number-1  col-4'>{getCountByStatus(3)} </div> <div className='clr-navy card-label-1  col-8'>Under Review</div></div> </div>
                    <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 4 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(4) }}><div className="grid m-0 align-items-center"><div className=' card-number-1  col-4'>{getCountByStatus(4)} </div> <div className='clr-navy card-label-1  col-8'>Reviewed</div></div> </div>

                </div>
                <div className="col-12" style={{ position: 'relative' }}>
                    <ProgressBar value={getPercentage()} displayValueTemplate={pbTemplate} color={getPercentageColor()}></ProgressBar>
                    <div className=" fw-7" style={{ color: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}> <label>{getPercentage()} % </label> </div>
                </div>
                <div className='col-12'>
                    <DataTable ref={dt} value={data} tableClassName="font-lato" filters={{ dcfId: { value: null, matchMode: 'in' }, coverage: { value: null, matchMode: 'in' }, reporting_period: { value: null, matchMode: 'in' } }} rowsPerPageOptions={[10, 20, 50, 100]} rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" paginator scrollable emptyMessage='No Assignment(s)' >
                        <Column header='DCF' field='dcfId' style={{ minWidth: '15%' }} body={dcfTemplate} filter showFilterMatchModes={false} showApplyButton={true} filterElement={dcfRowFilterTemplate} />
                        <Column header='Section' style={{ minWidth: '5%' }} body={sectionTemplate} />

                        <Column header='Reporting Entity' field='coverage' style={{ minWidth: '10%' }} filter showFilterMatchModes={false} showApplyButton={true} filterElement={entityRowFilterTemplate} />
                        <Column header='Reporting Period' style={{ minWidth: '8%' }} field='reporting_period' filter showFilterMatchModes={false} showApplyButton={true} filterElement={rpRowFilterTemplate} />
                        <Column header='Reporter(s)' style={{ minWidth: '25%' }} body={reporterTemplate} />
                        <Column header='Reviewer(s)' style={{ minWidth: '12%' }} body={reviewerTemplate} />
                        {/* <Column header='Approver(s)' style={{ minWidth: '25%' }} body={apporverTemplate} /> */}
                    </DataTable>
                </div>
            </div> :
                <div className="col-12 flex justify-content-center"><i className="fs-26 pi pi-spin pi-spinner" /> </div>
            }
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(TVSOverallQuantitativeSubmissionStatus, comparisonFn);
