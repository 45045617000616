import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from 'luxon';
import { Tag } from 'primereact/tag';
import { getDate } from '../../../../components/BGHF/helper';
import { Dialog } from 'primereact/dialog';
import DealerCompletedReport from './DealerCompletedReport';
import ActionofDealerStatus from './ActionofDealerStatus';
import { useSelector } from 'react-redux';

const DealersTableCompleted = ({ data, dealerList, assessorList, globalFilter, editDealer }) => {
 const select = useSelector((state) => state.userlist);
  console.log(select)
  console.log("users")
    console.log(data)
    const zonalOfficeList = [{ name: "Central", value: 1 }, { name: "East", value: 2 }, { name: "North", value: 3 }, { name: "South", value: 9 }, { name: "South1", value: 4 }, { name: "South2", value: 5 }, { name: "West", value: 8 }, { name: "West1", value: 6 }, { name: "West2", value: 7 }]
    console.log("data")
    const [reportdialog, setReportDialog] = useState(false)
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [actionReportData, setActionReportData] = useState([])
    const [actionStatusReport, setActionStatusReport] = useState(false)
    const pyInvoiceBodyTemplate = (rowData) => {
        return `${rowData.pyInvoiceValue} Cr. INR`;
    };

    
    const statusOfActionsTemplate = (rowData) => {
        if (!Array.isArray(rowData.actions)) {
            return <span className="redBox">0/0</span>;
        }

        const category3Items = rowData.actions;
        const totalCategory3 = category3Items.length;

        if (totalCategory3 === 0) {
            return <span className="redBox">0/0</span>;
        }

        const type3Count = 0;
        const ratio = `${type3Count}/${totalCategory3}`;

        const className = type3Count === totalCategory3 ? 'greenBox' : 'orangeBox';

        return <span className={className} style={{cursor:'pointer'}} onClick={() => dealerActionReport(rowData)}>{ratio}</span>;
    };

    const calibrationIdBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <a href="#" onClick={(e) => { e.preventDefault(); setSelectedAudit(rowData); setReportDialog(true); }}>
                {'MSI-' + (rowData?.vendor?.code || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}
            </a>
        );
    };

    const calibrationScoreTemplate = (rowData) => {
        return JSON.parse(rowData?.dealerAuditorChecklistSubmission?.score || '{overallScore:-}')?.overallScore
    }
    const dealerType = [{ name: 'Authorized Main Dealer', value: 1 }, { name: 'Authorized Dealer', value: 2 }, { name: 'Area Office', value: 3 }, { name: 'Authorized Parts Stockist (APS)', value: 4 }]

    const calibiratorTemplate = (rowData) => {
        console.log(assessorList, rowData?.dealerAuditorChecklistSubmission)

        if (rowData?.dealerAuditorChecklistSubmission) {
            let findId = rowData?.dealerAuditorChecklistSubmission?.modified_by || rowData?.dealerAuditorChecklistSubmission?.created_by || null
            console.log(assessorList.find(i => i.id === findId),)
            return assessorList.find(i => i.id === findId)?.information?.empname || ''
        } else {
            return 'Not Assigned'
        }
    }
    const nameTemplate = (rowData) => {
        console.log(rowData.vendorId, dealerList)
        return (

            rowData?.vendor?.dealerName || 'NA'


        );
    };

    const dealerActionReport = (rowData) => {
        setActionReportData(rowData)
        setActionStatusReport(true)

    }

    const contact1Template = (rowData) => {
        return (

            rowData.dealer?.information?.principalName || 'NA'

        );
    };
    const contact2Template = (rowData) => {
        return (

            rowData.dealer?.information?.workersmName || 'NA'

        );
    };
    const actionBodytemplate = (rowData) => {
        return (
            <div>
                <button className="btn btn-sm btn-primary" onClick={() => { editDealer(rowData) }}>Edit</button>
            </div>
        )
    }
    const contact3Template = (rowData) => {
        return (

            rowData.dealer?.information?.salesmName || 'NA'

        );
    };
    const locationTemplate = (rowData) => {
        return (

            rowData.vendor?.dealerLocation || 'NA'

        );
    };
    const zoneTemplate = (rowData) => {
        return (

            zonalOfficeList.find(x => x.value === rowData?.vendor?.dealerZone)?.name || 'NA'

        );
    };
    const categoryTemplate = (rowData) => {
        return (
            dealerType.find(x => x.value === rowData.vendor?.dealerCategory)?.name || 'NA'

        );
    };
    const statusTemplate = (rowData) => {
        return rowData?.dealerAuditorChecklistSubmission?.type === 1 ? <Tag className='status-tag-green' >Completed</Tag> : rowData?.dealerAuditorChecklistSubmission?.type === 0 ? <Tag className='status-tag-orange' >In Progress</Tag> : <Tag className='status-tag-gray' >Not Started</Tag>
    }
    // const statusOfActionsTemplate = (rowData) => {
    //     return <span>{rowData.statusOfActions}</span>;
    // };

    const handleCalibrationClick = (rowData) => {
        // Logic when calibration ID is clicked
        alert('Clicked on: ' + rowData.calibrationId);
    };

    return (<>
        <DataTable value={data} paginator rows={10} responsiveLayout="scroll" globalFilter={globalFilter} className="mt-2 h-500">
            <Column field="calibrationId" header="Calibration ID" body={calibrationIdBodyTemplate} ></Column>
            <Column field="name" header="Name" body={nameTemplate}></Column>
            <Column field="location" header="Location" body={locationTemplate}></Column>
            <Column field="location" header="Zone" body={zoneTemplate}></Column>
            {/* <Column field="pinCode" header="Pin Code" body={pincodeTemplate}></Column> */}
            {/* <Column field="dealerContact" header="Dealer Contact" ></Column> */}
            {/* <Column field="pyInvoiceValue" header="Spent by TVS(in INR)" body={pyInvoiceBodyTemplate} ></Column> */}
            {/* <Column field="status" header="Status" body={statusTemplate}  ></Column> */}

            <Column field="category" header="Category" body={categoryTemplate}></Column>
            <Column field="status" header="Latest Self-assessment Month" ></Column>
            <Column field="score" header="MSI Self-assessment Score" ></Column>
            <Column field="dealerAuditorChecklistSubmission" header="MSI Calibration Score" body={calibrationScoreTemplate} ></Column>
            <Column field="msiRating" header="MSI Rating" ></Column>


            {/* <Column field="selfAssessmentReportingMonth" header="Self-Assessment Reporting Month" ></Column> */}

            <Column field="calibrationDate" header="Calibration Submission Date" body={(rowData) => getDate(rowData?.dealerAuditorChecklistSubmission?.modified_on || rowData?.dealerAuditorChecklistSubmission?.created_on)} ></Column>
            <Column field="calibrationTeamMember" header="Calibration Team Member" body={calibiratorTemplate}></Column>

            <Column field="statusOfActions" header="Status of Actions" body={statusOfActionsTemplate}></Column>

            {/*            
            <Column field="statusOfActions" header="Status of Actions" body={statusOfActionsTemplate}></Column>
            <Column field="zonalManager" header="Zonal Manager" body={contact1Template}></Column>
            <Column field="areaManager" header="Area Manager" body={contact2Template}></Column>
            <Column field="territoryManager" header="Territory Manager" body={contact3Template}></Column> */}
            {/* <Column header="Action" body={actionBodytemplate}></Column> */}

        </DataTable>

        <Dialog visible={reportdialog} className="custom-dialog" style={{ width: 1200 }} onHide={() => { setReportDialog(false) }} >
            <DealerCompletedReport report={selectedAudit} />
        </Dialog>


        <Dialog visible={actionStatusReport} style={{ width: '90%' }} className="custom-dialog" onHide={() => { setActionStatusReport(false) }} >
            <ActionofDealerStatus report={actionReportData} />
        </Dialog>

    </>

    );
};

export default DealersTableCompleted;
