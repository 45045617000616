import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const GeneralChart = ({ data }) => {
  const maxValues = {
    "1s_2s": 10,
  };

  const extractScores = (criteriaData = {}) => {
    const subCriteria = criteriaData?.general?.subCriteria || [];

    const getScore = (name) => {
      const found = subCriteria.find((item) => item.name === name);
      return Math.max(0, found?.score || 0); // Handle negative & missing
    };

    return [
      {
        category: "1S & 2S",
        avgValue: getScore("1s_2s"),
        maxValue: maxValues["1s_2s"],
        remainingToMax: Math.max(
          0,
          maxValues["1s_2s"] - getScore("1s_2s")
        ),
        achievedColor: "#4f4f4f",
        maxColor: "#bdbdbd",
      },
    ];
  };

  const salesData = extractScores(data?.sales_criteria);
  const serviceData = extractScores(data?.service_criteria);

  const renderChart = (chartData, title) => (
    <div style={{ width: "48%", height: 400 }}>
      <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
        {title}
      </h3>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          barSize={60}
          margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="category"
            tick={{ fontSize: 12 }}
            angle={-30}
            textAnchor="end"
            interval={0}
            dy={10}
            height={80}
          />
          <YAxis domain={[0, 10]} />
          <Tooltip
            formatter={(value, name, props) => {
              const { payload } = props;
              if (name === "Achieved") {
                return [
                  `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                  name,
                ];
              }
              return [`${value}`, name];
            }}
          />
          <Legend />
          <Bar
            dataKey="avgValue"
            stackId="score"
            name="Achieved"
            label={{
              position: "insideTop",
              fill: "#fff",
              formatter: (value) => value.toFixed(1),
            }}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-achieved-${index}`} fill={entry.achievedColor} />
            ))}
          </Bar>
          <Bar
            dataKey="remainingToMax"
            stackId="score"
            name="Remaining"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "20px" }}
    >
      {renderChart(salesData, "Sales - General")}
      {renderChart(serviceData, "Service - General")}
    </div>
  );
};

export default GeneralChart;
