import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const ESGGScores = ({ data }) => {
  const filterDataByCategory = (category) =>
    data.filter((entry) => entry.category === category);

  const aggregateData = (filteredData) => {

    console.log(filteredData, "filteredData");
    const totals = {
      environment: 0,
      social: 0,
      governance: 0,
      general: 0,
    };

    const maxValues = {
      Environment: 40,
      Social: 40,
      Governance: 15,
      General: 5,
    };

    let count = filteredData.length || 1;

    filteredData.forEach((entry) => {
      totals.environment += isNaN(entry.environment)
        ? 0
        : entry.environment;
      totals.social += isNaN(entry.social) ? 0 : entry.social;
      totals.governance += isNaN(entry.governance) ? 0 : entry.governance;
      totals.general += isNaN(entry.general) ? 0 : entry.general;
    });

    const avgEnvironment = totals.environment / count;
    const avgSocial = totals.social / count;
    const avgGovernance = totals.governance / count;
    const avgGeneral = totals.general / count;

    return [
      {
        category: "Environment",
        avgValue: avgEnvironment,
        maxValue: maxValues.Environment,
        remainingToMax: maxValues.Environment - avgEnvironment,
        achievedColor: "#2C7C69",
        maxColor: "#7FC8A9",
      },
      {
        category: "Social",
        avgValue: avgSocial,
        maxValue: maxValues.Social,
        remainingToMax: maxValues.Social - avgSocial,
        achievedColor: "#FC6E51",
        maxColor: "#FEB2A8",
      },
      {
        category: "Governance",
        avgValue: avgGovernance,
        maxValue: maxValues.Governance,
        remainingToMax: maxValues.Governance - avgGovernance,
        achievedColor: "#4A90E2",
        maxColor: "#AFCBFF",
      },
      {
        category: "General",
        avgValue: avgGeneral,
        maxValue: maxValues.General,
        remainingToMax: maxValues.General - avgGeneral,
        achievedColor: "#b0b0b0",
        maxColor: "#e3e3e3",
      },
    ];
  };

  const processedData = aggregateData(data);

  return (
    <div>
      <div style={{ width: "100%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          ESG Score
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={processedData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, 40]} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Average Score") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Bar
              dataKey="avgValue"
              stackId="score"
              name="Achieved"
              label={{
                position: "insideBottom",
                fill: "#ffffff",
                formatter: (value) => value.toFixed(1),
              }}
            >
              {processedData.map((entry, index) => (
                <Cell
                  key={`cell-achieved-${index}`}
                  fill={entry.achievedColor}
                />
              ))}
            </Bar>
            <Bar dataKey="remainingToMax" stackId="score" name="Maximum">
              {processedData.map((entry, index) => (
                <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
              ))}
            </Bar>
          </BarChart>

          <div
            className="legend"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <span
                style={{
                  color: "#e3e3e3",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#e3e3e3",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#7FC8A9",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#7FC8A9",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FFB6C1",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FFB6C1",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div style={{ marginRight: "30px" }}>
              <span
                style={{
                  color: "#AFCBFF",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#AFCBFF",
                  marginRight: "5px",
                }}
              ></span>
              Maximum
            </div>
            <div>
              <span
                style={{
                  color: "#b0b0b0",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#b0b0b0",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#2C7C69",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#2C7C69",
                  marginRight: "5px",
                }}
              ></span>
            </div>
            <div>
              <span
                style={{
                  color: "#FF6F61",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%", // Make it round
                  backgroundColor: "#FF6F61",
                  marginRight: "5px",
                }}
              ></span>
            </div>

            <div>
              <span
                style={{
                  color: "#4A90E2",
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#4A90E2",
                  marginRight: "5px",
                }}
              ></span>
              Achieved
            </div>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ESGGScores;
