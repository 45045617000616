import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import APIServices from "../service/APIService";
import { API } from "../constants/api_url";

const ExcelUploader = () => {
  const [jsonData, setJsonData] = useState(null);
  const [supplier, setSupplier] = useState([])
  useEffect(() => {
    APIServices.get(API.Supplier_UP(289)).then((res) => setSupplier(res.data))
  }, [])

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = async(e) => {
      const binaryData = e.target.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const data = XLSX.utils.sheet_to_json(sheet);

      // Process Data
      const formattedData = data.map((row) => {
        const { auditStartDate, auditEndDate } = formatDate(row["Audit Date"]);

        return {

          "vendorCode": row["Vendor Code"].toString(),
          auditStartDate: auditStartDate,
          auditEndDate: auditEndDate,
          "created_by": 289,
          "vendorId": null,
          "supplierId": null,
          created_on: DateTime.utc(),
          auditorAssignmentSubmission: {
            auditorMSIScore: row["Total"],
            type: 1,
            vendorCode: row["Vendor Code"].toString(),
            vendorId: null,
            created_by: 289,
          created_on: DateTime.now(),
            modified_by: 289,
            modified_on: DateTime.now(),
            response: 
              JSON.stringify([
                {
                  "id": "6794b6f5-69eb-48a7-baf1-22b5db337504",
                  "title": "Occupational Health & Safety Framework",
                  "order": 4,
                  "sectionTotalScore": row["Electricals, Fire safety & Emergency Preparedness"] + row["Maching guarding & controls"] + row["Contractor safety & Permit To Work"] + row["Road safety"],
                  "assessmentSubSection1s": [
                    {
                      "id": "1e79eab5-624c-43c2-86da-27f96ad2eef5",
                      "title": "Contractor safety & Permit To Work",
                      "order": 19,
                      "isRoot": false,
                      "assessmentSectionId": "6794b6f5-69eb-48a7-baf1-22b5db337504",
                      "formId": null,
                      "totalScore": row["Contractor safety & Permit To Work"],
                      "assessmentSubSection2s": [
                        {
                          "id": "1be54370-6107-47a8-8767-468b1eb4d432",
                          "title": "Contractor safety & Permit To Work",
                          "isRoot": false,
                          "order": 46,
                          "totalScore": row["Contractor safety & Permit To Work"],
                          "assessmentSubSection1Id": "1e79eab5-624c-43c2-86da-27f96ad2eef5",
                          "formId": 69
                        }
                      ]
                    },
                    {
                      "id": "468a64c8-b39a-40dd-b156-a3cb47560a0b",
                      "title": "Maching guarding & controls",
                      "order": 18,
                      "isRoot": false,
                      "assessmentSectionId": "6794b6f5-69eb-48a7-baf1-22b5db337504",
                      "formId": null,
                      "totalScore": row["Maching guarding & controls"],
                      "assessmentSubSection2s": [
                        {
                          "id": "911d30ee-f10b-44dd-a4f2-f244827ea0d8",
                          "title": "Maching guarding & controls",
                          "isRoot": false,
                          "order": 45,
                          "totalScore": row["Maching guarding & controls"],
                          "assessmentSubSection1Id": "468a64c8-b39a-40dd-b156-a3cb47560a0b",
                          "formId": 68
                        }
                      ]
                    },
                    {
                      "id": "8bae2809-1683-4a62-9698-51e5ffa7f55e",
                      "title": "Electricals, Fire safety & Emergency Preparedness",
                      "order": 21,
                      "isRoot": false,
                      "assessmentSectionId": "6794b6f5-69eb-48a7-baf1-22b5db337504",
                      "formId": null,
                      "totalScore": row["Electricals, Fire safety & Emergency Preparedness"],

                      "assessmentSubSection2s": [
                        {
                          "id": "f0701fa3-26e2-40d2-8c5c-c85958cf63c4",
                          "title": "Electricals, Fire safety & Emergency Preparedness",
                          "isRoot": false,
                          "order": 48,
                          "totalScore": row["Electricals, Fire safety & Emergency Preparedness"],

                          "assessmentSubSection1Id": "8bae2809-1683-4a62-9698-51e5ffa7f55e",
                          "formId": 71
                        }
                      ]
                    },
                    {
                      "id": "cabfde99-c3a6-42be-80aa-e1885ff71a03",
                      "title": "Road safety",
                      "order": 20,
                      "isRoot": false,
                      "assessmentSectionId": "6794b6f5-69eb-48a7-baf1-22b5db337504",
                      "formId": null,
                      "totalScore": row["Road safety"],

                      "assessmentSubSection2s": [
                        {
                          "id": "295e8473-f05a-47ef-bf2b-94bb6c8482a7",
                          "title": "Road safety",
                          "isRoot": false,
                          "order": 47,
                          "totalScore": row["Road safety"],
                          "assessmentSubSection1Id": "cabfde99-c3a6-42be-80aa-e1885ff71a03",
                          "formId": 70
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "68a7836a-10fe-4632-af0d-44d37f59121a",
                  "title": "Legal Compliances",
                  "order": 5,
                  "sectionTotalScore": row["Legal Compliances"],
                  "assessmentSubSection1s": [
                    {
                      "id": "4ed9e133-e188-4e41-8bff-c44c862d9160",
                      "title": "Electrical Installations",
                      "order": 24,
                      "isRoot": false,
                      "assessmentSectionId": "68a7836a-10fe-4632-af0d-44d37f59121a",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "e07a59f6-617c-4450-b81d-367e81159d90",
                          "title": "Electrical Installations",
                          "isRoot": false,
                          "order": 51,
                          "assessmentSubSection1Id": "4ed9e133-e188-4e41-8bff-c44c862d9160",
                          "formId": 74
                        }
                      ]
                    },
                    {
                      "id": "525232c1-805b-4fb8-9f8f-a5116f931eb0",
                      "title": "General",
                      "order": 22,
                      "isRoot": false,
                      "assessmentSectionId": "68a7836a-10fe-4632-af0d-44d37f59121a",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "7a3077f9-01bd-4625-9f79-bdf07f8528da",
                          "title": "General",
                          "isRoot": false,
                          "order": 34,
                          "assessmentSubSection1Id": "525232c1-805b-4fb8-9f8f-a5116f931eb0",
                          "formId": 72
                        }
                      ]
                    },
                    {
                      "id": "74c539bc-9fce-4352-9c63-850bd90ca54f",
                      "title": "Fire Protection",
                      "order": 23,
                      "isRoot": false,
                      "assessmentSectionId": "68a7836a-10fe-4632-af0d-44d37f59121a",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "6c032b18-647c-452e-8b8a-9d6d1eb7fef9",
                          "title": "Fire Protection",
                          "isRoot": false,
                          "order": 50,
                          "assessmentSubSection1Id": "74c539bc-9fce-4352-9c63-850bd90ca54f",
                          "formId": 73
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "7884432d-9f75-42d6-a49f-fa6c6cd08fcd",
                  "title": "Sustainability Ambassadorship Framework",
                  "order": 2,
                  "sectionTotalScore": row["Supplier Sustainability Ambassadorship Framework"],
                  "assessmentSubSection1s": [
                    {
                      "id": "312a9bb4-7291-4da9-8437-834086e2d22e",
                      "title": "General",
                      "order": 7,
                      "isRoot": false,
                      "assessmentSectionId": "7884432d-9f75-42d6-a49f-fa6c6cd08fcd",
                      "formId": null,

                      "assessmentSubSection2s": [
                        {
                          "id": "c006899b-eaf6-4204-8f9f-7e5099c315b3",
                          "title": "General",
                          "isRoot": false,

                          "order": 49,
                          "assessmentSubSection1Id": "312a9bb4-7291-4da9-8437-834086e2d22e",
                          "formId": 57
                        }
                      ]
                    },
                    {
                      "id": "5dda5144-e4af-4c96-8545-dab5f93e8ac9",
                      "title": "Waste",
                      "order": 8,
                      "isRoot": false,
                      "assessmentSectionId": "7884432d-9f75-42d6-a49f-fa6c6cd08fcd",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "a51eebb2-4da2-44c2-92f0-80026656395e",
                          "title": "Waste",
                          "isRoot": false,
                          "order": 35,
                          "assessmentSubSection1Id": "5dda5144-e4af-4c96-8545-dab5f93e8ac9",
                          "formId": 58
                        }
                      ]
                    },
                    {
                      "id": "7d2c51c9-d33b-49e5-b901-14e00000f531",
                      "title": "Road Safety",
                      "order": 9,
                      "isRoot": false,
                      "assessmentSectionId": "7884432d-9f75-42d6-a49f-fa6c6cd08fcd",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "2d5ef6df-3f9e-4219-8d08-cacf51cd8c2c",
                          "title": "Road Safety",
                          "isRoot": false,
                          "order": 36,
                          "assessmentSubSection1Id": "7d2c51c9-d33b-49e5-b901-14e00000f531",
                          "formId": 59
                        }
                      ]
                    },
                    {
                      "id": "808d8fc3-6293-410e-a3b8-d7fbeb88b601",
                      "title": "Bio Diversity",
                      "order": 10,
                      "isRoot": false,
                      "assessmentSectionId": "7884432d-9f75-42d6-a49f-fa6c6cd08fcd",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "7c3f33b5-0a83-4a81-a538-550c06bb4a1f",
                          "title": "BioDiversity",
                          "isRoot": false,
                          "order": 37,
                          "assessmentSubSection1Id": "808d8fc3-6293-410e-a3b8-d7fbeb88b601",
                          "formId": 60
                        }
                      ]
                    },
                    {
                      "id": "b6b617bd-2874-4945-979d-a9ce2046034e",
                      "title": "Energy",
                      "order": 6,
                      "isRoot": false,
                      "assessmentSectionId": "7884432d-9f75-42d6-a49f-fa6c6cd08fcd",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "3b5c961a-8a96-4182-83f7-2c7bfd72734e",
                          "title": "Energy",
                          "isRoot": false,
                          "order": 33,
                          "assessmentSubSection1Id": "b6b617bd-2874-4945-979d-a9ce2046034e",
                          "formId": 56
                        }
                      ]
                    },
                    {
                      "id": "f74aa89e-6b27-406d-bf0b-286b9c992c26",
                      "title": "Water",
                      "order": 5,
                      "isRoot": false,
                      "assessmentSectionId": "7884432d-9f75-42d6-a49f-fa6c6cd08fcd",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "c18e497d-4acc-47ee-87d3-dcc9162636e2",
                          "title": "Water",
                          "isRoot": false,
                          "order": 32,
                          "assessmentSubSection1Id": "f74aa89e-6b27-406d-bf0b-286b9c992c26",
                          "formId": 55
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "8d1a0217-4a6a-4d4a-922e-46c345dc7291",
                  "title": "Environmental Framework",
                  "order": 1,
                  "sectionTotalScore": row["Water"] + row["Waste"] + row["Energy"] + row["Product Stewardship"],
                  "assessmentSubSection1s": [
                    {
                      "id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                      "title": "Waste",
                      "order": 2,
                      "isRoot": false,
                      "assessmentSectionId": "8d1a0217-4a6a-4d4a-922e-46c345dc7291",
                      "formId": null,
                      "totalScore": row["Waste"],

                      "assessmentSubSection2s": [
                        {
                          "id": "1b526699-bbfc-4093-886c-2e90563bf7c2",
                          "title": "Waste Intensity",
                          "isRoot": false,
                          "order": 14,
                          "assessmentSubSection1Id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                          "formId": 36
                        },
                        {
                          "id": "52d5c39a-e8d3-48d1-8573-1b881be5bab3",
                          "title": "Hazardous Waste",
                          "isRoot": false,
                          "order": 7,
                          "assessmentSubSection1Id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                          "formId": 30
                        },
                        {
                          "id": "606e0bc2-ee13-43da-8466-97dd09737023",
                          "title": "Battery Waste",
                          "isRoot": false,
                          "order": 11,
                          "assessmentSubSection1Id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                          "formId": 37
                        },
                        {
                          "id": "7331ef1e-4052-40e3-b2d6-bfde4160eea0",
                          "title": "Waste Management Playbook",
                          "isRoot": false,
                          "order": 13,
                          "assessmentSubSection1Id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                          "formId": 35
                        },
                        {
                          "id": "bbbe221c-88b9-4fb9-a4f3-ef3b05f753b9",
                          "title": "Zero Waste to Landfill",
                          "isRoot": false,
                          "order": 12,
                          "assessmentSubSection1Id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                          "formId": 34
                        },
                        {
                          "id": "c13c26f0-1408-492d-b30d-d194863abcc8",
                          "title": "Bio-Medical Waste",
                          "isRoot": false,
                          "order": 9,
                          "assessmentSubSection1Id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                          "formId": 32
                        },
                        {
                          "id": "d84804e2-3dd0-469c-aff1-d2ddd2849f71",
                          "title": "E-Waste",
                          "isRoot": false,
                          "order": 8,
                          "assessmentSubSection1Id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                          "formId": 31
                        },
                        {
                          "id": "e89d8e1d-4de4-41c1-a4a4-c1ceaa205b2b",
                          "title": "Plastic Waste",
                          "isRoot": false,
                          "order": 10,
                          "assessmentSubSection1Id": "0f393e49-7c17-4db6-adcb-6becc25640e8",
                          "formId": 33
                        }
                      ]
                    },
                    {
                      "id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                      "title": "Product Stewardship",
                      "order": 4,
                      "isRoot": false,
                      "assessmentSectionId": "8d1a0217-4a6a-4d4a-922e-46c345dc7291",
                      "formId": null,
                      "totalScore": row["Product Stewardship"],

                      "assessmentSubSection2s": [
                        {
                          "id": "0c42dcd3-6ec5-48b8-be50-a6c819fa2451",
                          "title": "Use of Alternate Materials",
                          "isRoot": false,
                          "order": 28,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 51
                        },
                        {
                          "id": "1a4184a9-7d00-40df-bb35-ddf14101b9c3",
                          "title": "Meet Line & Lot Rejection",
                          "isRoot": false,
                          "order": 27,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 50
                        },
                        {
                          "id": "1b37ce11-e265-4d27-98a1-35ee54b65b3c",
                          "title": "Product Recalls",
                          "isRoot": false,
                          "order": 31,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 54
                        },
                        {
                          "id": "38de5ad7-9074-4008-932e-766299a8ea23",
                          "title": "Recyclability",
                          "isRoot": false,
                          "order": 24,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 47
                        },
                        {
                          "id": "5030615f-a840-46c7-8ec3-ca6bd4435369",
                          "title": "Zero Hazardous Waste",
                          "isRoot": false,
                          "order": 26,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 49
                        },
                        {
                          "id": "63989c5d-21e9-4cbb-898a-68f443df64ff",
                          "title": "Lifecycle Assessments",
                          "isRoot": false,
                          "order": 22,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 45
                        },
                        {
                          "id": "853cb038-086b-4571-b606-54f47a996b29",
                          "title": "Recoverability",
                          "isRoot": false,
                          "order": 25,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 48
                        },
                        {
                          "id": "c7bbdf4b-4b04-43d6-ac9c-0317c2d4ecf2",
                          "title": "Product Stewardship Playbook",
                          "isRoot": false,
                          "order": 23,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 46
                        },
                        {
                          "id": "caf29174-955d-4381-808b-7ab1d694deed",
                          "title": "Eco-Labelling",
                          "isRoot": false,
                          "order": 30,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 53
                        },
                        {
                          "id": "df460ebf-4db8-45ee-ab79-952081448b24",
                          "title": "Alternate to Plastic in Packaging",
                          "isRoot": false,
                          "order": 29,
                          "assessmentSubSection1Id": "3c7472a6-8b3d-46f5-986f-d78cc7632a73",
                          "formId": 52
                        }
                      ]
                    },
                    {
                      "id": "4d0845cf-240b-4e4b-a260-3eea0676d47d",
                      "title": "Energy",
                      "order": 3,
                      "isRoot": false,
                      "assessmentSectionId": "8d1a0217-4a6a-4d4a-922e-46c345dc7291",
                      "formId": null,
                      "totalScore": row["Energy"],

                      "assessmentSubSection2s": [
                        {
                          "id": "81968497-1808-4edd-8552-2dea9e21f29e",
                          "title": "Projects towards EP 100",
                          "isRoot": false,
                          "order": 21,
                          "assessmentSubSection1Id": "4d0845cf-240b-4e4b-a260-3eea0676d47d",
                          "formId": 44
                        },
                        {
                          "id": "8e40e042-dddc-45ef-a5a1-31b2b0f9c01b",
                          "title": "Carbon Foot print Scope 1 & Scope 2 Reduction Plan",
                          "isRoot": false,
                          "order": 18,
                          "assessmentSubSection1Id": "4d0845cf-240b-4e4b-a260-3eea0676d47d",
                          "formId": 41
                        },
                        {
                          "id": "95c9f265-3626-4144-b949-a53df9570a95",
                          "title": "Renewable Energy",
                          "isRoot": false,
                          "order": 16,
                          "assessmentSubSection1Id": "4d0845cf-240b-4e4b-a260-3eea0676d47d",
                          "formId": 39
                        },
                        {
                          "id": "97578785-e0ae-4c9f-8f3c-57c7397486d8",
                          "title": "Scope 3: Reduction Plan",
                          "isRoot": false,
                          "order": 20,
                          "assessmentSubSection1Id": "4d0845cf-240b-4e4b-a260-3eea0676d47d",
                          "formId": 43
                        },
                        {
                          "id": "9c476f89-60cb-4214-981e-84c53ff87cc2",
                          "title": "Scope 3: Mapping",
                          "isRoot": false,
                          "order": 19,
                          "assessmentSubSection1Id": "4d0845cf-240b-4e4b-a260-3eea0676d47d",
                          "formId": 42
                        },
                        {
                          "id": "aa8becae-90df-478a-b5fd-9e458c4354f0",
                          "title": "Energy Monitoring",
                          "isRoot": false,
                          "order": 15,
                          "assessmentSubSection1Id": "4d0845cf-240b-4e4b-a260-3eea0676d47d",
                          "formId": 38
                        },
                        {
                          "id": "c3bbc0b4-1777-4174-8b38-0ed8058362e8",
                          "title": "Energy Excellence Playbook",
                          "isRoot": false,
                          "order": 17,
                          "assessmentSubSection1Id": "4d0845cf-240b-4e4b-a260-3eea0676d47d",
                          "formId": 40
                        }
                      ]
                    },
                    {
                      "id": "a9546326-9617-43c9-81ba-eba5d23f2160",
                      "title": "Water",
                      "order": 1,
                      "isRoot": false,
                      "assessmentSectionId": "8d1a0217-4a6a-4d4a-922e-46c345dc7291",
                      "formId": null,
                      "totalScore": row["Water"],

                      "assessmentSubSection2s": [
                        {
                          "id": "178b09f0-5962-472c-81a4-cdfe56240522",
                          "title": "STP Operation & Maintainence",
                          "isRoot": false,
                          "order": 3,
                          "assessmentSubSection1Id": "a9546326-9617-43c9-81ba-eba5d23f2160",
                          "formId": 26
                        },
                        {
                          "id": "692852a7-460c-4c1c-871b-85ff7311ddf6",
                          "title": "Monitoring Water Consumption",
                          "isRoot": false,
                          "order": 1,
                          "assessmentSubSection1Id": "a9546326-9617-43c9-81ba-eba5d23f2160",
                          "formId": 24
                        },
                        {
                          "id": "74ecda5d-4036-4e05-bbe5-5731f6df5b09",
                          "title": "Zero Liquid Discharge Statusy",
                          "isRoot": false,
                          "order": 6,
                          "assessmentSubSection1Id": "a9546326-9617-43c9-81ba-eba5d23f2160",
                          "formId": 29
                        },
                        {
                          "id": "aa03904c-b7fa-421b-a053-2e8e0ee6c59f",
                          "title": "Water Neutrality",
                          "isRoot": false,
                          "order": 5,
                          "assessmentSubSection1Id": "a9546326-9617-43c9-81ba-eba5d23f2160",
                          "formId": 28
                        },
                        {
                          "id": "e01db328-cd91-4594-b53d-cceb09fcbf20",
                          "title": "ETP Operation & Maintainence",
                          "isRoot": false,
                          "order": 2,
                          "assessmentSubSection1Id": "a9546326-9617-43c9-81ba-eba5d23f2160",
                          "formId": 25
                        },
                        {
                          "id": "f956a8a2-e8ee-4571-854f-bcdc235f9f68",
                          "title": "Water Stewardship Playbook",
                          "isRoot": false,
                          "order": 4,
                          "assessmentSubSection1Id": "a9546326-9617-43c9-81ba-eba5d23f2160",
                          "formId": 27
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "95cb751e-0c5e-4527-b2c6-e82550e151d4",
                  "title": "Governance Framework",
                  "order": 6,
                  "sectionTotalScore": row["Supplier Governance Framework"],
                  "assessmentSubSection1s": [
                    {
                      "id": "6412839f-33ac-42ad-a0bb-98cba0b03f53",
                      "title": "General",
                      "order": 25,
                      "isRoot": false,
                      "totalScore": row["Supplier Governance Framework"],
                      "assessmentSectionId": "95cb751e-0c5e-4527-b2c6-e82550e151d4",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "fd2c05cd-51b7-4b01-919c-02c81b61fbb3",
                          "title": "General",
                          "totalScore": row["Supplier Governance Framework"],
                          "isRoot": false,
                          "order": 52,
                          "assessmentSubSection1Id": "6412839f-33ac-42ad-a0bb-98cba0b03f53",
                          "formId": 75
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "bce965ce-13a6-476a-8f7b-f2bcea2a3593",
                  "title": "Social Stewardship Framework",
                  "order": 3,
                  "sectionTotalScore": row["Supplier Social Stewardship Framework"],
                  "assessmentSubSection1s": [
                    {
                      "id": "35303d41-b096-4857-84a3-10656973a993",
                      "title": "Freedom of Association",
                      "order": 14,
                      "isRoot": false,
                      "assessmentSectionId": "bce965ce-13a6-476a-8f7b-f2bcea2a3593",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "28cd336d-37d5-4a09-a589-87cb1458a5b6",
                          "title": "Freedom of Association",
                          "isRoot": false,
                          "order": 41,
                          "assessmentSubSection1Id": "35303d41-b096-4857-84a3-10656973a993",
                          "formId": 64
                        }
                      ]
                    },
                    {
                      "id": "435d6411-6f30-4135-8a36-7bc33d47eb25",
                      "title": "Child Labour",
                      "order": 11,
                      "isRoot": false,
                      "assessmentSectionId": "bce965ce-13a6-476a-8f7b-f2bcea2a3593",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "ef5d9ac2-b30b-4e84-aa5a-975f8054f276",
                          "title": "Child Labour",
                          "isRoot": false,
                          "order": 38,
                          "assessmentSubSection1Id": "435d6411-6f30-4135-8a36-7bc33d47eb25",
                          "formId": 61
                        }
                      ]
                    },
                    {
                      "id": "4f223df4-84a3-44b5-a958-2addca27bb76",
                      "title": "Remuneration",
                      "order": 16,
                      "isRoot": false,
                      "assessmentSectionId": "bce965ce-13a6-476a-8f7b-f2bcea2a3593",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "c75d21cc-1c59-4d7d-8d1e-bf686e12b0b4",
                          "title": "Remuneration",
                          "isRoot": false,
                          "order": 43,
                          "assessmentSubSection1Id": "4f223df4-84a3-44b5-a958-2addca27bb76",
                          "formId": 66
                        }
                      ]
                    },
                    {
                      "id": "61ff6be8-3875-4f1d-b983-6346ef144dee",
                      "title": "Discrimination",
                      "order": 13,
                      "isRoot": false,
                      "assessmentSectionId": "bce965ce-13a6-476a-8f7b-f2bcea2a3593",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "ad3690f2-83bd-4cfb-a0bf-42c76b859e0e",
                          "title": "Discrimination",
                          "isRoot": false,
                          "order": 40,
                          "assessmentSubSection1Id": "61ff6be8-3875-4f1d-b983-6346ef144dee",
                          "formId": 63
                        }
                      ]
                    },
                    {
                      "id": "cc59a442-194a-48a6-ae31-42e1ef9eb157",
                      "title": "Forced or Compulsary Labour",
                      "order": 15,
                      "isRoot": false,
                      "assessmentSectionId": "bce965ce-13a6-476a-8f7b-f2bcea2a3593",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "e89732a2-4eec-4690-a8b5-74ccea2d91b2",
                          "title": "Forced or Compulsary Labour",
                          "isRoot": false,
                          "order": 42,
                          "assessmentSubSection1Id": "cc59a442-194a-48a6-ae31-42e1ef9eb157",
                          "formId": 65
                        }
                      ]
                    },
                    {
                      "id": "d1b2bd4b-851a-4749-9fe1-3304de176920",
                      "title": "Disciplinary Practices",
                      "order": 12,
                      "isRoot": false,
                      "assessmentSectionId": "bce965ce-13a6-476a-8f7b-f2bcea2a3593",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "c4576a06-79d3-4e71-98be-26e7ac1f5793",
                          "title": "Disciplinary Practices",
                          "isRoot": false,
                          "order": 39,
                          "assessmentSubSection1Id": "d1b2bd4b-851a-4749-9fe1-3304de176920",
                          "formId": 62
                        }
                      ]
                    },
                    {
                      "id": "f49674a1-cd0d-409a-9144-e9c7f37b0bf3",
                      "title": "Working Hours",
                      "order": 17,
                      "isRoot": false,
                      "assessmentSectionId": "bce965ce-13a6-476a-8f7b-f2bcea2a3593",
                      "formId": null,
                      "assessmentSubSection2s": [
                        {
                          "id": "83ca159b-edfd-413a-909b-682dc4ed25bd",
                          "title": "Working Hours",
                          "isRoot": false,
                          "order": 44,
                          "assessmentSubSection1Id": "f49674a1-cd0d-409a-9144-e9c7f37b0bf3",
                          "formId": 67
                        }
                      ]
                    }
                  ]
                }
              ])
            
          },

        };
      });
      for (const item of formattedData) {
        let vendor = supplier.find(x => x.code === item.vendorCode);
        
        if (vendor && vendor.userProfileId && vendor.id) {
            const { auditorAssignmentSubmission, ...rest } = item;
            rest['supplierId'] = vendor.userProfileId;
            rest['vendorId'] = vendor.id;
            const newObj = {...auditorAssignmentSubmission,vendorId:vendor.id  }
            console.log(newObj)
            // try {
            //     const res = await APIServices.post(API.SupplierAssessmentAss_Up(289), rest);
            //     await APIServices.post(API.AuditorAssessmentSubmission(res.data.id), newObj);
            //     console.log(`Processed item with vendorCode: ${item.vendorCode}`);
            // } catch (error) {
            //     console.error(`Error processing item with vendorCode: ${item.vendorCode}`, error);
            // }
    
        } else {
            console.log('Vendor not found or incomplete data for item:', item);
        }
    }
    
      setJsonData(formattedData);
    };
  };

  const formatDate = (excelDate) => {
    if (!excelDate) return { auditStartDate: null, auditEndDate: null };

    const dates = excelDate.toString().split("-").map((d) => d.trim());

    if (dates.length === 1) {
      return { auditStartDate: DateTime.fromFormat(dates[0], 'dd.MM.yyyy').toUTC(), auditEndDate: DateTime.fromFormat(dates[0], 'dd.MM.yyyy').toUTC() };
    }

    return { auditStartDate: DateTime.fromFormat(dates[0], 'dd.MM.yyyy').toUTC(), auditEndDate: DateTime.fromFormat(dates[1], 'dd.MM.yyyy').toUTC() };
  };

  return (
    <div>
      <h2>Excel File Upload & JSON Preview</h2>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />

      {jsonData && (
        <div>
          <h3>Extracted JSON Data:</h3>
          <pre>{JSON.stringify(jsonData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default ExcelUploader;
