import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";


const IncidentCharts = () => {
  // Sample Data
  const incidentData = [
    { month: "1", "Non-Reportable Accidents": 1, "First Aid Incidents": 40, "Near-miss Incidents": 30, "Minor Fire Incidents": 2 },
    { month: "2", "Non-Reportable Accidents": 2, "First Aid Incidents": 60, "Near-miss Incidents": 50, "Minor Fire Incidents": 8 },
    { month: "3", "Non-Reportable Accidents": 2, "First Aid Incidents": 80, "Near-miss Incidents": 20, "Minor Fire Incidents": 10 },
    { month: "4", "Non-Reportable Accidents": 2, "First Aid Incidents": 100, "Near-miss Incidents": 40, "Minor Fire Incidents": 12 },
    { month: "5", "Non-Reportable Accidents": 4, "First Aid Incidents": 50, "Near-miss Incidents": 20, "Minor Fire Incidents": 9 },
    { month: "6", "Non-Reportable Accidents": 1, "First Aid Incidents": 70, "Near-miss Incidents": 50, "Minor Fire Incidents": 5 },
    { month: "7", "Non-Reportable Accidents": 1, "First Aid Incidents": 90, "Near-miss Incidents": 30, "Minor Fire Incidents": 3 },
    { month: "8", "Non-Reportable Accidents": 0, "First Aid Incidents": 110, "Near-miss Incidents": 10, "Minor Fire Incidents": 6 },
    { month: "9", "Non-Reportable Accidents": 4, "First Aid Incidents": 80, "Near-miss Incidents": 50, "Minor Fire Incidents": 8 },
    { month: "10", "Non-Reportable Accidents": 4, "First Aid Incidents": 100, "Near-miss Incidents": 20, "Minor Fire Incidents": 2 },
    { month: "11", "Non-Reportable Accidents": 0, "First Aid Incidents": 95, "Near-miss Incidents": 30, "Minor Fire Incidents": 4 },
    { month: "12", "Non-Reportable Accidents": 4, "First Aid Incidents": 50, "Near-miss Incidents": 40, "Minor Fire Incidents": 8 },
  ];

  const colors = {
    "Non-Reportable Accidents": "#ff6384",
    "First Aid Incidents": "#36a2eb",
    "Near-miss Incidents": "#ffce56",
    "Minor Fire Incidents": "#4bc0c0",
  };

  const locations = ["All", "Hosur", "Mysuru", "Nalagarh"];
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [fromMonth, setFromMonth] = useState(null);
  const [toMonth, setToMonth] = useState(null);

  return (
    <div style={{ padding: "20px" }}>
      {/* Filters */}
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <div>
          <h3 style={{ marginBottom: "10px" }}>Location Filter</h3>
          <Dropdown
            value={selectedLocation}
            options={locations}
            onChange={(e) => setSelectedLocation(e.value)}
            placeholder="All / Hosur / Mysuru / Nalagarh"
            style={{ width: "200px" }}
          />
        </div>
        <div>
          <h3 style={{ marginBottom: "10px" }}>Month Filter</h3>
          <div style={{ display: "flex", gap: "10px" }}>
            <Calendar
              value={fromMonth}
              onChange={(e) => setFromMonth(e.value)}
              view="month"
              dateFormat="mm/yy"
              placeholder="From"
              style={{ width: "100px" }}
            />
            <Calendar
              value={toMonth}
              onChange={(e) => setToMonth(e.value)}
              view="month"
              dateFormat="mm/yy"
              placeholder="To"
              style={{ width: "100px" }}
            />
          </div>
        </div>
      </div>

      {/* Charts Grid */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
        }}
      >
        {Object.keys(colors).map((key, index) => (
          <div key={index}>
            <h4 style={{ textAlign: "center", marginBottom: "10px" }}>{key}</h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={incidentData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Bar dataKey={key} fill={colors[key]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncidentCharts;