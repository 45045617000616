import React, { useState, useEffect } from "react";
import ListBox from "./ListBox";
import cogoToast from 'cogo-toast';
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import Swal from "sweetalert2";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
const deletePopup = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-light'
    },
    buttonsStyling: false
})

const singlePopup = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',

    },
    buttonsStyling: false
})

const secondaryPopup = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light'
    },
    buttonsStyling: false
})

const ThreeTier = () => {

    const [selectedCollection, setSelectedCollection] = useState(null);

    const [tier1, setTier1] = useState([]);
    const [selectedTier1, setSelectedTier1] = useState({ id: '' });
    const [selectedTier2, setSelectedTier2] = useState({ id: '' });
    const [selectedTier3, setSelectedTier3] = useState({ id: '' });
    const [collectionList, setCollectionList] = useState([]);

    const [modal, setModal] = useState(false)

    useEffect(() => { getGMS1() }, [])

    useEffect(() => { getCollection() }, [])


    const getCollection = async () => {

        let uriString = {
            where: {
                suffix: "SRF"
            }
        };

        APIServices.get(API.SRF + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`).then((res) => {
            setCollectionList(res.data); // Store response in state

        })
    }

    const getGMS1 = async () => {


        APIServices.get(API.Q_Categories).then((res) => {
            const tier1 = res.data;
            setTier1(tier1);
        })

    }


    const handleTier1Select = (id) => {
        setSelectedTier1(tier1.find(i => i.id === id))
    }

    const handleTier2Select = (id) => {
        setSelectedTier2(tier2.find(i => i.id === id))
    }
    const handleTier3Select = (id) => {
        setSelectedTier3(tier3.find(i => i.id === id))
    }

    const createTier1 = async (value) => {
        const response = await APIServices.post(API.Q_Categories, { name: value })
        if (response.status === 200) {
            const createdTier1 = response.data;
            setTier1((prev) => [...prev, createdTier1]);
            cogoToast.info('Created!', { position: 'top-right' })

        }

    }

    const getTier1Tier2 = async () => {
        const response = await APIServices.get(API.Q_Categories_Topics(selectedTier1.id));
        if (response.status === 200) {
            const tier2 = response.data;
            setTier2(tier2);

        }
    }

    const [tier2, setTier2] = useState([]);


    useEffect(() => {
        if (selectedTier1.id !== '')
            getTier1Tier2();
        setSelectedTier2({ id: '' });

    }, [selectedTier1.id])



    const createTier2 = async (value) => {
        const response = await APIServices.post(API.Q_Categories_Topics(selectedTier1.id), { name: value })
        if (response.status === 200) {
            const createdTier2 = response.data;
            setTier2((prev) => [...prev, createdTier2]);
            cogoToast.info('Created!', { position: 'top-right' })
        }
    }



    const [tier3, setTier3] = useState([]);


    useEffect(() => {
        if (selectedTier2.id !== '')
            getTier2Tier3();


    }, [selectedTier2.id])

    // useEffect(() => {
    //     if (selectedTier3.id !== '')
    //         getTier2Tier3()


    // }, [selectedTier3.id])

    const getTier2Tier3 = async () => {
        const response = await APIServices.get(API.Q_Topics_Section(selectedTier2.id));

        if (response.status === 200) {
            const tier3 = response.data;
            setTier3(tier3);
        }
    }

    const createTier3 = async (value) => {
        const response = await APIServices.post(API.Q_Topics_Section(selectedTier2.id), { name: value })
        if (response.status === 200) {
            const createdTier3 = response.data;
            setTier3((prev) => [...prev, createdTier3]);
            cogoToast.info('Created!', { position: 'top-right' })
        }
    }

    const onHandleCurate = (mode, id) => {

        // console.log(id, mode)
        setModal(true)

    }


    const handleDeleteItem = async (mode, id) => {

        deletePopup.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            reverseButtons: true,

            confirmButtonText: 'Delete'
        }).then(async (result) => {
            if (result.isConfirmed) {
                //   deleteChecklist(id);

                const response = await APIServices.delete(API.EDIT_AUDIT_GMS_URL(mode, id))

                if (response.status === 204) {
                    switch (mode) {
                        case 'tier1':
                            setTier1(prev => prev.filter(i => i.id !== id))
                            setSelectedTier1({ id: '' });
                            setSelectedTier2({ id: '' });
                            break;
                        case 'tier2':
                            setTier2(prev => prev.filter(i => i.id !== id))
                            setSelectedTier2({ id: '' });

                        case 'tier3':
                            setTier3(prev => prev.filter(i => i.id !== id))
                            break;


                        default: break;
                    }
                    singlePopup.fire(
                        'Deleted!',
                        '',
                        'success'
                    );
                }

            }
        })

    }

    const handleEditItem = async (id, name, mode) => {
        const response = await APIServices.patch(API.EDIT_AUDIT_GMS_URL(mode, id), { name: name })
        if (response.status === 204) {
            cogoToast.success('Updated!')
        }
    }

    const updateSRFId = async () => {
        const response = await APIServices.patch(API.EDIT_AUDIT_GMS_URL('tier3', selectedTier3.id), { srfId: selectedCollection })
        if (response.status === 204) {
            cogoToast.success('Updated!')
            getTier2Tier3()
        }
    }

    return (
        <>
            <div className="">
                <div className="col-lg-12 p-0">

                    <h4 className="card-title mb-3">Qualitative Configure </h4>

                    <>
                        <div className="row">
                            <div className="col-4 p-1 ps-3">
                                <ListBox handleDeleteItem={handleDeleteItem} handleEditItem={(id, name) => handleEditItem(id, name, 'tier1')} selected={'true'} title={'Categories'} onHandleCreateItem={createTier1} lists={tier1} handleSelect={handleTier1Select} selectedItem={selectedTier1} mode='tier1' />
                            </div>
                            <div className="col-4 p-1">
                                <ListBox handleDeleteItem={handleDeleteItem} handleEditItem={(id, name) => handleEditItem(id, name, 'tier2')} title={'Topics'} onHandleCreateItem={createTier2} lists={tier2} selected={selectedTier1.id !== ''} handleSelect={handleTier2Select} selectedItem={selectedTier2} mode='tier2' />
                            </div>
                            <div className="col-4 p-1">
                                <ListBox handleDeleteItem={handleDeleteItem} handleEditItem={(id, name) => handleEditItem(id, name, 'tier3')} title={'Section'} onHandleCreateItem={createTier3} lists={tier3} selected={selectedTier2.id !== ''} handleSelect={handleTier3Select} selectedItem={selectedTier3} mode='tier3' onHandleCurate={onHandleCurate} />
                            </div>

                        </div>
                    </>

                </div>
            </div>
            <Dialog
                header={`Select SRF`}
                visible={modal}
                style={{ width: '70vw' }}
                modal
                onHide={() => setModal(false)}
                footer={
                    <div className="d-flex justify-content-end">
                        <Button
                            label="Cancel"
                            className="p-button-text me-2"
                            onClick={() => setModal(false)}
                        />
                        <Button
                            label="Submit"
                            onClick={() => {
                                if (!selectedCollection) {
                                    cogoToast.warn('Please select a collection!');
                                    return;
                                }
                                console.log('Selected Collection ID:', selectedCollection);
                                updateSRFId()
                                cogoToast.success('Collection submitted!');
                                setModal(false);
                            }}
                        />
                    </div>
                }
            >
                <div className="p-fluid">

                    <div className="field">
                        <label htmlFor="collection">Select Collection</label>
                        <Dropdown
                            id="collection"
                            value={selectedCollection}
                            onChange={(e) => setSelectedCollection(e.value)}
                            options={collectionList}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select Collection"
                            filter
                        />
                    </div>

                </div>
            </Dialog>

        </>
    );

}

export default ThreeTier;