import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const ESG_Sales_Service = ({ data }) => {
  console.log(data);

  const maxValues = {
    Environment: 40,
    Social: 40,
    Governance: 15,
    General: 5,
  };

  const extractScores = (criteriaData) => [
    {
      category: "Environment",
      avgValue: criteriaData.environment.summary_score || 0,
      maxValue: maxValues.Environment,
      remainingToMax:
        maxValues.Environment - (criteriaData.environment.summary_score || 0),
      achievedColor: "#2C7C69",
      maxColor: "#7FC8A9",
    },
    {
      category: "Social",
      avgValue: criteriaData.social.summary_score || 0,
      maxValue: maxValues.Social,
      remainingToMax:
        maxValues.Social - (criteriaData.social.summary_score || 0),
      achievedColor: "#FC6E51",
      maxColor: "#FEB2A8",
    },
    {
      category: "Governance",
      avgValue: criteriaData.governance.summary_score || 0,
      maxValue: maxValues.Governance,
      remainingToMax:
        maxValues.Governance - (criteriaData.governance.summary_score || 0),
      achievedColor: "#4A90E2",
      maxColor: "#AFCBFF",
    },
    {
      category: "General",
      avgValue: criteriaData.general.summary_score || 0,
      maxValue: maxValues.General,
      remainingToMax:
        maxValues.General - (criteriaData.general.summary_score || 0),
      achievedColor: "#b0b0b0",
      maxColor: "#e3e3e3",
    },
  ];

  const salesData = extractScores(data.sales_criteria);
  const serviceData = extractScores(data.service_criteria);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: 500,
        marginTop:'50px'
      }}
    >
      {/* Sales Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Sales - ESG Score
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={salesData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, 40]} />
            <Tooltip
              formatter={(value, name) => [`${value.toFixed(2)}`, name]}
            />
            <Bar
              dataKey="avgValue"
              stackId="score"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            >
              {salesData.map((entry, index) => (
                <Cell
                  key={`cell-achieved-${index}`}
                  fill={entry.achievedColor}
                />
              ))}
            </Bar>
            <Bar dataKey="remainingToMax" stackId="score" name="Remaining">
              {salesData.map((entry, index) => (
                <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Service Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Service - ESG Score
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={serviceData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, 40]} />
            <Tooltip
              formatter={(value, name) => [`${value.toFixed(2)}`, name]}
            />
            <Bar
              dataKey="avgValue"
              stackId="score"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            >
              {serviceData.map((entry, index) => (
                <Cell
                  key={`cell-achieved-${index}`}
                  fill={entry.achievedColor}
                />
              ))}
            </Bar>
            <Bar dataKey="remainingToMax" stackId="score" name="Remaining">
              {serviceData.map((entry, index) => (
                <Cell key={`cell-remaining-${index}`} fill={entry.maxColor} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ESG_Sales_Service;
