import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = {
  Hosur: [
    {
      year: "FY-2023",
      renewable: 54264405.5,
      nonRenewable: 4268184.3,
    },
    {
      year: "FY-2024",
      renewable: 0,
      nonRenewable: 0,
    },
  ],
  Mysore: [
    {
      year: "FY-2023",
      renewable: 23748353.0,
      nonRenewable: 853304.0,
    },
    {
      year: "FY-2024",
      renewable: 0,
      nonRenewable: 0,
    },
  ],
  Nalagarh: [
    {
      year: "FY-2023",
      renewable: 7218677.0,
      nonRenewable: 0,
    },
    {
      year: "FY-2024",
      renewable: 0,
      nonRenewable: 0,
    },
  ],
  India: [
    {
      year: "FY-2023",
      renewable: 85231435.5,
      nonRenewable: 6187486.3,
    },
    {
      year: "FY-2024",
      renewable: 0,
      nonRenewable: 0,
    },
  ],
  Indonesia: [
    {
      year: "FY-2023",
      renewable: 0,
      nonRenewable: 1640097.0,
    },
    {
      year: "FY-2024",
      renewable: 0,
      nonRenewable: 0,
    },
  ],
  "United Kingdom": [
    {
      year: "FY-2023",
      renewable: 0,
      nonRenewable: 889749.0,
    },
    {
      year: "FY-2024",
      renewable: 0,
      nonRenewable: 0,
    },
  ],
  Global: [
    {
      year: "FY-2023",
      renewable: 0,
      nonRenewable: 0,
    },
    {
      year: "FY-2024",
      renewable: 0,
      nonRenewable: 0,
    },
  ],
};

const RenewableLocation = () => {
  const [location, setLocation] = useState("Hosur");

  return (
    <div
      style={{
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19.2px",
        textAlign: "left",
        margin: "18px 10px",
      }}
    >
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Renewable and Non-Renewable Energy Consumption by Location
      </h3>
      <div style={{ fontWeight: 200, fontSize: "14px", marginBottom: "10px" }}>
        Select a location to view the renewable and non-renewable energy
        consumption data.
      </div>
      {/* Location Selection Buttons */}
      <div style={{ marginBottom: "20px" }}>
        {Object.keys(data).map((locationName) => (
          <button
            key={locationName}
            onClick={() => setLocation(locationName)}
            style={{
              padding: "8px 16px",
              backgroundColor: location === locationName ? "#8888" : "#F0F0F0",
              color: location === locationName ? "white" : "gray",
              border: "none",
              borderRadius: "8px",
              height: "2.5rem",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            {locationName}
          </button>
        ))}
      </div>
      {/* Responsive Container for Recharts */}
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data[location]}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />

          <YAxis>
            <Label
              value="Energy Consumption (GJ)"
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: "middle",
                fill: "#333",
                fontSize: "14px",
              }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />
          {/* Renewable Energy Bar */}
          <Bar
            dataKey="renewable"
            name="Renewable Energy"
            fill="#47CC"
            barSize={60}
          />
          {/* Non-Renewable Energy Bar */}
          <Bar
            dataKey="nonRenewable"
            name="Non-Renewable Energy"
            fill="#88CC"
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RenewableLocation;
