import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext"; // Import InputText for search

const SupplierTable = ({ supplyData }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filterOption, setFilterOption] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [categories, setCategories] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(""); // State for search input

  useEffect(() => {
    if (supplyData?.length > 0) {
      // Extract unique categories dynamically
      const uniqueCategories = [
        "All",
        ...new Set(supplyData.map((item) => item.category)),
      ];
      setCategories(uniqueCategories);
    }
  }, [supplyData]);

  useEffect(() => {
    let sortedData = [...supplyData];

    // Apply Category Filter First
    if (selectedCategory !== "All") {
      sortedData = sortedData.filter(
        (item) => item.category === selectedCategory
      );
    }

    // Sort by MSI Score (Descending)
    sortedData.sort((a, b) => b.msi_score - a.msi_score);

    // Apply Top/Bottom Sorting
    switch (filterOption) {
      case "Top 5":
        setFilteredData(sortedData.slice(0, 5));
        break;
      case "Top 10":
        setFilteredData(sortedData.slice(0, 10));
        break;
      case "Top 15":
        setFilteredData(sortedData.slice(0, 15));
        break;
      case "Bottom 5":
        setFilteredData(sortedData.slice(-5));
        break;
      case "Bottom 10":
        setFilteredData(sortedData.slice(-10));
        break;
      case "Bottom 15":
        setFilteredData(sortedData.slice(-15));
        break;
      default:
        setFilteredData(sortedData);
        break;
    }
  }, [supplyData, filterOption, selectedCategory]);

  const RowFilterTemplate = (options, obj) => {
    return (
      <MultiSelect
        value={options.value}
        options={Array.from(new Set(filteredData?.map((i) => i[obj])))}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  return (
    <div className="container mt-4 ms-0">
      <h5 className="text-dark">Suppliers</h5>

      <div className="mb-3 d-flex gap-3">
        {/* 🔍 Search Box for Supplier Name & Vendor Code */}
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search by Supplier Name or Vendor Code"
            style={{ width: "300px" }}
          />
        </span>

        {/* Top/Bottom Filter Dropdown */}
        <Dropdown
          value={filterOption}
          options={[
            "All",
            "Top 5",
            "Top 10",
            "Top 15",
            "Bottom 5",
            "Bottom 10",
            "Bottom 15",
          ]}
          onChange={(e) => setFilterOption(e.value)}
          placeholder="Select Filter"
          className="w-25"
        />
      </div>

      <DataTable
        value={filteredData}
        paginator
        rows={10}
        globalFilter={globalFilter} // 🔍 Enable Global Filtering
        responsiveLayout="scroll"
      >
        <Column field="company_name" header="Supplier Name" sortable filter />
        <Column field="vendor_code" header="Vendor Code" sortable filter />
        <Column
          field="category"
          header="Category"
          filter
          showFilterMatchModes={false}
          filterElement={(options) => RowFilterTemplate(options, "category")}
        />
        <Column field="msi_score" header="MSI Score" sortable />
        <Column field="rank" header="Overall Rank" sortable />
      </DataTable>
    </div>
  );
};

export default SupplierTable;
