import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

const FilterComponent = ({ data, setFilteredData, tabIndex }) => {
  console.log(tabIndex, "INDEX");
  const [filters, setFilters] = useState({
    zone: "All",
    city: "All",
    branch_code: "All",
    area_office: "All",
    start_date_of_calibration: null,
    end_date_of_calibration: null,
  });
  useEffect(() => {
    applyFilters();
  }, [filters]);

  const handleChange = (key, value) => {
    setFilters((prev) => {
      let newFilters = { ...prev, [key]: value };

      // Reset dependent fields when a higher-level filter changes
      if (key === "zone") {
        newFilters.city = "All";
        newFilters.branch_code = "All";
      }
      if (key === "city") {
        newFilters.branch_code = "All";
      }

      return newFilters;
    });
  };

  const applyFilters = () => {
    let filtered = data;

    if (filters.zone !== "All") {
      filtered = filtered.filter((item) => item.zone === filters.zone);
    }
    if (filters.city !== "All") {
      filtered = filtered.filter((item) => item.city === filters.city);
    }
    if (filters.branch_code !== "All") {
      const selectedBranchCode = filters.branch_code.split("-").pop().trim();

      filtered = filtered.filter((item) => {
        const itemBranchCode = String(item.branch_code || "").trim();
        const itemDealerName = String(item.dealer_name || "").trim();

        return itemBranchCode
          ? itemBranchCode === selectedBranchCode
          : itemDealerName === filters.branch_code.split("-")[0].trim();
      });
    }
    if (filters.area_office !== "All") {
      if (tabIndex !== 2) {
        filtered = filtered.filter(
          (item) => item.area_office === filters.area_office
        );
      } else {
        if (filters.area_office !== "All") {
          // Extract everything except the last part (assuming last part is branch code)
          const selectedAreaOffice = filters.area_office
            .split(" - ")
            .slice(0, -1)
            .join(" - ")
            .trim();

          filtered = filtered.filter((item) => {
            const itemAreaOffice = item.area_office
              ? item.area_office.trim()
              : "";
            return itemAreaOffice === selectedAreaOffice;
          });
        }
      }
    }

    const startDate = filters.start_date_of_calibration
      ? new Date(filters.start_date_of_calibration).setHours(0, 0, 0, 0)
      : null;
    const endDate = filters.end_date_of_calibration
      ? new Date(filters.end_date_of_calibration).setHours(23, 59, 59, 999)
      : null;

    if (startDate || endDate) {
      filtered = filtered.filter((item) => {
        const itemDate = new Date(item.date_of_calibration).setHours(
          0,
          0,
          0,
          0
        );
        return (
          (!startDate || itemDate >= startDate) &&
          (!endDate || itemDate <= endDate)
        );
      });
    }

    setFilteredData(filtered);
  };

  const getAreaOfficeOptions = () => {
    let filteredData = data;

    let uniqueAreaOffices = new Set(
      filteredData
        .map(
          (item) =>
            `${item.area_office ? item.area_office : ""} - ${
              item.branch_code ? item.branch_code : ""
            }`
        )
        .filter((value) => value.trim() !== "-") // Avoid empty values
    );

    return [
      { label: "All", value: "All" },
      ...Array.from(uniqueAreaOffices).map((value) => ({
        label: value,
        value: value,
      })),
    ];
  };

  // Get unique options dynamically based on selected filters
  const getUniqueOptions = (key, filterBy = null) => {
    let filteredData = data;

    if (filterBy === "zone" && filters.zone !== "All") {
      filteredData = filteredData.filter((item) => item.zone === filters.zone);
    }
    if (filterBy === "city" && filters.city !== "All") {
      filteredData = filteredData.filter((item) => item.city === filters.city);
    }

    let uniqueValues = new Set(
      filteredData
        .map((item) => item[key])
        .filter(
          (value) => value !== undefined && value !== null && value !== ""
        )
    );

    return [
      { label: "All", value: "All" },
      ...Array.from(uniqueValues).map((value) => ({
        label: String(value),
        value: String(value),
      })),
    ];
  };

  // Get unique options for branch_code in "dealer_name - branch_code" format
  const getBranchCodeOptions = () => {
    let filteredData = data;
    if (filters.city !== "All") {
      filteredData = filteredData.filter((item) => item.city === filters.city);
    }

    let uniqueBranchCodes = new Set(
      filteredData
        .map(
          (item) =>
            `${item.dealer_name ? item.dealer_name : ""} - ${
              item.branch_code ? item.branch_code : ""
            }`
        )
        .filter(
          (value) => value !== undefined && value !== null && value !== ""
        )
    );

    return [
      { label: "All", value: "All" },
      ...Array.from(uniqueBranchCodes).map((value) => ({
        label: value,
        value: value,
      })),
    ];
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      {/* Zone Dropdown */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>Zone</label>
        <Dropdown
          value={filters.zone}
          options={getUniqueOptions("zone")}
          onChange={(e) => handleChange("zone", e.value)}
          placeholder="Select Zone"
          className="w-100"
          filter
        />
      </div>

      {/* City Dropdown - Dependent on Zone */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>City</label>
        <Dropdown
          value={filters.city}
          options={getUniqueOptions("city", "zone")}
          onChange={(e) => handleChange("city", e.value)}
          placeholder="Select City"
          className="w-100"
          filter
        />
      </div>

      {/* Dealer Dropdown - Dependent on City */}
      {tabIndex !== 2 && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Dealer Name and Code</label>
          <Dropdown
            value={filters.branch_code}
            options={getBranchCodeOptions()}
            onChange={(e) => handleChange("branch_code", e.value)}
            placeholder="Select Dealer Code"
            className="w-100"
            filter
          />
        </div>
      )}

      {/* Area Office Dropdown */}
      {tabIndex !== 2 && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Area Office</label>
          <Dropdown
            value={filters.area_office}
            options={getUniqueOptions("area_office")}
            onChange={(e) => handleChange("area_office", e.value)}
            placeholder="Select Area Office"
            className="w-100"
            filter
          />
        </div>
      )}

      {tabIndex === 2 && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Area Office and Code</label>
          <Dropdown
            value={filters.area_office}
            options={getAreaOfficeOptions()}
            onChange={(e) => handleChange("area_office", e.value)}
            placeholder="Select Area Office"
            className="w-100"
            filter
          />
        </div>
      )}

      {/* Start Date Calendar */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>From</label>
        <Calendar
          value={filters.start_date_of_calibration}
          onChange={(e) => handleChange("start_date_of_calibration", e.value)}
          placeholder="Start Date"
          showButtonBar
          showIcon
        />
      </div>

      {/* End Date Calendar */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>To</label>
        <Calendar
          value={filters.end_date_of_calibration}
          onChange={(e) => handleChange("end_date_of_calibration", e.value)}
          placeholder="End Date"
          showButtonBar
          showIcon
          minDate={filters.start_date_of_calibration}
          disabled={!filters.start_date_of_calibration}
        />
      </div>
    </div>
  );
};

export default FilterComponent;
