import React, { useEffect, useState } from "react";
import useForceUpdate from "use-force-update";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API } from "../constants/api_url";
import { DateTime } from "luxon";
import moment from "moment";
import APIServices from "../service/APIService";
const Assurance = () => {

    const forceUpdate = useForceUpdate()
    const selector = useSelector((state) => state.user.userdetail);
    const locationList = useSelector(state => state.sitelist.locationList)
    const siteList = useSelector((state) => state.sitelist.siteList)
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)

    const [data, setData] = useState([])
    const [year, setYear] = useState([])
    const [response, setResponse] = useState([])
    const [dcfdata, setDCFData] = useState([])
    const [dcflisitingmodal, setDCFListingModal] = useState(false)

    const [dpresponse, setDPResponse] = useState([])
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const getStandaloneIds = (met, metric) => {
        let ids = []
        const collectStandaloneIds = (met, ids) => {

            for (const item of met) {
                console.log(item)
                if (item.data1[0].type === 0 && item.data1[0].source === 0) {
                    // Recur for the indicator array if available
                    if (Array.isArray(item.data1[0].indicator)) {
                        let ind = []
                        item.data1[0].indicator.forEach((id) => {
                            let index = metric.findIndex((k) => { return k.id === id })
                            if (index !== -1) {
                                ind.push(metric.find((k) => { return k.id === id }))
                            }
                        })
                        console.log(item)
                        collectStandaloneIds(ind, ids);
                    }
                } else if (item.data1[0].type === 0 && item.data1[0].source === 1) {

                    ids.push(item.id);
                }
            }
        };
        collectStandaloneIds([met], ids)
        return ids
    }
    const checkSite = (id, filter) => {
        let idlist = []

        siteList.forEach((country) => {

            if (filter.a.id === 0 || filter.a.id === country.id) {

                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id)
                            }
                        })
                    }
                })
            }
        })

        return idlist.includes(id)
    }
    const checkReportingPeriod = (rp, filter) => {
        console.log(filter)
        let count = 0, rps = []
        filter.forEach((item) => {

            if (rp.includes(item)) {
                count = count + 1
                rps.push(item)
            }
        })
        console.log(rp, count, filter)
        return { result: count === rp.length, count: count, rps }
    }
    const groupArrayObject = (array, obj) => {
        return array.reduce(
            (group, arr) => {
                let key = arr[obj];

                group[key] = group[key] ?? [];

                group[key].push(arr);

                return group;
            },

            {}
        );
    };
    const getRP_ = (rp) => {
        if (rp.length === 1) {

            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2)
        } else {
            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2) + " to " + months_[parseInt(rp[rp.length - 1].split('-')[0]) - 1] + "-" + rp[rp.length - 1].split('-')[1].slice(-2)
        }
    }
    function getDateObjectByMonth_Year(month, year) {

        if (isNaN(month) || isNaN(year)) {
            throw new Error('Invalid month or year');
        }

        const normalizedMonth = Math.max(1, Math.min(12, month));

        const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

        return date.toJSDate();
    }
    useEffect(() => {

        var date = DateTime.fromISO(selector.information.startdate, { zone: 'utc' }).startOf('month')
        let endDate__ = DateTime.utc()
        let betweenYears = []
        while (date <= endDate__) {

            betweenYears.push(date.toFormat('yyyy'));
            date = date.plus({ year: 1 })

        }
        console.log(betweenYears, selector.information.startdate)
        setYear(betweenYears)
        let metricString = {
            "include": [{ "relation": "newDataPoints" }]

        }
        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.DCF_Submit_UP(selector.id))
        const promise3 = APIServices.get(API.LocationThree)
        const promise4 = APIServices.get(API.AssignDCFUser_UP(selector.id))
        const promise5 = APIServices.get(API.Metric + `?filter=${encodeURIComponent(JSON.stringify(metricString))}`)
        const promise6 = APIServices.get(API.AssignDCFClient_UP(selector.id))
        const promise7 = APIServices.get(API.DP_report_UP(selector.id))

        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7]).then((values) => {
            let indicators = [], val = [], filterarr = groupArrayObject(values[6].data, 'submitId')
            Object.keys(filterarr).forEach((item) => {

                val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], 'form_id')) })

            })
            console.log(val, 'val')
            values[1].data.forEach((i) => {
                let siteindex = locationList.findIndex((j) => { return j.id === i.site })
                let dcfindex = values[0].data.findIndex((j) => { return j.id === i.dcf })
                if (dcfindex === -1) {
                    i.dcf = 'DCF Deleted'
                } else {
                    i.dcf = values[0].data[dcfindex].title
                }
                if (siteindex === -1) {
                    i.site = 'Site Deleted'
                } else {
                    i.site = locationList[siteindex].title
                }
                i.rp_ = getRP_(i.reporting_period)
            })
            setResponse(values[1].data)
            setDPResponse(val)
            let filtered_ind = []
            values[5].data[0].metric_ids.forEach((id) => {
                let dcf = []
                let index = values[4].data.findIndex((x) => x.id === id)
                if (index !== -1) {
                    filtered_ind.push(values[4].data[index])
                    let loc = JSON.parse(JSON.stringify(values[4].data[index]))
                    if (loc.data1.length !== 0 && loc.data1[0].type === 0 && loc.data1[0].source === 0) {
                        let dcf_ids = []
                        getStandaloneIds(loc, values[4].data).forEach((met_id) => {
                            let find = values[4].data.findIndex((l) => { return l.id === met_id })
                            if (find !== -1 && values[4].data[find].newDataPoints !== undefined) {

                                values[4].data[find].newDataPoints.forEach((dp) => {
                                    if (Array.isArray(dp.data1)) {
                                        !dcf_ids.includes(dp.data1[0].datasource) && dcf_ids.push(dp.data1[0].datasource)
                                    }
                                })
                            }
                        })
                        betweenYears.forEach((yr) => {
                            loc[yr] = getDataByIndicator(dcf_ids, yr, val)
                        })
                        loc.dcf = dcf_ids
                        delete loc.newDataPoints

                        indicators.push(loc)
                    } else if (loc.newDataPoints !== undefined && loc.data1.length !== 0 && loc.data1[0].type === 0 && loc.data1[0].source === 1) {
                        let dcf_ids = []
                        loc.newDataPoints.forEach((dp) => {
                            if (Array.isArray(dp.data1)) {
                                !dcf_ids.includes(dp.data1[0].datasource) && dcf_ids.push(dp.data1[0].datasource)
                            }
                        })
                        betweenYears.forEach((yr) => {
                            loc[yr] = getDataByIndicator(dcf_ids, yr, val)
                        })
                        loc.dcf = dcf_ids
                        delete loc.newDataPoints

                        indicators.push(loc)
                    }

                }
            })

            let ind_obj = []
            let indicator_list = values[4].data
            indicatorLevel1(filtered_ind, values[4].data, betweenYears, val).forEach((i) => {
               i.indicator = i.title
                i.data1[0].indicator.forEach((j) => {
                    let indexdcf = indicator_list.findIndex(a => a.id === j)
                    if (indexdcf !== -1) {
                        ind_obj.push({...i,...indicator_list[indexdcf]})
                    }
                })
            })
            console.log(ind_obj,indicatorLevel1(filtered_ind, values[4].data, betweenYears, val))
            setData( ind_obj)


        })

    }, [])
    const indicatorLevel1 = (data, metric_list, betweenYears, val) => {
        const standaloneItems = [];

        let derived = data.filter(i => Array.isArray(i.data1) && i.data1[0].source === 0)
        derived.forEach((i, index) => {
            let count = 0
            let dcf_ids = []

            if (Array.isArray(i.data1)) {

                i.data1[0].indicator.forEach((j) => {
                    count += 1
                    let index = data.findIndex(k => k.id === j)
                    let index2 = metric_list.findIndex(k => k.id === j)
                    if (index !== -1 && index2 !== -1 && Array.isArray(data[index].data1)) {
                        if (data[index].data1[0].source === 1) {

                            if (metric_list[index2].newDataPoints !== undefined) {
                                metric_list[index2].newDataPoints.forEach((m) => {
                                    if (Array.isArray(m.data1)) {
                                        !dcf_ids.includes(m.data1[0].datasource) && dcf_ids.push(m.data1[0].datasource)
                                    }
                                })
                            }
                            count -= 1
                        }
                    } else {
                        count -= 1
                    }

                })
                if (count === 0) {
                    betweenYears.forEach((yr) => {
                        i[yr] = getDataByIndicator(dcf_ids, yr, val)
                    })
                    i.dcf = dcf_ids
                    standaloneItems.push(i)
                }
            }


        })
        return standaloneItems;
    };


    const renderData = (search) => {
        let betweenMonths = [], betweenYears = []
        let monthly_fg = [], submitIds = []


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')


        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(dpresponse))

            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0
                console.log(report)
                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && search.dcf.includes(16) && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {
                                !submitIds.includes(report.id) && submitIds.push(report.id)

                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && search.dcf.includes(36) && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {

                                !submitIds.includes(report.id) && submitIds.push(report.id)


                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && search.dcf.includes(11) && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            !submitIds.includes(report.id) && submitIds.push(report.id)
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && search.dcf.includes(10) && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {
                                !submitIds.includes(report.id) && submitIds.push(report.id)





                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && search.dcf.includes(72) && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                !submitIds.includes(report.id) && submitIds.push(report.id)
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && search.dcf.includes(15) && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            !submitIds.includes(report.id) && submitIds.push(report.id)
                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })







        }
        return { data: monthly_fg, id: submitIds }


    }
    const getDataByIndicator = (indi_id, year) => {

        return year
    }
    const dataTemplate = (rowData, yr) => {
        let result = 0
        let data_ = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, dcf: rowData.dcf, from: getDateObjectByMonth_Year(0, parseInt(yr) + 1), to: getDateObjectByMonth_Year(12, parseInt(yr) + 1) })
        console.log(data_.id)
        if (data_.data.length !== 0) {
            result = (data_.data.reduce((a, b) => { return a + b.ghg }, 0)).toFixed(2)
        }
        return (<>
            <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                setDCFData(response.filter((l) => { return data_.id.includes(l.id) }))
                setDCFListingModal(true)

            }}> {result}</a>
        </>)
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <>
                        {selector.role === 'clientadmin' ?
                            <div>
                                <DataTable rowGroupMode="rowspan" groupRowsBy="indicator" sortMode="single" sortField="indicator" sortOrder={1} scrollable value={data}>
                             
                                <Column field="indicator" header="Indicator"  style={{ minWidth: '200px' }}></Column>
                                    <Column field='title' header='' />
                                    {year.map((yr) => {
                                        return <Column body={(rowData) => dataTemplate(rowData, yr)} header={yr} />
                                    })

                                    }
                                </DataTable>
                            </div> :
                            <div>
                                You have no rights to access this page
                            </div>
                        }
                    </>
                </div>
            </div>
            <Dialog
                visible={dcflisitingmodal}
                style={{
                    width: "65%",
                }}
                header="Associated DCF"
                modal
                className="p-fluid"

                onHide={() => { setDCFListingModal(false) }}
            >
                <div>
                    <DataTable scrollable value={dcfdata} >
                        <Column header="DCF#" field='dcf' />
                        <Column header="Site" field='site' />
                        <Column header="Reporting Period" field='rp_' />
                        <Column header="Submission" body={() => { return <> <i className="pi pi-eye"></i> </> }} />

                    </DataTable>
                </div>
            </Dialog>
        </div >
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Assurance, comparisonFn);