import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as XLSX from "xlsx";
import APIServices from "../../../service/APIService";
import { API } from "../../../constants/api_url";
import { useSelector } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { format } from "date-fns";
import pdfMake from 'pdfmake/build/pdfmake';
import Swal from "sweetalert2";

import XlsxPopulate from 'xlsx-populate';
const SupplierLCATable = () => {
    const login_data = useSelector((state) => state.user.userdetail);
    console.log(login_data)
    const [load, setLoad] = useState(true)
    const [globalFilter, setGlobalFilter] = useState("");
    const [supplier, setSupplier] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(null); // ✅ Holds clicked status details
    const [isDialogVisible, setIsDialogVisible] = useState(false); // ✅ Controls Dialog visibility


    // Define status colors
    const statusColors = {
        "Completed": "#28a745",   // Green
        "In Process": "#ff9800",  // Orange
        "Not Started": "grey", // Red
    };

    useEffect(() => {
        get_lca_submission();

    }, [])

    const get_lca_submission = () => {

        APIServices.get(API.LCA_dataSubmission).then((res) => {
            setLoad(false)
            setSupplier(res.data)
        })
    }
    // Supplier Data with Data Collection Status
    const suppliers = [
        {
            supplier: "Supplier A",
            vendorCode: "1001",
            partNumber: "500",
            description: "Id voluptates natus delectus et nemo non.",
            imdsId: "1001",
            lastUpdated: "15-Feb-2025",
            dataCollectionStatus: [
                { name: "Process", status: "Completed", isLocked: true },
                { name: "Energy Consumption", status: "In Process", isLocked: false },
                { name: "Social Process Material", status: "Not Started", isLocked: true },
                { name: "Waste", status: "Completed", isLocked: true },
                { name: "Transportation", status: "Completed", isLocked: false },
            ],
        },
        {
            supplier: "Supplier B",
            vendorCode: "1002",
            partNumber: "500",
            description: "Id voluptates natus delectus et nemo non.",
            imdsId: "1001",
            lastUpdated: "15-Feb-2025",
            dataCollectionStatus: [
                { name: "Process", status: "Completed", isLocked: false },
                { name: "Energy Consumption", status: "In Process", isLocked: false },
                { name: "Social Process Material", status: "Not Started", isLocked: true },
                { name: "Waste", status: "Not Started", isLocked: true },
                { name: "Transportation", status: "Completed", isLocked: false },
            ],
        },
    ];

    // Function to render status boxes horizontally inside the "Data Collection Status" column
    const renderDataCollectionStatus = (rowData) => {
        if (rowData.dataCollectionStatus === 'Pending') return null
        return (
            <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                {rowData.dataCollectionStatus.map((item, index) => (
                    <div key={index} style={{
                        width: "120px",
                        textAlign: "center",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        padding: "10px",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
                    }} onClick={() => handleStatusClick(item)} >
                        <div style={{
                            borderTop: `4px solid ${statusColors[item.status] || "grey"}`,
                            paddingTop: "5px",
                            fontSize: "12px",
                        }}>
                            {item.name}
                        </div>
                        {/* <div style={{ marginTop: "5px" }}>
                            {item.status}
                        </div> */}
                        {item.isLocked && <i className="pi pi-lock" style={{ color: "#888", marginTop: "5px" }}></i>}
                    </div>
                ))}
            </div>
        );
    };

    const handleStatusClick = (status) => {
        setSelectedStatus(status);
        setIsDialogVisible(true);
    };
    const formatDate = (rowData) => {
        return rowData.lastUpdateDate
            ? format(new Date(rowData.lastUpdateDate), "dd-MM-yyyy") // Format as DD-MM-YYYY
            : "-"; // If date is null, show "-"
    };
    const generatePDF = (rowData, supplierName) => {
        Swal.fire({
            title: 'Please Wait !',
            html: `Generating report of ${rowData?.partNumber} from ${supplierName || 'Supplier'}...`,// add html attribute if you want or remove
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });

        try {
            const content = [];
            const data = JSON.parse(rowData?.data || '[]');

            // Add header with logo
            content.push({
                columns: [
                    {
                        text: `LCA Report of ${rowData.partNumber} from ${supplierName || 'Supplier'}`,
                        style: 'title',
                        alignment: 'center',
                        margin: [0, 0, 0, 10]
                    },
                    {
                        image: 'clientlogo', // Pass logo as base64 or file path
                        width: 60,
                        margin: [0, 0, 10, 0]
                    }
                ]
            });

            content.push({
                text: 'IMDS ID',
                style: 'descriptionTitle',
                margin: [0, 5, 0, 2]
            });
            content.push({
                text: rowData?.imdsId || '-',
                style: 'description',
                margin: [0, 0, 0, 10]
            });

            // Add description section
            if (rowData.description) {
                content.push({
                    text: 'Description',
                    style: 'descriptionTitle',
                    margin: [0, 5, 0, 2]
                });
                content.push({
                    text: rowData.description,
                    style: 'description',
                    margin: [0, 0, 0, 10]
                });
            }

            data.forEach(category => {

                const numColumns = category.categories[0]?.details[0]
                    ? Object.keys(category.categories[0].details[0]).length
                    : 2;
                const tableWidth = numColumns * 120;
                // Add categoryType as header with gradient background
                content.push({
                    canvas: [
                        {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 535,
                            h: 20,
                            color: '#315975'
                        },
                        {
                            type: 'rect',
                            x: 0,
                            y: 10,
                            w: 535,
                            h: 10,
                            color: '#4a6e82'
                        }
                    ]
                });

                content.push({
                    text: capitalizeFirstLetter(category.categoryType),
                    style: 'header',
                    margin: [0, -19, 0, 10],
                    alignment: 'center'
                });

                category.categories.forEach(subCategory => {
                    // Add subcategory as subheader
                    content.push({
                        text: capitalizeFirstLetter(subCategory.name),
                        style: 'subheader',
                        margin: [0, 5, 0, 5]
                    });

                    if (subCategory.details.length > 0) {
                        // Create table from details
                        const tableBody = [
                            Object.keys(subCategory.details[0]).map(key => ({
                                text: capitalizeFirstLetter(key),
                                style: 'tableHeader'
                            }))
                        ];

                        subCategory.details.forEach(detail => {
                            tableBody.push(
                                Object.values(detail).map(value => ({
                                    text: value !== null ? value.toString() : '',
                                    style: 'tableData'
                                }))
                            );
                        });

                        // Ensure table fits within page width by dynamically adjusting column widths
                        const numColumns = Object.keys(subCategory.details[0]).length;
                        const columnWidths = Array(numColumns).fill(`${100 / numColumns}%`);

                        content.push({
                            table: {
                                headerRows: 1,
                                widths: columnWidths,
                                body: tableBody
                            },
                            layout: {
                                fillColor: (rowIndex) =>
                                    rowIndex === 0 ? '#315975' : (rowIndex % 2 === 0 ? '#f9f9f9' : null) // Alternate row colors
                            },
                            margin: [0, 0, 0, 10]
                        });
                    }
                });
            });

            let images = {
                clientlogo: document.getElementById('clientlogo').src
            };

            const docDefinition = {
                info: {
                    title: `LCA Report_${rowData.partNumber}`,
                    author: 'Navigos',
                    subject: 'LCA Report',
                    keywords: 'Dont share unless people within same organization',
                    producer: 'EiSqr',
                },
                permissions: {
                    printing: 'highResolution', //'lowResolution'
                    modifying: false,
                    copying: false,
                    annotating: true,
                    fillingForms: true,
                    contentAccessibility: true,
                    documentAssembly: true
                },
                pageSize: 'A4',
                pageMargins: [30, 30, 30, 30],
                content,
                styles: {
                    title: {
                        fontSize: 18,
                        bold: true,
                        color: '#315975'
                    },
                    header: {
                        fontSize: 16,
                        bold: true,
                        color: '#ffffff',
                        alignment: 'center'
                    },
                    subheader: {
                        fontSize: 14,
                        bold: true,
                        color: '#4a6e82'
                    },
                    descriptionTitle: {
                        fontSize: 12,
                        bold: true,
                        color: '#315975',
                        margin: [0, 0, 0, 2]
                    },
                    description: {
                        fontSize: 12,
                        italics: true,
                        color: '#4a6e82',
                        margin: [0, 0, 0, 5]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 12,
                        color: '#ffffff',
                        fillColor: '#315975',
                        margin: [5, 5, 5, 5]
                    },
                    tableData: {
                        fontSize: 10,
                        color: 'black',
                        margin: [5, 5, 5, 5]
                    }
                },
                defaultStyle: {
                    fontSize: 10
                },
                footer: (currentPage, pageCount) => ({
                    text: `Page ${currentPage} of ${pageCount}`,
                    alignment: 'center',
                    margin: [0, 10, 0, 0],
                    fontSize: 10,
                    color: '#315975'
                }),
                images
            };


            pdfMake.createPdf(docDefinition).download(`LCA Report_${supplierName || 'Supplier'}_${rowData.partNumber}.pdf`);
        } catch {

        } finally {
            Swal.close()
        }
    };

    const exportExcel = async (rowData, supplierName) => {
        const wb = await XlsxPopulate.fromBlankAsync();
        const sheet = wb.sheet(0);

        const data = JSON.parse(rowData?.data || '[]');

        let row = 1;

        // ✅ Style for supplier info section
        const headerStyle = {
            bold: true,
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            fill: 'd9e1f2',
            border: true
        };

        const infoStyle = {
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            border: true
        };

        // ✅ Supplier Info
        const supplierInfo = [
            ['Supplier Name', supplierName],
            ['Part Number', rowData.partNumber],
            ['IMDS ID', rowData.imdsId],
            ['Description', rowData.description]
        ];

        supplierInfo.forEach(([key, value]) => {
            sheet.range(`A${row}:B${row}`).merged(true).value(key).style(headerStyle);
            sheet.cell(`C${row}`).value(value).style(infoStyle);
            row++;
        });

        row++; // Add extra row for spacing

        // ✅ Function to Capitalize Headers
        const capitalize = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase());

        // ✅ Function to Add Category Data
        const addCategoryData = (title, details) => {
            if (details.length > 0) {
                const headers = Object.keys(details[0]).map((key) =>
                    capitalize(key.replace(/([a-z])([A-Z])/g, '$1 $2'))
                );

                // ✅ Title and headers
                sheet.cell(`A${row}`).value(title).style({
                    bold: true,
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    fill: 'd9e1f2',
                    border: true
                });

                headers.forEach((header, i) => {
                    sheet.cell(row, i + 2).value(header).style({
                        bold: true,
                        fill: 'd9e1f2',
                        border: true,
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center'
                    });
                });

                // ✅ Fix Merge Issue: Merge at least 2 rows even if only 1 row of data exists
                const mergeEndRow = row + Math.max(details.length, 2) - 1;
                sheet.range(`A${row}:A${mergeEndRow}`).merged(true).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    wrapText: true,
                    border: true,
                    fill: 'b4c7dc'
                });

                row++;

                // ✅ Add Data
                details.forEach((detail) => {
                    headers.forEach((header, i) => {
                        const key = Object.keys(detail).find(
                            (k) => k.toLowerCase().replace(/\s+/g, '') === header.toLowerCase().replace(/\s+/g, '')
                        );
                        if (key) {
                            sheet.cell(row, i + 2).value(detail[key]).style({
                                border: true,
                                horizontalAlignment: 'center',
                                verticalAlignment: 'center',
                                wrapText: true
                            });
                        }
                    });
                    row++;
                });

                row++;
            }
        };

        // ✅ Process All Categories
        const processCategory = (type) => {
            const categoryData = data.find((d) => d.categoryType === type);
            if (categoryData) {
                // ✅ Add Category Header
                sheet.cell(`A${row}`).value(capitalize(type)).style({
                    bold: true,
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    fill: 'b4c7dc',
                    fontColor: '000000',
                    border: true
                });

                const maxCol = (categoryData.categories[0]?.details[0]
                    ? Object.keys(categoryData.categories[0]?.details[0]).length + 1
                    : 4);

                // ✅ Merge Category Header
                sheet.range(row, 1, row, maxCol).merged(true).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    wrapText: true,
                    border: true
                });

                row++;

                categoryData.categories.forEach((category) => {
                    addCategoryData(category.name, category.details);
                });
            }
        };

        // ✅ Call Categories
        ['process', 'energy', 'specialProcessMaterial', 'waste', 'transportation'].forEach(processCategory);

        // ✅ Auto-fit Columns
        sheet.usedRange().style({ wrapText: true });
        sheet.column("A").width(35);
        sheet.column("B").width(25);
        sheet.column("C").width(25);
        sheet.column("D").width(25);
        sheet.column("E").width(25);

        // ✅ Export File
        const fileBuffer = await wb.outputAsync();
        const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = supplierName + '_' + rowData.partNumber + '.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };
    



    // Capitalize First Letter Utility
    const capitalizeFirstLetter = (str) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderActionBodyTemplate = (rowData) => {
        console.log(rowData.dataCollectionStatus)
        const allCompleted = rowData.dataCollectionStatus !== 'Pending' ? rowData.dataCollectionStatus?.every(item => item.status === "Completed") : false;

        const onLockToggleClick = () => {
            console.log(rowData.lock ? "Unlocking" : "Locking", "for:", rowData);

            if (rowData.lock) {

                APIServices.patch(API.LCA_dataSubmission_id(rowData.id), {
                    lock: false
                }).then((res) => {

                    get_lca_submission();

                })

            } else {

                APIServices.patch(API.LCA_dataSubmission_id(rowData.id), {
                    lock: true
                }).then((res) => {

                    get_lca_submission();

                })

            }

        };

        return (
            <>
                <Button icon="pi pi-download" onClick={() => { exportExcel(rowData, rowData?.supplierInformation?.supplierName) }} className="p-button-text" />


                {allCompleted && (
                    <Button
                        icon={rowData.lock ? "pi pi-lock" : "pi pi-lock-open"}
                        className="p-button-text"
                        onClick={onLockToggleClick}
                        tooltip={rowData.lock ? "Locked" : "Unlocked"}
                    />
                )}

            </>

        )
    }

    return (
        <div className="p-container" style={{ padding: "20px" }}>
            {/* Header Section */}
            <div className="p-d-flex p-ai-center p-jc-between" style={{ marginBottom: "1rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <h2>Supplier LCA Data Collection Status</h2>

                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {/* Search Input */}
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            placeholder="Search"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            style={{ width: "200px" }}
                        />
                    </span>
                    {/* Report Button */}
                    <Button label="Report" icon="pi pi-download" className="p-button-outlined" />
                </div>
            </div>

            {/* Data Table */}
            <DataTable value={supplier} loading={load} className="h-500" paginator rows={5} globalFilter={globalFilter} responsiveLayout="scroll"
                style={{ border: "1px solid #ddd", borderRadius: "5px", overflow: "hidden" }}>
                <Column field="supplierInformation.supplierName" header="Supplier" sortable style={{ border: "1px solid #ddd" }} />
                <Column field="vendorCode" header="Vendor Code" sortable style={{ border: "1px solid #ddd" }} />
                <Column field="partNumber" header="Part Number" sortable style={{ border: "1px solid #ddd" }} />
                <Column field="description" header="Description" style={{ border: "1px solid #ddd" }} />
                <Column field="imdsId" header="IMDS ID" sortable style={{ border: "1px solid #ddd" }} />
                <Column field="lastUpdateDate" header="Last Updated Date" body={formatDate} sortable style={{ border: "1px solid #ddd" }} />

                {/* Data Collection Status as a single column with horizontal layout */}
                <Column header="Data Collection Status" body={renderDataCollectionStatus} style={{ textAlign: "center", border: "1px solid #ddd" }} />

                {/* Download button column */}
                <Column body={renderActionBodyTemplate}
                    style={{ textAlign: "center", width: "3rem", border: "1px solid #ddd" }} />
            </DataTable>


            <Dialog
                header="Data Collection Status Details"
                visible={isDialogVisible}
                style={{ width: "60vw" }}
                onHide={() => setIsDialogVisible(false)}
            >
                {selectedStatus ? (
                    <div>
                        <h3>{selectedStatus.name}</h3>
                        {/* <p><strong>Status:</strong> {selectedStatus.status}</p>
                        <p><strong>Locked:</strong> {selectedStatus.isLocked ? "Yes" : "No"}</p> */}

                        {/* ✅ Show structured details using Accordion */}
                        {selectedStatus.data && (
                            <Accordion>
                                {/* <AccordionTab header="Category Type">
                                    <p>{selectedStatus.data.categoryType}</p>
                                </AccordionTab> */}
                                {selectedStatus.data.categories.map((category, index) => (
                                    <AccordionTab key={index} header={category.name}>
                                        <table className="p-datatable p-component" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    {Object.keys(category.details[0]).map((key) => (
                                                        <th key={key} style={{ padding: "8px", borderBottom: "2px solid #ddd" }}>
                                                            {key.replace(/([A-Z])/g, " $1").trim()}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {category.details.map((detail, detailIndex) => (
                                                    <tr key={detailIndex}>
                                                        {Object.values(detail).map((value, valueIndex) => (
                                                            <td key={valueIndex} style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                                                                {value ? value : "-"}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </AccordionTab>
                                ))}
                            </Accordion>
                        )}
                    </div>
                ) : (
                    <p>No details available.</p>
                )}
            </Dialog>
        </div>
    );
};

export default SupplierLCATable;
