import React from "react";

const HeadStepper = ({ stages, onStepClick, selectedStage, completedStages }) => {

  console.log(selectedStage)
  return (<>
    <div className="stepper-container mt-4">
      {stages.map((stage, index) => {
        // Determine Step Color
        const stageStatus = (completedStages || []).find((s) => s.stage === stage);

        let stepClass = "";
        if (stageStatus?.status === "finalized") {
          stepClass = "finalized"; // Green for completed
        } else if (stageStatus?.status === "drafted") {
          stepClass = "drafted"; // Orange for in-progress
        }

        return (
          <div key={index} className="stepper-step" onClick={() => onStepClick(stage)} style={{ cursor: "pointer" }}>
            <div className={`circle ${stepClass}`}>
              {index + 1}
            </div>
            {index < stages.length - 1 && <div className="line"></div>}
            <div className={`step-title ${selectedStage === stage ? "active" : ""}`}>
              {stage}
            </div>
          </div>
        );
      })}
    </div>
    <div className='color mb-3 mt-3 d-flex justify-content-end'>
      <div className="d-flex align-items-center me-4">
        <div className="instruction-circle" style={{ backgroundColor: '#28a745' }}></div>
        <span className="ms-2">Finalized</span>
      </div>
      <div className="d-flex align-items-center me-4">
        <div className="instruction-circle" style={{ backgroundColor: '#ffa500' }}></div>
        <span className="ms-2">Drafted</span>
      </div>
      <div className="d-flex align-items-center">
        <div className="instruction-circle" style={{ backgroundColor: '#808080' }}></div>
        <span className="ms-2">No information entered</span>
      </div>
    </div>
  </>);
};

export default HeadStepper;
