import { Card } from "primereact/card";
import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Checkbox } from "@material-ui/core";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const initialData = [
  {
    category: "Forging & Machining",
    totalSuppliers: 57,
    auditedUnderMSI: 6,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 5.3,
  },
  {
    category: "Casting & Machining",
    totalSuppliers: 48,
    auditedUnderMSI: 10,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 8.3,
  },
  {
    category: "Pressing & Fabrication",
    totalSuppliers: 47,
    auditedUnderMSI: 15,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 4.3,
  },
  {
    category: "Prop Mechanical",
    totalSuppliers: 108,
    auditedUnderMSI: 15,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 3.7,
  },
  {
    category: "Prop Electrical",
    totalSuppliers: 78,
    auditedUnderMSI: 22,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 3.8,
  },
  {
    category: "Plastics/Rubber/Painting & Stickers",
    totalSuppliers: 88,
    auditedUnderMSI: 8,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 2.3,
  },
  {
    category: "EV/3W/2W",
    totalSuppliers: 18,
    auditedUnderMSI: 4,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 5.6,
  },
  {
    category: "BMW",
    totalSuppliers: 118,
    auditedUnderMSI: 0,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 0,
  },
  {
    category: "Accessories",
    totalSuppliers: 137,
    auditedUnderMSI: 0,
    totalSuppliersPercent: 100,
    auditedUnderMSIPercent: 0,
  },
  // {
  //   category: "IDM",
  //   totalSuppliers: 819,
  //   auditedUnderMSI: 0,
  //   totalSuppliersPercent: 100,
  //   auditedUnderMSIPercent: 85.71, // (90 / 105) * 100
  // },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "10px",
          fontSize: "14px",
          fontFamily: "Lato",
          lineHeight: "1.5",
        }}
      >
        <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
        {payload.map((entry) => (
          <p key={entry.name} style={{ margin: 0, color: "black" }}>{`${
            entry.name
          }:${entry.value}(${entry.payload[`${entry.dataKey}Percent`]}%)`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export const FirstBarDemo = ({ supplyData }) => {
  const [data, setData] = useState(initialData);
  const [chartData, setChartData] = useState([]);
  const [activeMode, setActiveMode] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const tableRef = useRef(null);
  const chartRef = useRef(null);
  const [visibleSeries, setVisibleSeries] = useState({
    totalSuppliers: true,
    auditedUnderMSI: true,
  });

  useEffect(() => {
    if (supplyData.length > 0) {
      const categoryStats = supplyData.reduce((acc, item) => {
        const category = item.category;
        if (!acc[category]) {
          acc[category] = { category, totalSuppliers: 0, auditedSuppliers: 0 };
        }
        acc[category].totalSuppliers += 1;
        if (item.msi_score > 0) acc[category].auditedSuppliers += 1; // Consider MSI score presence as audited
        return acc;
      }, {});

      setChartData(Object.values(categoryStats));
    }
  }, [supplyData]);

  const handleCheckboxChange = (key) => {
    setVisibleSeries((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    setData((prevData) =>
      prevData.map((item) => ({
        ...item,
        [key]: visibleSeries[key]
          ? 0
          : initialData.find((d) => d.category === item.category)[key],
      }))
    );
  };

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
          marginTop: 0,
          gap: "8px",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ color: entry.color, marginRight: 20 }}
          >
            <Checkbox
              checked={visibleSeries[entry.dataKey]}
              onChange={() => handleCheckboxChange(entry.dataKey)}
              style={{
                color: entry.color,
                marginRight: 4,
                fontSize: "20px",
              }}
            />

            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const panelItems = [
    {
      items: [
        {
          label: "Export as Excel",
          icon: "pi pi-file-excel",
          command: () => {
            // downloadExcelWithImage(chartRef);
          },
        },
        {
          label: "Export as PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            // downloadPdfWithImage(chartRef);
          },
        },
        {
          label: "Export as JPG",
          icon: "pi pi-image",
          command: () => {
            // downloadChartAsJpg(chartRef);
          },
        },
        activeMode && {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            // printChart(chartRef);
          },
        },
      ],
    },
  ];

  return (
    <>
      <Card>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ fontSize: "18px", marginLeft: "25px" }}>
              Suppliers under MSI Calibration (No. of suppliers under MSI
              Calibration/No. of suppliers under MSI Calibration)
            </h3>
            <p
              style={{
                fontStyle: "italic",
                fontSize: "12px",
                marginLeft: "25px",
              }}
            >
              (Excludes Indirect Material Suppliers)
            </p>
          </div>

          <div
            style={{
              margin: "18px 10px 18px 10px",
              display: "flex",
            }}
          >
            <div
              className="buttons"
              style={{
                background: "#F0F2F4",
                borderRadius: "3px",
                width: "4.5rem",
                marginLeft: "10px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                  padding: "6px",
                  color: "black",
                  border: "0px",
                  marginRight: "4px",
                }}
                onClick={() => {
                  setActiveMode(false);
                }}
              >
                <i className="pi pi-table fs-19" />
              </Button>
              <Button
                style={{
                  background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                  padding: "6px",
                  color: "black",
                  border: "0px",
                }}
                onClick={() => {
                  setActiveMode(true);
                }}
              >
                <i className="pi pi-chart-bar fs-19" />
              </Button>
            </div>
            <div ref={menuRef}>
              <Button
                style={{
                  color: "black",
                  height: "30px",
                  marginLeft: "3px",
                  background: "#F0F2F4",
                  border: "0px",
                  padding: "6px",
                  position: "relative",
                }}
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                <i className="pi pi-angle-down fs-19" />
              </Button>
              {dropdownOpen && (
                <Menu
                  model={panelItems}
                  style={{
                    position: "absolute",
                    right: 45,
                    zIndex: "1",
                    padding: 0,
                  }}
                ></Menu>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1px",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <span>Total Suppliers</span>
          <h5
            style={{
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            {initialData.reduce((sum, item) => sum + item.totalSuppliers, 0)}
          </h5>
        </div>
        {activeMode && (
          <div ref={chartRef} style={{ height: "500px" }}>
            <ResponsiveContainer width="100%" height={600}>
              <BarChart
                data={data}
                margin={{ top: 10, right: 30, left: 20, bottom: 50 }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                  dataKey="category"
                  // angle={-45}
                  style={{ fontSize: "10px" }}
                />
                <YAxis
                  domain={[
                    0,
                    (dataMax) => Math.ceil(dataMax * 1.25), // Scale max value by 1.25
                  ]}
                />
                <Tooltip content={CustomTooltip} />
                <Legend content={CustomLegend} />
                <Bar
                  dataKey="totalSuppliers"
                  name="Total Suppliers"
                  fill="#73C3B3"
                  barSize={40}
                >
                  <LabelList
                    dataKey="totalSuppliers"
                    position="top"
                    style={{ fontSize: "12px", fill: "black" }}
                  />
                </Bar>
                <Bar
                  dataKey="auditedUnderMSI"
                  name="Suppliers Audited Under MSI"
                  fill="#F5C37B"
                  barSize={40}
                >
                  <LabelList
                    dataKey="auditedUnderMSIPercent"
                    position="top"
                    formatter={(value) => `${value}%`}
                    style={{ fontSize: "12px", fill: "black" }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
        {!activeMode && (
          <DataTable value={data} paginator rows={5} ref={tableRef}>
            <Column field="year" header="Year" label />
            {visibleSeries.totalSuppliers && (
              <Column
                field="totalSuppliers"
                header="Total Suppliers"
                body={(rowData) =>
                  `${rowData.totalSuppliers} (${
                    rowData.totalSuppliersPercent || 0
                  }%)`
                }
              />
            )}
            {visibleSeries.auditedUnderMSI && (
              <Column
                field="auditedUnderMSI"
                header="Audited Under MSI"
                body={(rowData) =>
                  `${rowData.auditedUnderMSI} (${
                    rowData.auditedUnderMSIPercent || 0
                  }%)`
                }
              />
            )}
          </DataTable>
        )}
      </Card>
    </>
  );
};
export default FirstBarDemo;
