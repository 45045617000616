import React, { useState, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";
const data = [
  { month: "Apr", selfAssessment: null, calibrationScore: null },
  { month: "May", selfAssessment: null, calibrationScore: null },
  { month: "Jun", selfAssessment: null, calibrationScore: 48.3 },
  { month: "Jul", selfAssessment: null, calibrationScore: 48.3 },
  { month: "Aug", selfAssessment: null, calibrationScore: 42.19 },
  { month: "Sep", selfAssessment: null, calibrationScore: 48.54 },
  { month: "Oct", selfAssessment: null, calibrationScore: 49.04 },
  { month: "Nov", selfAssessment: null, calibrationScore: 51.6 },
  { month: "Dec", selfAssessment: null, calibrationScore: 52.9 },
  { month: "Jan", selfAssessment: null, calibrationScore: 52.9 },
  { month: "Feb", selfAssessment: null, calibrationScore: null },
  { month: "Mar", selfAssessment: null, calibrationScore: null },
];

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            color: entry.color,

            marginRight: "5px",
          }}
        >
          <span
            style={{
              color: entry.color,
              backgroundColor: entry.color,
              marginRight: 4,
              fontSize: "20px",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span style={{ color: "#555", fontSize: "14px" }}>
            {entry.value === "selfAssessment"
              ? "Self-assessment"
              : "Calibration Score"}
          </span>
        </li>
      ))}
    </ul>
  );
};

const ThirdLineDemo = () => {
  const [activeMode, setActiveMode] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const tableRef = useRef(null);
  const chartRef = useRef(null);
  const [visibleSeries, setVisibleSeries] = useState({
    selfAssessment: true,
    calibrationScore: true,
  });

  const panelItems = [
    {
      items: [
        {
          label: "Export as Excel",
          icon: "pi pi-file-excel",
          command: () => {
            // downloadExcelWithImage(chartRef);
          },
        },
        {
          label: "Export as PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            // downloadPdfWithImage(chartRef);
          },
        },
        {
          label: "Export as JPG",
          icon: "pi pi-image",
          command: () => {
            // downloadChartAsJpg(chartRef);
          },
        },
        activeMode && {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            // printChart(chartRef);
          },
        },
      ],
    },
  ];

  return (
    <Card>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ fontSize: "18px", margin: "25px" }}>
          12 month trend in Overall Supplier MSI scores of Calibrated Suppliers
          (2024-25)
        </h3>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19" />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <div ref={menuRef}>
            <Button
              style={{
                color: "black",
                height: "30px",
                marginLeft: "3px",
                background: "#F0F2F4",
                border: "0px",
                padding: "6px",
                position: "relative",
              }}
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
              }}
            >
              <i className="pi pi-angle-down fs-19" />
            </Button>
            {dropdownOpen && (
              <Menu
                model={panelItems}
                style={{
                  position: "absolute",
                  right: 45,
                  zIndex: "1",
                  padding: 0,
                }}
              ></Menu>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ fontSize: "12px" }}>Self Assessment Score</p>
          <p style={{ fontSize: "20px" }}>N/A</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ fontSize: "12px" }}>MSI calibration score</p>
          <p style={{ fontSize: "20px" }}>52.9</p>
        </div>{" "}
        {/* <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ fontSize: "12px" }}>Rating</p>
          <p style={{ fontSize: "20px", color: "#D28B24" }}>BRONZE</p>
        </div> */}
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend content={CustomLegend} />
          <Line
            type="linear"
            dataKey="selfAssessment"
            stroke="#F59E0B"
            activeDot={false}
            dot={false}
            strokeWidth={5}
          />
          <Line
            type="linear"
            dataKey="calibrationScore"
            stroke="#6C480B"
            activeDot={false}
            dot={false}
            strokeWidth={5}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ThirdLineDemo;
