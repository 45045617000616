import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog"; // PrimeReact modal component
import HeadStepper from "./HeadStepper";
import { Tooltip } from "primereact/tooltip";
import Swal from "sweetalert2";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";

const LCA = ({ data, getData, edit, getStatus, login_data, params, getLcaSubmittionData }) => {
  console.log(login_data)
  console.log(params)
  console.log(data)

  const defaultPartData = {
    partNumber: "",
    description: "",
    imdsId: "",
    data: [

      {
        "categoryType": "process",
        files: [],
        "categories": [
          {
            "name": "Manufacturing Process (Assembly / Forging / Casting / Stamping)",
            "details": [
              {
                "detail": "",
                "remarks": ""
              }
            ]
          },
          {
            "name": "Special Process (Heat Treatment / Plating / Coating)",
            "details": [
              {
                "detail": "",
                "remarks": ""
              }
            ]
          }
        ],

      },

      {
        "categoryType": "energy",
        "categories": [
          {
            "name": "Electricity",
            "details": [
              {
                "type": "",
                "units (in Kilowatt-hours)": null,

                "remarks": ""
              }

            ]
          },
          {
            "name": "Fuel",
            "details": [
              {
                "type": "",
                "units (in Liters)": null,
                "remarks": ""
              }
            ]
          },
          {
            "name": "Gas",
            "details": [
              {
                "type": "",
                "units (in Cubic meters)": null,
                "remarks": ""
              }
            ]
          }
        ]
      },
      {
        "categoryType": "specialProcessMaterial",
        "categories": [
          {
            "name": "Utilities & Auxiliaries (Water / Coolant / Lubricant / Paint / Plating)",
            "details": [
              {
                "nameOrGrade": "",
                "quantity": null,
                "unit of measure": "",
                "remarks": ""
              }
            ]
          }
        ]
      },

      {
        "categoryType": "waste",
        "categories": [
          {
            "name": "Air Emission",
            "details": [
              {
                "materialName": "",
                "quantity (in milligrams per cubic meter)": null,

                "remarks": ""
              }
            ]
          },
          {
            "name": "Water Emission",
            "details": [
              {
                "materialName": "",
                "quantity (in milligrams per liter)": null,
                "remarks": ""
              }
            ]
          },
          {
            "name": "Solid Waste",
            "details": [
              {
                "materialName": "",
                "quantity (in Kilogram)": null,
                "remarks": ""
              }
            ]
          }
        ]
      },

      {
        "categoryType": "transportation",
        "categories": [
          {
            "name": "Transportation",
            "details": [
              {
                "mode": "",
                "from (Manufacturing Location)": "",
                "to (TVS Motor Plant)": "",
                "distance": null,
                "unit of Measure": "",
                "remarks": ""
              }
            ]
          }
        ]
      }

    ],
    process: ['Process', 'Energy Consumption', 'Special Process Material', 'Waste', 'Transportation'],
    completed_stage: [],


  };

  const [newPart, setNewPart] = useState({ partNumber: "", description: "", imdsId: "" });
  const [partList, setPartList] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [partModal, setPartModal] = useState(false)
  const [selectedStage, setSelectedStage] = useState(""); // NEW STATE: Stores the selected step
  // useEffect(() => {
  //   // getData(partList);
  //   // getStatus(partList.length ? partList?.every(x => x?.completed_stage?.length === 5 && x?.completed_stage?.every(y => y?.status === 'finalized')) : false)
  // }, [partList])

  useEffect(() => {
    if (data.length !== 0) {
      const formattedData = data.map(item => ({
        ...item,
        data: item.data ? JSON.parse(item.data) : [], // Parse only if it's a valid JSON string
      }));

      setPartList(formattedData);
    }

  }, [data])

  const handleNewPartChange = (field, value) => {
    setNewPart((prev) => ({ ...prev, [field]: value }));
  };

  const processOptions = [
    "Assembly",
    "Casting",
    "Forging",
    "Machining",
    "Welding",
    "Stamping",
    "Injection Molding",
    "Extrusion",
    "3D Printing",
    "Brazing and Soldering",
    "Heat Treatment",
    "Other (Provide details in remarks)"
  ];
  const electricityOption = ['Electricity – Grid', 'Electricity – Solar', 'Electricity – Wind']
  const finishingOptions = [
    "Electroplating",
    "Painting",
    "Powder Coating",
    "Passivation",
    "Galvanizing",
    "Electrolytic polishing",
    "Anodizing",
    "Other (Provide details in remarks)"
  ];

  //   const handleAddPart = () => {
  //     if (!newPart.partNo.trim()) {
  //       alert("Please enter a valid Part No.");
  //       return;
  //     }


  //     setPartList([...partList, { ...newPart, data: JSON.parse(JSON.stringify(defaultPartData.data)), process: defaultPartData.process, completed_stage: [] }]);

  //     const newFormattedPart = {
  //       supplierId: params?.data.supplierId || 0,  // Ensure supplier ID is passed
  //       vendorCode: params?.data.vendorCode || "Unknown", // Set vendor code if available
  //       partNumber: Number(newPart.partNo) || 0,
  //       description: newPart.partDescription || "No description provided",
  //       imdsId: Number(newPart.imdsId) || 0,
  //       lastUpdateDate: new Date().toISOString(), // Current timestamp
  //       dataCollectionStatus: "Pending", // Default status
  //       created: new Date().toISOString(),
  //     };

  //     APIServices.post(API.LCA_dataSubmission,newFormattedPart).then((res) => {


  //     })
  // console.log(newFormattedPart)
  //     setNewPart({ partNo: "", partDescription: "", imdsId: "" });
  //     setPartModal(false);
  //   };

  // Open Modal & Set Selected Part

  const categoryTypeMapping = {
    "Process": "process",
    "Energy Consumption": "energy",
    "Special Process Material": "specialProcessMaterial",
    "Waste": "waste",
    "Transportation": "transportation"
  };


  const handleAddPart = () => {
    if (!newPart?.partNumber?.trim().length || !newPart?.description?.trim().length) {
      Swal.fire("Error!", !newPart?.partNumber?.trim().length ? "Please enter a valid Part No." : "Please enter Part Description", "error");
      return;
    }
    const processedData = Object.entries(categoryTypeMapping).map(([name, categoryType]) => {

      const categoryData = data.find((item) => item.categoryType === categoryType) || null;

      return {
        name, // The mapped category name (e.g., "Process", "Energy Consumption")
        status: "Not Stared",
        data: categoryData
      };
    });

    console.log(processedData);

    // ✅ Format new part data before sending
    const newFormattedPart = {
      supplierId: params?.state?.data.supplierId || 0, // Ensure supplier ID is passed
      vendorCode: params?.state?.data.vendorCode || "Unknown", // Set vendor code if available
      vendorId: params?.state?.data.vendorId || "Unknown",
      partNumber: newPart.partNumber,
      description: newPart.description || "No description provided",
      imdsId: newPart?.imdsId || '',
      lastUpdateDate: new Date().toISOString(), // Current timestamp
      dataCollectionStatus: processedData, // Default status
      created: new Date().toISOString(),
      data: JSON.stringify(defaultPartData.data),
      completed_stage: [],
      process: defaultPartData.process,
      lock: false
    };

    console.log("Submitting New Part:", newFormattedPart);

    // ✅ Send data to API before updating local state
    APIServices.post(API.LCA_dataSubmission, newFormattedPart)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire("Success!", "Part added successfully.", "success");

          // ✅ Update state only after successful API response
          // setPartList((prevParts) => [
          //   ...prevParts,
          //   {
          //     ...newPart,
          //     data: JSON.parse(JSON.stringify(defaultPartData.data)),
          //     process: defaultPartData.process,
          //     completed_stage: [],
          //   },
          // ]);
          getLcaSubmittionData()


          setNewPart({ partNumber: "", description: "", imdsId: "" });
          setPartModal(false);
        } else {
          throw new Error(res.message || "Failed to add part");
        }
      })
      .catch((error) => {
        Swal.fire("Error!", error.message, "error");
      });
  };


  const openModal = (part) => {
    console.log(part)
    setSelectedPart(part);

    console.log(part.data)
    setSelectedStage(""); // Reset selected stage when opening the modal
    setIsModalVisible(true);
  };

  const handleStepClick = (stage) => {
    console.log(stage)
    setSelectedStage(stage);
  };

  // Filter Data Based on Selected Step
  const getFilteredCategoryData = () => {
    if (!selectedPart || !selectedStage) return null;

    let categoryType = "";
    switch (selectedStage) {

      case "Process":
        categoryType = "process";
        break;
      case "Energy Consumption":
        categoryType = "energy";
        break;
      case "Special Process Material":
        categoryType = "specialProcessMaterial";
        break;
      case "Waste":
        categoryType = "waste";
        break;
      case "Transportation":
        categoryType = "transportation";
        break;
      default:
        return null;
    }


    // console.log(JSON.parse(selectedPart.data))
    return selectedPart.data.find((item) => item.categoryType === categoryType);
  };

  const saveProgress = () => {

    if (selectedPart && selectedStage) {
      const categoryTypeMapping = {
        "Process": "process",
        "Energy Consumption": "energy",
        "Special Process Material": "specialProcessMaterial",
        "Waste": "waste",
        "Transportation": "transportation"
      };
      setPartList((prevPartList) => {
        const updatedParts = prevPartList.map((part) => {
          if (part.id === selectedPart.id) {
            // ✅ Ensure `completed_stage` exists
            const updatedStages = part.completed_stage ? [...part.completed_stage] : [];

            console.log("Selected Stage:", selectedStage);

            // ✅ Check if the stage already exists
            const existingIndex = updatedStages.findIndex((s) => s.stage === selectedStage);
            console.log("Existing Index:", existingIndex);

            if (existingIndex === -1) {
              // ✅ First time: Add new stage
              updatedStages.push({ stage: selectedStage, status: "drafted" });
            } else {
              // ✅ Update existing stage
              updatedStages[existingIndex] = {
                ...updatedStages[existingIndex],
                status: "drafted"
              };
            }
            // let processIndex = part.data.findIndex(x => x.categoryType === categoryTypeMapping[selectedStage])

            // ✅ Update `selectedPart` with the latest completed_stage
            return { ...part, completed_stage: updatedStages };
          }
          return part;
        });

        // ✅ Update state with the new part list
        setPartList(updatedParts);

        // ✅ Find the latest updated part from the updated partList
        const latestPart = updatedParts.find((p) => p.id === selectedPart.id);

        // ✅ Define mapping between stage names & categoryType in `data`


        // ✅ Map completed_stage & data into the required format
        const dataCollectionStatus = latestPart.process.map((stage) => {
          const stageData = latestPart.completed_stage.find((s) => s.stage === stage) || {};
          const categoryType = categoryTypeMapping[stage];
          const categoryData = latestPart.data.find((item) => item.categoryType === categoryType) || null;
          const hasData = !!categoryData; // Check if data exists for this categoryType

          console.log(stageData)

          const status =
            stageData.status === "finalized"
              ? "Completed"
              : stageData.status === "drafted"
                ? "In Process"
                : "Not Started";

          return {
            name: stage,
            status: status,
            isLocked: false,
            data: categoryData
          };

        });

        // ✅ Ensure data is formatted correctly for API
        const { status, ...formattedPart } = {
          ...latestPart,
          completed_stage: latestPart.completed_stage || [], // ✅ Ensure `completed_stage` is not undefined
          data: JSON.stringify(latestPart.data || []), // ✅ Convert `data` field to JSON string
          dataCollectionStatus, // ✅ Include mapped category-wise status
        };

        // ✅ Update `selectedPart` with the latest data
        setSelectedPart(latestPart);

        // ✅ Show success message
        Swal.fire("Success!", "Progress draft successfully.", "success");

        console.log("Formatted Part for API:", formattedPart);

        // ✅ Call API with the latest updated data
        // APIServices.patch(API.LCA_dataSubmission_id(latestPart.id), formattedPart)
        //   .then((res) => {
        //     console.log("API Response:", res);
        //   })
        //   .catch((error) => {
        //     console.error("API Error:", error);
        //   });

        return updatedParts;
      });
    }
  };



  const finalizeStage = () => {
    if (selectedPart && selectedStage) {
      setPartList((prevPartList) => {
        const updatedParts = prevPartList.map((part) => {
          if (part.id === selectedPart.id) {
            // ✅ Ensure `completed_stage` exists
            const updatedStages = part.completed_stage ? [...part.completed_stage] : [];

            console.log("Selected Stage:", selectedStage);

            // ✅ Check if the stage already exists
            const existingIndex = updatedStages.findIndex((s) => s.stage === selectedStage);

            if (existingIndex === -1) {
              // ✅ First time: Add new stage
              updatedStages.push({ stage: selectedStage, status: "finalized" });
            } else {
              // ✅ Update existing stage
              updatedStages[existingIndex] = {
                ...updatedStages[existingIndex],
                status: "finalized"
              };
            }

            // ✅ Update selectedPart with the latest completed_stage
            return { ...part, completed_stage: updatedStages };
          }
          return part;
        });

        // ✅ Update state with the new part list
        setPartList(updatedParts);

        // ✅ Find the latest updated part from the updated partList
        const latestPart = updatedParts.find((p) => p.id === selectedPart.id);

        // ✅ Define mapping between stage names & categoryType in `data`
        const categoryTypeMapping = {
          "Process": "process",
          "Energy Consumption": "energy",
          "Special Process Material": "specialProcessMaterial",
          "Waste": "waste",
          "Transportation": "transportation"
        };

        // ✅ Map completed_stage & data into the required format
        const dataCollectionStatus = latestPart.process.map((stage) => {
          const stageData = latestPart.completed_stage.find((s) => s.stage === stage) || {};
          const categoryType = categoryTypeMapping[stage];
          const categoryData = latestPart.data.find((item) => item.categoryType === categoryType) || null;
          const hasData = !!categoryData; // Check if data exists for this categoryType

          const status =
            stageData.status === "finalized"
              ? "Completed"
              : stageData.status === "drafted"
                ? "In Process"
                : "Not Started";

          return {
            name: stage,
            status: status,
            isLocked: false,
            data: categoryData // ✅ Add matching category data
          };
        });

        // ✅ Ensure data is formatted correctly for API
        const { status, ...formattedPart } = {
          ...latestPart,
          completed_stage: latestPart.completed_stage || [], // ✅ Ensure `completed_stage` is not undefined
          data: JSON.stringify(latestPart.data || []), // ✅ Convert `data` field to JSON string
          dataCollectionStatus, // ✅ Include mapped category-wise status
        };

        // ✅ Update `selectedPart` with the latest data
        setSelectedPart(latestPart);

        console.log("Formatted Part for API:", formattedPart);

        // ✅ Call API with the latest updated data
        APIServices.patch(API.LCA_dataSubmission_id(latestPart.id), formattedPart)
          .then((res) => {
            console.log("API Response:", res);
            Swal.fire("Success!", `${selectedStage} finalized successfully.`, "success");
          })
          .catch((error) => {
            console.error("API Error:", error);
            Swal.fire("Error!", "Failed to finalize. Please try again.", "error");
          });

        return updatedParts;
      });
    }
  };


  const handleTVSSubmit = () => {


    if (!selectedPart) return;

    // ✅ Define mapping between stage names & categoryType in `data`
    const categoryTypeMapping = {
      "Process": "process",
      "Energy Consumption": "energy",
      "Special Process Material": "specialProcessMaterial",
      "Waste": "waste",
      "Transportation": "transportation"
    };

    // ✅ Map completed_stage & data into the required format
    const dataCollectionStatus = selectedPart.process.map((stage) => {
      const stageData = selectedPart.completed_stage.find((s) => s.stage === stage);
      const categoryType = categoryTypeMapping[stage];
      const categoryData = selectedPart.data.find((item) => item.categoryType === categoryType) || null;
      const hasData = !!categoryData; // Check if data exists for this categoryType

      return {
        name: stage,
        status: stageData ? (stageData.status === "finalized" ? "Completed" : "In Process") : (hasData ? "In Process" : "Not Started"),
        isLocked: false,
        data: categoryData // ✅ Add matching category data
      };
    });

    // ✅ Create formatted object
    const formattedPart = {
      vendorCode: selectedPart.vendorCode || "Unknown",
      partNumber: selectedPart.partNumber || "Unknown",
      description: selectedPart.description || "No description provided",
      imdsId: selectedPart?.imdsId || "",
      lastUpdateDate: new Date().toISOString(), // Format date as "15-Feb-2025"
      dataCollectionStatus, // ✅ Updated category mapping with data
      data: JSON.stringify(selectedPart.data || []), // ✅ Ensure data is sent in proper format
      status: 'Submitted'
    };

    console.log("Submitting to TVS:", formattedPart);

    APIServices.patch(API.LCA_dataSubmission_id(selectedPart.id), formattedPart)
      .then((res) => {
        console.log("API Response:", res);
        Swal.fire("Success!", "Submission to TVS completed successfully.", "success");
      })
      .catch((error) => {
        console.error("API Error:", error);
        Swal.fire("Error!", "Failed to submit to TVS. Please try again.", "error");
      });
  };



  const categoryDescriptions = {

    Process: "Describe the key manufacturing and finishing processes involved in producing the part (e.g., machining, forging, casting, coating). You are also required to upload the process flow diagram associated with this specific part. ",
    "Energy Consumption": "Enter the energy required for the manufacturing process, specifying the type of energy used (e.g., electricity, gas) and the consumption per unit of production. ",
    "Special Process Material": "List any specialized materials or chemicals used in processes such as heat treatment, plating, or surface finishing.",
    Waste: "Provide details of waste generated during production, including type (solid, liquid, gaseous), estimated quantity, and any treatment or disposal methods used. ",
    Transportation: "Indicate the mode(s) of transportation used to deliver the part to TVS Motor, along with relevant details such as distance traveled and frequency of shipments."
  };
  // function validateData(data) {
  //   for (let parentIndex = 0; parentIndex < data.length; parentIndex++) {
  //     const item = data[parentIndex];
  //     for (let dataIndex = 0; dataIndex < item.data.length; dataIndex++) {
  //       const category = item.data[dataIndex];
  //       for (let categoryIndex = 0; categoryIndex < category.categories.length; categoryIndex++) {
  //         const cat = category.categories[categoryIndex];
  //         const details = cat.details;

  //         // Check if categoryType is 'process'
  //         if (category.categoryType === "process") {
  //           for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
  //             const detail = details[detailIndex];
  //             // If 'Other' is present, 'remarks' must not be empty
  //             if (
  //               detail.detail.toLowerCase().includes("other") &&
  //               detail.remarks.trim() === ""
  //             ) {
  //               return {
  //                 valid: false,
  //                 message: `Remarks cannot be empty when detail is 'Other' for category "${cat.name}".`,
  //                 location: {
  //                   partNo: item.partNo,
  //                   categoryType: category.categoryType,
  //                   categoryName: cat.name,
  //                   parentIndex,
  //                   dataIndex,
  //                   categoryIndex,
  //                   detailIndex
  //                 }
  //               };
  //             }
  //           }
  //         } else {
  //           // For other category types
  //           for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
  //             const detail = details[detailIndex];

  //             // Check if all keys are empty
  //             const allKeysEmpty = Object.values(detail).every(value => !value);

  //             // Check if only 'remarks' is empty
  //             const onlyRemarksEmpty = Object.keys(detail).every(key => {
  //               if (key === "remarks") return detail[key].trim() === "";
  //               if (key.includes('quantity') || key.includes('distance') || key.includes('(in')) return detail[key] != null
  //               return detail[key].trim() !== "";
  //             });

  //             // If not all keys are empty and remarks is not the only empty key
  //             if (!allKeysEmpty && !onlyRemarksEmpty) {
  //               return {
  //                 valid: false,
  //                 message: `Details are invalid for category "${cat.name}". Except remarks other are mandatory or all fields can be empty`,
  //                 location: {
  //                   partNo: item.partNo,
  //                   categoryType: category.categoryType,
  //                   categoryName: cat.name,
  //                   parentIndex,
  //                   dataIndex,
  //                   categoryIndex,
  //                   detailIndex
  //                 }
  //               };
  //             }
  //           }
  //         }

  //         // Filter the details array to remove trailing empty objects
  //         cat.details = cat.details.filter((detail, index) => {
  //           // Check if all keys are empty
  //           const allKeysEmpty = Object.values(detail).every(value => !value);
  //           // Keep non-empty objects or if it's not a trailing empty object
  //           return !allKeysEmpty || index < cat.details.length - 1;
  //         });
  //       }
  //     }
  //   }

  //   // If all checks pass, return the filtered data
  //   return { valid: true, data };
  // }

  function validateData(data, processType = 0) {
    // test
    let hasSpecialProcess = false;
    let hasManufacturingProcess = false;

    console.log(processType, data);

    for (let parentIndex = 0; parentIndex < data.length; parentIndex++) {
      const item = data[parentIndex];

      for (let dataIndex = 0; dataIndex < item.data.length; dataIndex++) {
        const category = item.data[dataIndex];

        for (let categoryIndex = 0; categoryIndex < category.categories.length; categoryIndex++) {
          const cat = category.categories[categoryIndex];
          const details = cat.details;
          console.log(details)
          // ✅ Process validation
          if (category.categoryType === "process" && (processType === 0 || processType === "Process" || processType === "Special Process Material")) {
            if (cat.name.toLowerCase().includes("manufacturing process") && processType !== "Special Process Material") {
              // ✅ Check if any valid entry exists
              hasManufacturingProcess = details.some(detail => Object.values(detail).some(value => value?.toString().trim() !== ""));

              for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
                const detail = details[detailIndex];
                if (
                  detail.detail.toLowerCase().includes("other") &&
                  !detail.remarks?.trim()
                ) {
                  return {
                    valid: false,
                    message: `Remarks cannot be empty when detail is 'Other' for category "${cat.name}".`,
                    location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                  };
                }
              }
            }
            if (cat.name.toLowerCase().includes("special process")) {
              hasSpecialProcess = details.some(detail => Object.values(detail).some(value => value?.toString().trim() !== ""));
              if (processType !== "Special Process Material") {
                for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
                  const detail = details[detailIndex];
                  if (
                    detail.detail.toLowerCase().includes("other") &&
                    !detail.remarks?.trim()
                  ) {
                    return {
                      valid: false,
                      message: `Remarks cannot be empty when detail is 'Other' for category "${cat.name}".`,
                      location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                    };
                  }
                }
              }
            }
          }

          // ✅ Energy Consumption validation
          if (category.categoryType === "energy" && (processType === 0 || processType === "Energy Consumption")) {
            let hasElectricityGrid = false;

            for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
              const detail = details[detailIndex];

              // ✅ Electricity-specific validation
              if (
                cat.name.toLowerCase() === "electricity" &&
                detail.type?.trim()?.toLowerCase() === "electricity – grid"
              ) {
                hasElectricityGrid = true;
              }

              // ✅ Find correct unit key
              let unitKey = null;
              if (cat.name.toLowerCase() === "electricity") {
                unitKey = Object.keys(detail).find(key =>
                  key.toLowerCase().includes("kilowatt-hours")
                );
              } else if (cat.name.toLowerCase() === "fuel") {
                unitKey = Object.keys(detail).find(key =>
                  key.toLowerCase().includes("liters")
                );
              } else if (cat.name.toLowerCase() === "gas") {
                unitKey = Object.keys(detail).find(key =>
                  key.toLowerCase().includes("cubic meters")
                );
              }

              console.log("Unit Key:", unitKey);

              const type = detail.type?.trim() || "";
              const unitValue = unitKey ? detail[unitKey] : null;

              // ✅ Electricity Validation
              if (cat.name.toLowerCase() === "electricity") {
                if (type.toLowerCase() === "electricity – grid") {
                  // Must have valid type and unit > 0
                  if (unitValue === null || unitValue <= 0) {
                    return {
                      valid: false,
                      message: `Electricity with type 'Electricity – Grid' must have a unit greater than 0.`,
                      location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                    };
                  }
                } else if (type) {
                  // Other electricity types must have valid unit > 0
                  if (unitValue === null || unitValue <= 0) {
                    return {
                      valid: false,
                      message: `If type is provided for "${cat.name}", unit must be greater than 0.`,
                      location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                    };
                  }
                }
              }

              // ✅ Fuel/Gas Validation
              if (cat.name.toLowerCase() === "fuel" || cat.name.toLowerCase() === "gas") {
                if (unitValue !== null || type) {
                  if ((unitValue !== null && type.length === 0) || (!unitValue && type)) {
                    return {
                      valid: false,
                      message: `If unit or type is provided for "${cat.name}", both are required and type must have a length greater than 1.`,
                      location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                    };
                  }
                }
              }
            }

            // ✅ Electricity must have at least one 'Electricity – Grid' entry
            if (cat.name.toLowerCase() === "electricity" && !hasElectricityGrid) {
              return {
                valid: false,
                message: `Electricity must have an entry with type 'Electricity – Grid'.`,
                location: { parentIndex, dataIndex, categoryIndex }
              };
            }
          }



          // ✅ Special Process Material validation
          if (
            category.categoryType === "specialProcessMaterial" &&
            (processType === 0 || processType === "Special Process Material")
          ) {
            for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
              const detail = details[detailIndex];
              const keysToCheck = ["nameOrGrade", "quantity", "unit of measure"];

              // ✅ If hasSpecialProcess = true → All fields are mandatory, quantity > 0
              if (hasSpecialProcess) {
                const isValid = keysToCheck.every(key => {
                  if (key === "quantity") {
                    // ✅ Allow positive numeric value only
                    return detail[key] !== null && detail[key] !== "" && Number(detail[key]) > 0;
                  } else {
                    return detail[key]?.toString().trim() !== "";
                  }
                });

                if (!isValid) {
                  return {
                    valid: false,
                    message: `All fields except remarks are mandatory under "${cat.name}" and quantity must be greater than 0.`,
                    location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                  };
                }
              } else {
                // ✅ If hasSpecialProcess = false → Allow all fields to be empty or fully filled (no partial entries)
                const isEmpty =
                  (detail["nameOrGrade"]?.toString().trim() === "") &&
                  (detail["quantity"] === null) &&
                  (detail["unit of measure"]?.toString().trim() === "");

                // ✅ If any field is provided, all must be filled
                if (!isEmpty) {
                  const isValid = keysToCheck.every(key => {
                    if (key === "quantity") {
                      // ✅ If quantity is provided, it must be greater than 0
                      return detail[key] !== null && detail[key] !== "" && Number(detail[key]) > 0;
                    } else {
                      return detail[key]?.toString().trim() !== "";
                    }
                  });

                  if (!isValid) {
                    return {
                      valid: false,
                      message: `All fields except remarks are mandatory under "${cat.name}" if an entry is provided and quantity must be greater than 0.`,
                      location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                    };
                  }
                }
              }
            }
          }




          // ✅ Waste validation
          if (category.categoryType === "waste" && (processType === 0 || processType === "Waste")) {
            for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
              const detail = details[detailIndex];
              const materialName = detail["materialName"]?.toString().trim() || "";

              // ✅ Find the correct quantity key based on subcategory
              const quantityKey = Object.keys(detail).find(key =>
                key.toLowerCase().includes("quantity")
              );
              const quantity = quantityKey ? detail[quantityKey] : null;

              // ✅ If all fields are empty, it's valid
              if (!materialName && quantity === null) continue;

              // ✅ If any field is filled, all other fields should also be filled
              const isValid =
                materialName !== "" &&
                (quantity > 0); // ✅ Allow null or greater than 0 only

              if (!isValid) {
                return {
                  valid: false,
                  message: `All fields under "${cat.name}" are mandatory if any value is provided, and quantity should be greater than 0.`,
                  location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                };
              }
            }
          }



          // ✅ Transportation validation
          if (category.categoryType === "transportation" && (processType === 0 || processType === "Transportation")) {
            let hasValidEntry = false;

            for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
              const detail = details[detailIndex];
              const mode = detail["mode"]?.toString().trim() || "";
              const from = detail["from (Manufacturing Location)"]?.toString().trim() || "";
              const to = detail["to (TVS Motor Plant)"]?.toString().trim() || "";
              const unitOfMeasure = detail["unit of Measure"]?.toString().trim() || "";
              const distance = detail["distance"] != null ? Number(detail["distance"]) : null;
              console.log(detail)
              // ✅ If all fields are empty, consider row as empty
              const isEmpty = !mode && !from && !to && distance == null && !unitOfMeasure;
              if (isEmpty) continue;

              // ✅ If distance is provided, it must be a valid number > 0
              const isDistanceValid = distance > 0

              // ✅ If any field (except remarks) is filled, ensure all fields are filled
              const isValid =
                mode !== "" &&
                from !== "" &&
                to !== "" &&
                isDistanceValid &&
                unitOfMeasure !== "";
              console.log(mode, from, to, isDistanceValid, unitOfMeasure, distance)
              if (!isValid) {
                return {
                  valid: false,
                  message: `All fields except remarks are mandatory under "${cat.name}". Distance must be greater than 0 if provided.`,
                  location: { parentIndex, dataIndex, categoryIndex, detailIndex }
                };
              }

              // ✅ Mark the row as valid if all fields are filled correctly
              hasValidEntry = true;
            }

            // ✅ Ensure at least one fully valid entry exists
            if (!hasValidEntry) {
              return {
                valid: false,
                message: `At least one transportation entry is required.`,
                location: { parentIndex, dataIndex, categoryIndex }
              };
            }
          }





          // ✅ Remove trailing empty objects
          cat.details = cat.details.filter(detail =>
            Object.values(detail).some(value => value?.toString().trim() !== "")
          );
        }
      }
    }

    // ✅ Ensure at least one manufacturing process is added if validating process
    if ((processType === 0 || processType === "Process") && !hasManufacturingProcess) {
      return {
        valid: false,
        message: `At least one entry is required under Manufacturing Process.`,
      };
    }

    return { valid: true, data };
  }


  const isSubmitEnabled = selectedPart?.completed_stage?.length === 5 &&
    selectedPart?.completed_stage.every(stage => stage.status === "finalized");


  const dialogFooter = (

    <div className="d-flex justify-content-between align-items-center w-100">
      {/* ✅ Left-aligned Buttons (Save Progress & Save & Finalize) */}

      {selectedStage && (
        <div className="d-flex">
          <Tooltip target=".disabled-button" />

          {/* Save Progress Button - Orange */}
          <Button
            className="ml-2"
            outlined
            disabled={selectedPart.lock ? true : false}
            label="Save Progress"
            onClick={saveProgress}
            style={{ backgroundColor: "#ffa500", color: "#fff", border: "none", width: 200 }}
          />

          {/* Save & Finalize Button - Green */}
          <Button
            label={`Save & Finalize ${selectedStage}`}
            disabled={selectedPart.lock ? true : !validateData(JSON.parse(JSON.stringify([(selectedPart)])), selectedStage).valid}
            onClick={finalizeStage}
            style={{ backgroundColor: "#28a745", color: "#fff", border: "none", width: 200, marginLeft: "10px" }}
          />
        </div>
      )}

      {/* ✅ Right-aligned "Submit to TVS" Button */}
      {selectedPart && selectedPart.lock === true ?
        <>

        </>
        :
        <Button
          label="Submit to TVS"
          onClick={handleTVSSubmit}
          disabled={!isSubmitEnabled || !validateData(JSON.parse(JSON.stringify([(selectedPart)])), selectedStage).valid}  // ✅ Button remains disabled until all statuses are finalized
          style={{
            backgroundColor: isSubmitEnabled ? "#003366" : "#ccc", // ✅ Gray color when disabled
            color: "#fff",
            border: "none",
            width: 200,
            cursor: isSubmitEnabled ? "pointer" : "not-allowed"
          }}
        />
      }
    </div>
  );

  const [newDetails, setNewDetails] = useState({});


  const handleDetailChange = (catIndex, detailIndex, key, value) => {
    setSelectedPart((prev) => {
      const updatedPart = { ...prev };
      updatedPart.data
        .find((item) => item.categoryType === getCategoryTypeFromStage())
        .categories[catIndex]
        .details[detailIndex][key] = value;
      return updatedPart;
    });
  };
  const stageMapping = {
    "process": "Process",
    "energy": "Energy Consumption",
    "specialProcessMaterial": "Special Process Material",
    "waste": "Waste",
    "transportation": "Transportation"
  }
  const getCategoryTypeFromStage = () => {
    switch (selectedStage) {

      case "Process":
        return "process";
      case "Energy Consumption":
        return "energy";
      case "Special Process Material":
        return "specialProcessMaterial";
      case "Waste":
        return "waste";
      case "Transportation":
        return "transportation";
      default:
        return "";
    }
  };
  // ✅ Initialize Empty New Detail Input Fields
  const initializeNewDetail = (catIndex, template) => {
    setNewDetails((prev) => ({
      ...prev,
      [catIndex]: Object.keys(template).reduce((acc, key) => {
        acc[key] = "";
        return acc;
      }, {}),
    }));
  };

  // ✅ Handle Input Change for New Detail
  const handleNewDetailChange = (catIndex, key, value) => {
    setNewDetails((prev) => ({
      ...prev,
      [catIndex]: { ...prev[catIndex], [key]: value },
    }));
  };

  // ✅ Add New Detail to the Category
  const handleAddDetail = (catIndex) => {
    if (!newDetails[catIndex]) return;
    setSelectedPart((prev) => {
      const updatedPart = { ...prev };
      updatedPart.data
        .find((item) => item.categoryType === getCategoryTypeFromStage())
        .categories[catIndex]
        .details.push(newDetails[catIndex]);
      return updatedPart;
    });

    setNewDetails((prev) => ({
      ...prev,
      [catIndex]: null,
    }));
  };

  // ✅ Remove Detail from Category
  const handleRemoveDetail = (catIndex, detailIndex) => {
    setSelectedPart((prev) => {
      const updatedPart = { ...prev };
      updatedPart.data
        .find((item) => item.categoryType === getCategoryTypeFromStage())
        .categories[catIndex]
        .details.splice(detailIndex, 1);
      return updatedPart;
    });
  }

  const handleFileUpload = (files) => {
    const updatedData = { ...selectedPart };
    const processCategory = updatedData.data.find((item) => item.categoryType === "process");

    let formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("file", file);
    });

    APIServices.post(API.FilesUpload, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
      mode: "no-cors",
    })
      .then((res) => {
        if (res.status === 200) {
          const uploadedFileURLs = res.data.files.map((file) => API.Docs + file.originalname);

          // ✅ Store file URLs instead of actual files
          processCategory.files = [...(processCategory.files || []), ...uploadedFileURLs];

          setSelectedPart(updatedData);
        }
      })
      .catch((error) => {
        console.error("File Upload Error:", error);
      });
  };


  const handleFileRemove = (fileIndex) => {
    const updatedData = { ...selectedPart };
    const processCategory = updatedData.data.find((item) => item.categoryType === "process");

    // Remove file at the specified index
    processCategory.files.splice(fileIndex, 1);
    setSelectedPart(updatedData);
  };


  return (
    <div style={{ margin: "0 auto", padding: 20 }}>
      <h1>LCA Data Collection Form</h1>
      <p>As part of TVS Motor’s commitment to Net Zero and sustainability, we are enhancing transparency regarding the environmental impact of our supply chain. To support this effort, we require our valued suppliers to provide key information on the materials and manufacturing processes associated with the parts supplied to TVS. The data collected will aid in emissions studies, helping us assess and reduce the carbon footprint of our products. Your input is essential in driving sustainable manufacturing and achieving our shared sustainability goals. Please ensure accurate and complete data submission to enable meaningful analysis.</p>

      <Accordion className="mb-4">
        <AccordionTab header="Instructions">
          <p>
            To ensure accurate Life Cycle Assessment (LCA) data submission, follow these steps:
          </p>
          <ol>
            <li>
              <strong>List All Part Numbers</strong> – Identify and enter all part numbers supplied to TVS Motor.
              For each part, provide a clear description along with the approved IMDS ID.
            </li>
            <li>
              <strong>Enter Part-Specific Details</strong> – Click on each listed part to open its details section.
              Follow the provided guidance to input information on manufacturing and finishing processes,
              special processes, energy and water usage, waste generation, and transportation details.
            </li>
            <li>
              <strong>Attach Process Flow Documents</strong> – Upload the relevant process flow document
              for each part to ensure a comprehensive understanding of its production lifecycle.
            </li>
            <li>
              <strong>Save Your Progress</strong> – Use the <em>"Save as Draft"</em> option regularly to avoid
              data loss while working on each section.
            </li>
            <li>
              <strong>Finalize and Submit</strong> – Once all required details are entered and reviewed for accuracy,
              click <em>“Submit”</em> to finalize the data entry for that part.
            </li>
            <li>
              <strong>Post-Submission Review</strong> – After submission, TVS Motor will review the data.
              Once reviewed, the submission will be <strong>locked</strong> and cannot be modified.
            </li>
            <li>
              <strong>Viewing Locked Submissions</strong> – Submitted data will remain accessible for reference
              by clicking on the respective part number, though <strong>edits will no longer be possible</strong>.
            </li>
            <li>
              <strong>Requesting Updates</strong> – If updates are required due to process changes,
              contact your <strong>TVS Motor representative</strong> to request unlocking of the submission
              for necessary modifications.
            </li>
          </ol>
          <p>
            By following these steps, you ensure a structured and accurate LCA data submission process.
          </p>
        </AccordionTab>
      </Accordion>

      {partList.length > 0 && (
        <div className="" style={{ marginBottom: "10px" }}>
          {partList.map((part, index) => {
            return (
              <div
                key={index}
                className="p-card p-shadow-3 p-mb-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1rem",
                  marginBottom: 10,
                  position: 'relative'
                }}
              >
                <h3
                  onClick={() => openModal(part)}
                  style={{ cursor: "pointer", color: "#007ad9" }}
                >
                  {part.partNumber}
                </h3>

                {/* ✅ Display Process Data with Custom Radio Buttons */}
                {part.process?.length > 0 ? (
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {part.process.map((item, i) => {
                      // ✅ Check if this stage is "finalized" or "drafted"
                      const stageStatus = part.completed_stage?.find((s) => s.stage === item);
                      const isFinalized = stageStatus?.status === "finalized";
                      const isDrafted = stageStatus?.status === "drafted";

                      return (
                        <label
                          key={i}
                          style={{
                            marginRight: "15px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          {/* ✅ Custom Radio Button with Different Styles */}
                          <div
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: `2px solid ${isFinalized ? "#28a745" : isDrafted ? "#ffa500" : "#ccc"}`,
                              backgroundColor: isFinalized ? "#28a745" : isDrafted ? "#ffa500" : "transparent",
                              color: isFinalized ? "#fff" : "transparent",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {isFinalized ? "✔" : ""}
                          </div>

                          {/* Label Text */}
                          <span style={{ marginLeft: "5px" }}>{item}</span>
                        </label>
                      );
                    })}
                    <p style={{ position: 'absolute', right: 5, top: 0, fontSize: 10, color: 'green' }}>{part.status}</p>
                    {part.lock &&
                      <i style={{ position: 'absolute', right: 5, bottom: 4 }} className="pi pi-lock"></i>
                    }
                  </div>
                ) : (
                  <p style={{ color: "red" }}>No process data available</p>
                )}
              </div>
            );
          })}
        </div>
      )}
      {edit && <>

        <Button
          label="Add Part"
          icon="pi pi-plus"
          className="p-button-text mb-3"
          style={{
            width: "200px",
            backgroundColor: "#003366", // Dark blue background
            color: "#fff", // White text
            border: "none", // Remove border
          }}
          onClick={() => setPartModal(!partModal)}
        />


        {partModal && (
          <>
            <div className="p-card p-component p-shadow-3 p-mb-3" style={{ padding: "1rem" }}>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{ display: "block", fontWeight: "bold" }}>Part No: <span className="mandatory ml-1">*</span></label>
                <InputText
                  value={newPart.partNumber}
                  disabled={!edit}
                  onChange={(e) => handleNewPartChange("partNumber", e.target.value)}
                  className="p-inputtext-sm"
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{ display: "block", fontWeight: "bold" }}>Part Description: <span className="mandatory ml-1">*</span></label>
                <InputTextarea
                  disabled={!edit}
                  value={newPart.description}
                  onChange={(e) => handleNewPartChange("description", e.target.value)}
                  className="p-inputtext-sm"
                  rows={4}
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{ display: "block", fontWeight: "bold" }}>Approved IMDS ID:</label>
                <InputText
                  disabled={!edit}
                  value={newPart.imdsId}
                  onChange={(e) => handleNewPartChange("imdsId", e.target.value)}
                  className="p-inputtext-sm"
                  style={{ width: "100%" }}
                />
              </div>
              {edit && <Button
                label=""
                icon="pi pi-plus"
                className="p-button-lg"
                style={{
                  backgroundColor: "#003366", // Dark Blue Color
                  color: "#fff", // White text
                  border: "none", // Remove border
                  padding: "10px 20px", // Adjust padding for better appearance
                  fontSize: "16px", // Slightly larger font size
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}

                onClick={handleAddPart}
              />}

            </div>
          </>
        )}
      </>}

      {/* MODAL / DIALOG */}
      <Dialog header="Part Details" visible={isModalVisible} style={{ width: "60vw" }} onHide={() => setIsModalVisible(false)} footer={dialogFooter}>
        {selectedPart && (
          <>
            <div style={{
              marginBottom: "2rem",
              paddingBottom: "20px",
              borderBottom: "2px solid #ccc"
            }}>
              {/* Part No */}
              <div style={{ marginBottom: "5px" }}>
                {/* ✅ Part No */}
                <strong style={{ color: "#333", marginBottom: "3px", display: "block", fontSize: "16px" }}>Part No:</strong>
                <p style={{ fontSize: "18px" }}>{selectedPart.partNumber}</p>
              </div>

              {/* ✅ Part Description */}
              <div style={{ marginBottom: "5px" }}>
                <strong style={{ color: "#333", marginBottom: "3px", display: "block", fontSize: "16px" }}>Part Description:</strong>
                <p style={{ fontSize: "16px", color: "#555" }}>{selectedPart.description}</p>
              </div>

              {/* ✅ Approved IMDS ID */}
              <div>
                <strong style={{ color: "#333", marginBottom: "3px", display: "block", fontSize: "16px" }}>Approved IMDS ID:</strong>
                <p style={{ fontWeight: "bold", fontSize: "16px", color: "#555" }}>
                  {selectedPart.imdsId}
                </p>
              </div>

            </div>

            {/* Stepper with Click Handling */}
            <HeadStepper stages={selectedPart?.process || []} onStepClick={handleStepClick} selectedStage={selectedStage} completedStages={selectedPart?.completed_stage || []} />

            {/* Render Accordion Based on Step Selection */}
            {selectedStage && (
              <div>
                <h1>{selectedStage}</h1>
                <p style={{ fontStyle: "italic", marginBottom: "1rem", color: "#555" }}>
                  {categoryDescriptions[selectedStage]}
                </p>
                {selectedStage === "Process" && (
                  <div
                    style={{
                      marginBottom: "15px",
                      padding: "15px",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <h1 style={{ marginBottom: "15px", fontSize: 15, color: "#333" }}>Upload Process Flow diagram</h1>

                    {/* File Upload Input */}
                    <input
                      type="file"

                      multiple
                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                      onChange={(e) => handleFileUpload(e.target.files)}
                      style={{
                        display: "block",
                        marginBottom: "15px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#333",
                      }}
                    />

                    {/* Display Uploaded Files */}
                    {getFilteredCategoryData()?.files?.length > 0 && (
                      <div style={{ marginTop: "10px" }}>
                        <h1 style={{ color: "#555", fontSize: 15, marginBottom: "10px" }}>Uploaded Files:</h1>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                          {getFilteredCategoryData()?.files.map((file, fileIndex) => (
                            <li
                              key={fileIndex}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "8px",
                                padding: "10px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                {file}
                              </span>
                              {edit && <Button
                                icon="pi pi-trash"
                                className="p-button-danger p-button-sm"
                                onClick={() => handleFileRemove(fileIndex)}
                              />}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}


                <Accordion >
                  {getFilteredCategoryData()?.categories.map((category, catIndex) => (
                    <AccordionTab key={catIndex} header={category.name}>



                      {/* ✅ Display Details in Table Format */}
                      <table className="p-datatable p-component" style={{ width: "100%", borderCollapse: "collapse", marginTop: "1rem" }}>
                        <thead>
                          <tr>
                            {category.details.length > 0 &&
                              Object.keys(category.details[0]).map((key) => (
                                <th key={key} style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                                  {key.replace(/([A-Z])/g, " $1") // Insert space before capital letters
                                    .replace(/^./, (str) => str.toUpperCase()) // Capitalize first letter
                                  }
                                </th>
                              ))}

                            <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {category.details.map((detail, detailIndex) => (
                            <tr key={detailIndex}>
                              {Object.entries(detail).map(([key, value]) => (
                                <td key={key} style={{ border: "1px solid #ddd", padding: "8px" }}>
                                  {/* Render dropdowns for 'detail' or 'unit' */}
                                  {key === "detail" ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      disabled={!edit}
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select an option --</option>
                                      {(category.name === "Manufacturing Process (Assembly / Forging / Casting / Stamping)"
                                        ? processOptions
                                        : finishingOptions
                                      ).map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (key === "type" && category.name === 'Electricity') ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      disabled={!edit}
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select an option --</option>
                                      {electricityOption.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  ) : key === "to (TVS Motor Plant)" ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Location --</option>
                                      <option value="hsr">TVSM-Hosur</option>
                                      <option value="mysr">TVSM-Mysore</option>
                                      <option value="nlg">TVSM-Nalagarh</option>

                                    </select>
                                  ) : key === "unit" ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Unit --</option>
                                      <option value="KM">KM</option>
                                      <option value="Miles">Miles</option>
                                    </select>
                                  ) : key === "unit of Measure" ? (
                                    <select
                                      value={value}
                                      onChange={(e) =>
                                        handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Unit --</option>
                                      <option value="KM">KM</option>
                                      <option value="Miles">Miles</option>
                                    </select>
                                  ) : (key.includes('quantity') || key.includes('distance') || key.includes('(in'))
                                    ?
                                    (
                                      <input
                                        type='number'
                                        value={value}
                                        onChange={(e) =>
                                          handleDetailChange(catIndex, detailIndex, key, e.target.value ? parseFloat(e.target.value) : null)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px"
                                        }}
                                      />
                                    )
                                    : (
                                      <input
                                        type="text"
                                        value={value}
                                        onChange={(e) =>
                                          handleDetailChange(catIndex, detailIndex, key, e.target.value)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px"
                                        }}
                                      />
                                    )}
                                </td>
                              ))}
                              {/* Delete Button */}
                              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                {category.details.length > 1 && (
                                  <Button
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-sm"
                                    onClick={() => handleRemoveDetail(catIndex, detailIndex)}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}

                          {/* Add New Detail Row */}
                          {newDetails[catIndex] && (
                            <tr>
                              {Object.keys(category.details[0] || {}).map((key) => (
                                <td key={key} style={{ border: "1px solid #ddd", padding: "8px" }}>
                                  {key === "detail" ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      disabled={!edit}
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select an option --</option>
                                      {(category.name === "Manufacturing Process (Assembly / Forging / Casting / Stamping)"
                                        ? processOptions
                                        : finishingOptions
                                      ).map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (key === "type" && category.name === 'Electricity') ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      disabled={!edit}
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select an option --</option>
                                      {electricityOption.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  ) : key === "to (TVS Motor Plant)" ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Location --</option>
                                      <option value="hsr">TVSM-Hosur</option>
                                      <option value="mysr">TVSM-Mysore</option>
                                      <option value="nlg">TVSM-Nalagarh</option>

                                    </select>
                                  ) : key === "unit" ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Unit --</option>
                                      <option value="KM">KM</option>
                                      <option value="Miles">Miles</option>
                                    </select>
                                  ) : key === "unit of Measure" ? (
                                    <select
                                      value={newDetails[catIndex].value}
                                      onChange={(e) =>
                                        handleNewDetailChange(catIndex, key, e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                      }}
                                    >
                                      <option value="">-- Select Unit --</option>
                                      <option value="KM">KM</option>
                                      <option value="Miles">Miles</option>
                                    </select>
                                  ) : (key.includes('quantity') || key.includes('distance') || key.includes('(in'))
                                    ?
                                    (
                                      <input
                                        type='number'
                                        value={newDetails[catIndex].value}
                                        onChange={(e) =>
                                          handleNewDetailChange(catIndex, key, e.target.value ? parseFloat(e.target.value) : null)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px"
                                        }}
                                      />
                                    )
                                    : (
                                      <input
                                        type="text"
                                        value={newDetails[catIndex].value}
                                        onChange={(e) =>
                                          handleNewDetailChange(catIndex, key, e.target.value)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "5px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px"
                                        }}
                                      />
                                    )}
                                </td>
                              ))}
                              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                <Button
                                  icon="pi pi-check"
                                  className="p-button-success p-button-sm"
                                  onClick={() => handleAddDetail(catIndex)}
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>



                      </table>

                      {/* ✅ Add More Button */}
                      <Button
                        label="Add More"
                        icon="pi pi-plus"
                        className="p-button-text p-button-sm"
                        onClick={() => initializeNewDetail(catIndex, category.details[0])}
                        style={{ marginTop: "10px" }}
                      />
                    </AccordionTab>
                  ))}
                </Accordion>

              </div>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
}

export default LCA;
