import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { useState, useEffect } from "react";

const SocialTab = ({ data }) => {
  const [selectedData, setSelectedData] = useState([]);

  const transformData = (data) => {
    // Define the categories and their max values
    let categories = [
      { key: "road_safety", label: "Road Safety", maxValue: 10 },
      { key: "electrical_safety", label: "Electrical Safety", maxValue: 10 },
      { key: "fire_safety", label: "Fire Safety", maxValue: 10 },
      {
        key: "5s_and_house_keeping",
        label: "SS Housekeeping ",
        maxValue: 10,
      },
      { key: "personnel_safety", label: "Personal Safety", maxValue: 10 },
    ];

    // Check if 'personnel_safety' exists in any data entry
    const hasPersonalSafety = data.some(
      (item) => item.personnel_safety !== undefined
    );

    // Filter out 'Personal Safety' if no relevant data is found
    if (!hasPersonalSafety) {
      categories = categories.filter(
        (category) => category.key !== "personnel_safety"
      );
    }

    return categories.map(({ key, label, maxValue }) => {
      // Calculate the average value for the category across all entities
      const avgValue =
        data.reduce((sum, item) => sum + (item[key] || 0), 0) / data.length;

      return {
        category: label,
        avgValueForGraph: parseFloat(avgValue.toFixed(2)),
        maxValue,
        remainingToMax: parseFloat((maxValue - avgValue).toFixed(2)),
      };
    });
  };

  useEffect(() => {
    setSelectedData(transformData(data));
  }, [data]);

  // const tab2Data = [
  //   {
  //     category: "Road Safety",
  //     avgValueForGraph: 4.75,
  //     maxValue: 10,
  //     remainingToMax: 10 - 4.75,
  //   },
  //   {
  //     category: "Electrical Safety",
  //     avgValueForGraph: 7,
  //     maxValue: 10,
  //     remainingToMax: 3,
  //   },
  //   {
  //     category: "Fire Safety",
  //     avgValueForGraph: 5,
  //     maxValue: 10,
  //     remainingToMax: 10 - 5,
  //   },
  //   {
  //     category: "SS & Housekeeping Safety",
  //     avgValueForGraph: 6.5,
  //     maxValue: 10,
  //     remainingToMax: 10 - 6.5,
  //   },
  //   {
  //     category: "Personal Safety",
  //     avgValueForGraph: 2.5,
  //     maxValue: 10,
  //     remainingToMax: 10 - 2.5,
  //   },
  // ];

  // const tab3Data = [
  //   {
  //     category: "Road Safety",
  //     avgValueForGraph: 3.33,
  //     maxValue: 10,
  //     remainingToMax: 10 - 3.33,
  //   },
  //   {
  //     category: "Electrical Safety",
  //     avgValueForGraph: 8,
  //     maxValue: 10,
  //     remainingToMax: 2,
  //   },
  //   {
  //     category: "Fire Safety",
  //     avgValueForGraph: 7,
  //     maxValue: 10,
  //     remainingToMax: 3,
  //   },
  //   {
  //     category: "SS & Housekeeping Safety",
  //     avgValueForGraph: 7.67,
  //     maxValue: 10,
  //     remainingToMax: 10 - 7.67,
  //   },
  // ];

  // const selectedData =
  //   tabIndex === 1 ? tab2Data : tabIndex === 2 ? tab3Data : [];

  const getYAxisDomain = () => {
    return [0, 10];
  };

  const salesYAxisDomain = getYAxisDomain();

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,
              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };
  // const wrapText = (text, width = 10) => {
  //   let words = text.split(" ");
  //   let lines = [];
  //   let currentLine = "";

  //   words.forEach((word) => {
  //     if ((currentLine + " " + word).length > width) {
  //       lines.push(currentLine);
  //       currentLine = word;
  //     } else {
  //       currentLine += (currentLine ? " " : "") + word;
  //     }
  //   });

  //   lines.push(currentLine);
  //   return lines.map((line, index) => (
  //     <tspan key={index} x="0" dy={index === 0 ? "0" : "12"}>
  //       {line}
  //     </tspan>
  //   ));
  // };

  // const CustomizedTick = ({ x, y, payload }) => {
  //   return (
  //     <g transform={`translate(${x},${y})`}>
  //       <text
  //         x={0}
  //         y={0}
  //         dy={15} // Adjust spacing for better visibility
  //         textAnchor="middle"
  //         fontSize={10}
  //         fill="#666"
  //       >
  //         {wrapText(payload.value, 12)}
  //       </text>
  //     </g>
  //   );
  // };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      <div style={{ width: "100%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Social Management
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={selectedData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 7, width: "25px" }} />
            <YAxis domain={salesYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Achieved") {
                  return [
                    `${value.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />

            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              fill="#FC6E51"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              fill="#FEB2A8"
              name="Maximum"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SocialTab;
