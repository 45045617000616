import React, { useState, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

const lineChartData = [
  { month: "Feb 24", score2023: 75, selfAssessment: 78, calibration: 77 },
  { month: "Mar 24", score2023: 76, selfAssessment: 79, calibration: 78 },
  { month: "Apr 24", score2023: 77, selfAssessment: 80, calibration: 79 },
  { month: "May 24", score2023: 78, selfAssessment: 81, calibration: 80 },
  { month: "Jun 24", score2023: 79, selfAssessment: 82, calibration: 81 },
  { month: "Jul 24", score2023: 80, selfAssessment: 83, calibration: 82 },
  { month: "Aug 24", score2023: 81, selfAssessment: 84, calibration: 83 },
  { month: "Sep 24", score2023: 82, selfAssessment: 85, calibration: 84 },
  { month: "Oct 24", score2023: 83, selfAssessment: 86, calibration: 85 },
  { month: "Nov 24", score2023: 84, selfAssessment: 87, calibration: 86 },
  { month: "Dec 24", score2023: 85, selfAssessment: 88, calibration: 87 },
  { month: "Jan 25", score2023: 86, selfAssessment: 89, calibration: 88 },
];

const MSIScoresOverTime = () => {
  const [activeMode, setActiveMode] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const tableRef = useRef(null);
  const chartRef = useRef(null);
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const panelItems = [
    {
      items: [
        {
          label: "Export as Excel",
          icon: "pi pi-file-excel",
          command: () => {
            // downloadExcelWithImage(chartRef);
          },
        },
        {
          label: "Export as PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            // downloadPdfWithImage(chartRef);
          },
        },
        {
          label: "Export as JPG",
          icon: "pi pi-image",
          command: () => {
            // downloadChartAsJpg(chartRef);
          },
        },
        activeMode && {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            // printChart(chartRef);
          },
        },
      ],
    },
  ];

  return (
    <Card>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ fontSize: "18px", margin: "25px" }}>
          MSI Scores Over Time
        </h3>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19" />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <div ref={menuRef}>
            <Button
              style={{
                color: "black",
                height: "30px",
                marginLeft: "3px",
                background: "#F0F2F4",
                border: "0px",
                padding: "6px",
                position: "relative",
              }}
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
              }}
            >
              <i className="pi pi-angle-down fs-19" />
            </Button>
            {dropdownOpen && (
              <Menu
                model={panelItems}
                style={{
                  position: "absolute",
                  right: 45,
                  zIndex: "1",
                  padding: 0,
                }}
              ></Menu>
            )}
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={lineChartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" textAnchor="end" interval={0} />
          <YAxis />
          <Tooltip />
          <Legend content={CustomLegend} />
          <Line
            type="monotone"
            dataKey="score2023"
            stroke="#b2b2b2"
            name="2023-24"
          />
          <Line
            type="monotone"
            dataKey="selfAssessment"
            stroke="#0000cc"
            name="MSI Self Assessment Score"
          />
          <Line
            type="monotone"
            dataKey="calibration"
            stroke="#006600"
            name="MSI Calibration Score"
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default MSIScoresOverTime;
