import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from 'luxon';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { API } from '../../../../constants/api_url';
import APIServices from '../../../../service/APIService';
import { Button } from 'primereact/button';
import SupplierPanel from '../SupplierScreen/SupplierPanel';
import { Dialog } from 'primereact/dialog';
import useForceUpdate from 'use-force-update';

const SuppliersTable = ({ data, assessorList, globalFilter, editSupplier, deleteSupplier }) => {
    console.log(data)
    const [databk, setDatabk] = useState([])
    const [datas, setDatas] = useState([])
    const [search, setSearch] = useState('')
    const forceUpdate = useForceUpdate();
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [actionModal, setActionModal] = useState(false)


    useEffect(() => {
        setDatabk(data)
        setDatas(data)

    }, [data])
    const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }, { name: 'IDM (Indirect Material)', value: 10 }, { name: 'Import', value: 11 }]

    const calibrationIdBodyTemplate = (rowData) => {
        let finished = rowData?.supplierAssignmentSubmission?.type === 1
        return (
            <div >
                {'MSI-' + (rowData?.vendorCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}
            </div>
        );
    };
    const scoreTemplate = (rowData) => {
        let finished = rowData?.supplierAssignmentSubmission?.type === 1


        return (
            <div className={finished ? 'clr-navy fw-6 fs-14 cur-pointer text-underline' : 'fw-5 fs-14'} onClick={(e) => { e.preventDefault(); if (finished) { setSelectedAudit(rowData); setActionModal(true) } }}>
                {rowData?.supplierAssignmentSubmission?.supplierMSIScore ? rowData?.supplierAssignmentSubmission?.supplierMSIScore : 'NA'}
            </div>
        );

    }


    const getCalibirationId = (rowData) => {
        return 'MSI-' + (rowData?.vendorCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')
    }
    const nameTemplate = (rowData) => {

        return (

            rowData?.vendor?.supplierName || 'NA'

        );
    };
    const locationTemplate = (rowData) => {
        return (
            rowData?.vendor?.supplierLocation || 'NA'



        );
    };

    const categoryTemplate = (rowData) => {
        console.log(rowData)
        return (

            categoryList.find(i => i.value === rowData?.vendor?.supplierCategory)?.name || 'Not Found'

        );
    };
    const assessmentDueDate = (rowData) => {
        return (

            DateTime.fromISO(rowData.assessmentEndDate, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy')

        );
    };
    const calibrationStartDate = (rowData) => {
        console.log(rowData)
        return (

            rowData.auditStartDate ? DateTime.fromISO(rowData.auditStartDate, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy') : 'Not Set'

        );
    };
    const statusTemplate = (rowData) => {

        if (!rowData?.supplierAssignmentSubmission) {
            return <Tag value='Not Started' className='status-tag-orange' />
        } else if (rowData?.supplierAssignmentSubmission?.type !== 1) {
            return <Tag value='InProgress' className='status-tag-blue' />
        } else if (rowData?.supplierAssignmentSubmission?.type === 1) {
            return <Tag value='Completed' className='status-tag-green' />
        }


    }
    const calibrationEndDate = (rowData) => {
        return (

            rowData.auditEndDate ? DateTime.fromISO(rowData.auditEndDate, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy') : 'Not Set'


        );
    };
    const contactTemplate = (rowData) => {
        return (

            rowData?.vendor?.supplierContact || 'NA'

        );
    };
    const pySpendBodyTemplate = (rowData) => {
        return `${rowData?.vendor?.supplierSpentOn || '-'} Cr. INR`;
    };
    const RowFilterTemplate = (options, obj) => {
        console.log(data)
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(data.map((i) => i[obj]))).filter(x => x)}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                filter
                panelClassName='hidefilter'
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const statusOfActionsTemplate = (rowData) => {
        return <span>{rowData.statusOfActions}</span>;
    };
    const team1Template = (rowData) => {

        if (rowData?.group1?.assessors?.length) {
            return rowData?.group1?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }

    const team2Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group2?.assessors?.length) {
            return rowData?.group2?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team3Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group3?.assessors?.length) {
            return rowData?.group3?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team4Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group4?.assessors?.length) {
            return rowData?.group4?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const handleCalibrationClick = (rowData) => {
        // Logic when calibration ID is clicked
        alert('Clicked on: ' + rowData.calibrationId);
    };
    const calibirationtemplate = (rowData) => {
        return (
            <div>
                <Button
                    icon="pi pi-pencil"

                    onClick={() => editSupplier(rowData)}
                />

            </div>
        )
    }
    const deletetemplate = (rowData) => {
        return (
            <div>
                {(!rowData.supplierAssignmentSubmission && !rowData.auditorAssignmentSubmission) ?
                    <Button
                        icon="pi pi-trash"
                        className='mandatory'
                        onClick={() => deleteSupplier(rowData)}
                    /> :
                    'NA'
                }
            </div>
        )
    }
    const searchFn = (e) => {
        let val = e.target.value
        setDatas(databk.filter(x => x?.vendor?.supplierName.trim().toLowerCase().includes(val?.trim().toLowerCase()) || x?.vendor?.code.trim().toLowerCase().includes(val?.trim().toLowerCase())))
        setSearch(val)
    }
    const sortAuditStartDate = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.auditStartDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.auditStartDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.auditStartDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.auditStartDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const sortAuditEndDate = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.auditEndDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.auditEndDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.auditEndDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.auditEndDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    return (
        <div>
            <div className="col-12 flex justify-content-end" >
                <div className='col-5'>
                    <span className="p-input-icon-left" style={{ width: '100%' }}>
                        <i className="pi pi-search" />
                        <InputText value={search} style={{ width: '100%' }} onChange={searchFn} placeholder="Search Code/Name" />
                    </span>
                </div>
            </div>
            <DataTable scrollable paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} value={datas} responsiveLayout="scroll" filters={{ supplierName: { matchMode: 'in', value: null }, location: { matchMode: 'in', value: null }, msiId: { matchMode: 'in', value: null }, cat: { matchMode: 'in', value: null }, stat: { matchMode: 'in', value: null } }} globalFilter={globalFilter} className="mt-2 h-500">
                <Column field="msiId" header="Calibration ID" body={calibrationIdBodyTemplate}
                    showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "msiId")
                    }
                ></Column>
                <Column field="supplierName" header="Name" body={nameTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "supplierName")
                    }  ></Column>
                <Column field="location" header="Location" body={locationTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "location")
                    }  ></Column>
                <Column field="supplierContact" header="Supplier Contact" body={contactTemplate}></Column>

                <Column field="pySpend" header="PY Spend (Cr. INR)" body={pySpendBodyTemplate} ></Column>
                <Column field="cat" header="Category" body={categoryTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "cat")
                    } ></Column>
                <Column field="selfAssessmentDueDate" header="Self-Assessment Due Date" body={assessmentDueDate} ></Column>
                <Column field="stat" header="Self-Assessment Status" headerStyle={{ width: 300 }} body={statusTemplate} showFilterMatchModes={false}
                    filter
                    filterElement={(options) =>
                        RowFilterTemplate(options, "stat")
                    }  ></Column>
                <Column header="MSI Self-assessment Score" sortable field='supplierAssignmentSubmission.supplierMSIScore' body={scoreTemplate}  ></Column>
                <Column field="auditStartDate" sortable sortFunction={sortAuditStartDate} header="Calibration Start Date" body={calibrationStartDate}></Column>
                <Column field="auditEndDate" sortable sortFunction={sortAuditEndDate} header="Calibration End Date" body={calibrationEndDate}></Column>

                <Column field="teamMember1" header="Calibration Team Member 1" body={team1Template}></Column>
                <Column field="teamMember2" header="Calibration Team Member 2" body={team2Template}></Column>
                <Column field="teamMember3" header="Calibration Team Member 3" body={team3Template}></Column>
                <Column field="teamMember3" header="Calibration Team Member 4" body={team4Template}></Column>
                <Column header="Schedule MSI Calibration" headerStyle={{ width: 150 }} body={calibirationtemplate}></Column>
                <Column header="Action" headerStyle={{ width: 150 }} body={deletetemplate}></Column>



            </DataTable>
            {selectedAudit && <Dialog
                visible={actionModal}
                header={`TVS Motors Supplier MSI Self-assessment ( ${getCalibirationId(selectedAudit)} )`}
                style={{ width: '75%' }}
                onHide={() => setActionModal(false)}


            >



                <SupplierPanel vendorCode={selectedAudit.vendorCode} closeModal={(e) => { setActionModal(() => e); forceUpdate(); }} readOnly={true} auditId={selectedAudit} />



            </Dialog>
            }
        </div>
    );
};

export default SuppliersTable;
