import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../../constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { Editor } from "primereact/editor";
import APIServices from "../../../service/APIService";
import { DateTime } from "luxon";
import { StickyContainer, Sticky } from 'react-sticky';

import { InputNumber } from "primereact/inputnumber";
import { AttachmentComponent } from "../../../components/Attachment";
import { getReportingFiscalYearByReportingperiod, getRPTextFormat } from "../../../components/BGHF/helper";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { BlockUI } from 'primereact/blockui';
import { ReadMoreComponent } from "../../../components/Forms/ReadMoreComponent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { hardcoded } from "../../constants/hardcodedid";
import LCA from "../../admin/TVS/SupplierScreen/LCA";


window.jQuery = $;
window.$ = $;

const LCAActionSubmission = () => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail)

    const [structuredResponse, setStructuredResponse] = useState([])
    const [data, setData] = useState([])
    const dpnamerefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
    const [tempload, setTempLoad] = useState(false)
    const [remarksdialog, setRemarksDialog] = useState(false)
    const [remarksdata, setRemarksData] = useState([])
    const [assignment, setAssignment] = useState([])
    const [pastdata, setPastData] = useState([])
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [assigneedialog, setAssigneeDialog] = useState(false)
    const [assigneeobj, setAssigneeObj] = useState({ reviewer_ids: [], reporter_ids: [], approver_ids: [] })

    const [response, setResponse] = useState({})

    const [document, setDocument] = useState(null)
    const [efcatass, setEFCatAss] = useState([])
    const [actual, setActual] = useState([])
    const [submisisonStatus, setSubmissionStatus] = useState(true)
    const [show, setShow] = useState(false)
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();

    const { id, params, submitId } = JSON.parse(sessionStorage.getItem('srfsubmission'))

    const userList = useSelector(state => state.userlist.supplierList)


    const checkUpdation = () => {
        let a = JSON.stringify(data.data1)
        let b = actual
        console.log(a === b)
        return a === b

    }

    useEffect(() => {
        getLcaSubmittionData()
    }, [])

    const getLcaSubmittionData = () => {
        APIServices.get(API.LCA_dataSubmission).then((res) => {
            if (res.status === 200 && Array.isArray(res.data)) {
                // ✅ Filter based on supplierId
                const filteredData = res.data.filter(item => item.vendorId === params?.state?.data.vendorId);

                // ✅ Parse `data` field (if it's a JSON string)
                // const formattedData = filteredData.map(item => ({
                //     ...item,
                //     data: item.data ? JSON.parse(item.data) : [], // Ensure data is an object
                // }));

                setData(filteredData);
            } else {
                console.error("Invalid response format:", res);
            }

        })
    }






    // const onCellEditComplete = (e, dataind) => {
    //     let { rowData, newValue, cellIndex, field, rowIndex, originalEvent: event } = e;

    //     let loc = JSON.parse(JSON.stringify(data))
    //     setTimeout(() => {
    //         let item = getObjectAtIndex(loc.data1[dataind].data[rowIndex], cellIndex)
    //         item.data['value'] = rowData[field];
    //         loc.data1[dataind].data[rowIndex][loc.data1[dataind].value[cellIndex]].data['value'] = rowData[field];
    //         delete loc.data1[dataind].data[rowIndex][field]
    //         setData(loc)
    //         console.log(loc)
    //         forceUpdate()
    //     }, 100)

    // };
    // const addRow = (data_, index) => {

    //     let loc = JSON.parse(JSON.stringify(data))
    //     loc.data1[index].data.push(data_[0])
    //     setData(loc)
    //     forceUpdate()
    // }
    // const deleteRow = (rowData, rowindex, formindex) => {
    //     let loc = JSON.parse(JSON.stringify(data))
    //     loc.data1[formindex].data.splice(rowindex, 1)
    //     setData(loc)
    //     forceUpdate()
    // }
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const getObjectAtIndex = (data, index) => {
        const keys = Object.keys(data);
        const key = keys[index];
        return data[key];
    };
    const renderEditor = (options) => {
        let item = getObjectAtIndex(options.rowData, parseInt(options.field.split('_')[1]))

        switch (item.type) {

            case 1:
                return (<InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />)
            case 2:
                return (<InputTextarea value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />)
            case 3:
                return (<InputText type="number" value={options.value} onChange={(e) => options.editorCallback(parseFloat(e.target.value))} />)
            case 4:

                return (<Dropdown optionLabel="label" optionValue="value" value={options.value} options={item.data.values} onChange={(e) => options.editorCallback(e.value)} />)
            case 6:

                return (<Calendar dateFormat="dd/mm/yy" value={options.value} onChange={(e) => options.editorCallback(e.value)} />)

            case 5:
                return null
        }
    }








    return (
        <div className="col-12 font-lato" style={{ margin: 10 }}>
            <StickyContainer>
                <div className="col-12">
                    {(login_data.id !== undefined) ?
                        <div >





                            <LCA edit={1} data={data} login_data={login_data} params={params} getLcaSubmittionData={getLcaSubmittionData}/>

                        </div>
                        : <></>
                        // <div className="col-12 card">Form not found</div>
                        // <div className="col-12 card">You have no rights to access this page </div>

                    }
                </div>

            </StickyContainer>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(LCAActionSubmission, comparisonFn);
