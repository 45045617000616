// SupplierReport.js
import React, { useState, forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RadarChart, PolarGrid, PolarAngleAxis, Radar,
  CartesianGrid, ResponsiveContainer, BarChart, Bar,
  XAxis, YAxis, Tooltip, Legend
} from 'recharts';
import { Card, Table } from "react-bootstrap";
import ReactToPrint from 'react-to-print';
import { DateTime } from 'luxon';
import { groupArrayByKeys } from '../../../../components/BGHF/helper';
import APIServices from '../../../../service/APIService';
import { API } from '../../../../constants/api_url';
const DealerReport = forwardRef((props, ref) => {
  const [data, setData] = useState(props.report)
  const categoryOptions = [
    { label: 'Good Practices', id: 1 },
    { label: 'Opportunity of Improvement', id: 2 },
    { label: 'Non-compliance', id: 3 },
  ];

  const nonComplianceOptions = [
    { label: 'Regulatory (Major)', id: 1 },
    { label: 'Regulatory (Minor)', id: 2 },
    { label: 'Minor', id: 3 },
  ];
  const [report, setReport] = useState({})

  useEffect(() => {
    let loc = JSON.parse(JSON.stringify(data))
    APIServices.get(API.AuditorAssessmentSubmission_Edit(props?.report?.auditorAssignmentSubmission?.id)).then(res => {

      let auditorResponse = JSON.parse(res?.data?.response || '[]').flatMap(i => i.assessmentSubSection1s.map(x => ({ ...x, order: i.order, esg: i.order === 1 ? 1 : i.order >= 5 ? 3 : i.order ? 2 : null, categoryTitle: i.order === 1 ? "Environment" : i.order >= 5 ? 'Governanace' : i.order ? "Social" : null }))).filter(x => x.categoryTitle)

      for (const act of loc.supplierActions) {
        let order = auditorResponse.find(i => i.id === act.assessmentSubSection2Id)?.order
        act.category = act.categoryOfFinding === 3 ? categoryOptions.find(c => c.id === act.categoryOfFinding)?.label + ' - ' + nonComplianceOptions.find(c => c.id === act.nonComplianceType)?.label : categoryOptions.find(c => c.id === act.categoryOfFinding)?.label
        act.esg = order === 1 ? 1 : order >= 5 ? 3 : order ? 2 : null
        console.log(act, auditorResponse)
      }
      const groupedResponse = groupArrayByKeys(auditorResponse, ['categoryTitle'])
      console.log(groupedResponse)
      Object.entries(groupedResponse).forEach(([key, value]) => {
        console.log(value)
        for (const xyz of value) {
          console.log(xyz, loc.supplierActions)
          xyz.findings = groupArrayByKeys(loc.supplierActions.filter(x => x.esg === xyz.esg && xyz.id === x.assessmentSubSection2Id), ['category'])
        }


      })


      loc.response = groupedResponse
      console.log(loc)

      setReport(loc)
    })

  }, [data])

  const getMSIIDByAssignment = (item) => {

    return 'MSI-' + item?.vendor?.code + '-' + DateTime.fromISO(item.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')
  }
  const getDate = (date, format) => {
    if (!date) {
      return 'Not Set'
    }
    if (typeof date === 'string') {
      return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
    } else if (DateTime.isDateTime(date)) {
      return date.toFormat(format ? format : 'dd-MM-yyyy')
    } else {
      return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
    }

  };
  const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }]

  const radarData = [
    { category: "Environmental", value: 80 },
    { category: "Health & safety", value: 60 },
    { category: "Legal compliance", value: 70 },
    { category: "Governance", value: 90 },
    { category: "Social", value: 50 },
    { category: "Sustainability Ambassadorship", value: 65 },
  ];

  const barData = [
    { category: "Social", maxScore: 100, avgScore: 80 },
    { category: "Environment", maxScore: 100, avgScore: 85 },
    { category: "Governance", maxScore: 100, avgScore: 90 },
  ];

  const dataEnvironmental = [
    { name: "Water", maxScore: 100, avgScore: 70 },
    { name: "Waste", maxScore: 100, avgScore: 80 },
    { name: "Product Stewardship", maxScore: 100, avgScore: 90 },
    { name: "Energy", maxScore: 100, avgScore: 75 },
  ];

  const dataSocial = [
    { name: "Occupational health & safety", maxScore: 100, avgScore: 60 },
    { name: "Supplier social stewardship framework", maxScore: 100, avgScore: 70 },
    { name: "Supplier sustainability ambassadorship framework", maxScore: 100, avgScore: 80 },
  ];

  const dataGovernance = [
    { name: "Supplier governance framework", maxScore: 100, avgScore: 85 },
    { name: "Legal compliances", maxScore: 100, avgScore: 95 },
  ];

  const rows = [
    {
      category: "GOOD PRACTICES",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "OPPORTUNITY FOR IMPROVEMENT",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "MINOR NON CONFORMANCE",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "REGULATORY NON COMPLIANCE",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
  ];

  const admin_data = useSelector(state => state.user.admindetail);

  const sections = [
    {
      title: "Waste",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "GOOD PRACTICES",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
                "Voluptatibus excepturi fuga earum non dolorem voluptates vel aspernatur.",
              ],
            },
          ],
        },
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Energy",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Product Stewardship",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
  ];

  const sections1 = [
    {
      title: "Supplier Sustainability Ambassadorship Framework",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Supplier Social Stewardship Framework",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Supplier Occupational Health & Safety Framework",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
  ];

  const sections2 = [
    {
      title: "Legal Compliances",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Supplier Governance Framework",
      maxScore: 10,
      scoreObtained: 8,
      rows: [
        {
          category: "FINDING",
          data: [
            {
              finding: "Consectetur enim molestiae quia odio dolorem quam.",
              category: "mollitia quia et",
              evidence: [
                "Ut illum aut voluptatem est ea ut quae ullam.",
                "Aut dignissimos rerum.",
              ],
            },
          ],
        },
      ],
    },
  ];

  // Reusable BarChart Section
  const Section = ({ title, data, barColors, backgroundColor }) => (
    <div
      style={{
        backgroundColor,
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <h3 style={{ marginBottom: "20px" }}>{title}</h3>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="maxScore" fill={barColors[0]} />
        <Bar dataKey="avgScore" fill={barColors[1]} />
      </BarChart>
    </div>
  );

  return (
    <div ref={ref} style={{ width: "100%" }}>
      {/* PAGE 1 */}
      <div className="page text-white min-h-screen flex-col items-center justify-center mb-5" style={{ background: '#00634F' }}>
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img
            src={admin_data.information.companylogo} // Replace with the actual path if needed
            alt="TVS Logo"
            style={{ maxWidth: 100 }}
          />
        </header>
        <div className="flex flex-col flex-1">
          <div className="p-10 mt-12" style={{ marginTop: 250 }}>
            <div className="mb-8">
              <img
                // Update path as needed
                src={require('../../../../assets/images/test.png').default}
                alt="TVS Logo"
                style={{ maxWidth: 250 }}
              />
            </div>
            <div className="p-5">
              <h1 className="text-4xl font-bold mb-2">My sustainability index for suppliers:</h1>
              <p className="text-xl font-medium mb-4">On-site MSI Calibration Report</p>
              <p className="text-md">February 28, 2024</p>
            </div>
          </div>
        </div>
      </div>

      {/* PAGE 2 */}
      <div className="page min-h-screen p-8 mb-5" style={{ background: "#FFFFFF" }}>
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img src={admin_data.information.companylogo} alt="Logo" style={{ height: "25px" }} />
        </header>

        <h1 className="text-2xl font-bold">
          Calibration ID:
          <span className="text-gray-500">
            {getMSIIDByAssignment(data)}
          </span>
        </h1>

        <div className="grid grid-cols-2 gap-8 mb-12">
          <div>
            <p><strong>Supplier name:</strong> {report?.vendor?.supplierName}</p>
            <p><strong>Vendor code:</strong> {report?.vendor?.code}</p>
            <p><strong>Audit date:</strong>{getDate(report?.auditorAssignmentSubmission?.submitted_on)}</p>
          </div>
          <div>
            <p><strong>Supplier category:</strong> {categoryList.find(x => x.value === report?.vendor?.supplierCategory)?.name}</p>
            <p><strong>Supplier location:</strong> {report?.vendor?.supplierLocation}</p>
            <p><strong>Audit team:</strong> Vincent, Kalpesh</p>
          </div>
        </div>
        <div className="row mb-12" style={{ marginBottom: 40 }}>
          <p><strong>MSI Score:</strong> {report?.auditorAssignmentSubmission?.auditorMSIScore}</p>
          <p><strong>MSI Rank:</strong> 45</p>
          <p><strong>Number of critical non-compliance:</strong> 10</p>
        </div>
        <div className="grid grid-cols-2 gap-8">
          <div className="bg-blue-50 p-6 rounded-lg">
            <h2 className="text-lg font-semibold mb-4">
              ESG SCORE OF CALIBRATED SUPPLIERS
            </h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={barData}>
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="maxScore" fill="#b3d4fc" name="Max score" />
                <Bar dataKey="avgScore" fill="#4a90e2" name="Average score achieved by suppliers" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="bg-green-50 p-6 rounded-lg">
            <h2 className="text-lg font-semibold mb-4">SUPPLIER SUSTAINABILITY SCORE</h2>
            <ResponsiveContainer width="100%" height={300}>
              <RadarChart data={radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="category" />
                <Radar
                  name="Score"
                  dataKey="value"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* PAGE 3 */}
      <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img src={admin_data.information.companylogo} alt="Logo" style={{ height: "25px" }} />
        </header>
        <Section
          title="ENVIRONMENTAL SECTION PERFORMANCE"
          data={dataEnvironmental}
          barColors={["#90ee90", "#008000"]}
          backgroundColor="#e7f8f2"
        />
        <Section
          title="SOCIAL SECTION PERFORMANCE"
          data={dataSocial}
          barColors={["#ffcccb", "#ff4500"]}
          backgroundColor="#ffece7"
        />
        <Section
          title="GOVERNANCE SECTION PERFORMANCE"
          data={dataGovernance}
          barColors={["#d3d3d3", "#2f4f4f"]}
          backgroundColor="#eef3fb"
        />
      </div>

      {/* PAGE 4 */}
      <div
        className="page bg-white mb-5"
        style={{ fontFamily: "Arial, sans-serif", padding: "40px", lineHeight: "1.6" }}
      >
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img src={admin_data.information.companylogo} alt="Logo" style={{ height: "25px" }} />
        </header>

        <section style={{ marginBottom: "40px" }}>
          <h1>Supplier Information</h1>
          <p style={{ color: "gray", fontSize: "1.2rem" }}>
            (supplier name, nature of business, turnover, no. of units)
          </p>
        </section>

        <section style={{ marginBottom: "40px" }}>
          <h2>M/s Multilink</h2>
          <ul>
            <li>Founded in 1984 by Mr. Vasuki</li>
            <li>Operates 4 locations</li>
            <li>Serves 2-wheeler OEMs TVS, Bajaj, Suzuki</li>
            <li>Certified for IATF 16949, ISO 9001, ISO 14001</li>
            <li>Quality systems integrate customer-specific requirements</li>
            <li>Over 500 satisfied employees in the Hosur plant</li>
            <li>Prioritizes training & competency building</li>
          </ul>

          <h3>Nature of business with TVS Motor:</h3>
          <p>
            Their Hosur unit was established around 13 years back, supplying parts
            primarily to TVS Motor, Hosur plant. In 2023-24 they supplied us parts
            like Side stand switch, ~ INR 2-- cr. 60% from TVSM.
          </p>
        </section>

        <section style={{ marginBottom: "40px" }}>
          <Card style={{ border: "none", marginBottom: "20px" }}>
            <Card.Body>
              <Card.Title>Good Practices</Card.Title>
              <Card.Text>
                Actions, processes, or initiatives that go beyond compliance and
                demonstrate exemplary efforts in sustainability. These practices
                are innovative, scalable, and have a significant positive
                environmental, social, or governance impact.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ border: "none", marginBottom: "20px" }}>
            <Card.Body>
              <Card.Title>Opportunity for Improvement</Card.Title>
              <Card.Text>
                Areas where existing processes, policies, or practices meet
                minimum requirements but can be enhanced to deliver better
                sustainability outcomes. These do not represent non-conformance
                but provide room for growth and optimization.
              </Card.Text>
            </Card.Body>
          </Card>

          <h3 style={{ marginTop: "40px", color: "gray" }}>Non-Conformances</h3>

          <Card style={{ border: "none", marginBottom: "20px" }}>
            <Card.Body>
              <Card.Title>Critical Compliance Issues</Card.Title>
              <Card.Text>
                Findings highlighting severe lapses that could lead to
                regulatory penalties or serious incidents, posing significant
                risks to operations and stakeholders, and resulting in negative
                scoring as part of the assessment.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ border: "none", marginBottom: "20px" }}>
            <Card.Body>
              <Card.Title>Regulatory Compliance</Card.Title>
              <Card.Text>
                Failure to meet the statutory and legal requirements applicable
                to the organization's operations, resulting in risks to the
                sustainability objectives and compliance framework.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ border: "none", marginBottom: "20px" }}>
            <Card.Body>
              <Card.Title>Minor Non-Conformance</Card.Title>
              <Card.Text>
                Small deviations from established standards, policies, or
                procedures that do not pose an immediate or significant risk
                but need correction to prevent escalation into major issues.
              </Card.Text>
            </Card.Body>
          </Card>
        </section>
      </div>

      {/* PAGE 5 */}
      <div
        className="page bg-white mb-5"
        style={{ fontFamily: "Arial, sans-serif", padding: "40px", lineHeight: "1.6" }}
      >
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img
            src={admin_data.information.companylogo}
            alt="TVS Logo"
            style={{ height: "25px" }}
          />
        </header>
        {Object.entries(report?.response || {}).map((row, index) => (
          <section>
            <h1 className="fw-bold">{row[0]}</h1>
            {row[1].map((section) => (
              <div>
              <Table bordered>
                <thead style={{ backgroundColor: "#002147", color: "#fff" }}>
                  <tr>
                    <th style={{ background: "#002369", color: "#fff" }}>{section.title}</th>
                    <th style={{ background: "#002369", color: "#fff" }}>
                      Max. score: 10
                    </th>
                    <th style={{ background: "#002369", color: "#fff" }}>
                      Score obtained: {section.totalScore }
                    </th>
                  </tr>
                </thead>
              </Table>
              {Object.entries(section?.findings || {})?.map((row, index) => (
            <div
              key={index}
              style={{
                marginBottom: "20px",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <h4>{row[0]}</h4>

              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ background: "#B5EECE" }}>Finding</th>
                    <th style={{ background: "#B5EECE" }}>Category</th>
                    <th style={{ background: "#B5EECE" }}>Evidence</th>
                  </tr>
                </thead>
                <tbody>
                  {row[1].map((item, idx2) => (
                    <tr key={idx2}>
                      <td style={{ background: "#DCFFEB" }}>{item.finding}</td>
                      {item.category && (
                        <td style={{ background: "#DCFFEB" }}>{item.category}</td>
                      )}
                      <td style={{ background: "#DCFFEB" }}>
                        <ul>
                          {item?.auditorAttachments?.map((evi, id) => (
                            <li className='table-link-clickable' onClick={()=>{ window.open(API.Docs + evi.originalname)}} key={id}>{evi.originalname}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

            </div>
          ))

          }
              </div>
            ))}

          </section>
        ))}
      
      </div>

      {/* PAGE 6 */}
      <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img
            src={admin_data.information.companylogo}
            alt="TVS Logo"
            style={{ height: "25px" }}
          />
        </header>

        {sections.map((section, idx) => (
          <section key={idx} style={{ marginBottom: "40px" }}>
            <Table bordered>
              <thead style={{ backgroundColor: "#002147", color: "#fff" }}>
                <tr>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    {section.title}
                  </th>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    Max. score: {section.maxScore}
                  </th>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    Score obtained: {section.scoreObtained}
                  </th>
                </tr>
              </thead>
            </Table>

            {section.rows.map((row, index2) => (
              <div
                key={index2}
                style={{
                  marginBottom: "20px",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <h4>{row.category}</h4>
                {row.category === "GOOD PRACTICES" ? (
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th style={{ background: "#B5EECE" }}>Finding</th>
                        {row.data[0].category && (
                          <th style={{ background: "#B5EECE" }}>Category</th>
                        )}
                        <th style={{ background: "#B5EECE" }}>Evidence</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.data.map((item, idx3) => (
                        <tr key={idx3}>
                          <td style={{ background: "#DCFFEB" }}>{item.finding}</td>
                          {item.category && (
                            <td style={{ background: "#DCFFEB" }}>{item.category}</td>
                          )}
                          <td style={{ background: "#DCFFEB" }}>
                            <ul>
                              {item.evidence.map((evi, id4) => (
                                <li key={id4}>{evi}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th style={{ background: "#EAEAEA" }}>Finding</th>
                        {row.data[0].category && (
                          <th style={{ background: "#EAEAEA" }}>Category</th>
                        )}
                        <th style={{ background: "#EAEAEA" }}>Evidence</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.data.map((item, idx3) => (
                        <tr key={idx3}>
                          <td style={{ background: "#F4F4F4" }}>{item.finding}</td>
                          {item.category && (
                            <td style={{ background: "#F4F4F4" }}>{item.category}</td>
                          )}
                          <td style={{ background: "#F4F4F4" }}>
                            <ul>
                              {item.evidence.map((evi, id4) => (
                                <li key={id4}>{evi}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            ))}
          </section>
        ))}
      </div>

      {/* PAGE 7 */}
      <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img
            src={admin_data.information.companylogo}
            alt="TVS Logo"
            style={{ height: "25px" }}
          />
        </header>

        <h1>Social</h1>
        {sections.map((section, idx) => (
          <section key={idx} style={{ marginBottom: "40px" }}>
            <Table bordered>
              <thead style={{ backgroundColor: "#002147", color: "#fff" }}>
                <tr>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    {section.title}
                  </th>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    Max. score: {section.maxScore}
                  </th>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    Score obtained: {section.scoreObtained}
                  </th>
                </tr>
              </thead>
            </Table>

            {section.rows.map((row, index2) => (
              <div
                key={index2}
                style={{
                  marginBottom: "20px",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <h4>{row.category}</h4>
                {row.category === "GOOD PRACTICES" ? (
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th style={{ background: "#B5EECE" }}>Finding</th>
                        {row.data[0].category && (
                          <th style={{ background: "#B5EECE" }}>Category</th>
                        )}
                        <th style={{ background: "#B5EECE" }}>Evidence</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.data.map((item, idx3) => (
                        <tr key={idx3}>
                          <td style={{ background: "#DCFFEB" }}>{item.finding}</td>
                          {item.category && (
                            <td style={{ background: "#DCFFEB" }}>{item.category}</td>
                          )}
                          <td style={{ background: "#DCFFEB" }}>
                            <ul>
                              {item.evidence.map((evi, id4) => (
                                <li key={id4}>{evi}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th style={{ background: "#EAEAEA" }}>Finding</th>
                        {row.data[0].category && (
                          <th style={{ background: "#EAEAEA" }}>Category</th>
                        )}
                        <th style={{ background: "#EAEAEA" }}>Evidence</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.data.map((item, idx3) => (
                        <tr key={idx3}>
                          <td style={{ background: "#F4F4F4" }}>{item.finding}</td>
                          {item.category && (
                            <td style={{ background: "#F4F4F4" }}>{item.category}</td>
                          )}
                          <td style={{ background: "#F4F4F4" }}>
                            <ul>
                              {item.evidence.map((evi, id4) => (
                                <li key={id4}>{evi}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            ))}
          </section>
        ))}
      </div>

      {/* PAGE 8 */}
      <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img
            src={admin_data.information.companylogo}
            alt="TVS Logo"
            style={{ height: "25px" }}
          />
        </header>

        <h1 className="fw-bold">Safety</h1>
        {sections1.map((section, idx) => (
          <section key={idx} style={{ marginBottom: "40px" }}>
            <Table bordered>
              <thead style={{ backgroundColor: "#002147", color: "#fff" }}>
                <tr>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    {section.title}
                  </th>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    Max. score: {section.maxScore}
                  </th>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    Score obtained: {section.scoreObtained}
                  </th>
                </tr>
              </thead>
            </Table>

            {section.rows.map((row, index2) => (
              <div
                key={index2}
                style={{
                  marginBottom: "20px",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ background: "#EAEAEA" }}>Finding</th>
                      {row.data[0].category && (
                        <th style={{ background: "#EAEAEA" }}>Category</th>
                      )}
                      <th style={{ background: "#EAEAEA" }}>Evidence</th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.data.map((item, idx3) => (
                      <tr key={idx3}>
                        <td style={{ background: "#F4F4F4" }}>{item.finding}</td>
                        {item.category && (
                          <td style={{ background: "#F4F4F4" }}>{item.category}</td>
                        )}
                        <td style={{ background: "#F4F4F4" }}>
                          <ul>
                            {item.evidence.map((evi, id4) => (
                              <li key={id4}>{evi}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))}
          </section>
        ))}
      </div>

      {/* PAGE 9 */}
      <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
        <header style={{ marginBottom: "40px", textAlign: "right" }}>
          <img
            src={admin_data.information.companylogo}
            alt="TVS Logo"
            style={{ height: "25px" }}
          />
        </header>

        <h1 className="fw-bold">Governance</h1>
        {sections2.map((section, idx) => (
          <section key={idx} style={{ marginBottom: "40px" }}>
            <Table bordered>
              <thead>
                <tr>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    {section.title}
                  </th>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    Max. score: {section.maxScore}
                  </th>
                  <th style={{ background: "#002369", color: "#fff" }}>
                    Score obtained: {section.scoreObtained}
                  </th>
                </tr>
              </thead>
            </Table>

            {section.rows.map((row, index2) => (
              <div
                key={index2}
                style={{
                  marginBottom: "20px",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ background: "#EAEAEA" }}>Finding</th>
                      {row.data[0].category && (
                        <th style={{ background: "#EAEAEA" }}>Category</th>
                      )}
                      <th style={{ background: "#EAEAEA" }}>Evidence</th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.data.map((item, idx3) => (
                      <tr key={idx3}>
                        <td style={{ background: "#F4F4F4" }}>{item.finding}</td>
                        {item.category && (
                          <td style={{ background: "#F4F4F4" }}>{item.category}</td>
                        )}
                        <td style={{ background: "#F4F4F4" }}>
                          <ul>
                            {item.evidence.map((evi, id4) => (
                              <li key={id4}>{evi}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))}
          </section>
        ))}
      </div>
    </div>
  );
});

export default DealerReport;
