import React from 'react';
// PrimeReact components (optional for the dropdowns & table)
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

/** ------------- Color-coded Table Setup ------------- **/
const HEADERS = [
  'Policy & Commitment',
  'Water Risk Assessment',
  'Efficiency & Reduction Initiatives',
  'Wastewater Treatment & Discharge',
  'Water Stewardship & Community Engagement',
  'Targets & Performance Tracking',
  'Water accounting or auditing performed',
  'Technologies or practices to recycle or reuse water',
  'Product Water footprint',
];

// Colors for Shannon/Travis rows
const CELL_COLORS = [
  '#f3f5f9', // col1
  '#f3f5f9', // col2
  '#ffffff', // col3
  '#FEF5E5', // col4 (light orange)
  '#E5F5EA', // col5 (light green)
  '#ffffff', // col6
  '#E5F5EA', // col7 (light green)
  '#FEF5E5', // col8 (light orange)
  '#E5F5EA', // col9 (light green)
];

// Colors for Consolidated row
const CONSOLIDATED_COLORS = [
  '#ffffff', // col1
  '#ffffff', // col2
  '#E5F5EA', // col3
  '#FEF5E5', // col4
  '#E5F5EA', // col5
  '#ffffff', // col6
  '#E5F5EA', // col7
  '#FEF5E5', // col8
  '#E5F5EA', // col9
];

function HeaderRow() {
  return (
    <div style={styles.headerRow}>
      <div style={styles.headerCellUser}>User</div>
      {HEADERS.map((header, i) => (
        <div key={i} style={styles.headerCell}>{header}</div>
      ))}
    </div>
  );
}

function DataRow({ user, avatar, colorArr }) {
  return (
    <div style={styles.dataRow}>
      <div style={styles.userCell}>
        <div style={styles.avatarCircle}>{avatar}</div>
        {user}
      </div>
      {HEADERS.map((header, i) => (
        <div
          key={i}
          style={{
            ...styles.dataCell,
            backgroundColor: colorArr[i],
          }}
        >
          {header}
        </div>
      ))}
    </div>
  );
}

/** ------------- Document Repository Setup ------------- **/
function DocumentRepository({ documents }) {
  const handleDownload = (rowData) => {
    alert(`Downloading artifact: ${rowData.artifact}`);
  };

  const downloadBodyTemplate = (rowData) => (
    <i
      className="pi pi-download"
      style={{ cursor: 'pointer', fontSize: '1.2rem' }}
      onClick={() => handleDownload(rowData)}
    />
  );

  return (
    <div style={{ marginBottom: '2rem' }}>
      <h5 style={{ marginBottom: '1rem' }}>Document Repository</h5>
      <DataTable
        value={documents}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        responsiveLayout="scroll"
      >
        <Column field="artifact" header="Artifact" style={{ width: '15%' }} />
        <Column field="description" header="Description" style={{ width: '40%' }} />
        <Column field="uploader" header="Uploaded By" style={{ width: '20%' }} />
        <Column field="date" header="Uploaded On" style={{ width: '15%' }} />
        <Column
          header=""
          body={downloadBodyTemplate}
          style={{ width: '10%', textAlign: 'center' }}
        />
      </DataTable>
    </div>
  );
}

/** ------------- Main Page Content (without header/sidebar) ------------- **/
export default function ESGContentOnly() {
  // Example data for dropdowns
  const categoryOptions = ['All', 'Water', 'Energy', 'Waste'];
  const topicOptions = ['All', 'Topic A', 'Topic B'];
  const frameworkOptions = ['All', 'Framework A', 'Framework B'];

  // Example data for the Document Repository
  const documents = [
    {
      artifact: 'Perferendis Molesti',
      description: 'Id voluptates natus delectus et nemo non.',
      uploader: 'Shannon Murphy',
      date: 'Mar 05 2025',
    },
    {
      artifact: 'Tempore Saepe Illo',
      description: 'Voluptate qui sint.',
      uploader: 'Travis Altenwerth',
      date: 'Mar 05 2025',
    },
    {
      artifact: 'Ad Et Quia',
      description: 'Sed temporibus et esse tenetur ullam.',
      uploader: 'Shannon Murphy',
      date: 'Mar 04 2025',
    },
    {
      artifact: 'Nobis Unde Quia',
      description: 'Ipsam culpa vitae dolorem sed commodi omnis ab.',
      uploader: 'Travis Altenwerth',
      date: 'Mar 05 2025',
    },
  ];

  // "Selected" states for the dropdowns (could come from a parent or from useState, etc.)
  const [selectedCategory, setSelectedCategory] = React.useState('All');
  const [selectedTopic, setSelectedTopic] = React.useState('All');
  const [selectedFramework, setSelectedFramework] = React.useState('All');

  return (
    <div style={styles.pageContainer}>
      
      {/* 1) Qualitative Data Response header, filters, and legend */}
      <h2 style={{ marginBottom: '0.5rem' }}>Qualitative Data Response Status &amp; Collation</h2>
      <p style={{ margin: '0 0 1rem 0', color: '#6c757d' }}>Eum iusto architecto minus rerum.</p>

      {/* Filters Row */}
      <div style={styles.filtersRow}>
        <div style={styles.filterItem}>
          <label>Category</label>
          <Dropdown
            value={selectedCategory}
            options={categoryOptions}
            onChange={(e) => setSelectedCategory(e.value)}
            style={{ width: '100%' }}
          />
        </div>
        <div style={styles.filterItem}>
          <label>Topic</label>
          <Dropdown
            value={selectedTopic}
            options={topicOptions}
            onChange={(e) => setSelectedTopic(e.value)}
            style={{ width: '100%' }}
          />
        </div>
        <div style={styles.filterItem}>
          <label>Framework</label>
          <Dropdown
            value={selectedFramework}
            options={frameworkOptions}
            onChange={(e) => setSelectedFramework(e.value)}
            style={{ width: '100%' }}
          />
        </div>
        
        {/* Legend */}
        <div style={styles.legendContainer}>
          <div style={styles.legendItem}>
            <span style={{ ...styles.legendCircle, backgroundColor: '#f0ad4e' }} />
            Information required
          </div>
          <div style={styles.legendItem}>
            <span style={{ ...styles.legendCircle, backgroundColor: '#5cb85c' }} />
            Draft
          </div>
          <div style={styles.legendItem}>
            <span style={{ ...styles.legendCircle, backgroundColor: '#0275d8' }} />
            Data finalised
          </div>
        </div>
      </div>

      <h3 style={{ marginTop: '1.5rem' }}>Environment</h3>
      
      {/* 2) MANAGEMENT OF WATER RELATED ISSUES */}
      <h4 style={{ marginTop: '1.5rem' }}>MANAGEMENT OF WATER RELATED ISSUES</h4>
      <div style={styles.tableContainer}>
        <HeaderRow />
        <DataRow user="Shannon Murphy" avatar="SM" colorArr={CELL_COLORS} />
        <DataRow user="Travis Altenwerth" avatar="TA" colorArr={CELL_COLORS} />
        <DataRow user="Consolidated view" avatar="CV" colorArr={CONSOLIDATED_COLORS} />
      </div>

      <DocumentRepository documents={documents} />

      {/* 3) TOPIC 2 */}
      <h4 style={{ marginTop: '2rem' }}>TOPIC 2</h4>
      <div style={styles.tableContainer}>
        <HeaderRow />
        <DataRow user="Shannon Murphy" avatar="SM" colorArr={CELL_COLORS} />
        <DataRow user="Travis Altenwerth" avatar="TA" colorArr={CELL_COLORS} />
      </div>

      <DocumentRepository documents={documents} />
      
    </div>
  );
}

/* ------------- Inline Styles ------------- */
const styles = {
  pageContainer: {
    // maxWidth: '1200px',
    // margin: '0 auto',
    padding: '1.5rem',
  },
  filtersRow: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '1rem',
    marginBottom: '1rem',
  },
  filterItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  legendContainer: {
    display: 'flex',
    gap: '1.5rem',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.9rem',
    whiteSpace: 'nowrap',
  },
  legendCircle: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },

  /* Table container */
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #dee2e6',
    marginBottom: '2rem',
  },
  headerRow: {
    display: 'flex',
    borderBottom: '1px solid #dee2e6',
    backgroundColor: '#f8f9fa',
  },
  dataRow: {
    display: 'flex',
    borderBottom: '1px solid #dee2e6',
  },
  headerCellUser: {
    flex: '0 0 180px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '12px',
    borderRight: '1px solid #dee2e6',
  },
  headerCell: {
    flex: 1,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '12px',
    borderRight: '1px solid #dee2e6',
  },
  userCell: {
    flex: '0 0 180px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px',
    borderRight: '1px solid #dee2e6',
    fontWeight: '600',
    backgroundColor: '#ffffff',
  },
  avatarCircle: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#007bff',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dataCell: {
    flex: 1,
    textAlign: 'center',
    padding: '12px',
    borderRight: '1px solid #dee2e6',
    fontSize: '0.9rem',
  },
};
