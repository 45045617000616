
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import { API } from "../../constants/api_url";
import { DateTime } from "luxon";
const initialState = {
    // profile:{id:'12',name:'Venkat',mobno:'0000000'}
    userdetail: {},
    admindetail: {},
    config: [],
    tierLabel: [],
    fyStartMonth: { fymonth: 1 },
    tvsSubAdmin: false,
    tvsAudit: false,
    tvsSubAdminRoles: [],
    allRoles: [],
    currentVendorCode: null,
    allVendorCodes: []
}
export const fetchApi = createAsyncThunk('api/fetchApi1', async (token) => {
    return axios.get(API.GetMeNew, { headers: { "Authorization": "Bearer" + " " + token } }).then((res) => res.data)
})

export const fetchApi_Vendor = createAsyncThunk('api/fetchApi3', async ({ token, vendorCode }) => {
    return axios.get(API.GetMeNew, { headers: { "Authorization": "Bearer" + " " + token } }).then((res) => vendorCode ? { ...res.data, vendorCode } : res.data)
})
export const fetchApi_TVS = createAsyncThunk('api/fetchApi2', async ({ token, id_token }) => {
    return axios.get(API.TVS_GetMe, {
        headers: {
            "Authorization": "Bearer " + token,
            "x-id-token": id_token
        }
    }).then((res) => res.data)
})

const setData = (state, action) => {
    console.log(action)
    if (action.payload.role === 'clientadmin' || action.payload.role === 'clientuser' || action.payload.role === 'clientsupplier' || action.payload.role === 'clientdealer' || action.payload.role === 'eisqradmin' || action.payload.role === 'consultantadmin') {

        let data = action.payload
        if (data.role === 'clientadmin') {
            console.log(DateTime.fromISO(data.fyStartMonth, { zone: 'utc' }).toLocal().month, DateTime.fromISO(data.fyStartMonth, { zone: 'utc' }).month + 1)
            data.information['cid'] = data.id
            data.clientId = data.id
            state.tierLabel = data.tierLabel ? data.tierLabel : ['Country', 'City', 'Site']
            state.fyStartMonth = data.fyStartMonth ? { fymonth: DateTime.fromISO(data.fyStartMonth, { zone: 'utc' }).plus({ months: 1 }).month } : { fymonth: 1 }
            state.admindetail = data;
            state.userdetail = data;
            state.tvsSubAdmin = false
            state.tvsSubAdminRoles = []
            state.tvsAudit = data.id === 289 ? true : false
        } else if (data.role === 'clientuser' && data.roles_data && data.admin) {
            let accept_roles = [2, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
            let roles = data.roles_data.flatMap(i => i.roles).some(i => accept_roles.includes(i))
            console.log(data.roles_data)
            if (roles) {
                state.allRoles = Array.from(new Set(data.roles_data.flatMap(i => i.roles)))
                let tvsSubAdminRole = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
                state.tvsAudit = false
                state.tvsSubAdmin = data.roles_data.flatMap(i => i.roles).some(i => tvsSubAdminRole.includes(i))
                if (data.roles_data.flatMap(i => i.roles).some(i => tvsSubAdminRole.includes(i))) {
                    state.tvsSubAdminRoles = Array.from(new Set(data.roles_data.flatMap(i => i.roles).filter(i => tvsSubAdminRole.includes(i))))
                } else {
                    state.tvsSubAdminRoles = []
                }
                state.admindetail = { ...data.admin }
                state.userdetail = { ...data }
                state.tierLabel = data.admin.tierLabel ? data.admin.tierLabel : ['Country', 'City', 'Site']
                state.fyStartMonth = data.admin.fyStartMonth ? { fymonth: DateTime.fromISO(data.admin.fyStartMonth, { zone: 'utc' }).plus({ months: 1 }).month } : { fymonth: 1 }

            } else {
                state.tvsSubAdmin = false
                localStorage.removeItem("token");
                Swal.fire({
                    icon: 'warning',
                    title: 'Alert',
                    html: `<div>You have successfullly logged in to the Navigos Application. However, it appears you do not have any roles assigned within the application. You will be able to access the application only if you have specific role assignments. 
<p>Please contact the support team at support@eisqr.com for assistance.</p> </div>`,
                    returnInputValueOnDeny: () => {
                        console.log('deny')
                    }
                })
            }

        } else if ((data.role === 'clientsupplier' || data.role === 'clientdealer') && data.admin && data.vendorCodes && data.vendorCodes.length) {
            state.currentVendorCode = data.vendorCodes.find(x => x.code === data.vendorCode)
            state.tvsAudit = false
            state.admindetail = { ...data.admin }
            state.userdetail = { ...data }
            state.allVendorCodes = data.vendorCodes
        }
        else if (data.role !== 'clientuser' && data.role !== 'clientsupplier' && data.role !== 'clientdealer') {

            state.admindetail = { ...data }
            state.userdetail = { ...data }
        } else {
            state.allVendorCodes = []
            state.currentVendorCode = null
            localStorage.removeItem("token");
            Swal.fire({
                icon: 'warning',
                title: 'Alert',
                text: data.role === 'clientsupplier' ? "Vendor Code not configured for you, contact admin for futher details" : 'Seems you are not onboarded properly, contact admin for futher details',
                returnInputValueOnDeny: () => {
                    console.log('deny')
                }
            })
        }



    } else {
        localStorage.removeItem("token");
        Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            html: `<div>You will not able to access the application. 
            <p>Please contact the support team at support@eisqr.com for assistance.</p> </div>`,
            returnInputValueOnDeny: () => {
                console.log('deny')
            }
        })
    }

}

const userProfile = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedUserDetail: (state, action) => { state.userdetail = action.payload },
        setCurrentVendorCode: (state, action) => { state.currentVendorCode = action.payload },
        resetLoggedUserDetail: (state) => {
            // localStorage.clear();
            localStorage.removeItem('token')
            state.tvsSubAdmin = false;
            state.tvsSubAdminRoles = [];
            state.allRoles = [];
            state.allVendorCodes = [];
            state.currentVendorCode = null;
            sessionStorage.clear(); state.userdetail = {}
        },

    },
    extraReducers: (builder) => {

        builder.addCase(fetchApi.fulfilled, (state, action) => { setData(state, action) })
            .addCase(fetchApi_TVS.fulfilled, (state, action) => { setData(state, action) })
            .addCase(fetchApi_Vendor.fulfilled, (state, action) => { setData(state, action) })


    }



})

export default userProfile.reducer
export const { setLoggedUserDetail, resetLoggedUserDetail, setCurrentVendorCode } = userProfile.actions
