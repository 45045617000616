import React, { useState, useEffect } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { TabMenu } from "primereact/tabmenu";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import { Calendar } from 'primereact/calendar';
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { MultiSelect } from "primereact/multiselect";
import { adjustColumnWidths } from "../../../../components/BGHF/helper";
import * as XLSX from "xlsx";
import { Button } from "primereact/button";



const SAPRawDataDisplay = () => {

    const [activeindex, setActiveIndex] = useState(0);
    const [data, setData] = useState([])
    const [load, setLoad] = useState(false)
    const [filter, setFilter] = useState({ startDate: null, endDate: null })
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const forceUpdate = useForceUpdate()
    useEffect(() => {
        const now = DateTime.local();
        setFilter((prev) => ({ ...prev, startDate: now.startOf('month').toJSDate(), "endDate": now.endOf('month').toJSDate() }))
        setLoad(true)
        updateDate()


    }, [])
    const getDate = (rowData) => {
        console.log(rowData)
        if (!rowData) {
            return ''
        }
        if (typeof rowData === 'string') {
            if (DateTime.fromFormat(rowData, 'yyyyMdd').isValid) {
                return DateTime.fromFormat(rowData, 'yyyyMdd').toFormat('dd-MM-yyyy')
            }
        } else if (rowData.Date && DateTime.fromFormat(rowData.Date, 'yyyyMdd').isValid) {
            return DateTime.fromFormat(rowData.Date, 'yyyyMdd').toFormat('dd-MM-yyyy')
        }
        return 'Invalid Date'

    }
    const updateDate = (sd, ed) => {
        if (sd && ed) {
            const startDate = DateTime.fromJSDate(sd).toFormat('ddMMyyyy')
            const endDate = DateTime.fromJSDate(ed).toFormat('ddMMyyyy')
            setLoad(true)
            let sapfilter = { where: { sapId: 'SAP' + (activeindex + 1) } }
            APIServices.post(API.SapResponseCustom_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(sapfilter))}`, activeindex === 5 ? {} : { startDate, endDate }).then(res => {
                setLoad(false)
                if (res.data.result) {
                    setData(res.data.data)
                    forceUpdate()
                } else {
                    setData([])
                }
            }).catch((e) => {
                setLoad(false)
            })

        } else if (!sd && !ed) {
            const now = DateTime.local();
            const startDate = now.startOf('month').toFormat('ddMMyyyy');
            const endDate = now.endOf('month').toFormat('ddMMyyyy');
            setLoad(true)
            let sapfilter = { where: { sapId: 'SAP' + (activeindex + 1) } }
            APIServices.post(API.SapResponseCustom_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(sapfilter))}`, activeindex === 5 ? {} : { startDate, endDate }).then(res => {
                setLoad(false)
                if (res.data.result) {
                    setData(res.data.data)
                    forceUpdate()
                } else {
                    setData([])
                }
            }).catch((e) => {
                setLoad(false)
            })
        } else {
            setData([])
        }

    }
    const updateDataByTab = (val) => {
        if (filter.startDate && filter.endDate) {

            if (tabList[val].sapId) {
                setLoad(true)
                const startDate = DateTime.fromJSDate(filter.startDate).toFormat('ddMMyyyy')
                const endDate = DateTime.fromJSDate(filter.endDate).toFormat('ddMMyyyy')

                let sapfilter = { where: { sapId: tabList[val].sapId } }
                APIServices.post(API.SapResponseCustom_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(sapfilter))}`, val === 5 ? {} : { startDate, endDate }).then(res => {
                    setLoad(false)
                    if (res.data.result) {
                        setData(res.data.data)
                        forceUpdate()
                    } else {
                        setData([])
                    }
                }).catch((e) => {
                    setLoad(false)
                })

            } else {
                setLoad(false)
                setData([])
            }
        } else {
            setLoad(false)
            setData([])
        }
        setActiveIndex(val)
    }
    const locationFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(i => i.Plant)))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const fuelTypeFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(i => i.FuelType)))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const hazardTypeFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(i => i.WasteDescription)))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const dynamicFilterTemplate = (options, key) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(i => i[key] || ''))).filter(x => x)
        console.log(locationOptions, allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const exportReport = () => {
        // let data = getUnassignedEntities(indicatorlist,indicatorAssignments,rawsitelist).flatMap(i => i.entities.map( x => ({name:x.name,level:x.level, indicatorId:i.indicatorId,title:i.title}))   )
        let loc = JSON.parse(JSON.stringify(data)).map(i => ({ ...i, date_: getDate(i?.Date || null) }))

        if (loc.length) {

            let headers = [];
            if (activeindex === 0) {
                headers = [

                    { header: 'Date', key: 'date_' },
                    { header: 'Location', key: 'Location' },
                    { header: 'Fuel Type', key: 'FuelType' },
                    { header: 'Quantity', key: 'Quantity' },
                    { header: 'UOM', key: 'UoM' }
                ]
            } else if (activeindex === 1 || activeindex === 2) {
                headers = [

                    { header: 'Date', key: 'date_' },
                    { header: 'Location', key: 'Location' },
                    { header: 'Waste Description', key: 'WasteDescription' },
                    { header: 'Quantity', key: 'Quantity' },
                    { header: 'UOM', key: 'UoM' }
                ]
            } else if (activeindex === 3) {
                headers = [

                    { header: 'Month', key: 'Month' },
                    { header: 'Material Category', key: 'MaterialCategory' },
                    { header: 'Total Spent', key: 'TotalSpent' },
                    { header: 'UOM', key: 'UoM' }

                ]
            }
            else if (activeindex === 4) {
                headers = [

                    { header: 'Date', key: 'date_' },
                    { header: 'Location', key: 'Location' },
                    { header: 'Purchase Order No', key: 'PurchaseOrder' },

                    { header: 'HSN Code', key: 'HSNCode' },
                    { header: 'Material Description', key: 'MaterialDescription' },
                    { header: 'Total Spent', key: 'TotalSpent' },


                    { header: 'Quantity', key: 'Quantity' },
                    { header: 'UOM', key: 'UoM' },
                    { header: 'Vendor Code', key: 'VendorCode' },
                    { header: 'Supplier Name', key: 'SupplierName' }

                ]
            } else if (activeindex === 5) {
                headers = [
                    { header: 'Location', key: 'Location' },
                    { header: 'Employee ID', key: 'EmpId' },
                    { header: 'Origin of Travel', key: 'BTOrigin' },
                    { header: 'Destination of Travel', key: 'BTDestination' },
                    { header: 'Mode of Travel', key: 'BTMode' }
                ]
            }


            // Create worksheet
            const filteredData = loc.map(item =>
                headers.reduce((obj, header) => {
                    obj[header.key] = item[header.key] || '';
                    return obj;
                }, {})
            );

            const worksheet = XLSX.utils.json_to_sheet(filteredData, { header: headers.map(h => h.key) });
            // Set headers
            headers.forEach((header, index) => {
                const cell = XLSX.utils.encode_cell({ r: 0, c: index });
                worksheet[cell].v = header.header;
            });
            adjustColumnWidths(worksheet);

            // Create a new workbook
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

            XLSX.writeFile(workbook, (activeindex === 0 ? 'Fuel' : activeindex === 1 ? 'Hazardous Waste' : activeindex === 2 ? 'Non Hazardous Waste' : activeindex === 3 ? 'Purchased Goods & Services' : activeindex === 4 ? 'Capital Goods' : activeindex === 5 ? 'Business Travel' : '') + '.xlsx');

        }

    }
    const tabList = [
        { label: 'SAP1 - Fuel Data', icon: 'pi pi-receipt', sapId: 'SAP1', className: 'white-space-nowrap' },
        { label: 'SAP2 - Waste (Hazardous)', icon: 'pi pi-receipt', sapId: 'SAP2', className: 'white-space-nowrap' },
        { label: 'SAP3 - Waste (Non-Hazardous)', icon: 'pi pi-receipt', sapId: 'SAP3', className: 'white-space-nowrap' },
        { label: 'SAP4 - Purchased Goods and Services', icon: 'pi pi-receipt', sapId: 'SAP4', className: 'white-space-nowrap' },
        { label: 'SAP5 - Capital Goods', icon: 'pi pi-receipt', sapId: 'SAP5', className: 'white-space-nowrap' },
        { label: 'SAP6 - Business Travel', icon: 'pi pi-receipt', sapId: 'SAP6', className: 'white-space-nowrap' },
        { label: 'SAP7 - Downstream Transportation and Distribution', sapId: 'SAP7', className: 'white-space-nowrap' },
        { label: 'SAP8 - HR Employee Data', sapId: 'SAP8', className: 'white-space-nowrap' },
        { label: 'Employee Demograhics', icon: 'pi pi-receipt', className: 'white-space-nowrap' },
        { label: 'Employee Training', icon: 'pi pi-receipt', className: 'white-space-nowrap' }

    ]
    const sortRP = (e, obj) => {
        console.log(e);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a[obj], 'yyyyMdd');
                let dateB = DateTime.fromFormat(b[obj], 'yyyyMdd');


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a[obj], 'yyyyMdd');
                let dateB = DateTime.fromFormat(b[obj], 'yyyyMdd');
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const sortRP2 = (e, obj) => {
        console.log(e);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a[obj], 'dd-MM-yyyy');
                let dateB = DateTime.fromFormat(b[obj], 'dd-MM-yyyy');


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a[obj], 'dd-MM-yyyy');
                let dateB = DateTime.fromFormat(b[obj], 'dd-MM-yyyy');
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const sortRP3 = (e, obj) => {
        console.log(e);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a[obj], 'LLL-yyyy');
                let dateB = DateTime.fromFormat(b[obj], 'LLL-yyyy');


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a[obj], 'LLL-yyyy');
                let dateB = DateTime.fromFormat(b[obj], 'LLL-yyyy');
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const ageTemplate = (rowData) => {
        return Math.round(DateTime.utc().diff(DateTime.fromFormat(rowData.DOB, 'dd-MM-yyyy'), 'year').years)
    }
    const dobTemplate = (rowData) => {
        return DateTime.fromFormat(rowData.DOB, 'dd-MM-yyyy').toFormat('dd-MM-yyyy')
    }
    return (
        <div>
            <div className="col-12 flex align-items-center" >
                <span className="text-big-one"> Hello  {login_data?.role === 'clientadmin' ? login_data?.information?.companyname : login_data?.information?.empname} ! </span>   <span className="ml-1">{`<${login_data.email}>`} </span>
            </div>

            <div
                className="flex col-12 flex-start"
                style={{ flexDirection: "column" }}
            >
                <span className="text-big-one">
                    SAP Raw Data Repository
                </span>

            </div>
            <div className="flex col-12 grid m-0 p-0 " style={{ marginTop: '2rem' }}>
                <div className="col-2 flex justify-content-between align-items-center">
                    <label htmlFor="startDate">Start Date</label>
                    <Calendar
                        id="startDate"
                        value={filter.startDate}
                        onChange={(e) => { setFilter((prev) => ({ ...prev, startDate: e.value })) }}
                        dateFormat="dd/mm/yy"

                        placeholder="Select Start Date"
                    />
                </div>
                <div className="col-2 flex justify-content-between align-items-center">
                    <label htmlFor="endDate">End Date</label>
                    <Calendar
                        id="endDate"
                        value={filter.endDate}
                        onChange={(e) => { setFilter((prev) => ({ ...prev, endDate: e.value })) }}
                        dateFormat="dd/mm/yy"
                        placeholder="Select End Date"
                    />
                </div>
                <div className="col-1 flex justify-content-between align-items-center">
                    <Button label="Submit" disabled={!filter.startDate && !filter.endDate} onClick={() => { updateDate(filter.startDate, filter.endDate) }} />
                </div>
                {data.length !== 0 && <div className="col-7 align-items-center flex justify-content-end">
                    <Button onClick={() => { exportReport() }} label="Export Report" icon="pi pi-download" className="p-button-primary mr-3" />


                </div>}
            </div>
            {/* <div className="col-12 grid m-0 p-0">
                <div className="col-6">
                    <label className="flex">From - To </label>
                    <Calendar placeholder="From - To" value={filter.dateRange} style={{ width: 185 }} onChange={(e) => updateDate(e.value)} selectionMode="range" dateFormat="dd/mm/yy" />
                </div>
                {data.length !== 0 && <div className="col-6 flex justify-content-end">
                    <Button style={{ height: 50 }} onClick={() => { exportReport() }} label="Export Report" icon="pi pi-download" className="p-button-primary mr-3"/>


                </div>}
            </div > */}
            <div className="col-12">
                <TabMenu model={tabList} onTabChange={(e) => { updateDataByTab(e.index) }} activeIndex={activeindex} />
                {activeindex === 0 &&
                    <div>
                        <DataTable className="h-500" loading={load} value={data} filters={{ Plant: { value: null, matchMode: 'in' }, FuelType: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >
                            <Column field='Date' header={'Date'} sortFunction={(e) => { return sortRP(e, 'Date') }} sortable body={getDate} />
                            <Column header='Location' field='Plant' filter showFilterMatchModes={false} showApplyButton={true} filterElement={locationFilterTemplate} />
                            <Column field='FuelType' header={'Fuel Type'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={fuelTypeFilterTemplate} />
                            <Column field='Quantity' header={'Quantity'} body={(rowData) => { return <>{(parseFloat(rowData.Quantity)).toFixed(2)}</> }} />
                            <Column field='UoM' header={'UOM'} />
                        </DataTable>
                    </div>

                }
                {activeindex === 1 &&
                    <div>
                        <DataTable className="h-500" loading={load} value={data} filters={{ Plant: { value: null, matchMode: 'in' }, WasteDescription: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >
                            <Column field='Date' header={'Date'} sortFunction={(e) => { return sortRP(e, 'Date') }} sortable body={getDate} />
                            <Column header='Location' field='Plant' filter showFilterMatchModes={false} showApplyButton={true} filterElement={locationFilterTemplate} />
                            <Column field='WasteDescription' header={'Material Description'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={hazardTypeFilterTemplate} />
                            <Column field='Quantity' header={'Quantity'} body={(rowData) => { return <>{(parseFloat(rowData.Quantity)).toFixed(2)}</> }} />
                            <Column field='UoM' header={'UOM'} />
                        </DataTable>
                    </div>

                }
                {activeindex === 2 &&
                    <div>
                        <DataTable className="h-500" loading={load} value={data} filters={{ Plant: { value: null, matchMode: 'in' }, WasteDescription: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >
                            <Column field='Date' header={'Date'} sortFunction={(e) => { return sortRP(e, 'Date') }} sortable body={getDate} />
                            <Column header='Location' field='Plant' filter showFilterMatchModes={false} showApplyButton={true} filterElement={locationFilterTemplate} />
                            <Column field='WasteDescription' header={'Material Description'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'WasteDescription')} />
                            <Column field='Quantity' header={'Quantity'} body={(rowData) => { return <>{(parseFloat(rowData.Quantity)).toFixed(2)}</> }} />
                            <Column field='UoM' header={'UOM'} />
                        </DataTable>
                    </div>

                }
                {(activeindex === 3) &&
                    <div>
                        <DataTable className="h-500" loading={load} value={data} filters={{ Plant: { value: null, matchMode: 'in' }, MaterialCategory: { value: null, matchMode: 'in' }, VendorCode: { value: null, matchMode: 'in' }, SupplierName: { value: null, matchMode: 'in' }, MaterialDescription: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >
                            <Column field='Month' sortFunction={(e) => { return sortRP2(e, 'Month') }} sortable header={'Month'} />
                            <Column field='MaterialCategory' header={'Material Category'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'MaterialCategory')} />
                            <Column field='TotalSpent' header={'Total Spent'} body={(rowData) => { return <>{(parseFloat(rowData.TotalSpent)).toFixed(2)}</> }} />
                            <Column field='UoM' header={'UOM'} />

                        </DataTable>
                    </div>

                }
                {(activeindex === 4) &&
                    <div>
                        <DataTable className="h-500" loading={load} value={data} filters={{ Plant: { value: null, matchMode: 'in' }, MaterialDescription: { value: null, matchMode: 'in' }, VendorCode: { value: null, matchMode: 'in' }, SupplierName: { value: null, matchMode: 'in' }, MaterialDescription: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >
                            <Column field='Date' sortFunction={(e) => { return sortRP(e, 'Date') }} sortable header={'Date'} body={getDate} />

                            <Column header='Location' field='Plant' filter showFilterMatchModes={false} showApplyButton={true} filterElement={locationFilterTemplate} />
                            <Column field='PurchaseOrder' header={'Purchase Order No'} />
                            <Column field='HSNCode' header={'HSN Code'} />
                            <Column field='MaterialDescription' header={'Material Description'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'MaterialDescription')} />
                            <Column field='TotalSpent' header={'Total Spent'} />
                            <Column field='Currency' header={'Currency'} />
                            <Column field='Quantity' header={'Quantity'} body={(rowData) => { return <>{(parseFloat(rowData.Quantity)).toFixed(2)}</> }} />
                            <Column field='UoM' header={'UOM'} />
                            <Column field='VendorCode' header={'Vendor Code'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'VendorCode')} />
                            <Column field='SupplierName' header={'Supplier Name'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'SupplierName')} />

                        </DataTable>
                    </div>

                }
                {activeindex === 5 &&
                    <div>
                        <DataTable className="h-500" loading={load} value={data} filters={{ Plant: { value: null, matchMode: 'in' }, EmpId: { value: null, matchMode: 'in' }, BTOrigin: { value: null, matchMode: 'in' }, BTDestination: { value: null, matchMode: 'in' }, BTMode: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >


                            <Column header='Location' field='Plant' filter showFilterMatchModes={false} showApplyButton={true} filterElement={locationFilterTemplate} />

                            <Column field='EmpId' header={'TVS Emplpoyee ID'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'EmpId')} />
                            <Column field='BTOrigin' header={'Origin of Travel'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'BTOrigin')} />
                            <Column field='BTDestination' header={'Destination of Travel'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'BTDestination')} />
                            <Column field='BTMode' header={'Mode of Travel'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'BTMode')} />

                        </DataTable>
                    </div>

                }
                {activeindex === 6 &&
                    <div>
                        <DataTable className="h-500" loading={load} value={data} filters={{ Plant: { value: null, matchMode: 'in' }, EmpId: { value: null, matchMode: 'in' }, BTOrigin: { value: null, matchMode: 'in' }, BTDestination: { value: null, matchMode: 'in' }, BTMode: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >


                            <Column field='ProductType' header={'Product Type'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'ProductType')} />
                            <Column field='MaterialNumber' header={'Material Number'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'MaterialNumber')} />

                            <Column field='MaterialDescription' header={'Material Description'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'MaterialDescription')} />

                            <Column field='OriginLocation' header={'Origin Location'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'OriginLocation')} />
                            <Column field='DestinationLocation' header={'Destination Location'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'OriginDestination')} />
                            <Column field='Quantity' header={'Quantity'} body={(rowData) => { return <>{(parseFloat(rowData.Quantity)).toFixed(2)}</> }} />
                            <Column field='InvoiceAmount' header={'InvoiceAmount'} />
                            <Column field='Currency' header={'Currency'} />


                        </DataTable>
                    </div>

                }
                {activeindex === 7 &&
                    <div>
                        <DataTable className="h-500" loading={load} value={data} filters={{ Plant: { value: null, matchMode: 'in' }, EmpId: { value: null, matchMode: 'in' }, ContractType: { value: null, matchMode: 'in' }, EmpType: { value: null, matchMode: 'in' }, Gender: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >


                            <Column field='EmpId' header={'Employee Id'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'EmpId')} />
                            <Column field='ContractType' header={'Contract Type'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'ContractType')} />

                            <Column field='EmpType' header={'Employee Type'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'EmpType')} />
                            <Column field='DOB' sortFunction={(e) => { return sortRP2(e, 'DOB') }} body={dobTemplate} sortable header={'DOB'} />
                            <Column body={ageTemplate} field='id' sortFunction={(e) => { return sortRP2(e, 'DOB') }} sortable header={'Age (in year)'} />

                            <Column field='Gender' header={'Gender'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'Gender')} />
                            <Column field='Plant' header={'Office City'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={(option) => dynamicFilterTemplate(option, 'Plant')} />




                        </DataTable>
                    </div>

                }
                {[].includes(activeindex) &&
                    <div className="col-12 flex justify-content-center font-italic clr-gray-200
                   ">Data received from S3, its under review </div>}
                {[9, 10, 11].includes(activeindex) &&
                    <div className="col-12 flex justify-content-center font-italic clr-gray-200
                   ">Data has to be received </div>}
            </div>


        </div >
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SAPRawDataDisplay, comparisonFn);