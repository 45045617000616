// SupplierReport.js
import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  RadarChart, PolarGrid, PolarAngleAxis, Radar,
  CartesianGrid, ResponsiveContainer, BarChart, Bar,
  XAxis, YAxis, Tooltip, Legend
} from 'recharts';
import { Card, Table } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import { DateTime } from 'luxon';
import { groupArrayByKeys } from '../../../../components/BGHF/helper';
import APIServices from '../../../../service/APIService';
import { API } from '../../../../constants/api_url';
// import SubGraph1Demo from './reportGraph/SubGraph1Demo';
// import SubGraph3Demo from './reportGraph/SubGraph3Demo';
// import SubGraph4Demo from './reportGraph/SubGraph4Demo';
// import SubGraph5Demo from './reportGraph/SubGraph5Demo';
import ReactToPrint from 'react-to-print';
import { Button } from 'primereact/button';
import ReportSection from '../SupplierScreen/reportSection';
import './EnvironmentReport.css'
// import ESGGScores from '../adminscreen/DealersDashboard/ESGGScores';
import ESGScores from './DealerChat/ESGScores';
import ESG_Sales_Service from './DealerChat/ESG_Sales_Service';
import EnvironmentChart from './DealerChat/SalesServiceEnv';
import SocialChart from './DealerChat/SalesServiceSoc';
import GovernanceChart from './DealerChat/SalesServiceGov';
import GeneralChart from './DealerChat/SalesServiceGen';


const DealerCompletedReport = forwardRef((props, ref) => {
  console.log(props.report)
  console.log('report')

  const [actions, setActions] = useState([])


  const contentRef = useRef(null)
  const [data, setData] = useState(props.report)
  const [chart, setChart] = useState([])
  const categoryOptions = [
    { label: 'Good Practices', id: 1 },
    { label: 'Opportunity of Improvement', id: 2 },
    { label: 'Non-compliance', id: 3 },
  ];

  const nonComplianceOptions = [
    { label: 'Regulatory (Major)', id: 1 },
    { label: 'Regulatory (Minor)', id: 2 },
    { label: 'Minor', id: 3 },
  ];
  const [report, setReport] = useState({})
  useEffect(() => {
    if (contentRef.current) {
      console.log("Div element:", contentRef.current);
    }
  }, []);

  const stripHTML = (html) => {
    if (!html) return "Unnamed Section"; // Default name if label is empty
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "Unnamed Section";
  };


  useEffect(() => {
    // let loc = JSON.parse(JSON.stringify(data))

    APIServices.get(API.DealerAuditorSubmission_Edit_Response(props?.report?.dealerAuditorChecklistSubmission?.id)).then(res => {
      setChart(res.data)
    })
    APIServices.get(API.DealerAuditorSubmission_Edit(props?.report?.dealerAuditorChecklistSubmission?.id)).then(res => {

      // setReport(res.data)

      console.log(JSON.parse(res.data?.response))



      const groupedData = JSON.parse(res.data?.response).reduce((acc, item) => {
        if (!item.criteria) return acc; // Skip items without a valid criteria

        const criteriaKey = item.criteria; // Group by criteria
        const cleanLabel = stripHTML(item.label); // Remove HTML from section label

        if (!acc[criteriaKey]) {
          acc[criteriaKey] = {};
        }

        if (!acc[criteriaKey][cleanLabel]) {
          acc[criteriaKey][cleanLabel] = {};
        }

        // If checklist-group, group questions inside it and include `section`
        if (item.type === "checklist-group" && item.questions) {
          item.questions.forEach((question) => {
            const questionLabel = stripHTML(question.label);

            if (!acc[criteriaKey][cleanLabel][questionLabel]) {
              acc[criteriaKey][cleanLabel][questionLabel] = {
                section: item.section || "No Section", // Include section key from checklist-group
                questions: [],
              };
            }

            // Add question data including section
            acc[criteriaKey][cleanLabel][questionLabel].questions.push({
              ...question,
              section: item.section || "No Section", // Ensure each question carries its section
            });
          });
        }

        return acc;
      }, {});
      setActions(JSON.parse(res.data?.response))

      console.log(groupedData)

      // let auditorResponse = JSON.parse(res?.data?.response || '[]').flatMap(i => i.assessmentSubSection1s.map(x => ({ ...x, order: i.order, esg: i.order === 1 ? 1 : i.order >= 5 ? 3 : i.order ? 2 : null, categoryTitle: i.order === 1 ? "Environment" : i.order >= 5 ? 'Governance' : i.order ? "Social" : null }))).filter(x => x.categoryTitle)

      // for (const act of loc?.supplierActions || []) {
      //   let order = auditorResponse.find(i => i.id === act.assessmentSubSection2Id)?.order
      //   act.category = act.categoryOfFinding === 3 ? categoryOptions.find(c => c.id === act.categoryOfFinding)?.label + ' - ' + nonComplianceOptions.find(c => c.id === act.nonComplianceType)?.label : categoryOptions.find(c => c.id === act.categoryOfFinding)?.label
      //   act.esg = order === 1 ? 1 : order >= 5 ? 3 : order ? 2 : null
      //   act.actionId = getActionId(act)
      //   console.log(act, auditorResponse)
      // }
      // const groupedResponse = groupArrayByKeys(auditorResponse, ['categoryTitle'])
      // console.log(groupedResponse)
      // if (loc.supplierActions) {
      //   Object.entries(groupedResponse).forEach(([key, value]) => {
      //     console.log(value)
      //     for (const xyz of value) {
      //       console.log(xyz, loc.supplierActions)
      //       xyz.findings = groupArrayByKeys(loc.supplierActions.filter(x => x.esg === xyz.esg && xyz.id === x.assessmentSubSection2Id), ['category'])
      //     }


      //   })
      // }

      // loc.actualResponse = JSON.parse(res?.data?.response || '[]').map(i => ({ ...i, esg: i.order === 1 ? 1 : i.order >= 5 ? 3 : i.order ? 2 : null, }))

      // loc.response = groupedResponse
      // console.log(loc)

      // setReport(loc)
    })

  }, [data])
  const getActionId = (item) => {
    if (item.categoryOfFinding === 1) {
      return 'GP' + item.id
    } else if (item.categoryOfFinding === 2) {
      return 'OFI' + item.id
    } else if (item.categoryOfFinding === 3) {
      if (item.nonComplianceType === 1) {
        return 'NCR' + item.id + ' (Major)'
      } else if (item.nonComplianceType === 2) {
        return 'NCR' + item.id + ' (Minor)'
      } else if (item.nonComplianceType === 3) {
        return 'NC' + item.id + ' (Major)'
      }
    }
  }
  const getMSIIDByAssignment = (item) => {

    return 'MSI-' + item?.vendor?.code + '-' + DateTime.fromISO(item.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')
  }
  const getDate = (date, format) => {
    if (!date) {
      return 'Not Set'
    }
    if (typeof date === 'string') {
      return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
    } else if (DateTime.isDateTime(date)) {
      return date.toFormat(format ? format : 'dd-MM-yyyy')
    } else {
      return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
    }

  };
  const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }]

  const DealerZone = [{ name: 'Authorized Main Dealer', value: 1 }, { name: 'Authorized Dealer', value: 2 }, { name: 'Authorized Parts Stockist (APS)', value: 3 }, { name: 'Area Office', value: 4 }]

  // const radarData = [
  //   { category: "Environmental", value: 80 },
  //   { category: "Health & safety", value: 60 },
  //   { category: "Legal compliance", value: 70 },
  //   { category: "Governance", value: 90 },
  //   { category: "Social", value: 50 },
  //   { category: "Sustainability Ambassadorship", value: 65 },
  // ];

  // const barData = [
  //   { category: "Social", maxScore: 100, avgScore: 80 },
  //   { category: "Environment", maxScore: 100, avgScore: 85 },
  //   { category: "Governance", maxScore: 100, avgScore: 90 },
  // ];

  // const dataEnvironmental = [
  //   { name: "Water", maxScore: 100, avgScore: 70 },
  //   { name: "Waste", maxScore: 100, avgScore: 80 },
  //   { name: "Product Stewardship", maxScore: 100, avgScore: 90 },
  //   { name: "Energy", maxScore: 100, avgScore: 75 },
  // ];

  // const dataSocial = [
  //   { name: "Occupational health & safety", maxScore: 100, avgScore: 60 },
  //   { name: "Supplier social stewardship framework", maxScore: 100, avgScore: 70 },
  //   { name: "Supplier sustainability ambassadorship framework", maxScore: 100, avgScore: 80 },
  // ];

  // const dataGovernance = [
  //   { name: "Supplier governance framework", maxScore: 100, avgScore: 85 },
  //   { name: "Legal compliances", maxScore: 100, avgScore: 95 },
  // ];

  const zonalOfficeList = [{ name: "Central", value: 1 }, { name: "East", value: 2 }, { name: "North", value: 3 }, { name: "South", value: 9 }, { name: "South1", value: 4 }, { name: "South2", value: 5 }, { name: "West", value: 8 }, { name: "West1", value: 6 }, { name: "West2", value: 7 }]
  const rows = [
    {
      category: "GOOD PRACTICES",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "OPPORTUNITY FOR IMPROVEMENT",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "MINOR NON CONFORMANCE",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
    {
      category: "REGULATORY NON COMPLIANCE",
      evidences: [
        {
          finding: "Finding text here",
          category: "Optional category text",
          evidence: ["Evidence 1", "Evidence 2"],
        },
        {
          finding: "Another finding here",
          category: "Another category",
          evidence: ["Evidence A", "Evidence B"],
        },
      ],
    },
  ];

  const admin_data = useSelector(state => state.user.admindetail);

  // const sections = [
  //   {
  //     title: "Waste",
  //     maxScore: 10,
  //     scoreObtained: 8,
  //     rows: [
  //       {
  //         category: "GOOD PRACTICES",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //               "Voluptatibus excepturi fuga earum non dolorem voluptates vel aspernatur.",
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         category: "FINDING",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             category: "mollitia quia et",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: "Energy",
  //     maxScore: 10,
  //     scoreObtained: 8,
  //     rows: [
  //       {
  //         category: "FINDING",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             category: "mollitia quia et",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: "Product Stewardship",
  //     maxScore: 10,
  //     scoreObtained: 8,
  //     rows: [
  //       {
  //         category: "FINDING",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             category: "mollitia quia et",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  // const sections1 = [
  //   {
  //     title: "Supplier Sustainability Ambassadorship Framework",
  //     maxScore: 10,
  //     scoreObtained: 8,
  //     rows: [
  //       {
  //         category: "FINDING",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             category: "mollitia quia et",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: "Supplier Social Stewardship Framework",
  //     maxScore: 10,
  //     scoreObtained: 8,
  //     rows: [
  //       {
  //         category: "FINDING",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             category: "mollitia quia et",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: "Supplier Occupational Health & Safety Framework",
  //     maxScore: 10,
  //     scoreObtained: 8,
  //     rows: [
  //       {
  //         category: "FINDING",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             category: "mollitia quia et",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  // const sections2 = [
  //   {
  //     title: "Legal Compliances",
  //     maxScore: 10,
  //     scoreObtained: 8,
  //     rows: [
  //       {
  //         category: "FINDING",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             category: "mollitia quia et",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: "Supplier Governance Framework",
  //     maxScore: 10,
  //     scoreObtained: 8,
  //     rows: [
  //       {
  //         category: "FINDING",
  //         data: [
  //           {
  //             finding: "Consectetur enim molestiae quia odio dolorem quam.",
  //             category: "mollitia quia et",
  //             evidence: [
  //               "Ut illum aut voluptatem est ea ut quae ullam.",
  //               "Aut dignissimos rerum.",
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  // Reusable BarChart Section
  const Section = ({ title, data, barColors, backgroundColor }) => (
    <div
      style={{
        backgroundColor,
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <h3 style={{ marginBottom: "20px" }}>{title}</h3>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="maxScore" fill={barColors[0]} />
        <Bar dataKey="avgScore" fill={barColors[1]} />
      </BarChart>
    </div>
  );
  const reactToPrintFn = useReactToPrint({ contentRef });


  const overallScore = data?.dealerAuditorChecklistSubmission?.score
    ? JSON.parse(data.dealerAuditorChecklistSubmission.score).overallScore
    : null;

  const getMSIRankImage = () => {
    if (overallScore == null) return "NA";
    if (overallScore > 85) return require('../../../../assets/images/report/valuechain/platinum_rating.png').default;
    if (overallScore > 70) return require('../../../../assets/images/report/valuechain/gold_rating.png').default;
    if (overallScore > 55) return require('../../../../assets/images/report/valuechain/silver_rating.png').default;
    if (overallScore > 40) return require('../../../../assets/images/report/valuechain/bronze_rating.png').default;
    return "Needs Improvement";
  };

  const rankImage = getMSIRankImage();
  return (
    <>
      <div className='col-12 flex justify-content-end'>
        <Button label='Print' icon='pi pi-print' onClick={() => { reactToPrintFn() }} l></Button>

      </div>
      <div ref={contentRef} style={{ width: "100%" }}>
        {/* PAGE 1 */}

        <div className="page text-white min-h-screen flex-col items-center justify-center mb-5" style={{ background: '#00634F' }}>
          <header style={{ marginBottom: "40px", textAlign: "right" }} className='p-4 bg-white'>
            <img
              src={admin_data.information.companylogo} // Replace with the actual path if needed
              alt="TVS Logo"
              style={{ maxWidth: 150 }}
            />
          </header>
          <div className="flex flex-col flex-1">
            <div className="p-10 mt-12" style={{ marginTop: 250 }}>
              <div className="mb-8">
                <img
                  // Update path as needed
                  src={require('../../../../assets/images/test.png').default}
                  alt="TVS Logo"
                  style={{ maxWidth: 250, marginLeft: '-40px' }}
                />
              </div>
              <div className="p-5">
                <h1 className="text-4xl font-bold mb-2">My sustainability index
                  for dealers::</h1>
                <p className="text-xl font-medium mb-4">On-site MSI Calibration Report</p>
                <p className="text-md">{DateTime.fromISO(data?.created_on, { zone: 'utc' }).toLocal().toFormat('LLLL dd,yyyy')} </p>
              </div>
            </div>
          </div>
        </div>

        {/* PAGE 2 */}
        <div className="page min-h-screen p-8 mb-5" style={{ background: "#FFFFFF" }}>
          <header style={{ marginBottom: "40px", textAlign: "right" }}>
            <img src={admin_data.information.companylogo} alt="Logo" style={{ maxWidth: 150 }} />
          </header>

          <h1 className="text-2xl font-bold mb-4">
            Calibration ID:
            <span className="text-gray-500">
              {getMSIIDByAssignment(data)}
            </span>
          </h1>

          <div className='row'>
            <div className='col-6 ps-4' style={{ borderLeft: '4px solid rgb(224, 224, 224)' }}>
              <p><strong>Dealer name:</strong> {data?.vendor?.dealerName}</p>
              <p><strong>Dealer ID:</strong> {data?.vendor?.code}</p>
              <p><strong>Audit date:</strong>{getDate(data?.auditStartDate)}</p>

              <p><strong>Dealer category:</strong> {DealerZone.find(x => x.value === data?.vendor?.dealerCategory)?.name}</p>

              {/* <p><strong>Number of critical non-compliance:</strong> {data?.supplierActions?.filter(x => x.categoryOfFinding === 3)?.length || 0} </p> */}
            </div>

            <div className='col-6 ps-4' style={{ borderLeft: '4px solid rgb(224, 224, 224)' }}>
              <p><strong>Dealer Zone:</strong> {zonalOfficeList.find(x => x.value === data?.vendor?.dealerZone)?.name}</p>
              <p><strong>Dealer location:</strong> {data?.vendor?.dealerLocation}</p>


              <p><strong>MSI Score:</strong> {data?.dealerAuditorChecklistSubmission?.score
                ? (typeof data.dealerAuditorChecklistSubmission.score === "string"
                  ? JSON.parse(data.dealerAuditorChecklistSubmission.score).overallScore
                  : data.dealerAuditorChecklistSubmission.score.overallScore)
                : "N/A"}</p>

              {/* <p ><strong>MSI Rank:</strong> <div style={{ width: 50, display: 'inline-table' }}> {data?.auditorAssignmentSubmission?.auditorMSIScore != null ? report?.auditorAssignmentSubmission?.auditorMSIScore > 85 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/platinum_rating.png').default} /> : report?.auditorAssignmentSubmission?.auditorMSIScore > 70 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/gold_rating.png').default} /> : report?.auditorAssignmentSubmission?.auditorMSIScore > 55 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/silver_rating.png').default} /> : report?.auditorAssignmentSubmission?.auditorMSIScore > 40 ? <img width={'100%'} src={require('../../../../assets/images/report/valuechain/bronze_rating.png').default} /> : 'Needs Improvement' : "NA"} </div> </p> */}

              <p>
                <strong>MSI Rank:</strong>
                {typeof rankImage === "string" ? (
                  <img style={{ width: 50 }} src={rankImage} alt="MSI Rank" />
                  // <span> {rankImage} </span>
                ) : (
                  <span style={{ width: 50, display: 'inline-block' }}>
                    <img width="100%" src={rankImage} alt="MSI Rank" />
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8">

            {/* <SubGraph1Demo supplyData={report?.actualResponse || []} /> */}


          </div>
        </div>

        {/* PAGE 3 */}
        <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
          <header style={{ marginBottom: "40px", textAlign: "right" }}>
            <img src={admin_data.information.companylogo} alt="Logo" style={{ maxWidth: 150 }} />
          </header>
          {chart.length !== 0 && <>
            <ESGScores data={chart} />
            <ESG_Sales_Service data={chart} />
          </>}
          {/* <SubGraph3Demo supplyData={report?.response?.Environment || []} count={report?.supplierActions?.filter(x => x.esg === 1 && x.categoryOfFinding === 3).length || 0} />
            <SubGraph4Demo supplyData={report?.actualResponse || []} count={report?.supplierActions?.filter(x => x.esg === 2 && x.categoryOfFinding === 3).length || 0} />
            <SubGraph5Demo supplyData={report?.actualResponse || []} count={report?.supplierActions?.filter(x => x.esg === 3 && x.categoryOfFinding === 3).length || 0} /> */}
        </div>

        <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
          <header style={{ marginBottom: "40px", textAlign: "right" }}>
            <img src={admin_data.information.companylogo} alt="Logo" style={{ maxWidth: 150 }} />
          </header>
          {chart.length !== 0 && <>
            <EnvironmentChart data={chart} />
            <SocialChart data={chart} />
          </>}
          {/* <SubGraph3Demo supplyData={report?.response?.Environment || []} count={report?.supplierActions?.filter(x => x.esg === 1 && x.categoryOfFinding === 3).length || 0} />
            <SubGraph4Demo supplyData={report?.actualResponse || []} count={report?.supplierActions?.filter(x => x.esg === 2 && x.categoryOfFinding === 3).length || 0} />
            <SubGraph5Demo supplyData={report?.actualResponse || []} count={report?.supplierActions?.filter(x => x.esg === 3 && x.categoryOfFinding === 3).length || 0} /> */}
        </div>  <div className="page bg-white mb-5" style={{ fontFamily: "Arial, sans-serif", padding: "40px" }}>
          <header style={{ marginBottom: "40px", textAlign: "right" }}>
            <img src={admin_data.information.companylogo} alt="Logo" style={{ maxWidth: 150 }} />
          </header>
          {chart.length !== 0 && <>
            <GovernanceChart data={chart} />
            <GeneralChart data={chart} />
          </>}
          {/* <SubGraph3Demo supplyData={report?.response?.Environment || []} count={report?.supplierActions?.filter(x => x.esg === 1 && x.categoryOfFinding === 3).length || 0} />
            <SubGraph4Demo supplyData={report?.actualResponse || []} count={report?.supplierActions?.filter(x => x.esg === 2 && x.categoryOfFinding === 3).length || 0} />
            <SubGraph5Demo supplyData={report?.actualResponse || []} count={report?.supplierActions?.filter(x => x.esg === 3 && x.categoryOfFinding === 3).length || 0} /> */}
        </div>





        {/* PAGE 5 */}






        {/* <div className="page bg-white mb-5 environment-report" style={{ padding: 40, lineHeight: 1.6 }}>
          {(() => {
            let isGreenHeader = false; // Toggle for alternating checklist-group backgrounds
            let greenIndex = 0

            const criteriaColors = {
              environment: "#41AA95",
              social: "#FF9D7C",
              governance: "#5B8FF7",
              general: "#4F4F4F"
            };
            return actions.map((item, index) => {
              if (item.type === "header") {
                // Reset alternation when a new header appears
                isGreenHeader = true;

                greenIndex = greenIndex + 1

                return (
                  <section key={index} className="mb-4">
                    <header>
                      <h2 dangerouslySetInnerHTML={{ __html: item.label }} />
                    </header>
                  </section>
                );
              }

              if (item.type === "checklist-group") {
                const sectionTitle = item.label || "Untitled Section";

                const headerStyle = {
                  backgroundColor: criteriaColors[item.criteria.toLowerCase()] || "gray",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  // borderBottom: greenIndex === 1 ? '1px solid #41AA95' : '1px solid #FF9D7C'
                };

                // Toggle for next checklist-group
                isGreenHeader = !isGreenHeader;

                return (<>
                  <h2>
                    <h2>{item.criteria}</h2>
                  </h2>
                  <section key={index} className="report-section">

                    <header className="section-header" style={headerStyle}>
                      <h3 dangerouslySetInnerHTML={{ __html: sectionTitle }} />
                    </header>

                    <table className="report-table">
                      <thead>
                        <tr>
                          <th>CHECK POINT</th>
                          <th>DEPT</th>
                          <th>OBSERVATION EVIDENCE</th>
                          <th>REQUIRED ACTION</th>
                          <th>RESPONSIBLE PERSON</th>
                          <th>DUE DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(item.questions || []).map((question, qIndex) => {
                          const actions = question.actions || {};

                          const hasActions = actions.actionToBeTaken || actions.personResponsible || actions.dueDate;

                          if (!hasActions) return null;
                          const dueDate = actions.dueDate
                            ? new Date(actions.dueDate).toLocaleDateString("en-GB")
                            : "";

                          return (
                            <tr key={qIndex}>
                              <td>{question.label}</td>
                              <td>{item.section === 1 ? 'Sales' : 'Service'}</td>
                              <td>{question.remarks || ""}</td>
                              <td>{actions.actionToBeTaken || ""}</td>
                              <td>{actions.personResponsible || ""}</td>
                              <td>{dueDate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </section>
                </>);
              }

              return null;
            });
          })()}
        </div> */}


        <div
          className="page bg-white mb-5 environment-report"
          style={{ padding: 40, lineHeight: 1.6 }}
        >
          {(() => {
            let isGreenHeader = false;
            let greenIndex = 0;
            let lastCriteria = ""; // To track last displayed criteria

            const criteriaColors = {
              environment: "#41AA95",
              social: "#FF9D7C",
              governance: "#5B8FF7",
              general: "#4F4F4F",
            };

            return actions.map((item, index) => {
              if (item.type === "header") {
                isGreenHeader = true;
                greenIndex++;
                lastCriteria = ""; // Reset criteria when header appears

                return (
                  <section key={index} className="mb-4">
                    <header>
                      <h2 dangerouslySetInnerHTML={{ __html: item.label }} />
                    </header>
                  </section>
                );
              }

              if (item.type === "checklist-group") {
                const sectionTitle = item.label || "Untitled Section";

                const headerStyle = {
                  backgroundColor:
                    criteriaColors[item.criteria.toLowerCase()] || "gray",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                };

                // Store current criteria before returning
                const currentCriteria = item.criteria;

                // Toggle background flag
                isGreenHeader = !isGreenHeader;

                const sectionContent = (<>
                  {currentCriteria !== lastCriteria && (
                    <h2>{currentCriteria.toUpperCase()}</h2>
                  )}

                  <section key={index} className="report-section">
                    {/* Display criteria only if it's different */}


                    <header className="section-header" style={headerStyle}>
                      <h3 dangerouslySetInnerHTML={{ __html: sectionTitle }} />
                    </header>

                    <table className="report-table">
                      <thead>
                        <tr>
                          <th>CHECK POINT</th>
                          <th>DEPT</th>
                          <th>OBSERVATION EVIDENCE</th>
                          <th>REQUIRED ACTION</th>
                          <th>RESPONSIBLE PERSON</th>
                          <th>DUE DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(item.questions || []).map((question, qIndex) => {
                          const actions = question.actions || {};
                          const hasActions =
                            actions.actionToBeTaken ||
                            actions.personResponsible ||
                            actions.dueDate;
                          if (!hasActions) return null;

                          const dueDate = actions.dueDate
                            ? new Date(actions.dueDate).toLocaleDateString("en-GB")
                            : "";

                          return (
                            <tr key={qIndex}>
                              <td>{question.label}</td>
                              <td>{item.section === 1 ? "Sales" : "Service"}</td>
                              <td>{question.remarks || ""}</td>
                              <td>{actions.actionToBeTaken || ""}</td>
                              <td>{actions.personResponsible || ""}</td>
                              <td>{dueDate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </section>
                </>);

                // ✅ Update lastCriteria AFTER JSX return
                lastCriteria = currentCriteria;

                return sectionContent;
              }

              return null;
            });
          })()}
        </div>


        {/* <div
          className=" environment-report"
       
        >



          {Object.entries(actions).map(([criteria, sections], index) => {
           
            const criteriaColors = {
              environment: "#41AA95",
              social: "#FF9D7C",
              governance: "#5B8FF7",
              general: "#4F4F4F"
            };

            
            const bgColor = criteriaColors[criteria.toLowerCase()] || "gray";

            return (
              <section key={index} className="mb-4 page bg-white mb-5" style={{padding: "40px"}}>

                <header style={{ marginBottom: "40px", textAlign: "right" }}>
                  <img src={admin_data.information.companylogo} alt="Logo" style={{ maxWidth: 150 }} />
                </header>

                <header className="mb-4">
                  <h2>{criteria.toUpperCase()}</h2>
                </header>

               
                {Object.entries(sections).map(([sectionLabel, questionsGroup], idx) => {
                
                  const allActionQuestions = [];

                  Object.entries(questionsGroup).forEach(([questionLabel, questionData]) => {
                    questionData.questions.forEach((question) => {
                      const actions = question.actions || {};
                      const hasActions =
                        actions.actionToBeTaken ||
                        actions.personResponsible ||
                        actions.dueDate;

                      if (hasActions) {
                       
                        allActionQuestions.push({
                          label: question.label,
                          remarks: question.remarks || "",
                          section: question.section,
                          actionToBeTaken: actions.actionToBeTaken || "",
                          personResponsible: actions.personResponsible || "",
                          dueDate: actions.dueDate
                            ? new Date(actions.dueDate).toLocaleDateString("en-GB")
                            : ""
                        });
                      }
                    });
                  });

                
                  if (!allActionQuestions.length) return null;

                  return (
                    <section key={idx} className="mb-4">
                     
                      <header
                        style={{
                          backgroundColor: bgColor,
                          color: "white",
                          padding: "5px 10px",
                          borderRadius: "5px"
                        }}
                      >
                        <h3 style={{ fontSize: "20px" }}>{sectionLabel}</h3>
                      </header>
                      <table className="report-table">
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>CHECK POINT</th>
                            <th style={{ width: "10%" }}>DEPT</th>
                            <th style={{ width: "20%" }}>OBSERVATION EVIDENCE</th>
                            <th style={{ width: "20%" }}>REQUIRED ACTION</th>
                            <th style={{ width: "15%" }}>RESPONSIBLE PERSON</th>
                            <th style={{ width: "15%" }}>DUE DATE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allActionQuestions.map((item, rowIndex) => (
                            <tr key={rowIndex}>
                              <td>{item.label}</td>
                              <td>{item.section === 1 ? "Sales" : "Service"}</td>
                              <td>{item.remarks}</td>
                              <td>{item.actionToBeTaken}</td>
                              <td>{item.personResponsible}</td>
                              <td>{item.dueDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </section>
                  );
                })}
              </section>
            );
          })}
        </div> */}

      </div>
    </>
  );
});

export default DealerCompletedReport;
