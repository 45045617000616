import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

// Sample data derived from the table
const data = [
  {
    year: "FY-2023",
    hazardous: 4637.66, // MT
    nonHazardous: 13608.9, // MT
  },
  {
    year: "FY-2024",
    hazardous: 0,
    nonHazardous: 0,
  },
];

const DemoWaste = () => {
  return (
    <div style={{ height: 500 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Hazardous and Non-Hazardous Waste
      </h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />

          <YAxis>
            <Label
              value="Million Tons (MT)"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />
          <Bar
            dataKey="hazardous"
            name="Hazardous Waste"
            fill="#FF9878"
            barSize={60}
          />
          {/* Non-Renewable Energy Bar */}
          <Bar
            dataKey="nonHazardous"
            name="Non-Hazardous Waste"
            fill="#F9DF7F"
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DemoWaste;
