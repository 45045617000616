import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Sample data for energy consumption by category
const data = [
  // { name: "Renewable - Hosur", value: 195351.86 },
  // { name: "Renewable - Mysore", value: 85494.07 },
  // { name: "Renewable - Nalagarh", value: 25987.24 },
  { name: "Non-Renewable - Hosur", value: 15365.46 },
  { name: "Non-Renewable - Mysore", value: 3071.89 },
  { name: "Non-Renewable - Offices (India)", value: 3837.59 },
  { name: "Non-Renewable - Indonesia", value: 5904.35 },
  { name: "Non-Renewable - Norton", value: 3203.1 },
];

// Colors for the pie slices
const COLORS = [
  // "#82ca9d", // Green for Renewable
  // "#a1d99b", // Light Green for Renewable
  // "#c7e9c0", // Pale Green for Renewable
  "#8884d8", // Blue for Non-Renewable
  "#8ea9db", // Light Blue for Non-Renewable
  "#adcbe3", // Pale Blue for Non-Renewable
  "#d4d9ef", // Light Purple for Non-Renewable
  "#b6c8e5", // Sky Blue for Non-Renewable
];

const NonRenewablePie = () => {
  return (
    <div style={{ height: 500, width: 600 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>Non-Renewable Energy</h3>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label={(entry) => `${entry.name}: ${entry.value.toFixed(2)} GJ`}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NonRenewablePie;
