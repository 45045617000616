import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";

// Sample JSON data (replace with actual JSON import)
import data from "./hierarchical_questions.json";
import HeadStepper from "./HeadStepper";
import DocumentManager from "./DocumentManager";

const HierarchicalTabs = () => {
    // 1. Derive the list of categories
    const categories = Object.keys(data);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

    // 2. Current selected category
    const selectedCategory = categories[selectedCategoryIndex] || "";

    // 3. Derive the topics for the selected category
    const topics = selectedCategory ? Object.keys(data[selectedCategory]) : [];
    const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);

    // 4. Current selected topic
    const selectedTopic = topics[selectedTopicIndex] || "";

    // 5. Derive the sections for the selected category and topic
    const sections =
        selectedCategory && selectedTopic
            ? Object.keys(data[selectedCategory][selectedTopic])
            : [];

    const [selectedSection, setSelectedSection] = useState("");

    /**
     * Only reset selectedSection when:
     * 1) The new `sections` list is empty -> set it to "".
     * 2) The currently selectedSection is NOT in the new `sections` -> set it to the first item in `sections`.
     */
    useEffect(() => {
        if (sections.length === 0) {
            setSelectedSection("");
        } else if (!sections.includes(selectedSection)) {
            // If the previously selected section doesn't exist in the new array
            setSelectedSection(sections[0]);
        }
        // eslint-disable-next-line
    }, [selectedCategoryIndex, selectedTopicIndex, sections]);

    // Handle a click in HeadStepper
    const handleSectionStepClick = (section) => {
        setSelectedSection(section);
    };

    return (
        <div className="p-4">
            {/* 1. Top-level TabView for Categories */}
            <TabView
                activeIndex={selectedCategoryIndex}
                onTabChange={(e) => {
                    setSelectedCategoryIndex(e.index);
                    // Reset the topic index when switching categories
                    setSelectedTopicIndex(0);
                }}
            >
                {categories.map((category) => (
                    <TabPanel key={category} header={category}>
                        {/* 2. Second-level TabView for Topics */}
                        <TabView
                            activeIndex={selectedTopicIndex}
                            onTabChange={(e) => {
                                setSelectedTopicIndex(e.index);
                            }}
                        >
                            {topics.map((topic) => (
                                <TabPanel key={topic} header={topic}>
                                    {/* 3. Stepper for Sections */}
                                    <HeadStepper
                                        stages={sections}
                                        onStepClick={handleSectionStepClick}
                                        selectedStage={selectedSection}
                                        completedStages={[]}
                                    />

                                    {/* Display questions for the currently selected section */}
                                    {selectedSection &&

                                        data[selectedCategory][topic][selectedSection]?.map((item, index) => {
                                            // 1. Parse the responseType string into an array (case-insensitive)
                                            const rawTypes = item.responseType || "";
                                            const parsedTypes = rawTypes
                                                .split(",")
                                                .map((t) => t.trim().toLowerCase())
                                                .filter((t) => t.length > 0);

                                            // 2. A helper to check if the array includes a certain string (case-insensitive)
                                            const hasType = (type) => parsedTypes.includes(type.toLowerCase());

                                            // 3. Render UI conditionally
                                            const shouldRenderDocumentOnly = hasType("document only");
                                            const shouldRenderDescriptive = hasType("descriptive");
                                            const shouldRenderYesNo = hasType("yes / no");
                                            const shouldRenderDocument = hasType("document");

                                            return (
                                                <Card key={index} className="p-3 mb-3">
                                                    <strong>Q{index + 1}: </strong> {item.question}
                                                    <br />
                                                    {/* <span className="text-sm text-gray-500">
                                                        Response Type: {item.responseType}
                                                    </span> */}

                                                    {/* Conditionally Render Inputs */}
                                                    <div className="mt-3">

                                                        {/* CASE 1: If "Document Only" is present, we show ONLY the upload */}
                                                        {shouldRenderDocumentOnly && (
                                                            <div className="mb-3">
                                                                <label className="form-label">Upload Document:</label>
                                                                <input type="file" className="form-control" />
                                                            </div>
                                                        )}

                                                        {/* CASE 2: Otherwise, show whichever types are included */}
                                                        {!shouldRenderDocumentOnly && (
                                                            <>
                                                                {/* Descriptive => Textarea */}
                                                                {shouldRenderDescriptive && (
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Your Answer:</label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            rows={3}
                                                                            placeholder="Type your answer here..."
                                                                        />
                                                                    </div>
                                                                )}

                                                                {/* Yes / No => Radio Buttons */}
                                                                {shouldRenderYesNo && (
                                                                    <div className="mb-3">
                                                                        <label className="form-label d-block">Select Yes / No:</label>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`yesNoGroup-${index}`}
                                                                                id={`yes-${index}`}
                                                                                value="Yes"
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`yes-${index}`}>
                                                                                Yes
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`yesNoGroup-${index}`}
                                                                                id={`no-${index}`}
                                                                                value="No"
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`no-${index}`}>
                                                                                No
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {/* Document => File Upload */}
                                                                {shouldRenderDocument && (
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Upload Document:</label>
                                                                        <input type="file" className="form-control" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </Card>
                                            );
                                        })
                                    }

                                    <DocumentManager sectionId={selectedSection} />

                                </TabPanel>
                            ))}
                        </TabView>
                    </TabPanel>
                ))}
            </TabView>
        </div>
    );
};

export default HierarchicalTabs;
