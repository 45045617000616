import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const fire_safetyChart = ({ data }) => {
  // Function to filter data based on category
  const filterDataByCategory = (category) =>
    data.filter((entry) => entry.category === category);

  const aggregateData = (filteredData) => {
    const totals = {
      fire_safety: 0,
      road_safety: 0,
      electrical_safety: 0,
      office_safety: 0,
      sustainability_ambassador_program: 0,
    };
    const maxValues = {
      fire_safety: 10,
      road_safety: 10,
      electrical_safety: 5,
      office_safety: 5,
      sustainability_ambassador_program: 10,
    };
    let count = filteredData.length || 1; // Prevent division by zero

    filteredData.forEach((entry) => {
      const fire_safety = isNaN(entry.fire_safety) ? 0 : entry.fire_safety;
      const roadSafety = isNaN(entry.road_safety) ? 0 : entry.road_safety;
      const electricalSafety = isNaN(entry.electrical_safety)
        ? 0
        : entry.electrical_safety;
      const officeSafety = isNaN(entry.office_safety) ? 0 : entry.office_safety;
      const sap = isNaN(entry.sustainability_ambassador_program) ? 0 : entry.sustainability_ambassador_program;

      totals.fire_safety += fire_safety;
      totals.road_safety += roadSafety;
      totals.electrical_safety += electricalSafety;
      totals.office_safety += officeSafety;
      totals.sustainability_ambassador_program += sap;
    });

    // Helper function to clamp values between 0 and maxValue for graph display
    const clampForGraph = (value) => Math.max(0, value);
    const clampRemaining = (avgValue, maxValue) =>
      Math.max(0, Math.min(maxValue, maxValue - avgValue));

    return [
      {
        category: "fire_safety",
        avgValue: totals.fire_safety / count,
        avgValueForGraph: clampForGraph(totals.fire_safety / count),
        maxValue: maxValues.fire_safety,
        remainingToMax: clampRemaining(totals.fire_safety / count, maxValues.fire_safety),
      },
      {
        category: "Road Safety",
        avgValue: totals.road_safety / count,
        avgValueForGraph: clampForGraph(totals.road_safety / count),
        maxValue: maxValues.road_safety,
        remainingToMax: clampRemaining(
          totals.road_safety / count,
          maxValues.road_safety
        ),
      },
      {
        category: "Electrical Safety",
        avgValue: totals.electrical_safety / count,
        avgValueForGraph: clampForGraph(totals.electrical_safety / count),
        maxValue: maxValues.electrical_safety,
        remainingToMax: clampRemaining(
          totals.electrical_safety / count,
          maxValues.electrical_safety
        ),
      },
      {
        category: "Office Safety",
        avgValue: totals.office_safety / count,
        avgValueForGraph: clampForGraph(totals.office_safety / count),
        maxValue: maxValues.office_safety,
        remainingToMax: clampRemaining(
          totals.office_safety / count,
          maxValues.office_safety
        ),
      },
      {
        category: "SAP",
        avgValue: totals.sustainability_ambassador_program / count,
        avgValueForGraph: clampForGraph(totals.sustainability_ambassador_program / count),
        maxValue: maxValues.sustainability_ambassador_program,
        remainingToMax: clampRemaining(totals.sustainability_ambassador_program / count, maxValues.sustainability_ambassador_program),
      },
    ];
  };

  const salesData = aggregateData(filterDataByCategory("Sales"));
  const serviceData = aggregateData(filterDataByCategory("Service"));

  // Function to determine Y-Axis domain based on values
  const getYAxisDomain = () => {
    return [0, 10]; // Set Y-Axis max value to 10
  };

  const salesYAxisDomain = getYAxisDomain();
  const serviceYAxisDomain = getYAxisDomain();

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px",
      }}
    >
      {/* Sales Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Sales - Social
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={salesData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={salesYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Achieved") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              fill="#FC6E51"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              fill="#FEB2A8"
              name="Maximum"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Service Chart */}
      <div style={{ width: "48%", height: 400 }}>
        <h3 style={{ textAlign: "center", marginBottom: 20, color: "#555" }}>
          Service - Social
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={serviceData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={serviceYAxisDomain} />
            <Tooltip
              formatter={(value, name, props) => {
                const { payload } = props;
                if (name === "Achieved") {
                  return [
                    `${payload.avgValue.toFixed(2)} (Max: ${payload.maxValue})`,
                    name,
                  ];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="avgValueForGraph"
              stackId="score"
              fill="#FC6E51"
              name="Achieved"
              label={{
                position: "insideTop",
                fill: "#fff",
                formatter: (value) => value.toFixed(1),
              }}
            />
            <Bar
              dataKey="remainingToMax"
              stackId="score"
              fill="#FEB2A8"
              name="Maximum"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default fire_safetyChart;
