import React, { useState, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

const barChartData = [
  {
    region: "West 2",
    totalDealers: 116,
    audited: 0,
    auditedPercent: 0,
    totalDealersPercent: 100,
  },
  {
    region: "West 1",
    totalDealers: 233,
    audited: 26,
    auditedPercent: 11.16,
    totalDealersPercent: 100,
  },
  {
    region: "South 2",
    totalDealers: 364,
    audited: 34,
    auditedPercent: 9.34,
    totalDealersPercent: 100,
  },
  {
    region: "South 1",
    totalDealers: 220,
    audited: 12,
    auditedPercent: 5.45,
    totalDealersPercent: 100,
  },
  {
    region: "North",
    totalDealers: 171,
    audited: 8,
    auditedPercent: 4.68,
    totalDealersPercent: 100,
  },
  {
    region: "East",
    totalDealers: 244,
    audited: 14,
    auditedPercent: 5.74,
    totalDealersPercent: 100,
  },
  {
    region: "Central",
    totalDealers: 230,
    audited: 28,
    auditedPercent: 12.17,
    totalDealersPercent: 100,
  },
];

const SideBarGraph = () => {
  const [chartData, setChartData] = useState([]);
  const [activeMode, setActiveMode] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const tableRef = useRef(null);
  const chartRef = useRef(null);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            fontSize: "14px",
            fontFamily: "Lato",
            lineHeight: "1.5",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
          {payload.map((entry) => (
            <p key={entry.name} style={{ margin: 0, color: "black" }}>{`${
              entry.name
            }:${entry.value}(${entry.payload[`${entry.dataKey}Percent`]}%)`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const panelItems = [
    {
      items: [
        {
          label: "Export as Excel",
          icon: "pi pi-file-excel",
          command: () => {
            // downloadExcelWithImage(chartRef);
          },
        },
        {
          label: "Export as PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            // downloadPdfWithImage(chartRef);
          },
        },
        {
          label: "Export as JPG",
          icon: "pi pi-image",
          command: () => {
            // downloadChartAsJpg(chartRef);
          },
        },
        activeMode && {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            // printChart(chartRef);
          },
        },
      ],
    },
  ];

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,
              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Card style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ fontSize: "18px", margin: "25px" }}>
          Total No of Dealers vs Dealers Calibrated under MSI
        </h3>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19" />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <div ref={menuRef}>
            <Button
              style={{
                color: "black",
                height: "30px",
                marginLeft: "3px",
                background: "#F0F2F4",
                border: "0px",
                padding: "6px",
                position: "relative",
              }}
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
              }}
            >
              <i className="pi pi-angle-down fs-19" />
            </Button>
            {dropdownOpen && (
              <Menu
                model={panelItems}
                style={{
                  position: "absolute",
                  right: 45,
                  zIndex: "1",
                  padding: 0,
                }}
              ></Menu>
            )}
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart barSize={60} data={barChartData}>
          <YAxis type="number" />
          <XAxis dataKey="region" type="category"></XAxis>
          <Tooltip content={CustomTooltip} />
          <Legend content={CustomLegend} />
          <Bar dataKey="totalDealers" fill="#0000cc" name="Total No of Dealers">
            {" "}
            <LabelList
              dataKey="totalDealers"
              position="top"
              style={{ fontSize: "12px", fill: "black" }}
            />
          </Bar>
          <Bar
            dataKey="audited"
            fill="#006600"
            name="Dealers Calibrated under MSI"
          >
            {" "}
            <LabelList
              dataKey="auditedPercent"
              position="top"
              formatter={(value) => `${value}%`}
              style={{ fontSize: "12px", fill: "black" }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SideBarGraph;
