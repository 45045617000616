import React, { useState, useEffect } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AttachmentComponent } from "../../../../components/Attachment";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useSelector } from "react-redux";

const TakeActionDealer = ({ data, refresh }) => {

    console.log(data)

    const [rootCause, setRootCause] = useState("");
    const [correctiveAction, setCorrectiveAction] = useState("");
    const [actionDesc, setActionDesc] = useState("");
    const [document, setDocument] = useState([]);
    const [applicableLaw, setApplicableLaw] = useState('')
    const vendorCode = useSelector((state) => state.user.currentVendorCode);
    const [errors, setErrors] = useState({}); // Validation errors

    const [lastAction, setLastAction] = useState([])
    console.log(data)
    const quillStyle = { minHeight: "120px" };

    useEffect(() => {
        setLastAction(data.supplierActionHistories ? data.supplierActionHistories[data.supplierActionHistories.length - 1] : [])
    }, [data])

    const nonComplianceOptions = [
        { label: "Regulatory (Major)", id: 1 },
        { label: "Regulatory (Minor)", id: 2 },
        { label: "Minor", id: 3 },
    ];


    const nonComplianceLabel = nonComplianceOptions.find(option => option.id === data.nonComplianceType)?.label || "Unknown";


    useEffect(() => {
        APIServices.get(API.GetList_DD(2))
            .then((res) => {
                if (res && res.data && Array.isArray(res.data)) {
                    const foundLaw = res.data.find((law) => law.id === data.applicableLaw);
                    setApplicableLaw(foundLaw ? foundLaw.title : "Not Available");
                } else {
                    setApplicableLaw("Not Available");
                }
            })
            .catch((error) => {
                console.error("Error fetching applicable law:", error);
                setApplicableLaw("Error fetching data");
            });
    }, [data.applicableLaw]);

    // **Validation before submission**
    const validateFields = () => {
        let newErrors = {};
        // if (!rootCause.trim()) newErrors.rootCause = "Root cause is required.";
        // if (!correctiveAction.trim()) newErrors.correctiveAction = "Corrective action is required.";
        if (!actionDesc.trim()) newErrors.actionDesc = "Action description is required.";
        if (document.length === 0) newErrors.document = "You must upload at least one document.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const submitTakeAction = async () => {
        if (!validateFields()) return; // Prevent submission if validation fails
    
        console.log("Submitting data:", { rootCause, correctiveAction, actionDesc, document });
    
        const fileNames = document.map(file => file.originalname);
    
        try {
            await APIServices.patch(API.DealerActionTaken(data.id), {
                actionTaken: actionDesc,
                evidence: fileNames
            });
            console.log("Action submitted successfully");
        } catch (error) {
            console.error("Error submitting action:", error);
            // Optional: show error notification to user
        } finally {
            refresh(); // Ensure refresh runs after API call
        }
    };
    

    useEffect(() => {
        console.log(document)
    }, [document])

    return (
        <div className="container-fluid">
            {/* Action Details */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">{data.maskId} ({data.category === "2" ? "Service" : "Sales"})</h4>
                <Divider />
                <div className="row">
                    <div className="col-md-12 ">
                        {/* <p><strong>ID:</strong> </p> */}
                        <p><strong>Description:</strong> {data.description}</p>
                        <p><strong>Action To be Taken:</strong> {data.actionToBeTaken}</p>
                        <p><strong>Person Responsible:</strong> {data.applicationDetails.personResponsible
                        }</p>
                        <p><strong>Due Date:</strong> {(() => {
                            if (!data?.dueDate) return "";

                            // Parse the incoming date
                            const parsedDate = new Date(data.dueDate);

                            // If invalid, just show the raw value
                            if (isNaN(parsedDate)) return data.dueDate;

                            // Format: DD-MM-YYYY
                            const day = String(parsedDate.getDate()).padStart(2, "0");
                            const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
                            const year = parsedDate.getFullYear();

                            return `${day}-${month}-${year}`;
                        })()}</p>

                        {/* <p><strong>Category:</strong> {}</p> */}
                    </div>
                    <div className="col-md-12">





                    </div>
                </div>
            </Card>
            {data.comments &&
                <Card className="mb-4 shadow-sm">
                    <h4 className="mb-3 clr-navy">Comments</h4>
                    <Divider />

                    <div className="row">
                        <div>
                            {/* <p><strong>Root Cause:</strong></p>
                            <div dangerouslySetInnerHTML={{ __html: lastAction.rootCause }} />

                            <p><strong>Corrective Action:</strong></p>
                            <div dangerouslySetInnerHTML={{ __html: lastAction.correctiveAction }} /> */}

                            {/* <p><strong>Action Taken:</strong></p>
                            <div dangerouslySetInnerHTML={{ __html: lastAction.actionTaken }} /> */}
                        </div>


                        <p className="mt-4" style={{ color: 'red' }}><strong>Approver Comments: </strong> {data.comments}</p>
                    </div>


                </Card>
            }
            {/* Input Fields */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Description of the Action Taken </h4>
                {/* <Divider /> */}

                {/* Identify the Root Cause(s) */}
                {/* <div className="mb-4">
                    <h5 className="text-secondary">Identify the Root Cause(s)</h5>
                    <ReactQuill value={rootCause} onChange={setRootCause} className="form-control p-0 border" style={quillStyle} />
                        
                    {errors.rootCause && <Message severity="error" text={errors.rootCause} />}
                </div> */}

                {/* Identified Corrective Actions */}
                {/* <div className="mb-4">
                    <h5 className="text-secondary">Identified Corrective Actions</h5>
                    <ReactQuill value={correctiveAction} onChange={setCorrectiveAction} className="form-control p-0 border" style={quillStyle} />


                    {errors.correctiveAction && <Message severity="error" text={errors.correctiveAction} />}
                </div> */}

                {/* Description of the Action Taken */}
                <div className="mb-4">

                    <ReactQuill value={actionDesc} onChange={setActionDesc} className="form-control p-0 border" style={quillStyle} />

                    {errors.actionDesc && <Message severity="error" text={errors.actionDesc} />}
                </div>
            </Card>

            {/* Attachment */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Upload Evidence</h4>
                <Divider />
                <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => setDocument(e)} documents={document} labels={'Please attach any evidence or records to support the Action Taken.'} hint={''} />
                {errors.document && <Message severity="error" text={errors.document} />}
            </Card>

            {/* Submit Button */}
            <div className="flex justify-content-end mt-3">
                <Button label="Submit" icon="pi pi-check" onClick={submitTakeAction} className="p-button-primary" />
            </div>
        </div>
    );
};

export default TakeActionDealer;
