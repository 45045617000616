import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  Label,
  Cell,
} from "recharts";

const ESGGChart = ({ data }) => {
  // Function to filter data based on category
  const filterDataByCategory = (category) =>
    data.filter((entry) => entry.category === category);

  // Function to compute total scores
  const computeTotalScore = (filteredData) => {
    let totalScore = 0;
    let count = filteredData.length || 1;

    filteredData.forEach((entry) => {
      totalScore += isNaN(entry.score) ? 0 : entry.score;
    });

    return totalScore / count;
  };

  // Aggregating total scores for both Sales and Service
  const salesTotalScore = computeTotalScore(filterDataByCategory("Sales"));
  const serviceTotalScore = computeTotalScore(filterDataByCategory("Service"));

  // Calculating MSI Score and determining grade
  const msiScore = (salesTotalScore + serviceTotalScore) / 2;
  const getMSIGrade = (score) => {
    if (score > 86) return "Platinum";
    if (score >= 71) return "Gold";
    if (score >= 56) return "Silver";
    if (score >= 41) return "Bronze";
    return "Needs Improvement";
  };

  const msiGrade = getMSIGrade(msiScore);

  // Data for MSI Chart
  const msiChartData = [
    {
      category: "Sales",
      achieved: salesTotalScore,
      remaining: 100 - salesTotalScore,
    },
    {
      category: "Service",
      achieved: serviceTotalScore,
      remaining: 100 - serviceTotalScore,
    },
  ];

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
          marginTop: "10px",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };
  // Platinum: "#CCCED5",
  // Gold: "#F5C37B",
  // Silver: "#EAECF0",
  // Bronze: "#D28B24",
  // "Needs Improvement": "#EE5724",
  const getBarColor = (score) => {
    if (score === "Platinum") return "#CCCED5"; // Dark Green for Platinum
    if (score === "Gold") return "#F5C37B"; // Green for Gold
    if (score === "Silver") return "#EAECF0"; // Yellow for Silver
    if (score === "Bronze") return "#D28B24"; // Orange for Bronze
    return "#EE5724"; // Red for Needs Improvement
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* MSI Score and Grade Display */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ color: "#0D5EAF" }}>MSI Score: {msiScore.toFixed(1)}</h2>
        <h2 style={{ color: "#0D5EAF" }}>MSI Grade: {msiGrade}</h2>
      </div>

      {/* MSI Chart */}
      <div style={{ width: "100%", height: 400, marginBottom: "40px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={msiChartData}
            barSize={60}
            margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, 100]} />
            <Tooltip
              formatter={(value, name, props) => {
                if (name === "Achieved Score") {
                  return [`${value.toFixed(2)} (Max: 100)`, name];
                }
                return [null, null];
              }}
            />
            <Legend content={CustomLegend} />
            <Bar dataKey="achieved" stackId="score" name="Achieved">
              {msiChartData.map((entry, index) => (
                <Cell
                  key={`cell-achieved-${index}`}
                  fill={getBarColor(getMSIGrade(entry.achieved))}
                />
              ))}
            </Bar>
            <Bar
              dataKey="remaining"
              stackId="score"
              fill="#CCCED5"
              name="Maximum"
            />
            {/* Reference Line for MSI Score */}
            <ReferenceLine y={msiScore} stroke="#FF5733" strokeDasharray="3 3">
              <Label
                value={`Average MSI: ${msiScore.toFixed(1)}`}
                position="center"
                fill="#FF5733"
              />
            </ReferenceLine>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ESGGChart;
