import React, { useState, useEffect } from "react";
import { aps, ao } from "./dealer";
import SideBarGraph from "./SidebarGraph";
import { Card } from "primereact/card";
import EnvironmentChart from "./EnvironmentChart";
import SocialChart from "./SocialChart";
import GovernanceChart from "./GovernanceChart";
import GeneralChart from "./GeneralChart";
import MSIGradeChart from "./MSIGradeChart";
import FilterComponent from "./FilterComponent";
import ESGGChart from "./ESGGChart";
import ESGGOverviewChart from "./ESGGOverviewChart";
import ESGGScores from "./ESGGScores";
import MSIScoresOverTime from "./MSIScoresOverTime";
import { Tabs, Tab } from "@mui/material";
import SideBarTab from "./TabGraphs/SideBarTab";
import MSIScoresOverTimeTab from "./TabGraphs/MSIScoresOverTimeTab";
import ESGOverviewTab from "./TabGraphs/ESGOverviewTab";
import ESGScoresTab from "./TabGraphs/ESGScoresTab";
import EnvironmentTab from "./TabGraphs/EnvironmentTab";
import SocialTab from "./TabGraphs/SocialTab";
import GovernanceTab from "./TabGraphs/GovernanceTab";
import { API } from "../../../../../constants/api_url";
import APIServices from "../../../../../service/APIService";

const DealerDashboard = () => {
  const [data, setData] = useState([]);
  const [apsData, setApsData] = useState([]);
  const [aoData, setAoData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredApsData, setFilteredApsData] = useState([]);
  const [filteredAoData, setFilteredAoData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0); // Track the active tab
  useEffect(() => {
    getDealerData();
  }, []);

  const getDealerData = async () => {
    try {
      const response = await APIServices.get(API.dealerResponse);
      if (response.data) {
        setFilteredData(response.data);
        setData(response.data);
      }
    } catch (error) {
      console.log("Error fetching supply data: ", error);
      setFilteredData([]);  // Ensures it never remains undefined
      setData([]);
    }
  }

  useEffect(() => {
    
    setApsData(aps);
    setFilteredApsData(aps);
    setAoData(ao);
    setFilteredAoData(ao);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };



  const renderTab2Content = () => (
    <div>
      <div className="row">
        <SideBarTab tabIndex={tabIndex} />
      </div>
      <div className="row">
        <MSIScoresOverTimeTab tabIndex={tabIndex} />
      </div>
      <br />
      <div className="p-2 border card col-md-12">
        <FilterComponent
          data={apsData}
          setFilteredData={setFilteredApsData}
          tabIndex={tabIndex}
        />
      </div>
      <div className="card">
        <ESGScoresTab data={filteredApsData} tabIndex={tabIndex} />
      </div>
      <div className="row">
        <div className="p-2 border card col-md-4">
          <EnvironmentTab data={filteredApsData} tabIndex={tabIndex} />
        </div>
        <div className="p-2 border card col-md-4">
          <SocialTab data={filteredApsData} tabIndex={tabIndex} />
        </div>
        <div className="p-2 border card col-md-4">
          <GovernanceTab data={filteredApsData} tabIndex={tabIndex} />
        </div>
      </div>
    </div>
  );

  const renderTab1Content = () => (
    <div>
      <div className="row">
        <SideBarGraph />
      </div>
      <div className="row">
        <MSIScoresOverTime />
      </div>
      <br />
      <div className="p-2 border card col-md-12">
        <FilterComponent
          data={data}
          setFilteredData={setFilteredData}
          tabIndex={tabIndex}
        />
      </div>
      <div className="row">
        <div className="p-2 border card col-md-6">
          <ESGGOverviewChart data={filteredData} />
        </div>

        <div className="p-2 border card col-md-6">
          <MSIGradeChart data={filteredData} />
        </div>

        <div className="p-2 border card col-md-4">
          <ESGGScores data={filteredData} />
        </div>

        <div className="p-2 border card col-md-8">
          <ESGGChart data={filteredData} />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="p-2 border card col-md-12">
          <EnvironmentChart data={filteredData} />
        </div>

        <div className="p-2 border card col-md-12">
          <SocialChart data={filteredData} />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="p-2 border card col-md-12">
          <GovernanceChart data={filteredData} />
        </div>

        <div className="p-2 border card col-md-12">
          <GeneralChart data={filteredData} />
        </div>
      </div>
    </div>
  );

  const renderTab3Content = () => (
    <div>
      <div className="row">
        <SideBarTab tabIndex={tabIndex} />
      </div>
      <div className="row">
        <MSIScoresOverTimeTab tabIndex={tabIndex} />
      </div>
      <br />
      <div className="p-2 border card col-md-12">
        <FilterComponent
          data={aoData}
          setFilteredData={setFilteredAoData}
          tabIndex={tabIndex}
        />
      </div>
      <div className="card">
        <ESGScoresTab data={filteredAoData} tabIndex={tabIndex} />
      </div>
      <div className="row">
        <div className="p-2 border card col-md-4">
          <EnvironmentTab data={filteredAoData} tabIndex={tabIndex} />
        </div>
        <div className="p-2 border card col-md-4">
          <SocialTab data={filteredAoData} tabIndex={tabIndex} />
        </div>
        <div className="p-2 border card col-md-4">
          <GovernanceTab data={filteredAoData} tabIndex={tabIndex} />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="AMD, ADO, AO tabs"
      >
        <Tab label="Dealers" />
        <Tab label="APS" />
        <Tab label="Area Office" />
      </Tabs>

      {/* Render content based on selected tab */}
      {tabIndex === 0 && <div>{renderTab1Content()}</div>}
      {tabIndex === 1 && <div>{renderTab2Content()}</div>}
      {tabIndex === 2 && <div>{renderTab3Content()}</div>}
    </div>
  );
};

export default DealerDashboard;
