import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useSelector } from "react-redux";

const statusColors = {
    Completed: "#29C76F",
    "In Progress": "#F5C37B",
    "Not Started": "#CCCED5",
    Blocked: "#fff",
};

const QualitativeResponse = ({ data }) => {
    const login_data = useSelector((state) => state.user.userdetail);
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [formData, setFormData] = useState({});

    const openDialog = (item) => {
        const prefilled = {};
        item.form?.forEach((field) => {
            if (field.type === "radio-group" || field.type === "textarea") {
                prefilled[field.name] = field.value || "";
            }
        });

        setSelectedItem(item);
        setFormData(prefilled);
        setVisible(true);
    };



    return (
        <div className="p-4 border rounded-lg shadow-sm bg-white mb-4">
            <h2 className="fs-4 fw-bold mb-4">{data[0]?.title || "Environment"}</h2>
            <h2 className="fs-4 mb-4">{data[0]?.subHeading || "Environment"}</h2>

            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                <table className="table table-bordered" style={{ minWidth: "1000px", width: "100%" }}>
                    <tbody>
                        {data.map((env, index) => {
                            const allSectionNames = new Set();
                            Object.values(env.data).forEach((userSections) => {
                                Object.keys(userSections).forEach((section) => allSectionNames.add(section));
                            });

                            const rows = Object.entries(env.data).map(([userId, sections], userIndex) => (
                                <tr key={`${index}-${userId}`}>
                                    <td
                                        className="p-4 align-top bg-white"
                                        style={{ width: "300px", minWidth: "250px", position: "sticky", left: 0, zIndex: 2 }}
                                    >

                                        <p className="fw-semibold fs-5">User ID: {userId}</p>
                                        <p className="text-muted"> {env.location}</p>
                                    </td>
                                    <td className="p-0">
                                        <div
                                            style={{ display: "flex", overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100%" }}
                                        >
                                            {[...allSectionNames].map((sectionName, i) => {
                                                const item = sections[sectionName] || {
                                                    name: sectionName,
                                                    status: "Blocked",
                                                    dueDate: "",
                                                    isLocked: false,
                                                    form: [],
                                                };
                                                const isBlocked = item.status === "Blocked";

                                                return (
                                                    <div
                                                        key={i}
                                                        className={`d-flex flex-column border flex-shrink-0 ${isBlocked ? "text-muted" : "hover-effect"}`}
                                                        style={{
                                                            width: "250px",
                                                            minWidth: "250px",
                                                            cursor: isBlocked ? "not-allowed" : "pointer",
                                                            opacity: isBlocked ? 0.8 : 1,
                                                            pointerEvents: isBlocked ? "none" : "auto",
                                                        }}
                                                        onClick={() => !isBlocked && openDialog(item)}
                                                    >
                                                        <div
                                                            className="w-100"
                                                            style={{
                                                                height: "10px",
                                                                backgroundColor: statusColors[item.status] || "#fff",
                                                            }}
                                                        ></div>
                                                        <div className="p-3 flex-grow-1">
                                                            <h5 className="fw-bold text-wrap">{item.name}</h5>
                                                            {!isBlocked && (
                                                                <p className="text-muted mt-2">
                                                                    Due date:<br /> {item.dueDate}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </td>
                                </tr>
                            ));

                            const sectionMap = {};
                            Object.entries(env.data).forEach(([userId, sections]) => {
                                Object.entries(sections).forEach(([sectionName, sectionData]) => {
                                    if (!sectionMap[sectionName]) sectionMap[sectionName] = [];
                                    sectionMap[sectionName].push({
                                        ...sectionData,
                                        userId,
                                        location: env.location,
                                        rawResponse: sectionData.form?.reduce((acc, field) => {
                                            if (field.type === "radio-group" || field.type === "textarea") {
                                                acc[field.name] = field.value || "";
                                            }
                                            return acc;
                                        }, {}),
                                        form: sectionData.form || [],
                                    });
                                });
                            });

                            const consolidatedRowKey = `${env.subHeading}`;
                            const isLastEntry = data.findIndex(e => e.subHeading === env.subHeading) === index;
                            const isLastOfGroup = index === data.length - 1 || data[index + 1].subHeading !== env.subHeading;

                            const consolidatedRow = isLastOfGroup ? (
                                <tr key={`consolidated-${consolidatedRowKey}`}>
                                    <td
                                        className="p-4 align-top bg-white fw-bold"
                                        style={{ width: "300px", minWidth: "250px", position: "sticky", left: 0, zIndex: 2 }}
                                    >
                                        <h3 className="fw-semibold fs-5">Consolidated Report</h3>
                                        <p className="text-muted">Across all users</p>
                                    </td>
                                    <td className="p-0">
                                        <div style={{ display: "flex", overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100%" }}>
                                            {Object.entries(sectionMap).map(([sectionName, responses], i) => {

                                                const status = responses.some((r) => r.status === "In Progress")
                                                    ? "In Progress"
                                                    : responses.every((r) => r.status === "Completed")
                                                        ? "Completed"
                                                        : "Not Started";

                                                return (
                                                    <div
                                                        key={i}
                                                        className="d-flex flex-column border flex-shrink-0 hover-effect"
                                                        style={{ width: "250px", minWidth: "250px", cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedItem({ name: sectionName, responses });
                                                            setVisible(true);
                                                        }} 
                                                    >
                                                        <div className="w-100" style={{ height: "10px", backgroundColor: statusColors[status] || "#fff" }}></div>
                                                        <div className="p-3 flex-grow-1">
                                                            <h5 className="fw-bold text-wrap">{sectionName}</h5>
                                                            <p className="text-muted mt-2">Users: {responses.length}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </td>
                                </tr>
                            ) : null;

                            return [rows, consolidatedRow];
                        })}
                    </tbody>
                </table>
            </div>

            <Dialog header={selectedItem?.name} visible={visible} style={{ width: "60vw" }} onHide={() => setVisible(false)}>
                <div className="p-3">
                    {selectedItem?.responses ? (
                        <>
                            <h5 className="fw-bold mb-3">All User Responses</h5>

                            {selectedItem.responses.map((userItem, idx) => (
                                <div key={idx} className="mb-4 border rounded p-3 bg-light">
                                    <h6 className="fw-bold mb-3">
                                        User ID: {userItem.userId} | Location: {userItem.location}
                                    </h6>

                                    {userItem.rawResponse && Object.entries(userItem.rawResponse).map(([key, value], i) => {
                                        if (key === "status") return null;

                                        const formField = userItem.form?.find(f => f.name === key);
                                        const label = formField?.label || key;

                                        let displayValue = value;
                                        if (formField?.type === "radio-group") {
                                            const option = formField.values?.find(opt => opt.value === value);
                                            displayValue = option?.label || value;
                                        }

                                        return (
                                            <div key={i} className="mb-3">
                                                <label className="fw-semibold d-block">{label}</label>
                                                <div className="text-muted">{displayValue || "-"}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <h5 className="fw-bold mb-3">Your Response</h5>
                            {selectedItem?.form?.map((field, i) => (
                                <div key={i} className="mt-3">
                                    <label className="fw-bold">{field.label}</label>
                                    {field.type === "textarea" && (
                                        <div className="mt-2 text-muted border rounded p-2 bg-light">
                                            {formData[field.name] || "-"}
                                        </div>
                                    )}
                                    {field.type === "radio-group" && (
                                        <div className="mt-2 text-muted border rounded p-2 bg-light">
                                            {(() => {
                                                const selectedValue = formData[field.name];
                                                const selectedLabel = field.values.find(v => v.value === selectedValue)?.label;
                                                return selectedLabel || "-";
                                            })()}
                                        </div>
                                    )}
                                </div>
                            ))}


                        </>
                    )}
                </div>
            </Dialog>
        </div>
    );
};

export default QualitativeResponse;
