import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';

import moment from 'moment';
import axios from 'axios';

import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'

import { CascadeSelect } from 'primereact/cascadeselect';

import * as XLSX from 'xlsx'
import FileSaver from 'file-saver';
import { setRef } from '@fullcalendar/core';
import { SplitButton } from 'primereact/splitbutton';
import Swal from 'sweetalert2';
import 'react-image-crop/dist/ReactCrop.css'

import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"


import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import $ from 'jquery'
import { API } from '../../constants/api_url';
import APIServices from '../../service/APIService';
import { DateTime } from 'luxon';
import { Toast } from 'primereact/toast';
import { ReadMoreComponent } from '../../components/Forms/ReadMoreComponent';
import { TabMenu } from 'primereact/tabmenu';
import { InputNumber } from 'primereact/inputnumber';
import { fetchUserList } from '../../RTK/Background/userProfileList';

const google = window.google;

// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;
// (0.79/1000)

const MasterList = () => {

    const [data, setData] = useState([])
    const [apidata, setAPiData] = useState([])
    const [activeindex, setActiveIndex] = useState(0)
    const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
    const [adddialog, setAddDialog] = useState(false)
    const [adddealerdialog, setAddDealerDialog] = useState(false)
    const [cropdialog, setCropDialog] = useState(false)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [config, setConfig] = useState({ selectedLocationIds: [], selectedLocation: [], selectedModule: [], selectedModuleIds: [], admin: { approver: false, user: true } })
    const [selectedconfig, setSelectedConfig] = useState({ name: 'Assigned Locations' })
    const [configdialog, setConfigDialog] = useState(false)
    const imgRef = useRef(null)
    const admin_data = useSelector((state) => state.user.admindetail)
    const login_data = useSelector((state) => state.user.userdetail)
    const previewCanvasRef = useRef(null)
    const [employee, setEmployee] = useState({ empname: '', empmailid: '', orgname: '', orglocation: '' })
    const [dealer, setDealer] = useState({ zonalManager: null, pinCode: '', coords: { lat: null, lng: null }, principalContact: null, principalName: '', salesmContact: null, salesmName: '', workersmContact: null, workersmName: '', zonalOffice: null, servicecenterType: null, showroomType: null, dealerContact: null, dealerName: '', email: '', dealerCategory: null, dealerLocation: '', dealerCode: '' })
    const categoryList = [{ name: 'Two Wheeler', value: 1 }]
    const zonalOfficeList = [{ name: "Central", value: 1 }, { name: "East", value: 2 }, { name: "North", value: 3 }, { name: "South", value: 9 }, { name: "South1", value: 4 }, { name: "South2", value: 5 }, { name: "West", value: 8 }, { name: "West1", value: 6 }, { name: "West2", value: 7 }]
    const propertyTypeList = [{ name: 'Own property', value: 1 }, { name: 'Rented property', value: 2 }]

    const [submitted, setSubmitted] = useState(false)
    const [filter, setFilter] = useState('')
    const [filter2, setFilter2] = useState('')

    const [roledialog, setRoleDialog] = useState(false)
    const [dealerroledialog, setDealerRoleDialog] = useState(false)


    const [selectedObj, setSelectedObj] = useState({ userProfileId: admin_data.id, user_id: null, tier1_id: 0, tier2_id: 0, tier3_id: 0, roles: [] })
    const [selectedUser, setSelectedUser] = useState({})
    const [allLocationRoles, setAllLocationRoles] = useState([])
    const [applicationlist, setApplicationList] = useState([])
    const [rawsitelist, setRawSiteList] = useState([])
    const [userRole, setUserRole] = useState([])
    const [selectedlist, setSelectedList] = useState({ country: [], city: [], site: [] })
    const toast = useRef(null);
    const forceUpdate = useForceUpdate()
    const dispatch = useDispatch()
    const dt = useRef(null);
    const [loctree, setLocTree] = useState([])
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBAfSU3pX76vkQSAyo9YopnWBfN_CTTEd4', // Replace with your API key
        libraries: ['geocoding']
    });
    console.log(isLoaded)

    useEffect(() => {

        renderData()

    }, [])

    useEffect(() => {
        return () => {
            dispatch(fetchUserList(admin_data.id))
        }
    }, [])

    const renderData = () => {
        let datas = []
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let app = {
            "include": [{ "relation": "applicationRoles" }]

        }
        APIServices.get(API.UserRole).then((res) => {

            setUserRole(res.data.filter(i => i.userProfileId === admin_data.id))
        })
        APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`).then((res) => {
            const shapedSite = res.data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            console.log(shapedSite)
            shapedSite.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                i.locationTwos.forEach((j) => {
                    city.push({ name: j.name, id: j.id })
                    j.locationThrees.forEach((k) => {
                        site.push({ name: k.name, id: k.id })
                    })
                })
            })
            setRawSiteList(shapedSite)
            setSelectedList({ country, city, site })
        })
        APIServices.get(API.Application + `?filter=${encodeURIComponent(JSON.stringify(app))}`).then((res) => {
            setApplicationList(res.data)

        })
        let promise = new Promise((resolve, error) => {
            APIServices.get(API.UserProfile)
                .then((res) => {
                    setAPiData(res.data)

                    res.data.forEach((item, index) => {
                        if ((item.role === 'clientuser') && item.clientId === admin_data.id) {

                            datas.push({ id: item.id, empname: item.information.empname, empdesgination: item.information.empdesgination, empid: item.information.empid, empmailid: item.email, config: item.information.config, information: item, emplocation: item.information.emplocation === undefined ? '' : item.information.emplocation, role: item?.role })

                        } else if (item.role === 'clientextuser' && item.clientId === admin_data.id) {
                            datas.push({ id: item.id, empname: item.information.empname, orgname: item.information.orgname, orglocation: item.information.orglocation, empmailid: item.email, information: item, role: item?.role })

                        }
                        if (index === res.data.length - 1) {
                            resolve(datas)
                        }
                    })
                })
        })
        promise.then((d) => { console.log(d); setData(d); forceUpdate() })


    }
    const saveAssignment = () => {

        if (selectedObj.id) {
            let newObj = { user_id: selectedObj.user_id, modified_by: login_data.id, userProfileId: admin_data.id, modified_on: DateTime.utc(), roles: selectedObj.roles, locations: { tier1_id: selectedObj.tier1_id, tier2_id: selectedObj.tier2_id, tier3_id: selectedObj.tier3_id } }
            APIServices.post(API.AssingRolesToUser, newObj).then((res) => {
                console.log(res)
                let loc = userRole.filter(i => i.user_id !== newObj.user_id)
                let newUserRole = [...loc, ...res.data.data]
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Assignment Saved', life: 3000 });
                setUserRole(newUserRole)
                setAllLocationRoles(newUserRole.filter(i => i.user_id === newObj.user_id))
                forceUpdate()

            })


        } else {
            let newObj = { created_by: login_data.id, created_on: DateTime.utc(), user_id: selectedObj.user_id, userProfileId: admin_data.id, locations: { tier1_id: selectedObj.tier1_id, tier2_id: selectedObj.tier2_id, tier3_id: selectedObj.tier3_id }, roles: selectedObj.roles }
            APIServices.post(API.AssingRolesToUser, newObj).then((res) => {
                let loc = userRole.filter(i => i.user_id !== newObj.user_id)
                let newUserRole = [...loc, ...res.data.data]
                setSelectedObj((prev) => ({ ...prev, id: res.data.object[0].id }))
                setUserRole(newUserRole)
                forceUpdate()
                setAllLocationRoles(newUserRole.filter(i => i.user_id === newObj.user_id))
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Assignment Updated', life: 3000 });
            })
        }
    };
    const saveDealerAssignment = () => {
        if (selectedObj.id) {
            let newObj = { user_id: selectedObj.user_id, modified_by: login_data.id, userProfileId: admin_data.id, modified_on: DateTime.utc(), roles: selectedObj.roles, locations: { tier1_id: 0, tier2_id: null, tier3_id: null } }
            APIServices.post(API.AssingRolesToUser, newObj).then((res) => {
                console.log(res)
                let loc = userRole.filter(i => i.user_id !== newObj.user_id)
                let newUserRole = [...loc, ...res.data.data]
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Assignment Saved', life: 3000 });
                setUserRole(newUserRole)
                setAllLocationRoles(newUserRole.filter(i => i.user_id === newObj.user_id))
                forceUpdate()

            })


        } else {
            let newObj = { created_by: login_data.id, created_on: DateTime.utc(), user_id: selectedObj.user_id, userProfileId: admin_data.id, locations: { tier1_id: 0, tier2_id: null, tier3_id: null }, roles: selectedObj.roles }
            APIServices.post(API.AssingRolesToUser, newObj).then((res) => {
                let loc = userRole.filter(i => i.user_id !== newObj.user_id)
                let newUserRole = [...loc, ...res.data.data]
                setSelectedObj((prev) => ({ ...prev, id: res.data.object[0].id }))
                setUserRole(newUserRole)
                forceUpdate()
                setAllLocationRoles(newUserRole.filter(i => i.user_id === newObj.user_id))
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Assignment Updated', life: 3000 });
            })
        }
    }
    const resetAssignment = () => {
        console.log(selectedObj)
        if (selectedUser.id) {
            let newObj = { modified_by: login_data.id, modified_on: DateTime.utc(), roles: [] }
            Swal.fire({
                title: `Are you sure want to reset all role assignment for ${selectedUser.empname} ?`,
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    APIServices.delete(API.ResetRolesToUser(selectedUser.id)).then((res) => {
                        setUserRole(userRole.filter(i => i.user_id !== selectedUser.id))
                        setSelectedObj((prev) => ({ ...prev, userProfileId: admin_data.id, tier1_id: 0, tier2_id: 0, tier3_id: 0, roles: [] }))
                        setAllLocationRoles([])
                        forceUpdate()
                        toast.current.show({ severity: 'danger', summary: 'Success', detail: 'Assignment Reseted', life: 3000 });
                    })
                }
            })
        }



    };
    const handleDropdownChange = (obj, val) => {
        console.log("handleDropdownChange:", val);
        let loc = JSON.parse(JSON.stringify(selectedObj))
        if (obj === 'country') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {

                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.tier1_id = val
            loc.tier2_id = null
            loc.tier3_id = null

            setSelectedList({ country, city, site })
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === val && i.tier2_id === 0 && i.tier3_id === 0)



            console.log(country, city, site, loc)
        } else if (obj === 'city') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.tier1_id || loc.tier1_id === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {

                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })
            loc.tier2_id = val
            loc.tier3_id = null
            console.log(country, city, site, loc)
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === loc.tier1_id && i.tier2_id === val && i.tier3_id === 0)

            setSelectedList({ country, city, site })
        } else if (obj === 'site') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.tier1_id || loc.tier1_id === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.tier2_id || loc.tier2_id === 0) {
                            j.locationThrees.forEach((k) => {


                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            loc.tier3_id = val
            console.log(country, city, site, loc)
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === loc.tier1_id && i.tier2_id === loc.tier2_id && i.tier3_id === val)

            setSelectedList({ country, city, site })
        }
        console.log(selectedUser)
        if ((loc.tier1_id === 0 && loc.tier2_id === null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id === 0 && loc.tier3_id === null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id !== null && loc.tier2_id !== 0 && loc.tier3_id !== null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id !== null && loc.tier2_id !== 0 && loc.tier3_id !== 0)) {

            APIServices.post(API.Roles_Info, { locations: { tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id }, user_id: selectedUser.id, userProfileId: admin_data.id }).then((res) => {
                console.log(res.data)
                if (res.data.length && res.data[0].id) {

                    setSelectedObj((prev) => ({ ...loc, ...res.data[0] }))
                } else {
                    setSelectedObj({ tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id, user_id: selectedUser.id, roles: [], disabledRoles: res.data[0].disabledRoles })
                }

            })
        } else {
            setSelectedObj({ tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id, user_id: selectedUser.id, roles: [], disabledRoles: [] })
        }

        forceUpdate()
    };
    const onCheckBoxChecked = (role) => {
        let loc = JSON.parse(JSON.stringify(selectedObj))
        if (loc.roles.includes(role)) {
            let index = loc.roles.findIndex(i => i === role)
            loc.roles.splice(index, 1)
        } else {
            loc.roles.push(role)
        }
        setSelectedObj(loc)
    }
    const getRoleNameById = (roleId) => {
        let roleName = 'Unknown Role'; // Default if not found
        Object.values(applicationlist).forEach(category => {
            if (category.applicationRoles) {
                const role = category.applicationRoles.find(role => role.id === roleId);
                console.log(category.applicationRoles)
                if (role) {
                    roleName = role.title;
                    return;
                }
            }

        });
        return roleName;
    }
    const header = (
        <div className="flex justify-content-end ">

            <span className="block mt-2 md:mt-0 p-input-icon-left" style={{ width: '30%' }}>
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => { setFilter(e.target.value) }} placeholder="Search..." />
            </span>
        </div>
    );

    const header2 = (
        <div className="flex justify-content-end ">

            <span className="block mt-2 md:mt-0 p-input-icon-left" style={{ width: '30%' }}>
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => { setFilter2(e.target.value) }} placeholder="Search..." />
            </span>
        </div>
    );
    const checkValidMailID = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const addNewEmployee = () => {

        setSubmitted(true)

        if (checkValidMailID(employee.empmailid.trim()) && employee.empname.trim().length !== 0 && employee.orgname && employee.orgname.trim().length) {


            if (employee.id) {
                let obj = employee
                let newObj = { information: { empname: employee.empname.trim(), orgname: employee.orgname.trim() } }
                if (employee.orglocation) {
                    newObj.information['orglocation'] = employee.orglocation
                } else {
                    newObj.information['orglocation'] = ''

                }

                console.log(newObj)
                APIServices.patch(API.UserProfile_Edit(employee.id), newObj)
                    .then((res) => {


                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `Updated successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                        renderData()
                    }).catch((e) => {


                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
            } else {
                let newObj = { clientId: admin_data.id, active: true, email: employee.empmailid.trim(), information: { empname: employee.empname.trim(), orglocation: employee.orglocation, orgname: employee.orgname.trim() } }
                APIServices.post(API.TVSExternalUser, newObj).then((res) => {
                    if (res?.data?.status || res?.data?.id) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `Added successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }


                    renderData()
                }).catch((e) => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Something went wrong`,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }

            setAddDialog(false)
            setSelectedConfig({ name: 'Assigned Locations' })
            setSubmitted(false)

        }

    }
    const addNewDealer = () => {

        setSubmitted(true)
        console.log(dealer)
        if (dealer.zonalManager && dealer.showroomType && dealer.servicecenterType && dealer.pinCode && dealer.dealerName.trim().length !== 0 && dealer.dealerCategory !== null && dealer.dealerCode.trim().length) {
            let obj = employee
            let newObj = { information: {} }
            newObj['dealerCode'] = dealer.dealerCode
            newObj.information['dealerCategory'] = dealer.dealerCategory
            newObj.information['dealerName'] = dealer.dealerName
            newObj.information['dealerLocation'] = dealer.dealerLocation
            newObj.information['dealerContact'] = dealer.dealerContact
            newObj.information['pinCode'] = dealer.pinCode
            newObj.information['principalContact'] = dealer.principalContact
            newObj.information['principalName'] = dealer.principalName
            newObj.information['salesmContact'] = dealer.salesmContact
            newObj.information['salesmName'] = dealer.salesmName
            newObj.information['workersmContact'] = dealer.workersmContact
            newObj.information['workersmName'] = dealer.workersmName
            newObj.information['zonalOffice'] = dealer.zonalOffice
            newObj.information['showroomType'] = dealer.showroomType
            newObj.information['servicecenterType'] = dealer.servicecenterType
            newObj.information['zonalManager'] = dealer.zonalManager
            newObj.information['placeId'] = dealer.placeId



            console.log(newObj)
            APIServices.patch(API.UserProfile_Edit(dealer.id), newObj)
                .then((res) => {
                    setDealer({ zonalManager: null, pinCode: '', coords: { lat: null, lng: null }, principalContact: null, principalName: '', salesmContact: null, salesmName: '', workersmContact: null, workersmName: '', zonalOffice: null, servicecenterType: null, showroomType: null, dealerContact: null, dealerName: '', email: '', dealerCategory: null, dealerLocation: '', dealerCode: '' })

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `Updated successfully`,
                        showConfirmButton: false,
                        timer: 1500
                    })

                    renderData()
                }).catch((e) => {
                    setDealer({ zonalManager: null, pinCode: '', coords: { lat: null, lng: null }, principalContact: null, principalName: '', salesmContact: null, salesmName: '', workersmContact: null, workersmName: '', zonalOffice: null, servicecenterType: null, showroomType: null, dealerContact: null, dealerName: '', email: '', dealerCategory: null, dealerLocation: '', dealerCode: '' })

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Something went wrong`,
                        showConfirmButton: false,
                        timer: 1500
                    })

                })
            setAddDealerDialog(false)

            setSubmitted(false)

        }

    }
    const addDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewEmployee() }} />

        </div>)
    }
    const addDealerDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewDealer() }} />

        </div>)
    }

    const editUser = (user) => {
        console.log(user)
        setAddDialog(true)
        setEmployee(user)

    }
    const editDealer = (rowData) => {
        console.log(rowData)
        setDealer(rowData)
        setAddDealerDialog(true)
    }
    const dealerCategoryTemplate = (rowData) => {

        return (
            <div >

                {categoryList.find(x => x.id === rowData.category)?.name || 'NA'}
            </div>
        );
    }
    const dealerNameTemplate = (rowData) => {

        return (
            <div className='cur-pointer text-underline' onClick={() => { editDealer(rowData) }}>

                {rowData.dealerName}
            </div>
        );
    }
    const nameTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empname}
            </>
        );
    }
    const nameextTemplate = (rowData) => {

        return (
            <div className='clr-navy fs-14 fw-6 cur-pointer text-underline' onClick={() => { editUser(rowData) }}>

                {rowData.empname}
            </div>
        );
    }
    const emailTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empmailid}
            </>
        );
    }
    const logoTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <img src={rowData.logo} width='50' />
            </>
        );
    }
    const configTemplate = (rowData) => {

        return (
            <>
                <i onClick={() => { openConfig(rowData) }} className='pi pi-list'></i>
            </>
        );
    }


    const openConfig = (config) => {
        console.log(config)
        setSelectedUser(config)
        if (config.role === 'clientuser' || config.role === 'clientextuser') {
            setSelectedObj((prev) => ({ ...prev, tier1_id: null, tier2_id: null, tier3_id: null, user_id: config.id, roles: [] }))
            setAllLocationRoles(userRole.filter(i => i.user_id === config.id))
            setRoleDialog(true)
        } else if (config.role === 'clientdealer') {

            setSelectedUser(config)
            let exisitingRoleAss = userRole.find(i => i.user_id === config.id && i.tier1_id === 0 && i.tier2_id === null && i.tier3_id === null)
            console.log(exisitingRoleAss, { tier1_id: 0, tier2_id: null, tier3_id: null, user_id: config.id, roles: exisitingRoleAss?.roles || [], disabledRoles: exisitingRoleAss?.disabledRoles || [] })
            setSelectedObj((prev) => ({ ...prev, tier1_id: 0, tier2_id: null, tier3_id: null, user_id: config.id, roles: exisitingRoleAss?.roles || [], disabledRoles: exisitingRoleAss?.disabledRoles || [] }))
            setAllLocationRoles(userRole.filter(i => i.user_id === config.id))
            setDealerRoleDialog(true)
        }


    }


    const onRoleChange = (e, obj) => {
        let loc = employee
        loc.role[obj] = !e.value
        setEmployee(loc)
        console.log(loc)
        forceUpdate()
    }
    const updateEmployee = (obj, val) => {
        let loc = employee
        loc[obj] = val
        setEmployee(loc)
        forceUpdate()
    }
    const updateDealer = (obj, val) => {
        let loc = dealer
        loc[obj] = val
        setDealer(loc)
        forceUpdate()
    }
    const updateLocationData = async () => {
        let pincode = document.getElementById('pincode').value

        if (pincode) {
            var geocoder = new google.maps.Geocoder();


            try {

                geocoder.geocode({ address: pincode }, function (results, status) {
                    if (status === "OK") {
                        // Successfully retrieved geocoding data
                        console.log("Geocoding Results:", results);

                        const location = results[0].geometry.location;

                        // Get latitude and longitude
                        const latitude = location.lat();
                        const longitude = location.lng();
                        let loc = dealer
                        loc.pinCode = pincode
                        loc['placeId'] = results[0].place_id
                        loc.coords = { lng: longitude, lat: latitude }
                        setDealer(loc)
                        forceUpdate()

                    } else {
                        console.error("Geocoding failed due to:", status);
                        let loc = dealer
                        loc.pinCode = ''
                        loc.coords = { lng: null, lat: null }
                        loc['placeId'] = undefined
                        setDealer(loc)
                        forceUpdate()
                    }
                });
            } catch (error) {
                console.error('Error during Geocoding Request:', error.message);
                let loc = dealer
                loc.pinCode = ''
                loc.coords = { lng: null, lat: null }
                loc['placeId'] = undefined
                setDealer(loc)
                forceUpdate()
                return null;
            }
        }
    }
    const editTemplate = (rowData) => {

        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <i onClick={() => { editUser(rowData) }} className='material-icons'>edit</i>


            </div>
        );
    }
    const extheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="flex col-12 justify-content-end" >

                    <Button label="New External User" icon="pi pi-plus" style={{ width: 220 }} onClick={() => {
                        setEmployee({ empname: '', empmailid: '', orgname: '', orglocation: '' }); setSubmitted(false); setAddDialog(true)
                    }} />

                </div>
            </React.Fragment>

        </div>
    )
    return (
        <div className="grid p-fluid">
            {(login_data.clientId === 289 || login_data.clientId === 28 || login_data.clientId === 17) ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{
                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`User List`} </h5>
                    </div>
                    <Toast ref={toast} />
                    <div>
                        <TabMenu model={[{ label: 'Internal User', icon: 'pi pi-users' }, { label: 'External User', icon: 'pi pi-users' }
                        ]} onTabChange={(e) => { setActiveIndex(e.index); setFilter(''); setFilter2('') }} activeIndex={activeindex} />
                        {activeindex === 0 && <DataTable ref={dt} value={data.filter(i => i.role === 'clientuser')}
                            scrollable
                            dataKey="id" paginator rows={20} rowsPerPageOptions={[10, 20, 50, 100]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            globalFilterFields={['empname']} globalFilter={filter} emptyMessage="No records found." header={header} responsiveLayout="scroll">
                            <Column field="config" header="Role Config" body={configTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column>
                            <Column field="empname" header="Name" body={nameTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="empmailid" header="Email" body={emailTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        </DataTable>}
                        {activeindex === 1 && <DataTable ref={dt} value={data.filter(i => i.role === 'clientextuser')}
                            scrollable
                            dataKey="id" paginator rows={20} rowsPerPageOptions={[10, 20, 50, 100]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            globalFilterFields={['empname']} globalFilter={filter} emptyMessage="No records found." header={extheader} responsiveLayout="scroll">
                            <Column field="config" header="Role Config" body={configTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column>
                            <Column field="empname" header="Name" body={nameextTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="empmailid" header="Email" body={emailTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="orgname" header="Organization Name" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        </DataTable>}
                        {activeindex === 100 && <DataTable ref={dt} value={data.filter(i => i.role === 'clientdealer')}
                            scrollable globalFilterFields={['dealerName']} globalFilter={filter2}
                            dataKey="id" paginator rows={20} rowsPerPageOptions={[10, 20, 50, 100]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            emptyMessage="No records found." header={header2} responsiveLayout="scroll">
                            {/* <Column field="config" header="Role Config" body={configTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column> */}
                            <Column field="empname" header="Name" body={dealerNameTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="empmailid" header="Email" body={emailTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="dealerContact" header="Contact" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Category" headerStyle={{ width: '14%', minWidth: '10rem' }} body={dealerCategoryTemplate}></Column>

                        </DataTable>}
                        <Dialog visible={adddialog} style={{ width: '450px' }} header={employee.id ? "Edit User" : "Add User"} modal className="p-fluid" footer={addDialogFooter} onHide={() => { setSubmitted(false); setAddDialog(false) }}>

                            <div className="field">
                                <label htmlFor="refdate">User Name <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} value={employee.empname} onChange={(e) => { updateEmployee('empname', e.target.value) }} placeholder="Name" />

                            </div>

                            <div className="field">
                                <label htmlFor="refdate">User E-Mail ID / Login ID <span style={{ color: 'red' }}>*</span></label>
                                <InputText disabled={employee.id} type={'email'} value={employee.empmailid} onChange={(e) => { updateEmployee('empmailid', e.target.value) }} placeholder="Mail ID" />


                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Organization name <span style={{ color: 'red' }}>*</span> </label>
                                <InputText type={'text'} value={employee.orgname} onChange={(e) => { updateEmployee('orgname', e.target.value) }} placeholder="Organization name" />
                                {submitted && !employee.orgname && <small className="p-invalid" style={{ color: 'red' }}>Organization name is mandatory</small>}


                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Organization Location</label>
                                <InputText type={'text'} value={employee.orglocation} onChange={(e) => { updateEmployee('orglocation', e.target.value) }} placeholder="location name" />


                            </div>


                        </Dialog>
                        <Dialog visible={adddealerdialog} style={{ width: '450px' }} header="Edit Dealer" modal className="p-fluid" footer={addDealerDialogFooter} onHide={() => { setSubmitted(false); setAddDealerDialog(false) }}>

                            <div className="field">
                                <label htmlFor="refdate">Dealer Name <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} disabled value={dealer.dealerName} onChange={(e) => { updateDealer('dealerName', e.target.value) }} placeholder="Name" />

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Dealer ID <span style={{ color: 'red' }}>*</span></label>
                                <InputText value={dealer.dealerCode} onChange={(e) => { updateDealer('dealerCode', e.target.value) }} placeholder="Dealer Code" />
                                {submitted && dealer.dealerCode.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Dealer Id is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Dealer E-Mail ID / Login ID <span style={{ color: 'red' }}>*</span></label>
                                <InputText disabled={true} type={'email'} value={dealer.empmailid} placeholder="Mail ID" />


                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Dealer Contact No</label>
                                <InputText keyfilter={'pnum'} value={dealer.dealerContact} onChange={(e) => { updateDealer('dealerContact', e.target.value) }} placeholder="Contact No" />
                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Category <span style={{ color: 'red' }}>*</span></label>
                                <Dropdown value={dealer.dealerCategory} optionLabel='name' onChange={(e) => { updateDealer('dealerCategory', e.target.value) }} placeholder="Category" options={categoryList} />
                                {submitted && dealer.dealerCategory === null && <small className="p-invalid" style={{ color: 'red' }}>Dealer Category is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Postal Code {dealer.pinCode ? <i style={{ color: 'green' }} className='ml-2 pi pi-check fs-14' /> : <i style={{ color: 'red' }} className='ml-2 pi pi-times fs-14' />} </label>
                                <div className="p-inputgroup flex-1">
                                    <InputNumber inputId='pincode' useGrouping={false} placeholder="postal/pin/zip code" value={dealer.pinCode} />
                                    <Button icon="pi pi-search" style={{ color: 'white' }} className="clr-navy" onClick={() => { updateLocationData(dealer) }} />
                                </div>
                            </div>

                            <div className="field">
                                <label htmlFor="refdate">Location </label>
                                <InputText type={'text'} value={dealer.dealerLocation} onChange={(e) => { updateDealer('dealerLocation', e.target.value) }} placeholder="location name" />

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Zonal Manager <span style={{ color: 'red' }}>*</span></label>
                                <Dropdown value={dealer.zonalManager} optionLabel='name' optionValue='id' onChange={(e) => { updateDealer('zonalManager', e.target.value) }} placeholder="Zonal Office" options={data.filter(i => i.role === 'clientuser').map(i => ({ name: i.empname, id: i.id }))} />
                                {submitted && dealer.zonalManager === null && <small className="p-invalid" style={{ color: 'red' }}>Zonal Manager is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Zonal office <span style={{ color: 'red' }}>*</span></label>
                                <Dropdown value={dealer.zonalOffice} optionLabel='name' onChange={(e) => { updateDealer('zonalOffice', e.target.value) }} placeholder="Zonal Office" options={zonalOfficeList} />
                                {submitted && dealer.zonalOffice === null && <small className="p-invalid" style={{ color: 'red' }}>Zonal office is mandatory</small>}

                            </div>

                            <label className='fw-6 fs-18 clr-navy mb-2'>Dealer Principal Detail</label>
                            <div className="field">
                                <label htmlFor="refdate">Dealer Principal Name</label>
                                <InputText value={dealer.principalName} onChange={(e) => { updateDealer('principalName', e.target.value) }} placeholder="Principal Name" />

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Dealer Principal Contact No</label>
                                <InputNumber useGrouping={false} value={dealer.principalContact} onChange={(e) => { updateDealer('principalContact', e.value) }} placeholder="Principal Contact" />

                            </div>
                            <label className='fw-6 fs-18 clr-navy mb-2'>Sales Manager Detail</label>
                            <div className="field">
                                <label htmlFor="refdate">Sales Manager Name</label>
                                <InputText value={dealer.salesmName} onChange={(e) => { updateDealer('salesmName', e.target.value) }} placeholder="Sales Manager Name" />

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Sales Manager Contact No</label>
                                <InputNumber useGrouping={false} value={dealer.salesmContact} onChange={(e) => { updateDealer('salesmContact', e.value) }} placeholder="Sales Manager Contact" />

                            </div>
                            <label className='fw-6 fs-18 clr-navy mb-2'>Worker Manager Detail</label>
                            <div className="field">
                                <label htmlFor="refdate">Worker Manager Name</label>
                                <InputText value={dealer.workersmName} onChange={(e) => { updateDealer('workersmName', e.target.value) }} placeholder="Worker Manager Name" />

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Worker Manager Contact No</label>
                                <InputNumber useGrouping={false} value={dealer.workersmContact} onChange={(e) => { updateDealer('workersmContact', e.value) }} placeholder="Worker Manager Contact" />

                            </div>
                            <label className='fw-6 fs-18 clr-navy mb-2'>Dealership Ownership</label>
                            <div className="field">
                                <label htmlFor="refdate">Showroom <span style={{ color: 'red' }}>*</span></label>
                                <Dropdown value={dealer.showroomType} optionLabel='name' onChange={(e) => { updateDealer('showroomType', e.target.value) }} placeholder="Show Room Type" options={propertyTypeList} />
                                {submitted && dealer.showroomType === null && <small className="p-invalid" style={{ color: 'red' }}>Show Room Type is mandatory</small>}

                            </div>

                            <div className="field">
                                <label htmlFor="refdate">Service Centre <span style={{ color: 'red' }}>*</span></label>
                                <Dropdown value={dealer.servicecenterType} optionLabel='name' onChange={(e) => { updateDealer('servicecenterType', e.target.value) }} placeholder="Service Center Type" options={propertyTypeList} />
                                {submitted && dealer.servicecenterType === null && <small className="p-invalid" style={{ color: 'red' }}>Service Centre Type is mandatory</small>}

                            </div>



                        </Dialog>
                        <Dialog style={{ width: '85%' }} header={`Assignment permission to ` + selectedUser.empname} visible={roledialog} modal onHide={() => { setRoleDialog(false) }}>
                            {userRole.filter(i => (i.user_id === selectedUser.id && i.roles.length)).length !== 0 && <div className='flex justify-content-end'>
                                <Button
                                    label="Reset Assignment"
                                    severity={"danger"}

                                    onClick={resetAssignment}
                                />
                            </div>}
                            <div>
                                <ReadMoreComponent percent={100} content={`<div>Use this tab which appears on a click of  <i class="pi pi-list" ></i>  to assign a specific role to the user added for managing access and administrative functions for managing the data at various levels. Follow the steps below to configure permissions.
<b>Select Location:</b> Use the dropdown menu to choose a location for which the user would be assigned to manage the data. Locations have been structured in three-tier i.e., Country, Region/State, Site. Based on the former tier selected, the next tier options will open. Please choose "All Countries“  to assign the user a role for managing the data globally.
<b>User Roles:</b> Select one or more roles to define the user's level of access:
<b>Reporter:</b> Collect and submit required quantitative or qualitative data (or amendments) for the assigned entity together with necessary evidence and comments, if any. To also re-submit the data with the necessary changes done as per the comments provided by the Reviewer, if the data has been sent back by the Reviewer. 
<b>Reviewer:</b> Review the submitted data for correctness and submission quality, including relevant attachments and data trends. Can request amendments by sending it back to the Reporter. 
<b>Approver:</b> Reviews the consolidated data for accuracy and relevancy, provides final approval, and, if necessary, requests further clarification from Reviewers or Reporters. The Approver also oversees the generation of final reports for compliance with regulatory or business requirements.
<b>Viewer:</b> Has read-only access to view data.
<b>Sub Admin Role:</b> Check the Admin box if the user requires the Admin privileges. This role provides higher-level access to manage functions within the platform.
<b>Section Admin Permissions:</b> Grant specific section-based permissions to allow the user to oversee and manage these areas.
Available sections include <b>Sustainability, Health, Safety Central, Safety Operational, Supply Chain, Dealership Sustainability, Environmental, Social, Governance</b>
Once you've selected the roles and permissions, click Save Assignment to apply the changes.
This setup helps control user access levels, ensuring they have the appropriate permissions for their Roles.</div>`} />
                            </div>
                            <div className='grid m-0 col-12'>

                                <div className='col-6'>
                                    <div class="grid p-4">
                                        <div class="col-4">
                                            <Dropdown
                                                id="country"
                                                optionLabel="name"
                                                optionValue="id"
                                                value={selectedObj.tier1_id}
                                                options={selectedlist.country}
                                                onChange={(e) =>
                                                    handleDropdownChange('country', e.value)
                                                }
                                                placeholder='Choose country'
                                                className="w-full"
                                            />
                                        </div>
                                        {(selectedObj.tier1_id !== null && selectedObj.tier1_id !== 0) && <div class="col-4">
                                            <Dropdown
                                                id="city"
                                                optionLabel="name"
                                                optionValue="id"
                                                value={selectedObj.tier2_id}
                                                options={selectedlist.city}
                                                onChange={(e) =>
                                                    handleDropdownChange('city', e.value)
                                                }
                                                placeholder='Choose city'
                                                className="w-full"
                                            />
                                        </div>}
                                        {selectedObj.tier1_id !== null && selectedObj.tier1_id !== 0 && selectedObj.tier2_id !== null && selectedObj.tier2_id !== 0 && <div class="col-4">
                                            <Dropdown
                                                id="unit"
                                                optionLabel="name"
                                                optionValue="id"
                                                value={selectedObj.tier3_id}
                                                options={selectedlist.site}
                                                onChange={(e) =>
                                                    handleDropdownChange('site', e.value)
                                                }
                                                placeholder='Choose business unit'
                                                className="w-full"
                                            />
                                        </div>}
                                    </div>
                                    {(selectedObj.tier1_id !== null ? selectedObj.tier2_id !== null ? selectedObj.tier3_id !== null ? true : selectedObj.tier2_id === 0 : selectedObj.tier1_id === 0 : false) &&
                                        <div>

                                            {applicationlist.map((app, index) => {
                                                let displayedCategories = new Set();
                                                if (index === 0) {
                                                    return (
                                                        <>
                                                            {app.applicationRoles && app.applicationRoles.length > 0 && <div>

                                                                <div className="grid p-2">
                                                                    {app.applicationRoles && app.applicationRoles.sort((a, b) => {
                                                                        return a.categoryOrder - b.categoryOrder
                                                                    }).map((role, index) => {

                                                                        if (role.status && (!role.client_ids || (role.client_ids && !role.client_ids.length) || (role.client_ids && role.client_ids.length && role.client_ids.includes(admin_data.id)))) {
                                                                            if (role.id === 6 ? (selectedObj.tier1_id > 0 && selectedObj.tier2_id === 0 && selectedObj.tier3_id === null && login_data.role === 'clientadmin') : [7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(role.id) ? (selectedObj.tier1_id === 0 && selectedObj.tier2_id === null && selectedObj.tier3_id === null && login_data.role === 'clientadmin') : [17, 18, 19].includes(role.id) ? (selectedObj.tier1_id === 0 && selectedObj.tier2_id === null && selectedObj.tier3_id === null) : true) {
                                                                                const shouldShowCategory = !displayedCategories.has(role.category);


                                                                                displayedCategories.add(role.category);
                                                                                return (
                                                                                    <React.Fragment key={index}>

                                                                                        {shouldShowCategory && role.category && (
                                                                                            <div className="col-12">
                                                                                                <h4>{role.category}</h4>
                                                                                            </div>
                                                                                        )}

                                                                                        <div className='m-1 p-2'>
                                                                                            <Checkbox inputId={"role" + index} disabled={selectedObj.disabledRoles && selectedObj.disabledRoles.includes(role.id)} name="Reporter" value="Reporter" onChange={() => { onCheckBoxChecked(role.id) }} checked={(selectedObj.roles.includes(role.id) || (selectedObj.disabledRoles && selectedObj.disabledRoles.includes(role.id)))} />
                                                                                            <label htmlFor={"role" + index} className="ml-2">{role.title}</label>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        }

                                                                    })

                                                                    }
                                                                </div>
                                                            </div>}
                                                        </>

                                                    )
                                                }
                                            })

                                            }

                                            <div className="flex justify-content-evenly p-4">

                                                <Button
                                                    label="Save Assignment"

                                                    onClick={saveAssignment}
                                                />
                                            </div>

                                        </div>}


                                </div>
                                <div className='col-6'>
                                    <label className='fw-7 fs-18 clr-navy mb-2'> List of Role Assigned by Location </label>
                                    <div style={{ height: 400, overflow: 'auto' }} >
                                        {allLocationRoles.map((locationRole, index) => {
                                            // Initialize an array to hold the names for each level
                                            let locationNames = [];
                                            const roleNames = locationRole.roles.map(roleId => getRoleNameById(roleId));
                                            console.log(locationRole)
                                            // Function to get name by ID or handle special 'all' cases
                                            const getLocationName = (id, locationArray, allText) => {

                                                if (id === null) return ''; // Return empty if ID is not set
                                                if (id === 0) return allText; // Handle 'all' cases
                                                const location = locationArray.find(location => location.id === id);
                                                return location ? location.name : 'Unknown';
                                            };

                                            // Get names for each level
                                            locationNames.push(getLocationName(locationRole.tier1_id, rawsitelist, 'All Country'));
                                            locationNames.push(getLocationName(locationRole.tier2_id, rawsitelist.flatMap(i => i.locationTwos), 'All Region'));
                                            locationNames.push(getLocationName(locationRole.tier3_id, rawsitelist.flatMap(i => i.locationTwos.flatMap(i => i.locationThrees)), 'All Business Unit'));

                                            // Filter out empty or unknown locations before joining
                                            locationNames = locationNames.filter(name => name && name !== 'Unknown');
                                            if (roleNames.length) {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div>
                                                            {locationNames.join(' > ')} {/* Join names with ' > ' for breadcrumb style display */}
                                                            <ul>
                                                                {roleNames.map((name, roleIndex) => (
                                                                    <li key={roleIndex}>{name}</li> // Render each role name in a bullet point
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <hr />
                                                    </React.Fragment>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>

                        </Dialog>
                        <Dialog style={{ width: '85%' }} header={`Assignment permission to ` + selectedUser.dealerName} visible={dealerroledialog} modal onHide={() => { setDealerRoleDialog(false) }}>
                            {userRole.filter(i => (i.user_id === selectedUser.id && i.roles.length)).length !== 0 && login_data.clientId === 289 && <div className='flex justify-content-end'>
                                <Button
                                    label="Reset Assignment"
                                    severity={"danger"}

                                    onClick={resetAssignment}
                                />
                            </div>}

                            <div className='grid m-0 col-12'>

                                <div className='col-6'>
                                    <div>

                                        {applicationlist.map((app, index) => {
                                            let displayedCategories = new Set();
                                            if (index === 0) {
                                                return (
                                                    <>
                                                        {app.applicationRoles && app.applicationRoles.length > 0 && <div>

                                                            <div className="grid p-2">
                                                                {app.applicationRoles && app.applicationRoles.sort((a, b) => {
                                                                    return a.categoryOrder - b.categoryOrder
                                                                }).map((role, index) => {

                                                                    if (role.status && (!role.client_ids || (role.client_ids && !role.client_ids.length) || (role.client_ids && role.client_ids.length && role.client_ids.includes(admin_data.id)))) {
                                                                        if ([19, 20].includes(role.id) ? (selectedObj.tier1_id === 0 && selectedObj.tier2_id === null && selectedObj.tier3_id === null) : false) {
                                                                            const shouldShowCategory = !displayedCategories.has(role.category);


                                                                            displayedCategories.add(role.category);
                                                                            return (
                                                                                <React.Fragment key={index}>

                                                                                    {shouldShowCategory && role.category && (
                                                                                        <div className="col-12">
                                                                                            <h4>{role.category}</h4>
                                                                                        </div>
                                                                                    )}

                                                                                    <div className='m-1 p-2'>
                                                                                        <Checkbox inputId={"role" + index} disabled={selectedObj.disabledRoles && selectedObj.disabledRoles.includes(role.id)} name="Reporter" value="Reporter" onChange={() => { onCheckBoxChecked(role.id) }} checked={(selectedObj.roles.includes(role.id) || (selectedObj.disabledRoles && selectedObj.disabledRoles.includes(role.id)))} />
                                                                                        <label htmlFor={"role" + index} className="ml-2">{role.title}</label>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                    }

                                                                })

                                                                }
                                                            </div>
                                                        </div>}
                                                    </>

                                                )
                                            }
                                        })

                                        }

                                        <div className="flex justify-content-evenly p-4">

                                            {login_data.clientId === 289 && <Button
                                                label="Save Assignment"

                                                onClick={saveDealerAssignment}
                                            />}
                                        </div>

                                    </div>


                                </div>
                                <div className='col-6'>

                                    <div style={{ height: 400, overflow: 'auto' }} >
                                        {allLocationRoles.map((locationRole, index) => {
                                            // Initialize an array to hold the names for each level
                                            let locationNames = [];
                                            const roleNames = locationRole.roles.map(roleId => getRoleNameById(roleId));
                                            console.log(locationRole)
                                            // Function to get name by ID or handle special 'all' cases
                                            const getLocationName = (id, locationArray, allText) => {

                                                if (id === null) return ''; // Return empty if ID is not set
                                                if (id === 0) return allText; // Handle 'all' cases
                                                const location = locationArray.find(location => location.id === id);
                                                return location ? location.name : 'Unknown';
                                            };

                                            // Get names for each level
                                            locationNames.push(getLocationName(locationRole.tier1_id, rawsitelist, 'All Country'));
                                            locationNames.push(getLocationName(locationRole.tier2_id, rawsitelist.flatMap(i => i.locationTwos), 'All Region'));
                                            locationNames.push(getLocationName(locationRole.tier3_id, rawsitelist.flatMap(i => i.locationTwos.flatMap(i => i.locationThrees)), 'All Business Unit'));

                                            // Filter out empty or unknown locations before joining
                                            locationNames = locationNames.filter(name => name && name !== 'Unknown');
                                            if (roleNames.length) {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div>
                                                            {locationNames.join(' > ')} {/* Join names with ' > ' for breadcrumb style display */}
                                                            <ul>
                                                                {roleNames.map((name, roleIndex) => (
                                                                    <li key={roleIndex}>{name}</li> // Render each role name in a bullet point
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <hr />
                                                    </React.Fragment>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>

                        </Dialog>

                    </div>







                </div>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
        </div>
    )
}

export default MasterList

