import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AttachmentComponent } from '../../../../components/Attachment';


function ActionofDealerStatus({ report }) {
  const select = useSelector((state) => state.userlist);
  console.log(select)
  console.log("users")
  console.log(report)

  const customFontStyle = {
    fontFamily: 'Lato, sans-serif',

  }
  const getName = (id) => {
    const user = select.find(user => user.id === id);
    return user?.email || '';
  };
  // const category3Items = report.supplierActions.filter(item => item.categoryOfFinding === 3);
  // const totalCategory3 = category3Items

  // console.log(totalCategory3)

  // const type3Count = category3Items.filter(item => item.type === 3)

  // console.log(type3Count)

  const getStatusAction = (item, outerIndex) => {
    // const latestActionIndex = item.supplierActionHistories?.length || 0;
    // const latestActionNumber = `${outerIndex + 1}.${latestActionIndex > 0 ? latestActionIndex - 1 : 0}`; // e.g., 1.0, 1.1, etc.

    return (
      <Typography variant="body1" style={customFontStyle}>
        <div className='row d-flex mb-2'>
          <div className='col-6'>
            <h4>{`CM `}</h4>
            <p className='p-0' style={{ fontSize: '14px' }}>{item.finding}</p>
          </div>
          <div className='col-6'>
            <span className={`badge fw-bold status-tag-orange`}>
              {item.type === 1
                ? "Action Assigned"
                : item.type === 2
                  ? 'Action Returned'
                  : item.type === 3
                    ? "Action Completed"
                    : ''}
            </span>
          </div>
        </div>
        <div className='row d-flex'>
          <div className='col-6'>
            {/* Optional: show additional details here */}
          </div>
        </div>
      </Typography>
    );
  };


  const getCMData = (item, outerIndex) => {
    return (
      <>
        {item.supplierActionHistories.map((history, index) => {
          return (
            <div key={index} style={{ boxShadow: '0px 0px 4px 4px #f5f5f5', padding: 10, marginBottom: 15 }}>
              <p className='obs-title'>Action {outerIndex + 1}.{index}</p>
              <div className='row' style={{ marginBottom: 10 }}>
                <p className='obs-title'>Root Cause</p>
                <p className='obs-content' dangerouslySetInnerHTML={{ __html: history.rootCause }}></p>
              </div>
              <div className='row' style={{ marginBottom: 10 }}>
                <p className='obs-title'>Corrective Action</p>
                <p className='obs-content' dangerouslySetInnerHTML={{ __html: history.correctiveAction }}></p>
              </div>
              <div className='row' style={{ marginBottom: 10 }}>
                <p className='obs-title'>Action Taken</p>
                <p className='obs-content' dangerouslySetInnerHTML={{ __html: history.actionTaken }}></p>
              </div>
              <div className='row' style={{ marginBottom: 10 }}>
                <p className='obs-title'>Action Taken Date</p>

                <p className='obs-content'>{moment(history.supplier_submitted_on).format('DD-MM-YYYY hh:mm A')}</p>
              </div>

              <AttachmentComponent mandatory={true} edit={0} documents={history.supplierAttachments
              } labels={'uploads'} hint={''} />

              {history.reject === 1 &&

                <>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Approver Comments</p>
                    <p className='obs-content'>{history.approverComments}</p>
                  </div>

                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Rejected Date</p>
                    <p className='obs-content'>{moment(history.returned_on).format('DD-MM-YYYY hh:mm A')}</p>
                  </div>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Rejected By</p>
                    <p className='obs-content'>{getName(history.returned_by)}</p>
                  </div>

                </>}

              {(history.reject === 0 && history.type === 2) &&

                <>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Approver Comments</p>
                    <p className='obs-content'>{history.approverComments}</p>
                  </div>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Approved On</p>
                    <p className='obs-content'>{moment(history.approved_on).format('DD-MM-YYYY hh:mm A')}</p>
                  </div>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Approved By</p>
                    <p className='obs-content'>{getName(history.approved_by)}</p>
                  </div>
                </>}

              {(history.reject === 0 && history.type === 3) &&

                <>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Approver Comments</p>
                    <p className='obs-content'>{history.approverComments}</p>
                  </div>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Approved On</p>
                    <p className='obs-content'>{moment(history.approved_on).format('DD-MM-YYYY hh:mm A')}</p>
                  </div>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <p className='obs-title'>Approved By</p>
                    <p className='obs-content'>{getName(history.approved_by)}</p>
                  </div>
                </>}


            </div>
          );
        })}
      </>
    );
  };


  return (
    <div>

      <Accordion>
        {report.actions.map((item, i) => {
          return (
            <AccordionTab
              header={getStatusAction(item, i)}
              headerClassName='test-header'
              key={i}
            >
              {getCMData(item, i)}
            </AccordionTab>

          )
        })}

      </Accordion>

    </div>
  )
}

export default ActionofDealerStatus
