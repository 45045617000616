import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";

// For more advanced file-handling, you'd use an uploader or handle files in your parent component

const DocumentManager = ({ sectionId, uploadedBy = "Current User" }) => {
  // We'll store documents in local state keyed by "sectionId".
  // If you prefer, store them in your parent or a global store.
  const [documents, setDocuments] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  // Fields for the doc being added or edited
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedDate, setUploadedDate] = useState(null);
  const [file, setFile] = useState(null);

  const openNew = () => {
    resetForm();
    setEditIndex(null);
    setShowDialog(true);
  };

  const openEdit = (rowData, rowIndex) => {
    setTitle(rowData.title);
    setDescription(rowData.description);
    setUploadedDate(new Date(rowData.uploadedDate));
    setEditIndex(rowIndex);
    setShowDialog(true);
  };

  const saveDocument = () => {
    const doc = {
      title,
      description,
      uploadedBy,
      uploadedDate: uploadedDate || new Date(), // fallback if not chosen
      file,
    };

    let updatedDocs = [...documents];
    if (editIndex !== null) {
      // Update existing doc
      updatedDocs[editIndex] = doc;
    } else {
      // Add new doc
      updatedDocs.push(doc);
    }

    setDocuments(updatedDocs);
    setShowDialog(false);
    resetForm();
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setUploadedDate(null);
    setFile(null);
  };

  const onFileSelect = (event) => {
    // You can handle multi-file or single file
    if (event.files && event.files.length > 0) {
      setFile(event.files[0]);
    }
  };

  // Delete logic (optional)
  const deleteDocument = (rowIndex) => {
    const updatedDocs = documents.filter((_, i) => i !== rowIndex);
    setDocuments(updatedDocs);
  };

  // Custom cell templates
  // 1) Document Title is clickable
  const titleTemplate = (rowData) => {
    return (
      <span
        style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
        onClick={() => alert("Open or download the file here.")}
      >
        {rowData.title}
      </span>
    );
  };

  // 2) Format date
  const dateTemplate = (rowData) => {
    const date = new Date(rowData.uploadedDate);
    return date.toLocaleDateString("en-US");
  };

  // 3) Action buttons (Edit, Delete)
  const actionTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Button
          label="Edit"
          icon="pi pi-pencil"
          className="p-button-text p-mr-2"
          onClick={() => openEdit(rowData, rowIndex)}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          className="p-button-text p-button-danger"
          onClick={() => deleteDocument(rowIndex)}
        />
      </div>
    );
  };

  return (
    <div>
      {/* Table Header */}
      {/* <div className="mb-2">
        <Button label="Add Document" icon="pi pi-plus" onClick={openNew} />
      </div> */}

      {/* Documents Table */}
      <DataTable value={documents} responsiveLayout="scroll">
        <Column
          field="title"
          header="Document Title"
          body={titleTemplate}
        ></Column>
        <Column
          field="description"
          header="Brief Description"
        ></Column>
        <Column
          field="uploadedBy"
          header="Uploaded By"
        ></Column>
        <Column
          field="uploadedDate"
          header="Uploaded Date"
          body={dateTemplate}
        ></Column>
        <Column body={actionTemplate} header="Actions" style={{ width: "150px" }} />
      </DataTable>

      {/* Add/Edit Modal */}
      <Dialog
        visible={showDialog}
        style={{ width: "500px" }}
        header="Document Details"
        modal
        onHide={() => setShowDialog(false)}
      >
        <div className="p-fluid">
          <div className="field">
            <label htmlFor="title">Document Title</label>
            <InputText
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="field">
            <label htmlFor="description">Brief Description</label>
            <InputTextarea
              id="description"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* <div className="field">
            <label htmlFor="uploadedDate">Upload Date</label>
            <Calendar
              id="uploadedDate"
              value={uploadedDate}
              onChange={(e) => setUploadedDate(e.value)}
              showIcon
              dateFormat="mm/dd/yy"
            />
          </div> */}

          <div className="field">
            <label>File Upload</label>
            <FileUpload
              name="file"
              customUpload
              auto={false}
              chooseLabel="Select File"
              uploadHandler={onFileSelect}
              maxFileSize={10000000} // 10MB
            />
            {/* 
              Alternatively, for a simpler approach:
              <input type="file" onChange={(e) => setFile(e.target.files[0])} />
            */}
          </div>
        </div>

        <div className="p-dialog-footer" style={{ marginTop: "1rem" }}>
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => {
              setShowDialog(false);
              resetForm();
            }}
            className="p-button-text"
          />
          <Button
            label="Save"
            icon="pi pi-check"
            onClick={saveDocument}
            autoFocus
          />
        </div>
      </Dialog>
    </div>
  );
};

export default DocumentManager;
