import { Card } from "primereact/card";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import FirstBarDemo from "./FirstBarDemo";
import SecondPieDemo from "./SecondPieDemo";
import ThirdLineDemo from "./ThirdLineDemo";
import SubGraph2Demo from "./SubGraph2Demo";
import SubGraph3Demo from "./SubGraph3Demo";
import SubGraph4Demo from "./SubGraph4Demo";
import SubGraph5Demo from "./SubGraph5Demo";
import SupplierTable from "./SupplierTable";
import SubGraph1Demo from "./SubGraph1Demo";
import FiltersSectionDemo from "./FiltersSectionDemo";
// import supplyData from "./supply.json";
import { API } from "../../../../../constants/api_url";
import APIServices from "../../../../../service/APIService";

const SuppliersDashboard = () => {
  const [data, setData] = useState([]);

  const [activeMode, setActiveMode] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedbu, setSelectedBU] = useState('All');

  const [supplyData, setSupplyData] = useState([]);
  const [filteredSupplyData, setFilteredSupplyData] = useState([]);

  useEffect(() => {
    getSupplyData();
  }, []);

  const getSupplyData = async () => {
    try {
      const response = await APIServices.get(API.supplierResponse);
      if (response.data) {
        setSupplyData(response.data);
      }
    } catch (error) {
      console.log("Error fetching supply data: ", error);
    }
  }

  const menuRef = useRef(null);
  const panelItems = [
    {
      items: [
        {
          label: "Export as Excel",
          icon: "pi pi-file-excel",
          command: () => {
            // downloadExcelWithImage(chartRef);
          },
        },
        {
          label: "Export as PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            // downloadPdfWithImage(chartRef);
          },
        },
        {
          label: "Export as JPG",
          icon: "pi pi-image",
          command: () => {
            // downloadChartAsJpg(chartRef);
          },
        },
        activeMode && {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            // printChart(chartRef);
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (supplyData.length > 0) {
      // Rank the data initially (without filtering)
      let rankedData = [...supplyData].sort(
        (a, b) => b.msi_score - a.msi_score
      );

      rankedData = rankedData.map((item, index) => ({
        ...item,
        rank: index + 1, // Rank starts from 1
      }));

      setData(rankedData); // Store the ranked data in the state
      setFilteredSupplyData(rankedData); // Set the initial filtered data to the ranked data
    }
  }, [supplyData]);

  useEffect(() => {
    // Apply filter logic here, if any
    if (!data?.length) return;

    // Example: Apply filter (if any)
    // let filteredData = data.filter(item => item.someCondition);
    // setFilteredSupplyData(filteredData); // Update filtered data
  }, [data]);

  return (
    <>
      <FirstBarDemo supplyData={filteredSupplyData} />
      <SecondPieDemo supplyData={filteredSupplyData} />
      <ThirdLineDemo supplyData={filteredSupplyData} />

      <Card>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>MSI Performance Analytics</h3>
          <div
            style={{
              margin: "18px 10px 18px 10px",
              display: "flex",
            }}
          >
            <div
              className="buttons"
              style={{
                background: "#F0F2F4",
                borderRadius: "3px",
                width: "4.5rem",
                marginLeft: "10px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                  padding: "6px",
                  color: "black",
                  border: "0px",
                  marginRight: "4px",
                }}
                onClick={() => {
                  setActiveMode(false);
                }}
              >
                <i className="pi pi-table fs-19" />
              </Button>
              <Button
                style={{
                  background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                  padding: "6px",
                  color: "black",
                  border: "0px",
                }}
                onClick={() => {
                  setActiveMode(true);
                }}
              >
                <i className="pi pi-chart-bar fs-19" />
              </Button>
            </div>
            <div ref={menuRef}>
              <Button
                style={{
                  color: "black",
                  height: "30px",
                  marginLeft: "3px",
                  background: "#F0F2F4",
                  border: "0px",
                  padding: "6px",
                  position: "relative",
                }}
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                <i className="pi pi-angle-down fs-19" />
              </Button>
              {dropdownOpen && (
                <Menu
                  model={panelItems}
                  style={{
                    position: "absolute",
                    right: 45,
                    zIndex: "1",
                    padding: 0,
                  }}
                ></Menu>
              )}
            </div>
          </div>
        </div>
        <FiltersSectionDemo
          supplyData={supplyData} setSelectedBu={(e) => { setSelectedBU(e) }}
          setFilteredSupplyData={setFilteredSupplyData}
        />
        <Card style={{ height: "120px", backgroundColor: "#FAFAFA" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <h3 style={{ marginLeft: "-180px" }}>Summary</h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "18px", fontWeight: 600 }}>
                Self Assessment Score
              </p>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {"N/A"}
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "18px", fontWeight: 600 }}>
                MSI Calibration Score
              </p>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {filteredSupplyData.length
                  ? filteredSupplyData[0].msi_score
                  : "N/A"}
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "18px", fontWeight: 600 }}>Rank</p>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {filteredSupplyData.length === 1
                  ? filteredSupplyData[0].rank
                  : "N/A"}{" "}
              </p>
            </div>
          </div>
        </Card>

        <div style={{ display: "flex", marginTop: "30px", maxWidth: "100%" }}>
          <SubGraph1Demo supplyData={filteredSupplyData} />
          <SubGraph2Demo selectedSite={selectedbu} supplyData={filteredSupplyData} />
        </div>
      </Card>
      <Card>
        <div style={{ display: "flex" }}>
          <SubGraph3Demo supplyData={filteredSupplyData} />
          <SubGraph4Demo supplyData={filteredSupplyData} />
          <SubGraph5Demo supplyData={filteredSupplyData} />
        </div>
      </Card>
      <Card>
        <SupplierTable supplyData={filteredSupplyData} />
      </Card>
    </>
  );
};

export default SuppliersDashboard;
