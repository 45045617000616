
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";

const initialState = {

    userList: [],
    admindetail: {},
    clientList: [],
    supplierList: [],
    dealerList: [],
    tvsExtUserList: []
}
export const fetchUserList = createAsyncThunk('api/userlist', async (cid) => {
    return await APIServices.get(API.UserProfileFiltered + `?filter=${encodeURIComponent(JSON.stringify({where:{clientId:cid}, "include": [{relation:"vendorCodes"}] }))}`).then((res) => [...res.data.filter((i) => { return (i.clientId === cid ) })])

})
export const fetchClientList = createAsyncThunk('api/clientlist', async () => {
    return await APIServices.get(API.UserProfile).then((res) => res.data)

})


const userProfileList = createSlice({
    name: 'userlist',
    initialState,
    extraReducers: (builder) => {

        builder.addCase(fetchUserList.fulfilled, (state, action) => { console.log(action.payload); return { ...state, supplierList: action.payload.filter((i) => { return i.role === 'clientsupplier' }), tvsExtUserList: action.payload.filter((i) => { return (i.role === 'clientextuser') }), userList: action.payload.filter((i) => { return (i.role === 'clientuser' || i.role === 'clientadmin') }), admindetail: action.payload.filter((i) => { return i.role === 'clientadmin' })[0], supplierList: action.payload.filter((i) => { return (i.role === 'clientsupplier') }), dealerList: action.payload.filter((i) => { return (i.role === 'clientdealer') }) } })
            .addCase(fetchClientList.fulfilled, (state, action) => { return { ...state, clientList: action.payload.filter((i) => { return i.role === 'clientadmin' && i.role !== 'eisqradmin' }) } })

    }



})

export default userProfileList.reducer

