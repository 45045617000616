import React, { useState, useRef } from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";

const initialData = [
  { name: "Forging Machining", value: 8.1, number: 1930.44, suppliers: 57 },
  { name: "Casting Machining", value: 11.0, number: 2618.04, suppliers: 48 },
  { name: "Pressing Fabrication", value: 18.0, number: 4290.68, suppliers: 47 },
  { name: "Prop Mechanical", value: 19.8, number: 4728.91, suppliers: 108 },
  { name: "Prop Electrical", value: 21.8, number: 5205.96, suppliers: 78 },
  {
    name: "Plastics Rubber Painting Stickers",
    value: 10.2,
    number: 2438.64,
    suppliers: 88,
  },
  { name: "ev3w2w", value: 4.3, number: 1021.32, suppliers: 18 },
  { name: "BMW", value: 1.9, number: 443, suppliers: 118 },
  { name: "Accessories", value: 1.1, number: 272.1, suppliers: 137 },
  { name: "IDM", value: 3.9, number: 920, suppliers: 819 },
];

const COLORS = [
  "#5B8FF7",
  "#BDD1F7",
  "#F9DF7F",
  "#F5C37B",
  "#F4A460",
  "#FF9D7C",
  "#A0522D",
  "#CCCED5",
  "#A7FECD",
  "#29C76F",
  "#209F00",
];

const SecondPieDemo = () => {
  const [data, setData] = useState(initialData);
  const [activeMode, setActiveMode] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);

  // const [visibleSeries, setVisibleSeries] = useState(
  //   initialData.reduce((acc, item) => {
  //     acc[item.name] = true;
  //     return acc;
  //   }, {})
  // );

  const panelItems = [
    {
      items: [
        {
          label: "Export as Excel",
          icon: "pi pi-file-excel",
          command: () => {
            // downloadExcelWithImage(chartRef);
          },
        },
        {
          label: "Export as PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            // downloadPdfWithImage(chartRef);
          },
        },
        {
          label: "Export as JPG",
          icon: "pi pi-image",
          command: () => {
            // downloadChartAsJpg(chartRef);
          },
        },
        activeMode && {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            // printChart(chartRef);
          },
        },
      ],
    },
  ];
  const CustomLegend = ({ position }) => {
    const legendItems = position === "left" ? data.slice(0, 5) : data.slice(5);

    return (
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {legendItems.map((entry) => {
          const index = data.findIndex((item) => item.name === entry.name);
          return (
            <div
              key={entry.name}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "25px",
              }}
            >
              <li>
                <span
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: COLORS[index % COLORS.length],
                    display: "inline-block",
                    marginRight: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
                <span style={{ color: "#555", fontSize: "14px" }}>
                  {entry.name}
                </span>
              </li>
              <div style={{ display: "flex" }}>
                <h4 style={{ fontSize: "20px", fontWeight: 600 }}>
                  {entry.number}
                </h4>
                <p
                  style={{
                    marginLeft: "10px",
                    color: "#828282",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {entry.value}%
                </p>
              </div>
            </div>
          );
        })}
      </ul>
    );
  };

  const renderCustomizedLabel = ({ suppliers, x, y }) => {
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor="middle"
        dominantBaseline="10"
        fontSize={12}
        fontWeight={600}
      >
        {suppliers}
      </text>
    );
  };

  return (
    <Card>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 style={{ fontSize: "18px", margin: "25px" }}>
            Supplier Spend (2023-24) in Cr. INR by category
          </h3>
          <div
            style={{
              margin: "18px 10px 18px 10px",
              display: "flex",
            }}
          >
            <div
              className="buttons"
              style={{
                background: "#F0F2F4",
                borderRadius: "3px",
                width: "4.5rem",
                marginLeft: "10px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                  padding: "6px",
                  color: "black",
                  border: "0px",
                  marginRight: "4px",
                }}
                onClick={() => {
                  setActiveMode(false);
                }}
              >
                <i className="pi pi-table fs-19" />
              </Button>
              <Button
                style={{
                  background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                  padding: "6px",
                  color: "black",
                  border: "0px",
                }}
                onClick={() => {
                  setActiveMode(true);
                }}
              >
                <i className="pi pi-chart-bar fs-19" />
              </Button>
            </div>
            <div ref={menuRef}>
              <Button
                style={{
                  color: "black",
                  height: "30px",
                  marginLeft: "3px",
                  background: "#F0F2F4",
                  border: "0px",
                  padding: "6px",
                  position: "relative",
                }}
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                <i className="pi pi-angle-down fs-19" />
              </Button>
              {dropdownOpen && (
                <Menu
                  model={panelItems}
                  style={{
                    position: "absolute",
                    right: 45,
                    zIndex: "1",
                    padding: 0,
                  }}
                ></Menu>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <CustomLegend position="left" />
          </div>

          {/* Pie Chart */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "0px",
              marginBottom: "1px",
              marginRight: "25px",
              marginLeft: "25px",
            }}
          >
            <PieChart width={600} height={400}>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label={renderCustomizedLabel}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "225px",
                marginTop: "1px",
              }}
            >
              <span>Total Spend 2023 - 24</span>
              <h5
                style={{
                  fontWeight: 700,
                  fontSize: "24px",
                  marginRight: "70px",
                }}
              >
                {initialData
                  .reduce((sum, item) => sum + (item?.number || 0), 0)
                  .toFixed(2)}
                {/* cr INR */}
              </h5>
            </div>
          </div>

          <div>
            <CustomLegend position="right" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SecondPieDemo;
