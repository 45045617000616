import React, { useState, useEffect } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AttachmentComponent } from "../../../../components/Attachment";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useSelector } from "react-redux";

const TakeActionSupplier = ({ data, refresh }) => {
    const [rootCause, setRootCause] = useState("");
    const [correctiveAction, setCorrectiveAction] = useState("");
    const [actionDesc, setActionDesc] = useState("");
    const [document, setDocument] = useState([]);
    const [applicableLaw, setApplicableLaw] = useState('')
    const vendorCode = useSelector((state) => state.user.currentVendorCode);
    const [errors, setErrors] = useState({}); // Validation errors

    const [lastAction, setLastAction] = useState([])
    console.log(data)
    const quillStyle = { minHeight: "120px" };

    useEffect(() => {
        setLastAction(data.supplierActionHistories ? data.supplierActionHistories[data.supplierActionHistories.length - 1] : [])
    }, [data])

    const nonComplianceOptions = [
        { label: "Regulatory (Major)", id: 1 },
        { label: "Regulatory (Minor)", id: 2 },
        { label: "Minor", id: 3 },
    ];


    const nonComplianceLabel = nonComplianceOptions.find(option => option.id === data.nonComplianceType)?.label || "Unknown";


    useEffect(() => {
        APIServices.get(API.GetList_DD(2))
            .then((res) => {
                if (res && res.data && Array.isArray(res.data)) {
                    const foundLaw = res.data.find((law) => law.id === data.applicableLaw);
                    setApplicableLaw(foundLaw ? foundLaw.title : "Not Available");
                } else {
                    setApplicableLaw("Not Available");
                }
            })
            .catch((error) => {
                console.error("Error fetching applicable law:", error);
                setApplicableLaw("Error fetching data");
            });
    }, [data.applicableLaw]);

    // **Validation before submission**
    const validateFields = () => {
        let newErrors = {};
        if (!rootCause.trim()) newErrors.rootCause = "Root cause is required.";
        if (!correctiveAction.trim()) newErrors.correctiveAction = "Corrective action is required.";
        if (!actionDesc.trim()) newErrors.actionDesc = "Action description is required.";
        if (document.length === 0) newErrors.document = "You must upload at least one document.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const submitTakeAction = () => {
        if (!validateFields()) return; // Prevent submission if validation fails

        console.log("Submitting data:", { rootCause, correctiveAction, actionDesc, document });
        // Proceed with API submission logic here...
        const fileNames = document.map(file => file.originalname);
        try {
            APIServices.patch(API.SupplierAction_Edit(data.id), {

                type: 2,
                reject: 0
            })
            APIServices.post(API.History_SupplierAction(data.id), {
                rootCause: rootCause,
                correctiveAction: correctiveAction,
                actionTaken: actionDesc,
                supplierAttachments: document,
                supplier_submitted_by: vendorCode.id,
                supplier_submitted_on: new Date().toISOString(),
                vendorId: vendorCode.id,
                type: 2,
                reject: 0
            })
        } catch {

        } finally {
            refresh()
        }

    };

    useEffect(() => {
        console.log(document)
    }, [document])

    return (
        <div className="container-fluid">
            {/* Action Details */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Observation</h4>
                <Divider />
                <div className="row">
                    <div className="col-md-6">
                        <p><strong>ID:</strong> {data.actionId}</p>
                        <p><strong>Findings:</strong> {data.finding}</p>
                        <p><strong>Description:</strong> {data.description}</p>
                    </div>
                    <div className="col-md-6">
                        <p><strong>Non-Compliance Type:</strong> {nonComplianceLabel}</p>
                        <p><strong>Due Date:</strong> {data.dueDate}</p>
                        <p><strong>Applicable Law:</strong> {applicableLaw}</p>
                    </div>
                </div>
            </Card>
            {Object.keys(lastAction).length !== 0 &&
                <Card className="mb-4 shadow-sm">
                    <h4 className="mb-3 clr-navy">Comments</h4>
                    <Divider />

                    <div className="row">
                        <div>
                            {/* <p><strong>Root Cause:</strong></p>
                            <div dangerouslySetInnerHTML={{ __html: lastAction.rootCause }} />

                            <p><strong>Corrective Action:</strong></p>
                            <div dangerouslySetInnerHTML={{ __html: lastAction.correctiveAction }} /> */}

                            {/* <p><strong>Action Taken:</strong></p>
                            <div dangerouslySetInnerHTML={{ __html: lastAction.actionTaken }} /> */}
                        </div>


                        <p className="mt-4" style={{ color: 'red' }}><strong>Approver Comments: </strong> {lastAction.approverComments
                        }</p>
                    </div>


                </Card>
            }
            {/* Input Fields */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Analysis & Action (To be filled by Supplier)</h4>
                <Divider />

                {/* Identify the Root Cause(s) */}
                <div className="mb-4">
                    <h5 className="text-secondary">Identify the Root Cause(s)</h5>
                    {data.type == 1 ? <ReactQuill value={rootCause} onChange={setRootCause} className="form-control p-0 border" style={quillStyle} />
                        : <div
                            className="border p-2"
                            style={{ backgroundColor: "#f8f9fa" }}
                            dangerouslySetInnerHTML={{ __html: lastAction?.rootCause }}
                        />}
                    {errors.rootCause && <Message severity="error" text={errors.rootCause} />}
                </div>

                {/* Identified Corrective Actions */}
                <div className="mb-4">
                    <h5 className="text-secondary">Identified Corrective Actions</h5>
                    {data.type == 1 ? <ReactQuill value={correctiveAction} onChange={setCorrectiveAction} className="form-control p-0 border" style={quillStyle} />
                        : <div
                            className="border p-2"
                            style={{ backgroundColor: "#f8f9fa" }}
                            dangerouslySetInnerHTML={{ __html: lastAction?.correctiveAction }}
                        />}

                    {errors.correctiveAction && <Message severity="error" text={errors.correctiveAction} />}
                </div>

                {/* Description of the Action Taken */}
                <div className="mb-4">
                    <h5 className="text-secondary">Description of the Action Taken</h5>
                    {data.type === 1 ? <ReactQuill value={actionDesc} onChange={setActionDesc} className="form-control p-0 border" style={quillStyle} />
                        : <div
                            className="border p-2"
                            style={{ backgroundColor: "#f8f9fa" }}
                            dangerouslySetInnerHTML={{ __html: lastAction?.actionTaken }}
                        />}
                    {errors.actionDesc && <Message severity="error" text={errors.actionDesc} />}
                </div>
            </Card>

            {/* Attachment */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Upload Evidence</h4>
                <Divider />
                <AttachmentComponent mandatory={true} edit={data.type === 1 ? 1 : 0} getFiles={(e) => setDocument(e)} documents={data.type === 1 ? document : (lastAction?.supplierAttachments || [])} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                {errors.document && <Message severity="error" text={errors.document} />}
            </Card>

            {/* Submit Button */}
            {data.type === 1 && <div className="flex justify-content-end mt-3">
                <Button label="Submit" icon="pi pi-check" onClick={submitTakeAction} className="p-button-primary" />
            </div>}
        </div>
    );
};

export default TakeActionSupplier;
